import { useAppSelector } from '../../../../../../App/store';
import { getFoldersState } from '../../../../../folders/foldersSlice';
import UrbanismeContent from './components/urbanisme/UrbanismeContent';

export default function UrbanismeFolder() {
  const { folderRightPanelDatas } = useAppSelector(getFoldersState);

  return (
    <div className="urbanisme-body">
      <h2>Information d’urbanisme</h2>
      <UrbanismeContent
        plot={folderRightPanelDatas.result?.urbanismeTab?.plot}
        folderPlotZones={folderRightPanelDatas.result?.urbanismeTab?.folderPlotZones}
      />
    </div>
  );
}
