import { useAppSelector } from '../../../App/store';
import { getCompanyState } from '../companySlice';

function useSubscriptionAccess() {
  const { subscription } = useAppSelector(getCompanyState);

  const isUrbaneaseOnly = subscription
    ? !subscription?.isPdfLandAccess &&
      !subscription?.hasPdfLandAccess &&
      !subscription?.isPdfInterfaceAccess
    : false;
  const isUrbaneaseAndLandPdf = subscription?.hasPdfLandAccess ?? false;
  const isPdfLandOnly = subscription?.isPdfLandAccess ?? false;
  const isPdfInterfaceOnly = subscription?.isPdfInterfaceAccess ?? false;

  return {
    isUrbaneaseOnly,
    isUrbaneaseAndLandPdf,
    hasUrbaneaseAccess: isUrbaneaseOnly || isUrbaneaseAndLandPdf,
    isPdfLandOnly,
    hasPdfLandAccess: isUrbaneaseAndLandPdf || isPdfLandOnly,
    isPdfInterfaceOnly,
  };
  // return {
  //   isUrbaneaseOnly: false,
  //   isUrbaneaseAndLandPdf: false,
  //   hasUrbaneaseAccess: false,
  //   isPdfLandOnly: true,
  //   hasPdfLandAccess: true,
  //   isPdfInterfaceOnly: false,
  // };
}

export default useSubscriptionAccess;
