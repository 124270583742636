import { useLocation } from 'react-router';
import { useAppSelector } from '../../../App/store';

import Alert from '../../../shared/components/modals/Alert';
import AnnouncementModal from '../../../shared/components/modals/announcementModal/AnnouncementModal';
import DeleteStudyModal from '../../../shared/components/modals/deleteStudyModal/DeleteStudyModal';
import RecursiveFolderStatusModal from '../../../shared/components/modals/RecursiveSubFolderModal';
import UploadImageModal from '../../../shared/components/modals/uploadModal/UploadImageModal';
import MultiPlotSelectSynthesis from '../../../shared/components/multiPlotSelectSynthesis/MultiPlotSelectSynthesis';
import AdvancedSearchModal from '../../advancedPlotSearch/components/advancedPlotSearchModal/AdvancedSearchModal';
import BodaccModal from '../../advancedPlotSearch/components/bodaccModal/BodaccModal';
import AuthErrorModal from '../../auth/components/AuthErrorModal';
import ConnexionProcessModal from '../../auth/components/connectionProcess/ConnexionProcessModal';
import ExpiredSubscriptionModal from '../../auth/components/ExpiredSubscriptionModal';
import ChooseCompanyModal from '../../company/components/chooseCompanyModal/ChooseCompanyModal';
import SearchCompanyNameModal from '../../dashboardOwnerDatas/components/searchCompanyNameModal/SearchCompanyNameModal';
import AreaChangeConfirmationModal from '../../externalPdf/landPDF/components/AreaChangeConfirmationModal';
import DeleteFolderModal from '../../folders/components/DeleteFolderModal';
import EditFolderModal from '../../folders/components/editFolderModal/EditFolderModal';
import CesiumHelperModal from '../../map/components/cesium/CesiumHelperModal';
import AltimetryHelperModal from '../../map/plugins/altimetryPlugin/AltimetryHelperModal';
import DrawHelperModal from '../../map/plugins/drawPlugin/DrawHelperModal';
import MeasureHelperModal from '../../map/plugins/measurePlugin/components/MeasureHelperModal';
import MultiSelectPlotModal from '../../multiPlotSelectFeature/components/multiSelectPlotModal/MultiSelectPlotModal';
import { getMultiPlotSelectState } from '../../multiPlotSelectFeature/multiPlotSelectSlice';
import AskPluModal from '../../plu/components/askPluModal/AskPluModal';
import DeleteProcedureModal from '../../procedures/components/deleteProcedureModal/DeleteProcedureModal';
import DeleteSectorModal from '../../sectors/components/DeleteSectorModal';
import EditSectorModal from '../../sectors/components/editSectorModal/EditSectorModal';
import StudyContactModal from '../../study/components/studyContact/rightPanelContactBloc/StudyContactModal';
import ProcedureDeleteConfirmationModal from '../../study/components/studyProcedure/rightPanelProcedureBloc/ProcedureDeleteConfirmationModal';
import ReadMoreModal from '../../subscription/components/readMoreModal/ReadMoreModal';
import DeleteUrbaGptModal from '../../urbaGpt/components/deleteUrbaGptModal/DeleteUrbaGptModal';
import ErrorTokenUrbaGptModal from '../../urbaGpt/components/errorTokenUrbaGptModal/ErrorTokenUrbaGptModal';
import UrbaGpt from '../../urbaGpt/components/urbaGpt/UrbaGpt';
import { getModalsState } from '../modalsSlice';

export interface IModalProps {
  open: boolean;
  value?: string;
  callback?: nullable<(sien: string) => void>;
}

const ModalContainer = (): JSX.Element => {
  const {
    sectorEdit,
    sectorDelete,
    folderDelete,
    folderEdit,
    companyChange,
    procedureDelete,
    connectionProcess,
    advancedSearchResult,
    companySearch,
    multiPlotAdd,
    recusiveAssignForSubfolder,
    pluDemand,
    plotDelete,
    readMore,
    alert,
    measureHelperModal,
    announcement,
    urbaGptDelete,
    drawHelperModal,
    altimetryHelperModal,
    errorTokenUrbaGptModal,
    loadProspecDataConfirm,
    expiredSubscriptionModal,
    authErrorModal,
    bodaccModal,
    studyContactModal,
    cesiumHelperModal,
    uploadImageModal,
    deleteStudyProcedureConfirmationModal,
  } = useAppSelector(getModalsState);
  const { isMultiPlotSelector } = useAppSelector(getMultiPlotSelectState);

  const { pathname } = useLocation();

  return (
    <>
      {alert.status && <Alert context={alert.context} modalType={alert.modalType} />}
      {procedureDelete && <DeleteProcedureModal open={procedureDelete} />}
      {folderDelete && <DeleteFolderModal />}
      {sectorDelete && <DeleteSectorModal />}
      {folderEdit && <EditFolderModal open={folderEdit} />}
      {sectorEdit && <EditSectorModal />}
      {plotDelete && <DeleteStudyModal open={plotDelete} />}
      {advancedSearchResult.status && (
        <AdvancedSearchModal {...advancedSearchResult} />
      )}
      {bodaccModal && <BodaccModal />}
      {companySearch && <SearchCompanyNameModal open={companySearch} />}
      {multiPlotAdd.status && (
        <>
          <MultiSelectPlotModal plots={multiPlotAdd.plots} />
        </>
      )}
      {readMore.status && <ReadMoreModal type={readMore.type} />}
      {companyChange && <ChooseCompanyModal />}
      {recusiveAssignForSubfolder && <RecursiveFolderStatusModal />}
      {pluDemand && <AskPluModal />}
      {connectionProcess && <ConnexionProcessModal />}
      {announcement && <AnnouncementModal open={announcement} />}
      {urbaGptDelete && <DeleteUrbaGptModal open={urbaGptDelete} />}
      {errorTokenUrbaGptModal && <ErrorTokenUrbaGptModal />}
      {drawHelperModal && <DrawHelperModal />}
      {measureHelperModal && <MeasureHelperModal />}
      {altimetryHelperModal && <AltimetryHelperModal />}
      {loadProspecDataConfirm.display && <AreaChangeConfirmationModal />}
      {expiredSubscriptionModal && <ExpiredSubscriptionModal />}
      {authErrorModal && <AuthErrorModal />}
      {isMultiPlotSelector && <MultiPlotSelectSynthesis />}
      {studyContactModal && <StudyContactModal />}
      {cesiumHelperModal && pathname === '/3d-view' && <CesiumHelperModal />}
      {uploadImageModal.isOpen && <UploadImageModal />}
      {deleteStudyProcedureConfirmationModal.display && (
        <ProcedureDeleteConfirmationModal />
      )}
      <UrbaGpt />
    </>
  );
};

export default ModalContainer;
