import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import styles from './advancedPlotSearchModal.module.scss';
function LoadingContent() {
  return (
    <div className={styles.loadingContent}>
      <h3 className={styles.title}>En cours de chargement ...</h3>
      <IndividualCircularLoader size={100} />
    </div>
  );
}

export default LoadingContent;
