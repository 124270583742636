import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../../shared/components/modals/genericModal/GenericModal';
import { getModalsState, modalsActions } from '../../../../modals/modalsSlice';
import deleteStudyProcedureThunk from '../../../services/thunks/deleteStudyProcedureThunk';

function ProcedureDeleteConfirmationModal() {
  const { deleteStudyProcedureConfirmationModal } = useAppSelector(getModalsState);
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(
      modalsActions.deleteStudyProcedureConfirmationModal({
        display: false,
        idIri: null,
      })
    );
  };

  const handleOkCallback = async () => {
    dispatch(
      deleteStudyProcedureThunk({
        procedureIdIri: deleteStudyProcedureConfirmationModal.idIri,
      })
    );
  };

  return (
    <GenericModal
      title="Confirmation de suppression"
      actionType={GenericModalActionEnum.OKCANCEL}
      modalType={GenericModalEnum.WARNING}
      okButtonContent="Supprimer"
      okCallback={handleOkCallback}
      cancelCallback={handleCloseModal}
      closeModal={handleCloseModal}
    >
      <p>Voulez vous vraiment supprimer cette démarche entreprise?</p>
    </GenericModal>
  );
}

export default memo(ProcedureDeleteConfirmationModal);
