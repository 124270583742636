import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import { getNationalServitudesState } from '../nationalServitudesSlice';

function NationalServitudesLayer() {
  const { servitudesDisplayed } = useAppSelector(getNationalServitudesState);

  return (
    <>
      {servitudesDisplayed.map((servitude) => (
        <WMSTileLayer
          key={nanoid()}
          url="https://data.geopf.fr/wms-v/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities"
          params={{
            layers: servitude,
            version: '1.3.0',
            format: 'image/png',
            transparent: true,
            // height: 256,
            // width: 256,
          }}
          zIndex={100}
        />
      ))}
    </>
  );
}

export default memo(NationalServitudesLayer);
