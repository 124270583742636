import { Path, Svg } from '@react-pdf/renderer';

const ArrowGreenSvg = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 13L6 9H14L10 13Z"
      fill="#73CF77"
    />
  </Svg>
);

export default ArrowGreenSvg;
