import { nanoid } from '@reduxjs/toolkit';
import { FC, memo } from 'react';
import { TileLayer } from 'react-leaflet';
import usePLU from '../../../../shared/hooks/usePLU';
import PmTilesPlu from '../../../plu/components/pmTiles/PmTilesPlu';
import PmTilesPluOther from '../../../plu/components/pmTiles/PmTilesPluOther';

const PluLayer: FC = memo(() => {
  const { pluOthers, pluDisplayed, pluMapbox, pluPmtiles, pluOthersPmtilesUrl } =
    usePLU();

  // display only if pmtiles url is null
  const canDisplayMapboxPlu = pluDisplayed && pluMapbox && !pluPmtiles;

  return (
    <>
      {canDisplayMapboxPlu && (
        <TileLayer maxZoom={22} opacity={0.6} url={pluMapbox || ''} zIndex={2} />
      )}
      <PmTilesPlu />
      {!pluOthersPmtilesUrl &&
        pluOthers
          ?.map((elt) => elt.childs)
          .flat(1)
          ?.filter((e) => e?.displayed === true)
          .map((plu) => {
            return (
              <TileLayer
                key={nanoid()}
                maxZoom={22}
                opacity={0.6}
                zIndex={2}
                url={plu?.mapboxUrl ?? ''}
              />
            );
          })}
      <PmTilesPluOther />
    </>
  );
});

PluLayer.displayName = 'PluLayer';
export default PluLayer;
