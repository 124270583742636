import { isEmpty } from 'lodash';
import PieGraph from './PieGraph';

interface IPieGraphBlocProps {
  plotStatuses: ReportingUserIndividualPlotsStatus[];
}
function PieGraphBloc({ plotStatuses }: IPieGraphBlocProps): JSX.Element {
  const labelsPie = plotStatuses?.map(
    (elt: ReportingUserIndividualPlotsStatus) => elt.label
  );

  const datasetsPie = [
    {
      data: plotStatuses,
      backgroundColor: plotStatuses?.map(
        (elt: ReportingUserIndividualPlotsStatus) => elt.color
      ),
    },
  ];

  return (
    <div className="pie-graph">
      <h4>Répartition opportunités par statut:</h4>
      {plotStatuses && !isEmpty(labelsPie) ? (
        <PieGraph labels={labelsPie} datasets={datasetsPie} />
      ) : (
        <p>Aucune opportunités pour les 12 derniers mois</p>
      )}
    </div>
  );
}

export default PieGraphBloc;
