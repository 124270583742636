import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../App/store';
import { urbaGptActions } from '../urbaGptSlice';
import { urbaGptQueryThunk } from '../urbaGptThunk';
import styles from './urbaGpt/urbaGpt.module.scss';

function UrbaGptTutorial(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClick = (question: string) => {
    dispatch(
      urbaGptActions.setConversation({
        conversationUuid: '',
        datelog: new Date().toLocaleDateString(),
        id: 0,
        question: question,
        response: '',
        isAnim: false,
      })
    );
    dispatch(urbaGptQueryThunk({ question }));
  };

  const questions = [
    {
      question:
        "Quel est l'impact de la loi Climat et Résilience sur le secteur de l'immobilier en France?",
    },
    {
      question: 'Comment devenir un bon prospecteur immobilier?',
    },
    {
      question:
        "Pourrais tu m'écrire un courrier type pour démarcher des biens en prospection immobilière?",
    },
  ];

  return (
    <div className={styles.urbaGptTutorial}>
      <p>Consignes d&apos;utilisation:</p>
      <ul>
        <li>Formulez clairement votre question/sujet.</li>
        <li>
          Entrez-le dans <strong>UrbaGPT</strong> et appuyez sur Entrée.
        </li>
        <li>
          Si besoin, affinez votre demande jusqu&apos;à obtenir une réponse
          satisfaisante.
        </li>
        <li>
          Merci de ne formuler que des questions en relation avec les problématiques
          immobilières
        </li>
      </ul>
      <p>
        Écrivez votre question, ou cliquez sur l’une des questions ci-dessous pour
        parler à UrbaGPT
      </p>
      {questions.map((elt) => (
        <button
          key={nanoid()}
          className={styles.question}
          onClick={() => handleClick(elt.question)}
        >
          {elt.question}
        </button>
      ))}
    </div>
  );
}

export default UrbaGptTutorial;
