import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { PDF_COLOR_PRIMARY } from '../../../shared/constants';
import AreaIconSvg from './svg/AreaIconSvg';
import FloorCountIconSvg from './svg/FloorCountIconSvg';
import GoodTypoIconSvg from './svg/GoodTypoIconSvg';
import LandAreaIconSvg from './svg/LandAreaIconSvg';
import RoomCountIconSvg from './svg/RoomCountIconSvg';
export enum IconTypeEnum {
  ROOM = 'ROOM',
  FLOOR = 'FLOOR',
  HOUSE = 'HOUSE',
  AREA = 'AREA',
  LANDAREA = 'LANDAREA',
}
interface IFoodDetailProps {
  label: string;
  value: string | number;
  icon: IconTypeEnum;
}

const styles = StyleSheet.create({
  goodDetail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  },
  detailIconContainer: {
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textBold: {
    fontWeight: 700,
  },
  detailIcon: {
    width: '23px',
  },
  line: {
    margin: '0 10px',
    height: '100%',
    border: '1px solid ' + PDF_COLOR_PRIMARY,
  },
});

function GoodDetail(props: IFoodDetailProps) {
  return (
    <View style={styles.goodDetail}>
      <View style={styles.detailIconContainer}>
        {props.icon === IconTypeEnum.FLOOR && <FloorCountIconSvg />}
        {props.icon === IconTypeEnum.AREA && <AreaIconSvg />}
        {props.icon === IconTypeEnum.LANDAREA && <LandAreaIconSvg />}
        {props.icon === IconTypeEnum.HOUSE && <GoodTypoIconSvg />}
        {props.icon === IconTypeEnum.ROOM && <RoomCountIconSvg />}
        {/* <Image src={getLogo(props.icon)} style={styles.detailIcon} /> */}
      </View>
      <View style={styles.line}></View>
      <View style={styles.textContainer}>
        <Text>{props.label + ' : '}</Text>
        <Text style={styles.textBold}>{props.value}</Text>
      </View>
    </View>
  );
}

export default GoodDetail;
