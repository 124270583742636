import * as L from 'leaflet';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { getMapState, mapActions } from '../../../mapSlice';
import '../../lib/leafletAreaMeasure/leaflet-measure';
import '../../lib/leafletAreaMeasure/leafletMeasure.scss';
import '../../lib/leafletPolylineMeasure/Leaflet-PolylineMeasure.js';
import '../../lib/leafletPolylineMeasure/Leaflet-PolylineMeasure.scss';
import polylineOptions from '../../lib/leafletPolylineMeasure/options';
import { getMapPluginsMeasureState, mapPluginsActions } from '../../mapPluginSlice';
import { measureOptions } from '../constants';
import { MeasureActionEnum } from '../types/measureActionEnum';

function MeasureLayer() {
  const [surfaceControl, setSurfaceControl] = useState<any | null>(null);
  const [lineControl, setLineControl] = useState<any | null>(null);
  const { action, lineActivated, datas } = useAppSelector(getMapPluginsMeasureState);
  const { franceLayerDisplay } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();

  const removeLayers = () => {
    const classesToRemove = [
      'leaflet-measure-resultpopup',
      'layer-measure-resultline',
      'layer-measure-resultarea',
    ];
    const layers: any[] = [];
    map.eachLayer((l: any) => {
      if (classesToRemove.includes(l.options.className)) {
        layers.push(l);
      }
    });
    if (!isEmpty(layers)) {
      layers.forEach((layer) => map.removeLayer(layer));
    }
  };

  const map = useMapEvents({
    async click(e: any) {
      if (e.lastCoord) {
        const parsed = {
          area: e.area,
          length: e.length,
          lastCoord: e.lastCoord,
        };
        dispatch(mapPluginsActions.measureDataAdd(parsed));
      }
    },
  });

  useEffect(() => {
    // create event handlers
    if (map) {
      (map as any).on('polylinemeasure:start', (e: any) => {
        dispatch(mapPluginsActions.measureActionSet(MeasureActionEnum.START_LINE));
      });
      (map as any).on('polylinemeasure:add', (e: any) => {});
      (map as any).on('polylinemeasure:finish', (e: any) => {
        dispatch(mapPluginsActions.measureActionSet(null));
      });
    }
  }, []);

  useEffect(() => {
    if (map) {
      if (!surfaceControl) {
        const surfaceControl = new (L.Control as any).Measure(measureOptions);
        setSurfaceControl(surfaceControl);
        surfaceControl.addTo(map);
      }

      if (!lineControl) {
        const polylineMeasureControl = new (L.control as any).polylineMeasure(
          polylineOptions
        );
        setLineControl(polylineMeasureControl);
        polylineMeasureControl.addTo(map);
      }
    }
  }, []);

  useEffect(() => {
    if (lineControl) {
      if (lineActivated) {
        franceLayerDisplay && dispatch(mapActions.franceLayerDisplaySet(false));
        lineControl?._toggleMeasure();
      } else {
        // lineControl?._clearAllMeasurements();
        lineControl?._measuring && lineControl?._toggleMeasure();
        dispatch(mapPluginsActions.measureLineActivate(false));
      }
    }
  }, [lineActivated, lineControl]);

  useEffect(() => {
    switch (action) {
      case MeasureActionEnum.START_DRAW_SURFACE:
        franceLayerDisplay && dispatch(mapActions.franceLayerDisplaySet(false));
        surfaceControl?._startMeasure();
        break;
      case MeasureActionEnum.END_DRAW_SURFACE:
        surfaceControl._handleMeasureDoubleClick();
        dispatch(mapPluginsActions.measureActionSet(null));
        break;
      case MeasureActionEnum.RESET_SURFACES:
        removeLayers();
        dispatch(mapPluginsActions.measureActionSet(null));
        break;
      case MeasureActionEnum.CANCEL_DRAW_SURFACE:
        surfaceControl._finishMeasure();
        dispatch(mapPluginsActions.measureActionSet(null));
        break;
      case MeasureActionEnum.RESET_LINES:
        if (lineControl) {
          lineControl?._clearAllMeasurements();
          dispatch(mapPluginsActions.measureActionSet(null));
        }
        break;
      default:
        break;
    }
  }, [action]);

  return null;
}

export default MeasureLayer;
