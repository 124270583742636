interface IQuestion {
  question: string;
  response: JSX.Element;
}

interface IVideo {
  title: string;
  url: string;
}

export const questions: IQuestion[] = [
  {
    question:
      'Je suis sur le terrain et je n’arrive pas à avoir d’information sur l’application mobile',
    response: (
      <div>
        <p>
          L’application mobile nécessite une connexion internet minimum pour
          fonctionner. Elle risque donc de ne pas fonctionner dans des zones peu ou
          pas couvertes en réseau mobile.
        </p>
        <p>
          Il se peut également que vous ne soyez tout simplement pas ou plus connecté
          à votre compte sur votre téléphone portable.
        </p>
      </div>
    ),
  },
  {
    question:
      'Je n’ai pas d’information sur une commune, et/ou les calques ne s’affichent pas',
    response: (
      <div>
        <p>
          Sur Urbanease, il faut toujours avoir renseigné quelque chose dans la barre
          de recherche (Ville, rue, adresse, numéro de parcelle…) pour générer de
          l’information sur la commune en question. Cela ne fonctionnera donc pas si
          l’on se contente de zoomer directement sur la ville sans au préalable avoir
          rentré le nom de cette ville dans la barre de recherche.
        </p>
        <p>
          Si vous avez bien renseigné une adresse dans la barre de recherche et qu’il
          n’y a toujours pas d’information sur la ville en question, peut-être que
          celle-ci n’est pas comprise dans votre abonnement. Pour rajouter ou
          remplacer des villes de votre abonnement, merci d’envoyer un mail à{' '}
          <a href="mailto: contact@urbanease.fr">contact@urbanease.fr</a>
        </p>
        <p>
          Si votre ville est bien dans votre abonnement et que vous l’avez renseignée
          dans la barre de recherche, il se peut que vous soyez tout simplement
          déconnecté(e) de la plateforme.{' '}
        </p>
        <p>
          Si ce n’est pas le cas et que vous êtes sur ordinateur, essayez de vider le
          cache.
        </p>
        <p>
          En dernier recours il est tout simplement possible qu’Urbanease n’ait pas
          récupéré les informations et documents d’urbanisme pour cette commune. Pour
          en demander la récupération dans le cadre de votre abonnement, il suffit
          d’en faire la demande par mail à{' '}
          <a href="mailto: contact@urbanease.fr">contact@urbanease.fr</a>.
        </p>
      </div>
    ),
  },
  {
    question:
      'Je clique sur « demander coordonnées propriétaires » mais rien ne se passe ou la mauvaise boîte mail est utilisée',
    response: (
      <div>
        <p>
          Pour demander les coordonnées propriétaires, Urbanease utilise la boîte
          mail définie par défaut sur votre ordinateur. Si cela ne fonctionne pas,
          c’est qu’il n’y a pas de boîte mail définie par défaut sur votre
          ordinateur.
        </p>
        <p>
          La solution est donc de changer votre boîte mail par défaut et d’ajouter
          votre adresse mail professionnelle ou personnelle à cette boîte (ex : boîte
          gmail).
        </p>
        <p>
          Voici des guides pour vous aider à configurer votre boîte mail par défaut :
        </p>
        <p>
          Pour un ordinateur Windows:{' '}
          <a
            href="https://fr.officetooltips.com/office_2016/tips/comment_modifier_le_client_de_messagerie_par_defaut_dans_windows_10.html "
            target="_blank"
            rel="noopener noreferrer"
          >
            https://fr.officetooltips.com/office_2016/tips/comment_modifier_le_client_de_messagerie_par_defaut_dans_windows_10.html
          </a>
        </p>
        <p>
          Pour un ordinateur Mac:{' '}
          <a
            href="https://support.apple.com/fr-fr/HT201607"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.apple.com/fr-fr/HT201607
          </a>
        </p>
      </div>
    ),
  },
  {
    question:
      'Je sélectionne une parcelle, mais l’adresse renseignée sur Urbanease ne correspond pas exactement à l’adresse postale dont j’ai connaissance ?',
    response: (
      <p>
        Cela peut arriver car l’adresse que nous renseignons est celle du centre
        géographique de la parcelle sélectionnée. Or, il y a parfois une légère
        différence entre l’adresse cadastrale, l’adresse postale, et l’adresse du
        centre de la parcelle.
      </p>
    ),
  },
  {
    question:
      'Je ne trouve pas d’information sur les propriétaires des permis de construire délivrés à des particuliers ?',
    response: (
      <p>
        La loi RGPD interdit à l’administration de communiquer des données
        administratives et fiscales sur des personnes physiques. Il nous est donc
        impossible de récupérer ou de communiquer les informations personnelles des
        déposants. Il est donc normal que cette donnée ne s’affiche pas.
      </p>
    ),
  },
  {
    question:
      ' Lorsque je rentre une adresse, la carte me montre bien la ville en question, mais je ne vois pas le cadastre s’afficher ?',
    response: (
      <p>
        Le plan du cadastre étant un document lourd à charger, il arrive qu’il mette
        un peu de temps à apparaître. Pour parer à cela il suffit de faire un
        double-clic sur la carte afin de le voir apparaitre. Si jamais le problème
        persiste, merci d’en référer par mail à{' '}
        <a href="mailto: contact@urbanease.fr">contact@urbanease.fr</a>.
      </p>
    ),
  },
  {
    question:
      'Lorsque je lance une « Recherche et Calculs » j’ai l’impression que le nombre de résultat affiché sur la carte est limité ?',
    response: (
      <p>
        Le nombre de résultat affiché est en effet limité à 200 parcelles
        concomitantes afin de limiter les exploitations abusives, ou mettant en péril
        le bon fonctionnement des serveurs de notre plateforme. Si vous avez une
        recherche très « large » qui suppose des très nombreux résultats sur une
        commune, nous vous conseillons de procéder par secteur grâce à l’outil de
        sélection d’un secteur de recherche sur la carte en traçant un polygone
        (tutoriel :{' '}
        <a
          href="https://vimeo.com/458458365/4a569a6264"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://vimeo.com/458458365/4a569a6264
        </a>
        )
      </p>
    ),
  },
  {
    question:
      'J’ai l’impression que tous les permis de construire délivrés qui sont présent dans le tableau, ne sont pas forcément sur la carte, pourquoi ?',
    response: (
      <p>
        En effet, pour certains permis de construire, l’information délivrée par
        l’administration ne nous permet pas de positionner un repère sur la carte car
        nous n’avons ni adresse, ni numéro d’une parcelle toujours existante
        (celles-ci ayant fait l’objet d’un nouveau document d’arpentage depuis la
        demande de l’autorisation d’urbanisme).
      </p>
    ),
  },
];

export const videos: IVideo[] = [
  {
    title: 'Urbanease - vidéo tuto Interface de la plateforme',
    url: 'https://youtu.be/zYntyOy7_No',
  },
  {
    title: 'Urbanease - vidéo tuto Informations Parcelle et Prospection',
    url: 'https://youtu.be/3IlST5-WuIs',
  },
  {
    title: 'Urbanease - vidéo tuto Recherche et Calculs',
    url: 'https://youtu.be/Jl4dT50yy08',
  },
  {
    title: 'Urbanease - vidéo tuto Ma Prospection dossiers & secteurs',
    url: 'https://youtu.be/x51x8n4NWO4',
  },
  {
    title: 'Urbanease - vidéo tuto Interface Dashboard',
    url: 'https://youtu.be/mLFMNHiUlEA',
  },
  {
    title: 'Urbanease - vidéo tuto Mon Compte',
    url: 'https://youtu.be/1Cow2hbB93g',
  },
  {
    title: 'Tips - compte/découpage lots/mesurage',
    url: 'https://player.vimeo.com/video/458471788?h=6c2598bf23',
  },
  {
    title: 'Tutoriel Publipostage - création des templates de courrier',
    url: 'https://youtu.be/6_avLt3cCjU',
  },
  {
    title: 'Tutoriel Publipostage - génération automatique des courriers',
    url: 'https://youtu.be/ts6oUx_s5fw',
  },
];
