import { useState } from 'react';
import ForgotPasswordForm from '../ForgotPasswordForm';
import LoginForm from '../loginForm/LoginForm';

import styles from './loginContent.module.scss';

export interface ILoginContentProps {
  isSignupModal?: boolean;
}

export default function LoginContent({ isSignupModal }: ILoginContentProps) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <div className={styles.loginContent}>
      <h3 className={isSignupModal ? '' : styles.blueTitle}>
        {showForgotPassword ? ' Réinitialisation du mot de passe ' : 'Se connecter'}
      </h3>

      {showForgotPassword ? (
        <ForgotPasswordForm setShowForgotPassword={setShowForgotPassword} />
      ) : (
        <LoginForm isSignupModal={isSignupModal} />
      )}

      <p
        className={styles.forgotPassword}
        onClick={() => setShowForgotPassword(!showForgotPassword)}
      >
        {showForgotPassword ? 'Annuler' : 'Mot de passe oublié?'}
      </p>
    </div>
  );
}
