import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import SelectCustom from '../../../../../shared/components/common/formComponents/SelectCustom';
import useMailshotsModels from '../../../hooks/useMailshotsModels';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
function MailshotModelSelect() {
  const { models, selectedModel, setSelectedModel, selectType } = useMailshotsModels(
    {}
  );

  // model filter on selectType value
  const filteredModels =
    models
      // display only active models
      ?.filter((f) => f.isActive)
      // display only useable type
      .filter((f) =>
        selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
          ? f.modelType === 'subfolder'
          : f.modelType === 'plot'
      ) ?? [];

  const handleModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selected = models?.find((f) => f.id === e.target.value) ?? null;
    setSelectedModel(selected);
  };

  return (
    <div className={styles.modelChoiceContainer}>
      <label>Modèle à utiliser</label>
      <SelectCustom
        items={filteredModels.map((m) => ({ value: m.id, display: m.name })) ?? []}
        value={isEmpty(filteredModels) ? '' : selectedModel?.id ?? ''}
        noValue="Choix du modèle"
        displayEmpty
        onChange={handleModelChange}
      />
    </div>
  );
}

export default MailshotModelSelect;
