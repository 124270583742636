import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getMapState } from '../../../../features/map/mapSlice';
import {
  fetchAssietteSupSThunk,
  fetchInfoPctThunk,
  fetchInfoSurfThunk,
  fetchPrescriptionLinThunk,
  fetchPrescriptionPctThunk,
  fetchScotThunk,
} from '../../plotServitudesThunks';
import styles from './plotServitudesContent.module.scss';

interface ILoadingErrorProps {
  type: 'ppct' | 'plin' | 'isurf' | 'ipct' | 'sups' | 'scot';
}
function LoadingError({ type }: ILoadingErrorProps) {
  const { plotDatas, mapCenter } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();
  const handleReloadClick = () => {
    if (plotDatas?.parcelleLayer?.parcelle) {
      switch (type) {
        case 'ppct':
          dispatch(
            fetchPrescriptionPctThunk({
              geo: JSON.parse(plotDatas?.parcelleLayer.parcelle),
            })
          );
          break;
        case 'plin':
          dispatch(
            fetchPrescriptionLinThunk({
              geo: JSON.parse(plotDatas?.parcelleLayer.parcelle),
            })
          );
          break;
        case 'isurf':
          dispatch(
            fetchInfoSurfThunk({
              geo: JSON.parse(plotDatas?.parcelleLayer.parcelle),
            })
          );
          break;
        case 'ipct':
          dispatch(
            fetchInfoPctThunk({ geo: JSON.parse(plotDatas?.parcelleLayer.parcelle) })
          );
          break;
        case 'sups':
          dispatch(
            fetchAssietteSupSThunk({
              geo: JSON.parse(plotDatas?.parcelleLayer.parcelle),
            })
          );
          break;
        case 'scot':
          dispatch(fetchScotThunk({ lat: mapCenter[0], lng: mapCenter[1] }));
          break;
        default:
          break;
      }
    }
  };
  return (
    <p className={styles.loadingError}>
      Information indisponible pour le moment.{' '}
      <a href="#" onClick={handleReloadClick}>
        relancer...
      </a>
    </p>
  );
}

export default LoadingError;
