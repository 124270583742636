import { isEmpty } from 'lodash';
import { useAppSelector } from '../../../../../../App/store';
import { getFoldersState } from '../../../../../../features/folders/foldersSlice';
import IndividualCircularLoader from '../../../../../../features/loaders/components/individualCircularLoader/IndividualCircularLoader';
import { APIStatus } from '../../../../../../services/axiosFiles/apiTypes';
import SyntheseFolderTable from './components/syntheseFolder/SyntheseFolderTable';

export default function SyntheseFolder() {
  const { folderRightPanelDatas } = useAppSelector(getFoldersState);

  if (folderRightPanelDatas.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={200} />;
  }

  return (
    <div className="synthese-plots">
      <h2>Synthèse des parcelles du sous-dossier</h2>

      <SyntheseFolderTable
        plotsData={folderRightPanelDatas.result?.syntheseTab.plotsData}
      />

      <h3>Total surfaces : </h3>
      {!isEmpty(folderRightPanelDatas.result?.syntheseTab.notFoundPlot) && (
        <p className="not-found-plot">
          Les parcelles suivantes n’existent plus depuis la dernière modification du
          cadastre. Elles seront par conséquent ré-attribuées prochainement sur les
          nouvelles parcelles à leur emplacement, et le calcul total ne prend pour
          l’instant pas en compte la surface de ces parcelles:
          <br />
          <span>
            {folderRightPanelDatas.result?.syntheseTab.notFoundPlot?.join(', ')}
          </span>
        </p>
      )}
      <p>
        Total parcelles cadastre :{' '}
        <span>
          {parseFloat(
            folderRightPanelDatas.result?.syntheseTab?.totalPlotArea?.toString() ||
              ''
          ).toFixed(2)}{' '}
          m²
        </span>
      </p>
      <p>
        Total emprise au sol bâti :{' '}
        <span>
          {parseFloat(
            folderRightPanelDatas.result?.syntheseTab?.totalUsedGroundArea?.toString() ||
              ''
          ).toFixed(2)}{' '}
          m²
        </span>
      </p>
      <p>
        Total emprise au sol libre de construction :{' '}
        <span>
          {parseFloat(
            folderRightPanelDatas.result?.syntheseTab?.totalFreeGroundArea?.toString() ||
              ''
          ).toFixed(2)}{' '}
          m²
        </span>
      </p>
    </div>
  );
}
