import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';

import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import { getAdvancedPlotSearchState } from '../../advancedPlotSearchSlice';
import { fetchAdvancedPlotSearchThunk } from '../../advancedPlotSearchThunks';
import styles from './advancedPlotSearchModal.module.scss';
import ErrorContent from './ErrorContent';
import FulfilledContent from './FulfilledContent';
import LoadingContent from './LoadingContent';

export default function AdvancedSearchModal({ context }: AdvancedPlotSearchModal) {
  const { plots, plotCount } = useAppSelector(getAdvancedPlotSearchState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (context) {
      dispatch(fetchAdvancedPlotSearchThunk({ ...context }));
    }
  }, []);

  const handleClose = () => {
    dispatch(modalsActions.advancedSearchResult({ status: false, context: null }));
  };

  return (
    <GenericModal
      modalType={
        plots.apiStatus === APIStatus.REJECTED
          ? GenericModalEnum.WARNING
          : GenericModalEnum.INFO
      }
      actionType={
        plots.apiStatus !== APIStatus.PENDING
          ? GenericModalActionEnum.OK
          : GenericModalActionEnum.NONE
      }
      hasIcon={plots.apiStatus === APIStatus.PENDING ? false : true}
      okButtonContent="OK"
      title="Recherche & Calculs"
      okCallback={handleClose}
      closeModal={handleClose}
    >
      <div className={styles.advancedPlotSearchModal}>
        {plots.apiStatus === APIStatus.PENDING && <LoadingContent />}
        {plots.apiStatus === APIStatus.REJECTED && <ErrorContent />}
        {plots.apiStatus === APIStatus.IDLE && (
          <FulfilledContent plotCount={plotCount} />
        )}
      </div>
    </GenericModal>
  );
}
