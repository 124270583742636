import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getMapState, mapActions } from '../../../../mapSlice';
import './rightBottom.scss';

const CadastreButton = () => {
  const { cadastreDisplay, isPmTilesLoading } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();

  const handleCadastre = (): void => {
    dispatch(mapActions.cadastreDisplaySet(!cadastreDisplay));
  };

  return (
    <div className="cadastre-buttons">
      <Button
        className={`btn ${cadastreDisplay ? 'selected' : ''}`}
        variant="contained"
        onClick={(): void => handleCadastre()}
        color={cadastreDisplay ? 'primary' : undefined}
        disabled={isPmTilesLoading}
      >
        {cadastreDisplay ? 'Afficher le cadastre' : 'Masquer le cadastre'}
      </Button>
    </div>
  );
};

export default CadastreButton;
