import { GeoJSON } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import MarkerBase from '../../map/components/leaflet/common/MarkerBase';
import { getBrownfieldsState } from '../brownfieldsSlice';
import BrownfieldPopup from './BrownfieldPopup';

const jsonStyle = {
  fillColor: '#D16002',
  weight: 3,
  color: '#D16002',
  fillOpacity: 0.4,
};

function BrownfieldsOnMap() {
  const { brownfields, brownfieldsVisible } = useAppSelector(getBrownfieldsState);

  return (
    <>
      {brownfieldsVisible &&
        brownfields.result?.map((m) => {
          return (
            <>
              <GeoJSON data={m.geometry} style={jsonStyle} />
              <MarkerBase markerLatLng={[m.lat, m.lng]} markerUrl="orange">
                <BrownfieldPopup content={m} />
              </MarkerBase>
            </>
          );
        })}
    </>
  );
}

export default BrownfieldsOnMap;
