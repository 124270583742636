import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import Logo from '../../../../assets/images/logo_urbanease_horizontal.svg';
import Xion from '../../../../assets/images/xicon-grey.png';
import { getModalsState, modalsActions } from '../../../modals/modalsSlice';
import './urbaneaseIntroModal.scss';

const UrbaneaseIntroModal = () => {
  const dispatch = useAppDispatch();
  const { urbaneaseIntro } = useAppSelector(getModalsState);

  const onCloseModal = () => {
    openSignUpModal();
  };

  const openSignUpModal = () => {
    dispatch(modalsActions.urbaneaseIntro(false));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={urbaneaseIntro}
      onClose={onCloseModal}
      aria-labelledby="urbanease-intro-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="intro-modal-container" data-cy="urba-intro-modal">
          <div className="xicon-wrapper" onClick={onCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <div className="content-wrapper">
            <img src={Logo} alt="logo" />
            <div className="title-description">
              Bienvenu sur Urbanease, la première plateforme pour tous les
              professionnels de l’immobilier, qui vous permet de récupérer toutes les
              informations d’une parcelle, simplement.
            </div>
            <div className="list-text-wrapper">
              <div className="list-items">
                <div className="digit">1</div>
                <div className="description">
                  Entrez une adresse ou la référence cadastrale du bien ou de la
                  parcelle qui vous intéresse puis cliquez sur « Rechercher »
                </div>
              </div>
              <div className="list-items">
                <div className="digit">2</div>
                <div className="description">
                  Confirmez qu’il s’agit de la bonne parcelle en double-cliquant
                  dessus
                </div>
              </div>
              <div className="list-items">
                <div className="digit">3</div>
                <div className="description">
                  Consultez de nombreuses informations utiles concernant la parcelle
                  en quelques clics
                </div>
              </div>
              <div className="last-description">
                Profitez de toutes la puissance d’Urbanease pour faire bien plus en
                prospection
              </div>
            </div>
            <div className="button-wrapper">
              <button
                className="button"
                onClick={openSignUpModal}
                data-cy="intro-button"
              >
                C&apos;est parti !
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UrbaneaseIntroModal;
