import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useAppSelector } from '../../../../App/store';
import { fetchPluZoneFromPlotListSlice } from '../../../../shared/services/fetchPluZoneFromPlotList';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { getAdvancedPlotSearchState } from '../../advancedPlotSearchSlice';
import { advancedPlotSearchExportExcel } from '../exportExcel/excel';
import styles from './advancedPlotSearchContainer.module.scss';

function PrintButton() {
  const { plots, plotCount } = useAppSelector(getAdvancedPlotSearchState);
  const [isLoading, setIsLoading] = useState(false);

  const handlePrintClick = async () => {
    try {
      if (plots.result) {
        let plotsToExcel = plots.result;

        if (plots.result[0] && !plots.result[0].zone) {
          setIsLoading(true);
          const zones = await fetchPluZoneFromPlotListSlice(plots.result);

          plotsToExcel = plots.result.map((m) => ({
            ...m,
            zone: zones[m.fullPlotId]?.plu_zone?.zone ?? '',
            zoneChild: zones[m.fullPlotId]?.plu_zone?.zone_child ?? '',
          }));
        }
        setIsLoading(false);
        advancedPlotSearchExportExcel(plotsToExcel);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      {plotCount > 0 && (
        <div
          className={`${styles.printIconContainer} ${
            plotCount === 0 && styles.disabled
          }`}
          onClick={handlePrintClick}
        >
          {isLoading ? (
            <IndividualCircularLoader size={30} />
          ) : (
            <>
              <div className={styles.printTooltip}>
                {`Télécharger les ${plotCount} parcelles selectionnées`}
              </div>
              <FontAwesomeIcon icon={faDownload} />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default PrintButton;
