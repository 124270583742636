import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import AutocompleteFormCustom, {
  AutocompleteOptionsType,
} from '../../../../shared/components/common/formComponents/AutcompleteFormCustom';
import { getCompanyState } from '../../../company/companySlice';
import { contactsActions, getContactsState } from '../../contactsSlice';
import fetchContactsThunk from '../../services/thunks/fetchContactsThunk';
interface ILastNameAutocompleteProps {
  name: string;
  dataCy?: string;
  placeholder?: string;
  errorTooltip?: boolean;
  required?: boolean;
}

const optionsForAutocomplete = (
  contacts: Contacts = []
): AutocompleteOptionsType => {
  return contacts.map((m) => ({
    label: `${m.firstName} ${m.lastName}`,
    id: m.idIri,
  }));
};

function LastNameAutocomplete({
  name,
  dataCy,
  placeholder,
  required,
}: ILastNameAutocompleteProps): React.ReactElement {
  const [emptyPattern, setEmptyPattern] = useState(true);
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { contacts } = useAppSelector(getContactsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchContactsThunk({ companyIdIri }));
  }, []);

  const handleInputChange = (value: string | null) => {
    if (emptyPattern !== !value) {
      setEmptyPattern(!emptyPattern);
    }
  };
  const handleOptionChange = (contactIdIri: string | null) => {
    if (contacts.result) {
      const contact = contacts.result.find((f) => f.idIri === contactIdIri);
      dispatch(contactsActions.contactForActionSet(contact ?? null));
    }
  };

  return (
    <AutocompleteFormCustom
      list={emptyPattern ? [] : optionsForAutocomplete(contacts.result ?? [])}
      name={name}
      onInputChange={handleInputChange}
      onOptionChange={handleOptionChange}
      dataCy={dataCy}
      placeholder={placeholder}
      required={required}
    />
  );
}

export default LastNameAutocomplete;
