import { nanoid } from '@reduxjs/toolkit';
import L from 'leaflet';
import { memo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { useAppSelector } from '../../../../../App/store';
import { getPlotState } from '../../../../../redux/plot/reducer';
import styles from './activePlot.module.scss';
import BuildingMarkerDivIcon from './BuildingMarkerDivIcon';

const ActivePlotBuildingMarkers = memo((): JSX.Element => {
  const { displayBuildingCentroid, parcelle } = useAppSelector(getPlotState);

  return (
    <div>
      {displayBuildingCentroid &&
        parcelle &&
        parcelle.buildings
          ?.filter((f) => f.use1 || f.height || f.stageCount || f.houseCount)
          .map((m, i: number) => (
            <Marker
              key={nanoid()}
              zIndexOffset={1000}
              position={[m.centroidLat, m.centroidLng]}
              icon={L.divIcon({
                className: styles.buildingIcon,
                html: ReactDOMServer.renderToString(
                  <BuildingMarkerDivIcon index={i + 1} />
                ),
              })}
            />
          ))}
    </div>
  );
});

ActivePlotBuildingMarkers.displayName = 'ActivePlotMarker';
export default ActivePlotBuildingMarkers;
