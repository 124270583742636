import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../../../services/axiosFiles/genericCrud';
import { GoogleSearchContryEnum, appActions } from '../../../app/appSlice';
import { sweepbrightDataApiToStoreParser } from '../../shared/utils/externalPdfParser';

export const fetchSweepbrightPdfDataThunk = createAsyncThunk(
  'externalPdfErrial/fetchSweepbrightPdfDataThunk',
  async ({ id }: { id: string | null }, { dispatch }) => {
    try {
      if (!id) throw new Error('Param not found');

      const response: any = await fetchDatas(`/sweepbright_pdf_data/${id}`);

      // if postalCode is dom-tom, change googleSearch type to dom-tom
      const cp = response.data.property?.location?.address?.postCode;
      if (cp && cp.substring(0, 2) === '97') {
        dispatch(appActions.setGoogleCountryType(GoogleSearchContryEnum.DOM_TOM));
      }

      const parsedResponse = sweepbrightDataApiToStoreParser(response.data);

      return parsedResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
