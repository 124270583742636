import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import FilterSortBloc from '../../../../shared/components/filterSortBloc/FilterSortBloc';
import ContactContainer from '../../../contacts/components/dashboardDirectory/ContactContainer';
import { contactHeaderCell } from '../../../contacts/components/dashboardDirectory/datas';
import { contactsActions } from '../../../contacts/contactsSlice';
import useContacts from '../../../contacts/hooks/useContacts';
import DashboardLoader from '../dashboardLoader/DashboardLoader';
import './directoryTab.scss';

function DirectoryTab(): JSX.Element {
  const { contacts, contactsApiStatus } = useContacts();
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('id');
  const [order, setOrder] = useState<Order>('asc');

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(contactsActions.contactForActionReset());
    };
  }, []);

  return (
    <div className="tab-directory">
      <h1 className="tab-inner" data-cy="tab-directory-title">
        Annuaire
      </h1>
      <FilterSortBloc
        setSearchPattern={setSearchPattern}
        setSortKey={setSortKey}
        setOrder={setOrder}
        selectItems={[{ value: 'lastName', display: 'Nom' }]}
        selectPlaceHolder="Trier contacts par"
      />

      <div className="tab-body">
        {contactsApiStatus === APIStatus.PENDING ? (
          <DashboardLoader />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {contactHeaderCell?.map((elt) => (
                    <TableCell key={nanoid()}>{elt.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <ContactContainer
                contacts={contacts ? [...contacts] : []}
                order={order}
                sortKey={sortKey}
                pattern={searchPattern}
              />
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default memo(DirectoryTab);
