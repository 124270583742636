import { Path, Svg } from '@react-pdf/renderer';

function LogoUrbaneasePricehubbleVerticalSvg() {
  return (
    <Svg width="128" height="113" viewBox="0 0 258 230" fill="none">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M67.7708 109.612L62.9081 107.875L67.7708 106.138L68.1181 107.007L65.5131 108.049L68.1181 108.917L67.7708 109.612Z"
        fill="#D41031"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.041 77.3098L149.22 75.9205L146.268 77.1361L145.92 76.2678L149.22 75.0521L153.388 76.6151L153.041 77.3098ZM132.201 82.3461L131.854 81.4778L138.974 78.6991L139.321 79.5674L132.201 82.3461ZM167.281 82.6934L160.161 79.9148L160.508 79.0464L167.629 81.8251L167.281 82.6934ZM117.96 87.7297L117.613 86.8614L124.733 84.0828L125.081 84.9511L117.96 87.7297ZM181.522 88.0771L174.402 85.2984L174.749 84.4301L181.869 87.2087L181.522 88.0771ZM103.72 93.1134L103.372 92.2451L110.493 89.4664L110.84 90.3347L103.72 93.1134ZM195.763 93.4607L188.642 90.6821L188.99 89.8137L196.11 92.5924L195.763 93.4607ZM89.479 98.497L89.1317 97.6287L96.252 94.85L96.5993 95.7184L89.479 98.497ZM209.83 99.018L202.709 96.2394L203.057 95.371L210.177 98.1497L209.83 99.018ZM75.4121 104.054L75.0648 103.186L82.1851 100.407L82.5324 101.276L75.4121 104.054ZM224.07 104.402L216.95 101.623L217.297 100.755L224.417 103.533L224.07 104.402Z"
        fill="#D41031"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M229.975 109.612L229.628 108.743L232.232 107.875L229.628 106.833L229.975 105.965L234.837 107.701L229.975 109.612Z"
        fill="#D41031"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.1851 116.558L75.0648 113.78L75.4121 112.911L82.5324 115.69L82.1851 116.558ZM217.297 116.906L216.95 116.037L224.07 113.259L224.417 114.127L217.297 116.906ZM96.252 121.942L89.1317 119.163L89.479 118.295L96.5993 121.074L96.252 121.942ZM203.057 122.289L202.709 121.421L209.83 118.642L210.177 119.511L203.057 122.289ZM110.493 127.499L103.372 124.721L103.72 123.852L110.84 126.631L110.493 127.499ZM188.816 127.847L188.469 126.978L195.763 124.2L196.11 125.068L188.816 127.847ZM124.733 132.883L117.613 130.104L117.96 129.236L125.081 132.015L124.733 132.883ZM174.749 133.23L174.402 132.362L181.522 129.583L181.869 130.452L174.749 133.23ZM138.974 138.267L131.854 135.488L132.201 134.62L139.321 137.398L138.974 138.267ZM160.508 138.614L160.161 137.746L167.281 134.967L167.629 135.835L160.508 138.614ZM150.088 142.608L145.92 141.045L146.268 140.177L150.088 141.566L153.041 140.351L153.388 141.219L150.088 142.608Z"
        fill="#D41031"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M109.798 140.872C47.9729 117.427 84.7901 131.32 27.133 109.612L109.277 78.3519L191.942 109.612C124.733 135.141 150.088 125.415 109.798 140.872ZM194.026 106.659L110.145 74.8785C109.451 74.5312 108.582 74.5312 107.888 74.8785L24.7016 106.659C23.486 107.18 22.7913 108.222 22.7913 109.438C22.7913 110.654 23.486 111.869 24.7016 112.217C108.93 144.171 24.007 111.869 108.409 143.998C108.756 144.171 109.103 144.171 109.451 144.171C109.798 144.171 110.145 144.171 110.493 143.998C194.373 112.043 109.972 144.171 193.679 112.217C194.894 111.696 195.589 110.654 195.589 109.438C196.11 108.222 195.242 107.18 194.026 106.659Z"
        fill="#6199C2"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M124.212 118.469C62.3872 95.0238 99.2043 108.917 41.5472 87.2088L123.691 55.9489L206.356 87.2088C139.148 112.738 164.676 103.012 124.212 118.469ZM208.614 84.2565L124.733 52.4756C124.039 52.1283 123.17 52.1283 122.476 52.4756L39.4632 84.2565C38.2476 84.7775 37.5529 85.8195 37.5529 87.0351C37.5529 88.2508 38.2476 89.4664 39.4632 89.8138C123.691 121.768 38.7686 89.4664 123.17 121.595C123.518 121.768 123.865 121.768 124.212 121.768C124.56 121.768 124.907 121.768 125.254 121.595C209.135 89.6401 124.733 121.768 208.44 89.8138C209.656 89.2928 210.351 88.2508 210.351 87.0351C210.524 85.8195 209.83 84.7775 208.614 84.2565Z"
        fill="#066ABE"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M141.579 51.9546C141.579 62.2009 133.243 70.5368 122.823 70.5368C112.577 70.5368 104.067 62.2009 104.067 51.9546C104.067 41.7083 112.403 33.3723 122.823 33.3723C133.069 33.3723 141.579 41.7083 141.579 51.9546Z"
        fill="white"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M122.823 67.9318C113.966 67.9318 106.846 60.8115 106.846 51.9545C106.846 43.0976 113.966 35.9773 122.823 35.9773C131.68 35.9773 138.8 43.0976 138.8 51.9545C138.8 60.8115 131.68 67.9318 122.823 67.9318ZM122.823 20C122.476 20 122.128 20 121.607 20C103.893 20.6947 90 35.4563 90.6947 52.9965C90.6947 54.5595 90.8684 56.2962 91.2157 57.8592C91.2157 58.2065 91.3893 58.5538 91.3893 59.0748C91.9103 61.1588 92.605 63.2428 93.4734 65.3268C96.773 72.9681 105.456 89.2927 120.739 101.97C121.955 103.012 123.344 103.012 124.386 102.144C136.369 90.3347 147.831 74.3574 151.825 65.5005C152.693 63.4165 153.562 61.3325 153.909 59.0748C154.083 58.3802 154.083 58.0328 154.083 58.0328C154.43 55.9489 154.604 54.0385 154.604 51.9545C154.951 34.2406 140.537 20 122.823 20Z"
        fill="#D2112F"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M122.997 44.3134C118.829 44.3134 115.355 47.613 115.355 51.781C115.355 55.949 118.829 59.4223 122.997 59.4223C127.165 59.4223 130.638 56.1226 130.638 51.781C130.638 47.613 127.165 44.3134 122.997 44.3134Z"
        fill="#D2112F"
      />
      <Path
        d="M223.384 180.557H237.629V183.961H219.425V159.541H237.111V162.945H223.384V169.975H234.114V173.305H223.384V180.557Z"
        fill="#D2112F"
      />
      <Path
        d="M204.011 159.171C208.525 159.171 211.633 160.799 213.779 164.129L210.782 166.83C209.413 163.907 207.526 162.612 204.048 162.612C200.57 162.612 198.683 164.166 198.683 166.238C198.683 168.199 200.2 169.235 204.936 170.086C211.485 171.233 213.89 173.268 213.89 177.19C213.89 181.556 209.931 184.331 204.159 184.331C198.831 184.331 195.538 182.407 193.651 179.373L196.426 176.598C198.017 179.558 200.459 180.89 204.122 180.89C207.6 180.89 209.746 179.669 209.746 177.412C209.746 175.673 208.266 174.674 203.678 173.823C196.981 172.565 194.502 170.53 194.502 166.645C194.502 162.501 198.054 159.171 204.011 159.171Z"
        fill="#D2112F"
      />
      <Path
        d="M187.427 183.961L185.133 177.56H174.847L172.516 183.961H168.446L177.659 159.541H182.395L191.608 183.961H187.427ZM175.994 174.341H183.949L181.137 166.497L180.027 162.982H179.916L178.88 166.423L175.994 174.341Z"
        fill="#D2112F"
      />
      <Path
        d="M151.769 180.557H166.014V183.961H147.81V159.541H165.496V162.945H151.769V169.975H162.499V173.305H151.769V180.557Z"
        fill="#D2112F"
      />
      <Path
        d="M140.582 159.541V183.961H135.883L126.448 168.569L124.08 164.055H124.043L124.191 168.199V183.961H120.565V159.541H125.227L134.625 174.896L137.03 179.447H137.067L136.919 175.303V159.541H140.582Z"
        fill="#D2112F"
      />
      <Path
        d="M112.054 183.961L109.76 177.56H99.474L97.143 183.961H93.073L102.286 159.541H107.022L116.235 183.961H112.054ZM100.621 174.341H108.576L105.764 166.497L104.654 162.982H104.543L103.507 166.423L100.621 174.341Z"
        fill="#D2112F"
      />
      <Path
        d="M71.75 183.961V159.541H81.37C87.216 159.541 89.843 162.057 89.843 166.238C89.843 168.791 88.326 171.048 85.477 171.566V171.603C88.622 172.121 90.472 174.452 90.472 177.19C90.472 181.371 87.327 183.961 81.518 183.961H71.75ZM75.635 170.049H81.074C84.182 170.049 85.736 168.865 85.736 166.46C85.736 164.055 84.182 162.871 81.074 162.871H75.635V170.049ZM75.635 180.668H81.703C84.811 180.668 86.365 179.336 86.365 177.005C86.365 174.674 84.811 173.342 81.703 173.342H75.635V180.668Z"
        fill="#D2112F"
      />
      <Path
        d="M62.2826 183.961L56.0666 174.193H50.8126V183.961H46.8906V159.541H56.8436C62.0976 159.541 65.3906 162.279 65.3906 166.867C65.3906 170.382 63.4666 172.787 60.2106 173.749L66.8336 183.961H62.2826ZM50.8126 162.908V170.9H56.5106C59.7296 170.9 61.2836 169.605 61.2836 166.904C61.2836 164.203 59.7296 162.908 56.5106 162.908H50.8126Z"
        fill="#D2112F"
      />
      <Path
        d="M39.98 159.541V174.6C39.98 181.075 36.465 184.331 29.99 184.331C23.515 184.331 20 181.075 20 174.6V159.541H23.996V174.119C23.996 178.744 25.883 180.89 29.99 180.89C34.097 180.89 35.984 178.744 35.984 174.119V159.541H39.98Z"
        fill="#D2112F"
      />
      <Path
        d="M187.706 205.974C186.809 205.974 186.036 205.782 185.387 205.399C184.743 205.011 184.245 204.468 183.893 203.768C183.545 203.064 183.371 202.24 183.371 201.295C183.371 200.362 183.545 199.539 183.893 198.828C184.245 198.116 184.735 197.561 185.363 197.161C185.996 196.762 186.735 196.562 187.581 196.562C188.095 196.562 188.594 196.647 189.076 196.817C189.558 196.987 189.991 197.254 190.375 197.618C190.758 197.982 191.061 198.454 191.282 199.035C191.503 199.613 191.614 200.314 191.614 201.141V201.769H184.373V200.441H189.876C189.876 199.974 189.782 199.561 189.592 199.201C189.402 198.838 189.135 198.551 188.791 198.341C188.451 198.132 188.052 198.027 187.593 198.027C187.095 198.027 186.66 198.15 186.289 198.395C185.921 198.636 185.636 198.952 185.435 199.344C185.237 199.731 185.138 200.152 185.138 200.607V201.645C185.138 202.254 185.245 202.771 185.458 203.198C185.676 203.625 185.978 203.952 186.366 204.177C186.753 204.398 187.206 204.509 187.724 204.509C188.06 204.509 188.366 204.462 188.643 204.367C188.92 204.268 189.159 204.122 189.361 203.928C189.562 203.734 189.716 203.495 189.823 203.21L191.501 203.513C191.367 204.007 191.126 204.44 190.778 204.812C190.434 205.179 190.001 205.466 189.479 205.671C188.961 205.873 188.37 205.974 187.706 205.974Z"
        fill="#066ABE"
      />
      <Path d="M181.395 193.645V205.79H179.621V193.645H181.395Z" fill="#066ABE" />
      <Path
        d="M169.31 205.79V193.645H171.083V198.158H171.19C171.293 197.968 171.441 197.748 171.635 197.499C171.828 197.25 172.097 197.033 172.441 196.847C172.785 196.657 173.24 196.562 173.805 196.562C174.54 196.562 175.197 196.748 175.774 197.12C176.351 197.491 176.804 198.027 177.132 198.727C177.464 199.427 177.63 200.269 177.63 201.253C177.63 202.238 177.466 203.082 177.138 203.786C176.81 204.485 176.359 205.025 175.786 205.405C175.213 205.78 174.558 205.968 173.823 205.968C173.269 205.968 172.817 205.875 172.465 205.689C172.117 205.503 171.844 205.286 171.646 205.037C171.449 204.788 171.297 204.566 171.19 204.373H171.042V205.79H169.31ZM171.047 201.235C171.047 201.876 171.14 202.437 171.326 202.92C171.512 203.402 171.781 203.78 172.133 204.052C172.485 204.321 172.916 204.456 173.426 204.456C173.955 204.456 174.398 204.315 174.754 204.035C175.11 203.75 175.379 203.364 175.561 202.878C175.746 202.392 175.839 201.844 175.839 201.235C175.839 200.634 175.748 200.095 175.566 199.616C175.389 199.138 175.12 198.76 174.76 198.484C174.404 198.207 173.959 198.069 173.426 198.069C172.912 198.069 172.477 198.201 172.121 198.466C171.769 198.731 171.502 199.1 171.32 199.575C171.138 200.049 171.047 200.603 171.047 201.235Z"
        fill="#066ABE"
      />
      <Path
        d="M158.856 205.79V193.645H160.629V198.158H160.736C160.839 197.968 160.987 197.748 161.181 197.499C161.375 197.25 161.643 197.033 161.987 196.847C162.331 196.657 162.786 196.562 163.351 196.562C164.087 196.562 164.743 196.748 165.32 197.12C165.897 197.491 166.35 198.027 166.678 198.727C167.01 199.427 167.176 200.269 167.176 201.253C167.176 202.238 167.012 203.082 166.684 203.786C166.356 204.485 165.905 205.025 165.332 205.405C164.759 205.78 164.104 205.968 163.369 205.968C162.816 205.968 162.363 205.875 162.011 205.689C161.663 205.503 161.39 205.286 161.193 205.037C160.995 204.788 160.843 204.566 160.736 204.373H160.588V205.79H158.856ZM160.594 201.235C160.594 201.876 160.687 202.437 160.872 202.92C161.058 203.402 161.327 203.78 161.679 204.052C162.031 204.321 162.462 204.456 162.972 204.456C163.502 204.456 163.944 204.315 164.3 204.035C164.656 203.75 164.925 203.364 165.107 202.878C165.293 202.392 165.385 201.844 165.385 201.235C165.385 200.634 165.295 200.095 165.113 199.616C164.935 199.138 164.666 198.76 164.306 198.484C163.95 198.207 163.506 198.069 162.972 198.069C162.458 198.069 162.023 198.201 161.667 198.466C161.315 198.731 161.048 199.1 160.866 199.575C160.685 200.049 160.594 200.603 160.594 201.235Z"
        fill="#066ABE"
      />
      <Path
        d="M154.552 202.012V196.681H156.331V205.79H154.588V204.213H154.493C154.283 204.699 153.947 205.104 153.485 205.428C153.026 205.748 152.455 205.909 151.771 205.909C151.186 205.909 150.668 205.78 150.217 205.523C149.77 205.262 149.418 204.877 149.161 204.367C148.908 203.857 148.782 203.226 148.782 202.475V196.681H150.555V202.261C150.555 202.882 150.727 203.376 151.071 203.744C151.415 204.112 151.862 204.296 152.411 204.296C152.743 204.296 153.073 204.213 153.402 204.046C153.734 203.88 154.008 203.629 154.226 203.293C154.447 202.957 154.556 202.53 154.552 202.012Z"
        fill="#066ABE"
      />
      <Path
        d="M136.53 205.79V193.645H138.362V198.923H144.417V193.645H146.255V205.79H144.417V200.494H138.362V205.79H136.53Z"
        fill="#066ABE"
      />
      <Path
        d="M130.512 205.974C129.614 205.974 128.841 205.782 128.193 205.399C127.548 205.011 127.05 204.468 126.698 203.768C126.351 203.064 126.177 202.24 126.177 201.295C126.177 200.362 126.351 199.539 126.698 198.828C127.05 198.116 127.541 197.561 128.169 197.161C128.802 196.762 129.541 196.562 130.387 196.562C130.901 196.562 131.399 196.647 131.882 196.817C132.364 196.987 132.797 197.254 133.18 197.618C133.564 197.982 133.866 198.454 134.088 199.035C134.309 199.613 134.42 200.314 134.42 201.141V201.769H127.179V200.441H132.682C132.682 199.974 132.587 199.561 132.398 199.201C132.208 198.838 131.941 198.551 131.597 198.341C131.257 198.132 130.858 198.027 130.399 198.027C129.901 198.027 129.466 198.15 129.094 198.395C128.727 198.636 128.442 198.952 128.24 199.344C128.043 199.731 127.944 200.152 127.944 200.607V201.645C127.944 202.254 128.051 202.771 128.264 203.198C128.482 203.625 128.784 203.952 129.171 204.177C129.559 204.398 130.012 204.509 130.529 204.509C130.866 204.509 131.172 204.462 131.449 204.367C131.725 204.268 131.965 204.122 132.166 203.928C132.368 203.734 132.522 203.495 132.629 203.21L134.307 203.513C134.173 204.007 133.932 204.44 133.584 204.812C133.24 205.179 132.807 205.466 132.285 205.671C131.767 205.873 131.176 205.974 130.512 205.974Z"
        fill="#066ABE"
      />
      <Path
        d="M120.947 205.974C120.066 205.974 119.307 205.774 118.67 205.375C118.038 204.972 117.551 204.416 117.211 203.708C116.871 203.001 116.701 202.19 116.701 201.277C116.701 200.352 116.875 199.535 117.223 198.828C117.571 198.116 118.061 197.561 118.694 197.161C119.326 196.762 120.072 196.562 120.93 196.562C121.622 196.562 122.238 196.691 122.78 196.948C123.322 197.201 123.758 197.557 124.091 198.015C124.427 198.474 124.626 199.01 124.69 199.622H122.964C122.869 199.195 122.651 198.828 122.311 198.519C121.975 198.211 121.525 198.057 120.959 198.057C120.465 198.057 120.032 198.187 119.661 198.448C119.293 198.705 119.006 199.073 118.801 199.551C118.595 200.026 118.492 200.587 118.492 201.235C118.492 201.9 118.593 202.473 118.795 202.955C118.996 203.438 119.281 203.811 119.649 204.076C120.02 204.341 120.457 204.473 120.959 204.473C121.295 204.473 121.6 204.412 121.873 204.29C122.149 204.163 122.381 203.983 122.566 203.75C122.756 203.517 122.889 203.236 122.964 202.908H124.69C124.626 203.497 124.435 204.023 124.114 204.485C123.794 204.948 123.365 205.312 122.827 205.577C122.294 205.841 121.667 205.974 120.947 205.974Z"
        fill="#066ABE"
      />
      <Path
        d="M112.952 205.79V196.681H114.725V205.79H112.952ZM113.847 195.275C113.539 195.275 113.274 195.173 113.053 194.967C112.835 194.757 112.726 194.508 112.726 194.22C112.726 193.927 112.835 193.678 113.053 193.473C113.274 193.263 113.539 193.158 113.847 193.158C114.156 193.158 114.419 193.263 114.636 193.473C114.857 193.678 114.968 193.927 114.968 194.22C114.968 194.508 114.857 194.757 114.636 194.967C114.419 195.173 114.156 195.275 113.847 195.275Z"
        fill="#066ABE"
      />
      <Path
        d="M106.543 205.79V196.681H108.256V198.128H108.351C108.517 197.638 108.81 197.252 109.229 196.971C109.652 196.687 110.13 196.544 110.664 196.544C110.775 196.544 110.905 196.548 111.056 196.556C111.21 196.564 111.33 196.574 111.417 196.586V198.282C111.346 198.262 111.22 198.241 111.038 198.217C110.856 198.189 110.674 198.175 110.492 198.175C110.073 198.175 109.699 198.264 109.371 198.442C109.047 198.616 108.79 198.859 108.6 199.172C108.411 199.48 108.316 199.832 108.316 200.227V205.79H106.543Z"
        fill="#066ABE"
      />
      <Path
        d="M96.0027 205.79V193.645H100.332C101.277 193.645 102.06 193.817 102.68 194.16C103.301 194.504 103.766 194.975 104.074 195.572C104.382 196.165 104.537 196.833 104.537 197.576C104.537 198.324 104.38 198.996 104.068 199.593C103.76 200.186 103.293 200.656 102.669 201.004C102.048 201.348 101.267 201.52 100.326 201.52H97.3489V199.966H100.16C100.757 199.966 101.241 199.864 101.613 199.658C101.985 199.448 102.257 199.164 102.431 198.804C102.605 198.444 102.692 198.035 102.692 197.576C102.692 197.118 102.605 196.711 102.431 196.355C102.257 195.999 101.983 195.72 101.607 195.519C101.235 195.317 100.745 195.216 100.136 195.216H97.8352V205.79H96.0027Z"
        fill="#066ABE"
      />
      <Path
        d="M85.0315 205.79V196.681H86.7454V198.128H86.8403C87.0063 197.638 87.2989 197.252 87.718 196.971C88.141 196.687 88.6194 196.544 89.1531 196.544C89.2638 196.544 89.3943 196.548 89.5445 196.556C89.6987 196.564 89.8193 196.574 89.9063 196.586V198.282C89.8351 198.262 89.7086 198.241 89.5267 198.217C89.3449 198.189 89.163 198.175 88.9811 198.175C88.5621 198.175 88.1884 198.264 87.8603 198.442C87.5361 198.616 87.2791 198.859 87.0893 199.172C86.8996 199.48 86.8047 199.832 86.8047 200.227V205.79H85.0315Z"
        fill="#066ABE"
      />
      <Path
        d="M78.1745 205.992C77.5973 205.992 77.0754 205.885 76.6089 205.671C76.1423 205.454 75.7727 205.14 75.4999 204.728C75.231 204.317 75.0966 203.813 75.0966 203.216C75.0966 202.702 75.1955 202.279 75.3931 201.947C75.5908 201.615 75.8577 201.352 76.1937 201.158C76.5298 200.965 76.9054 200.818 77.3205 200.72C77.7356 200.621 78.1587 200.546 78.5896 200.494C79.1352 200.431 79.578 200.38 79.918 200.34C80.258 200.296 80.5051 200.227 80.6593 200.132C80.8135 200.038 80.8906 199.883 80.8906 199.67V199.628C80.8906 199.11 80.7443 198.709 80.4518 198.424C80.1632 198.14 79.7322 197.997 79.1589 197.997C78.5619 197.997 78.0915 198.13 77.7475 198.395C77.4075 198.656 77.1723 198.946 77.0418 199.267L75.3753 198.887C75.573 198.334 75.8616 197.887 76.2412 197.547C76.6247 197.203 77.0655 196.954 77.5637 196.8C78.0618 196.641 78.5857 196.562 79.1352 196.562C79.499 196.562 79.8844 196.606 80.2916 196.693C80.7028 196.776 81.0863 196.93 81.4421 197.155C81.8019 197.381 82.0965 197.703 82.3258 198.122C82.5551 198.537 82.6697 199.077 82.6697 199.741V205.79H80.9381V204.545H80.8669C80.7522 204.774 80.5803 204.999 80.351 205.221C80.1216 205.442 79.8271 205.626 79.4673 205.772C79.1075 205.919 78.6766 205.992 78.1745 205.992ZM78.56 204.568C79.0502 204.568 79.4693 204.471 79.8172 204.278C80.1691 204.084 80.436 203.831 80.6178 203.519C80.8036 203.202 80.8965 202.864 80.8965 202.505V201.33C80.8333 201.394 80.7107 201.453 80.5289 201.508C80.351 201.56 80.1473 201.605 79.918 201.645C79.6887 201.68 79.4653 201.714 79.2479 201.745C79.0305 201.773 78.8486 201.797 78.7023 201.817C78.3583 201.86 78.044 201.933 77.7594 202.036C77.4787 202.139 77.2533 202.287 77.0833 202.481C76.9173 202.671 76.8342 202.924 76.8342 203.24C76.8342 203.679 76.9963 204.011 77.3205 204.236C77.6447 204.458 78.0579 204.568 78.56 204.568Z"
        fill="#066ABE"
      />
      <Path
        d="M65.2166 209.206V196.681H66.9482V198.158H67.0965C67.1993 197.968 67.3475 197.748 67.5413 197.499C67.735 197.25 68.0038 197.033 68.3478 196.847C68.6918 196.657 69.1464 196.562 69.7118 196.562C70.4472 196.562 71.1035 196.748 71.6807 197.12C72.2579 197.491 72.7106 198.027 73.0387 198.727C73.3709 199.427 73.5369 200.269 73.5369 201.253C73.5369 202.238 73.3728 203.082 73.0447 203.786C72.7165 204.485 72.2658 205.025 71.6925 205.405C71.1193 205.78 70.465 205.968 69.7296 205.968C69.1761 205.968 68.7234 205.875 68.3715 205.689C68.0236 205.503 67.7508 205.286 67.5531 205.037C67.3555 204.788 67.2032 204.566 67.0965 204.373H66.9897V209.206H65.2166ZM66.9542 201.235C66.9542 201.876 67.0471 202.437 67.2329 202.92C67.4187 203.402 67.6876 203.78 68.0394 204.052C68.3913 204.321 68.8222 204.456 69.3322 204.456C69.862 204.456 70.3048 204.315 70.6607 204.035C71.0165 203.75 71.2853 203.364 71.4672 202.878C71.653 202.392 71.7459 201.844 71.7459 201.235C71.7459 200.635 71.655 200.095 71.4731 199.616C71.2952 199.138 71.0264 198.761 70.6666 198.484C70.3108 198.207 69.866 198.069 69.3322 198.069C68.8183 198.069 68.3834 198.201 68.0276 198.466C67.6757 198.731 67.4088 199.101 67.227 199.575C67.0451 200.049 66.9542 200.603 66.9542 201.235Z"
        fill="#066ABE"
      />
    </Svg>
  );
}

export default LogoUrbaneasePricehubbleVerticalSvg;
