import createCtx from '../../../../../App/contexts/GenenricStateContext';
import styles from '../dpe.module.scss';
import DpePopupContents from './DpePopupContents';
import DpePopupTabs from './DpePopupTabs';

interface IDpePopupWithLoaderProps {
  datas: DpeForMap | null;
}

const [ctx, GenericPopupProvider] = createCtx<number>(0);
export const GenericPopupContext = ctx;

function DpeMarkerPopupContainer({ datas }: IDpePopupWithLoaderProps) {
  return (
    <>
      {datas && (
        <div className={styles.popupContainer}>
          <GenericPopupProvider>
            <DpePopupTabs tabs={datas?.tabs} />
            <DpePopupContents popupContents={datas.content} />
          </GenericPopupProvider>
        </div>
      )}
    </>
  );
}

export default DpeMarkerPopupContainer;
