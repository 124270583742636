import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../../shared/components/modals/genericModal/GenericModal';
import { getPdfState, pdfActions } from '../../../pdfSlice';

import ErrorNoAdjacentPlots from './ErrorNoAdjacentPlots';
import GenericErrorMessage from './GenericErrorMessage';

function ErrialInfoModal() {
  const { infoModalDisplay, multiPlotsPdf } = useAppSelector(getPdfState);
  const dispatch = useAppDispatch();
  const noPlotSelected =
    infoModalDisplay.errorMessage === 'Tableau de parcelles vide';

  const handleCloseModal = () => {
    dispatch(
      pdfActions.setInfoModalDisplay({
        display: false,
        type: null,
        plotIds: null,
        errorMessage: null,
      })
    );
  };

  const handleForceGenerate = () => {
    dispatch(pdfActions.setForceGenerate(true));
    handleCloseModal();
  };

  return (
    <GenericModal
      actionType={
        infoModalDisplay.type === 'forceGenerate'
          ? noPlotSelected
            ? GenericModalActionEnum.OK
            : GenericModalActionEnum.OKCANCEL
          : GenericModalActionEnum.OK
      }
      modalType={GenericModalEnum.INFO}
      title={'Génération du PDF'}
      closeModal={handleCloseModal}
      okButtonContent={
        infoModalDisplay.type === 'forceGenerate' && !noPlotSelected ? 'Oui' : 'OK'
      }
      okCallback={
        infoModalDisplay.type === 'forceGenerate'
          ? noPlotSelected
            ? handleCloseModal
            : handleForceGenerate
          : handleCloseModal
      }
      cancelButtonContent="Non"
      cancelCallback={handleCloseModal}
    >
      <div>
        {infoModalDisplay.type === 'forceGenerate' && (
          <ErrorNoAdjacentPlots
            infoModalDisplay={infoModalDisplay}
            selectionHasTwoPlots={multiPlotsPdf.result?.length === 2}
            noPlotSelected={noPlotSelected}
          />
        )}

        {infoModalDisplay.type === 'errorAdjacent' && (
          <GenericErrorMessage
            texts={[
              `Une erreur s'est produite lors de la vérification des parcelles
        sélectionnées.`,
            ]}
          />
        )}

        {infoModalDisplay.type === 'plotNotFound' && (
          <GenericErrorMessage
            noEmail
            texts={[
              `La parcelle recherchée n'a pas été trouvée.`,
              `Veuillez renouveler l'opération.`,
            ]}
          />
        )}

        {infoModalDisplay.type === 'errorInsee' && (
          <GenericErrorMessage
            noEmail
            texts={[
              `La parcelle que vous voulez ajouter à votre sélection ne se situe pas dans la
        même commune que celles déjà sélectionnées.`,
              `Merci de sélectionner une parcelle adjacente à la sélection, dans la même
              commune.`,
            ]}
          />
        )}

        {infoModalDisplay.type === 'errorSweepbright' && (
          <GenericErrorMessage
            texts={['Erreur lors du chargement des données sweepbright.']}
          />
        )}

        {infoModalDisplay.type === 'errorPhFolder' && (
          <GenericErrorMessage
            texts={['Erreur lors du chargement des données pricehubble.']}
          />
        )}

        {infoModalDisplay.type === 'errorFetchDatasForPdfGeneration' && (
          <GenericErrorMessage
            texts={['Erreur lors du chargement des données liées aux parcelles.']}
          />
        )}
      </div>
    </GenericModal>
  );
}

export default ErrialInfoModal;
