import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import SubscriptionCard from '../../../subscription/components/subscriptionCard/SubscriptionCard';
import useSubscription from '../../../subscription/hooks/useSubscription';
import SwitchDomTom from '../switchDomTom/SwitchDomTom';
import './subscriptionTab.scss';

export default function SubscriptionTab(): JSX.Element {
  const subscription = useSubscription({ loadCities: true, loadDepartments: true });

  return (
    <div className="tab-subscription">
      <h1 data-cy="account-subscription-tab-title">Mon abonnement</h1>
      <div className="tab-body">
        {subscription &&
        subscription.cities.result &&
        subscription.departments.result ? (
          <>
            <SubscriptionCard subscription={subscription} />
            {subscription?.hasFullMapAccess && <SwitchDomTom />}
          </>
        ) : (
          <IndividualCircularLoader size={200} />
        )}
      </div>
    </div>
  );
}
