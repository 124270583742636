import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import LocalServitudes from '../../errialPDF/pdfFiles/components/LocalServitudes';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import EnvConstraintsLand from './components/EnvConstraintsLand';
import Footer from './components/Footer';
import GeneralInformations from './components/GeneralInformations';
import Header from './components/Header';
import LocationInformationPdf from './components/LocationInformationPdf';
import PdfSeparateLine from './components/PdfSeparateLine';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  pythonDatas: DatasForPdfGeneration;
  servitudes: OrpiServitudes;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function YourGoodEnvironmentPage({
  agency,
  pythonDatas,
  servitudes,
  formDatas,
  isOrpi,
  address,
  date,
}: IProps) {
  const headerLines = ['Votre bien', 'Parlons peu, parlons bien'];

  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <Page style={styles.page}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}
      {isOrpi ? <Header lines={headerLines} fixed isOrpi /> : null}
      <View>
        <View style={styles.flexRow}>
          <View style={styles.rowBlocSize}>
            <GeneralInformations formDatas={formDatas} isOrpi={isOrpi} />
          </View>

          <View style={[styles.flexColumn, styles.rowBlocSize]}>
            <LocationInformationPdf formDatas={formDatas} isOrpi={isOrpi} />
          </View>
        </View>
      </View>
      <PdfSeparateLine marginBottom="0px" marginTop="20px" />
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>L&#039;urbanisme de votre terrain</Text>
      </View>
      <EnvConstraintsLand {...pythonDatas.parcelsEnvironmentDatas.envConstraints} />
      <View wrap={false}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>
            Servitudes locales présentes sur le Géoportail de l’urbanisme
          </Text>
        </View>

        <LocalServitudes {...servitudes} />
      </View>
      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default YourGoodEnvironmentPage;

const commonStyles = {
  flexRow: { display: 'flex' as const, flexDirection: 'row' as const },
  flexColumn: { display: 'flex' as const, flexDirection: 'column' as const },
  rowBlocSize: { width: '50%', marginTop: '10px' },
  globalInfoContainer: {
    marginVertical: '10px',
  },
  globalInfoText: {
    fontSize: '14px',
    paddingBottom: '3px',
  },
  titleContainer: {
    marginVertical: '10px',
  },
  address: { paddingTop: '20px' },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  page: { ...pageStyles.page, ...fontStyles.ffOrpi },
  titleText: {
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
  },
  titleText: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
  },
});
