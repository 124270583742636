import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { modalsActions } from '../../../../../modals/modalsSlice';
import {
  getMapPluginsMeasureState,
  mapPluginsActions,
} from '../../../mapPluginSlice';
import CreateToolbarBtn from '../../../toolbars/urbaToolbar/CreateToolbarBtn';
import ToolbarCancelBtn from '../../../toolbars/urbaToolbar/ToolbarCancelBtn';
import ToolbarTooltip from '../../../toolbars/urbaToolbar/ToolbarTooltip';
import { MeasureToolbarButtonEnum } from '../../types/measureActionEnum';
import MeasurePluginInterface from '../MeasurePluginInterface';
import styles from './measureToolbar.module.scss';

function MeasureToolbar() {
  const { displayInterface } = useAppSelector(getMapPluginsMeasureState);
  const dispatch = useAppDispatch();
  const handleClick = () => {
    if (displayInterface) {
      // if close interface, close helper modal
      dispatch(modalsActions.toolbarHelperModalReset());
    }
    dispatch(mapPluginsActions.measureInterfaceDisplaySet(!displayInterface));
  };

  return (
    <div className={styles.measureToolbar}>
      {displayInterface ? (
        <ToolbarCancelBtn
          onClick={handleClick}
          type={MeasureToolbarButtonEnum.MEASURE_STOP}
          tooltip={
            <ToolbarTooltip text="Fermeture de l'interface de distance et de surface" />
          }
          optionsContainer={<MeasurePluginInterface />}
        />
      ) : (
        <CreateToolbarBtn
          icon={MeasureToolbarButtonEnum.MEASURE_START}
          onClick={handleClick}
          isMeasureIcon
          tooltip={<ToolbarTooltip text="Mesure de distance et de surface" />}
        />
      )}
    </div>
  );
}

export default MeasureToolbar;
