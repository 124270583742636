import { Document } from '@react-pdf/renderer';
import { dateFormat } from '../../../lib/formats/dataFormat';
import PageOne from '../errialPDF/pdfFiles/Page1';
import ComparativeStudiesPage from './pdfFiles/ComparativeStudiesPage';
import EstimatePage from './pdfFiles/EstimatePage';
import GeoriskExpositionPage from './pdfFiles/GeoriskExpositionPage';
import PriceAnalysisPage from './pdfFiles/PriceAnalysisPage';
import YourGoodEnvironmentPage from './pdfFiles/YourGoodEnvironmentPage';
import YourGoodPage from './pdfFiles/YourGoodPage';

interface IProps {
  datas: PDFLandDataType | null;
}

function LandPdfContainer({ datas }: IProps) {
  if (datas) {
    const address = {
      addressLine1: datas.formDatas.addressStreet,
      addressLine2: datas.formDatas.addressCity,
    };
    const user = {
      lastName: datas.formDatas?.lastName,
      firstName: datas.formDatas?.firstName,
      phone: datas.formDatas?.phone,
      email: datas.formDatas?.email,
    };
    const date = dateFormat(new Date().toISOString());

    return (
      <Document>
        <PageOne
          {...{
            date: date,
            address: address,
            ownerName: datas.formDatas?.owners,
            plotIds: datas.plotIds,
            user: user,
            companyName: datas.formDatas.companyName,
            homePagePicture: datas.plotDatas.homePagePicture,
          }}
        />
        <YourGoodPage
          formDatas={datas.formDatas}
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          plotIds={datas.plotIds}
          plotDatas={datas.plotDatas}
          pluZone={datas.pythonDatas.zonePlu}
          address={address}
          date={date}
        />
        <YourGoodEnvironmentPage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          pythonDatas={datas.pythonDatas}
          servitudes={datas.servitudes}
          formDatas={datas.formDatas}
          address={address}
          date={date}
        />
        <GeoriskExpositionPage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          {...datas.pythonDatas.parcelsEnvironmentDatas}
          contaminatedAreas={datas.pythonDatas.contaminatedAreas}
          environmentRisksDetails={datas.pythonDatas.cityEnvironmentDatas}
          catNats={datas.pythonDatas.cityEnvironmentDatas.catnat}
          formDatas={datas.formDatas}
          address={address}
          date={date}
        />
        {!datas.prospecDatas.similariesError && (
          <ComparativeStudiesPage
            agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
            prospecDatas={datas.prospecDatas}
            formDatas={datas.formDatas}
            address={address}
            date={date}
          />
        )}
        {datas.prospecDatas.adsStats && (
          <PriceAnalysisPage
            agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
            prospecDatas={datas.prospecDatas}
            avgPriceImg={datas.avgPriceChart ?? ''}
            avgPriceAreaImg={datas.avgPriceAreaChart ?? ''}
            formDatas={datas.formDatas}
            address={address}
            date={date}
          />
        )}
        <EstimatePage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          formDatas={datas.formDatas}
          plotDatas={datas.plotDatas}
          landType={`Terrain ${datas?.prospecDatas?.type?.text.toLowerCase() ?? ''}`}
          address={address}
          date={date}
        />
      </Document>
    );
  } else {
    return <Document />;
  }
}

export default LandPdfContainer;
