import { memo } from 'react';
import { useAppSelector } from '../../../../../App/store';
import useSubscriptionAccess from '../../../../company/subscription/useSubscriptionAccess';
import MultiSelectToolbar from '../../../../multiPlotSelectFeature/components/MultiSelectToolbar';
import AltimetryToolbar from '../../altimetryPlugin/AltimetryToolbar';
import DrawToolbar from '../../drawPlugin/DrawToolbar';
import { getMapPluginsUrbaToolbarState } from '../../mapPluginSlice';
import MeasureToolbar from '../../measurePlugin/components/measureToolbar/MeasureToolbar';
import styles from '../toolbars.module.scss';
import { ToolbarTypeEnum } from './types/ToolbarBtnEnums';

function UrbaToolbar() {
  const { displayedToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();

  if (!hasUrbaneaseAccess) return null;

  const displayed = (toolbar: ToolbarTypeEnum) => {
    return displayedToolbar.includes(toolbar);
  };
  return (
    <div className={styles.urbaToolbar}>
      {displayed(ToolbarTypeEnum.DRAW) && <DrawToolbar />}
      {displayed(ToolbarTypeEnum.MEASURE) && <MeasureToolbar />}
      {displayed(ToolbarTypeEnum.ALTIMETRY) && <AltimetryToolbar />}
      {displayed(ToolbarTypeEnum.MULTISELECT) && <MultiSelectToolbar />}
    </div>
  );
}

export default memo(UrbaToolbar);
