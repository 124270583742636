import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import { foldersActions } from '../../../features/folders/foldersSlice';
import { DrawActionEnum } from '../../../features/map/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsUrbaToolbarState,
  mapPluginsActions,
} from '../../../features/map/plugins/mapPluginSlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { sectorActions } from '../../../features/sectors/sectorSlice';
import FolderIcon from '../icons/FolderIcon';
import { IIconActionProps } from './ItemActions';
import styles from './itemActions.module.scss';

function AddFolderIcon<T>({ type, item }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { isManager, isAdmin } = useAppSelector(getAuthState);

  const handleClick = () => {
    if (type === 'folder') {
      dispatch(foldersActions.subFolderParentSet((item as IFolder).idIri));
      dispatch(modalsActions.folderEdit(true));
    } else {
      if (activeToolbar === null && (isAdmin || isManager)) {
        dispatch(sectorActions.subSectorParentSet(item as ISector));
        dispatch(mapPluginsActions.drawStart(DrawActionEnum.DRAW_SECTOR));
      }
    }
  };

  return (
    <FolderIcon
      tooltipText={
        type === 'folder' ? 'Ajouter sous-dossier' : 'Ajouter sous-secteur'
      }
      width={37}
      height={37}
      bgColor={type === 'folder' ? 'PRIMARY' : 'ERROR'}
      onClick={handleClick}
      isRoundIcon
      className={[styles.icon, type === 'folder' ? '' : styles.redIcon].join(' ')}
    />
  );
}

export default AddFolderIcon;
