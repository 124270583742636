import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as plainStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { favoritePlacesActions } from '../../../../redux/favoritePlaces/actions';
import { FavoriteSearch } from '../../../../redux/favoritePlaces/reducer';
import plotActions from '../../../../redux/plot/actions';
import { getAuthState } from '../../../auth/authSlice';
import useHistoricCookie from '../../hooks/useHistoricCookie';
import styles from './favAndHistoric.module.scss';

interface FavAndHistoricProps {
  rowData: FavoriteSearch;
  isFavorite: boolean;
  favoriteCount: number;
}
function FavAndHistoricRow({
  rowData,
  isFavorite,
  favoriteCount,
}: FavAndHistoricProps) {
  const {
    address,
    idIri,
    city,
    lat,
    lng,
    department,
    isCitySearch = false,
    postalCode,
  } = rowData;

  const { setHistoricAsFavorite } = useHistoricCookie();
  const { userIdIri } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleLabelClick = () => {
    dispatch(
      plotActions.fetchPlot.request({
        data: { lat, lon: lng, department, townHall: isCitySearch },
      })
    );
    dispatch(favoritePlacesActions.windowDisplay.set(false));
  };

  const handleStarClick = () => {
    if (isFavorite) {
      dispatch(favoritePlacesActions.deleteFavoriteSearch.request(idIri));
    } else {
      if (userIdIri && favoriteCount < 5 && rowData.predictionId) {
        setHistoricAsFavorite(rowData, userIdIri);
      }
    }
  };

  return (
    <div className={styles.row}>
      <span onClick={handleLabelClick}>
        {isCitySearch ? `${city} (${postalCode})` : address}
      </span>
      <FontAwesomeIcon
        icon={isFavorite ? plainStar : emptyStar}
        onClick={handleStarClick}
        className={favoriteCount === 5 ? styles.svgDisabled : ''}
        title={
          isFavorite
            ? 'Supprimer le favori'
            : favoriteCount === 5
              ? 'Vous avez atteint le maximum de favoris'
              : 'Ajouter aux favoris'
        }
      />
    </div>
  );
}

export default FavAndHistoricRow;
