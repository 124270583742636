import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import DeleteWithConfirmationModal from '../../../shared/components/deleteWithConfirmationModal/DeleteWithConfirmationModal';
import checkUserCanDelete from '../../../shared/utils/checkUserCanDelete';
import { getAuthState } from '../../auth/authSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { getSectorState, sectorActions } from '../sectorSlice';
import sectorDeleteThunk from '../services/thunks/sectorDeleteThunk';

const DeleteSectorModal = () => {
  const { user } = useAppSelector(getAuthState);
  const { sectorForAction } = useAppSelector(getSectorState);
  const [hasUndeletableSubfolder, setHasUndeletableSubfolder] = useState<
    string[] | null
  >(null);

  const dispatch = useAppDispatch();

  const isEmptySector = isEmpty(sectorForAction?.subsIsEmpty);

  useEffect(() => {
    if (sectorForAction && user) {
      const result = checkUserCanDelete(sectorForAction.subs, user);

      if (!isEmpty(result)) {
        setHasUndeletableSubfolder(result as string[]);
      }
    }
  }, [sectorForAction, user]);

  const handleCloseModal = () => {
    dispatch(sectorActions.sectorForActionSet(null));
    dispatch(modalsActions.sectorDelete(false));
  };

  const handleConfirmationValid = () => {
    dispatch(sectorDeleteThunk({ sector: sectorForAction }));
    handleCloseModal();
  };

  return (
    <DeleteWithConfirmationModal
      onDeleteClick={handleConfirmationValid}
      isEmpty={isEmptySector}
      hasUndeletableSubs={hasUndeletableSubfolder}
      name={sectorForAction?.name ?? ''}
      onCloseModal={handleCloseModal}
      type="secteur"
    />
  );
};

export default DeleteSectorModal;
