import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { favoritePlacesActions } from '../../../redux/favoritePlaces/actions';
import {
  FavoriteSearch,
  getFavoritePlacesState,
} from '../../../redux/favoritePlaces/reducer';

export default function () {
  const cookieName = 'ufh';
  const [cookies, setCookie] = useCookies([cookieName]);
  const { favoritePlaces } = useAppSelector(getFavoritePlacesState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cookies[cookieName]) {
      setCookie(cookieName, [], {
        expires: new Date('01/01/2100'),
      });
    }
  }, []);

  const setHistoryCookie = (
    values: Partial<IMapState>,
    predictionBase: Prediction,
    isCity: boolean
  ) => {
    const existsHistoric = cookies[cookieName].find(
      (f: FavoriteSearch) => f.predictionId === predictionBase.id
    );
    const existsFavorite = favoritePlaces.find(
      (f: any) => f.predictionId === predictionBase.id
    );

    if (!existsHistoric && !existsFavorite) {
      if (values)
        if (cookies[cookieName].length === 10) {
          cookies[cookieName].pop();
        }
      // const split = predictionBase.type === PredictionTypeEnum.MUNICIPALITY;

      cookies[cookieName].unshift({
        idIri: '',
        predictionId: predictionBase.id,
        address: predictionBase.label ?? '',
        city: values.city ?? '',
        department: values.department ?? '',
        inseeCode: values.inseeCode ?? '',
        lat: parseFloat(values.mapCenter[0]),
        lng: parseFloat(values.mapCenter[1]),
        postalCode: values.postalCode ?? '',
        isCitySearch: isCity,
        ownerId: '',
      });

      setCookie(cookieName, cookies[cookieName], {
        expires: new Date('01/01/2100'),
      });
    }
  };

  const setHistoricAsFavorite = (value: FavoriteSearch, userId: string) => {
    const valueTemp: Partial<FavoriteSearch> = { ...value };
    delete valueTemp.idIri;
    dispatch(
      favoritePlacesActions.addFavoriteSearch.request({
        ...valueTemp,
        ownerId: userId,
      })
    );
    const tempCookie = cookies[cookieName].filter(
      (f: Partial<FavoriteSearch>) => f.predictionId !== value.predictionId
    );
    setCookie(cookieName, tempCookie);
  };

  const resetHistory = () => {
    //removeCookie(cookieName);
    setCookie(cookieName, JSON.stringify([]), {
      expires: new Date('01/01/2100'),
    });
  };

  return {
    cookie: cookies[cookieName] as FavoriteSearch[],
    setCookie: setHistoryCookie,
    removeCookie: () => resetHistory(),
    setHistoricAsFavorite,
  };
}
