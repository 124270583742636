import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../../assets/images/logo_urbanease_horizontal.svg';
import CrossIcon from '../../../../shared/components/icons/CrossIcon';
import styles from './genericModal.module.scss';
import GenericModalBackground from './GenericModalBackground';
import { getCssTheme } from './utils';

export enum GenericModalActionEnum {
  NONE,
  CROSS,
  OK,
  OKCANCEL,
}

export enum GenericModalEnum {
  INFO,
  CONFIRMATION,
  WARNING,
  ERROR,
}

interface IGenericModalProps {
  actionType: GenericModalActionEnum;
  modalType: GenericModalEnum;
  title?: string;
  children: React.ReactNode;
  closeModal: () => void;
  okButtonContent?: string;
  cancelButtonContent?: string;
  okCallback?: () => void;
  cancelCallback?: () => void;
  hasIcon?: boolean;
  icon?: string;
  hasLogo?: boolean;
  hasExtendWidth?: boolean;
  suppressionConfirm?: boolean;
  noCross?: boolean;
  className?: string;
  dataCy?: string;
}

function GenericModal(props: IGenericModalProps) {
  const {
    okButtonContent,
    cancelButtonContent,
    actionType,
    modalType,
    okCallback,
    cancelCallback,
    closeModal,
    title,
    children,
    hasIcon = true,
    hasLogo,
    hasExtendWidth,
    noCross = false,
    className,
    dataCy,
  } = props;

  const handleCancelClick = () => {
    if (cancelCallback) {
      cancelCallback();
    } else {
      closeModal();
    }
  };

  const handleOkClick = () => {
    if (okCallback) {
      okCallback();
    } else {
      closeModal();
    }
  };

  return (
    <GenericModalBackground modalType={modalType} hasExtendWidth={hasExtendWidth}>
      <div className={[styles.modalContainer, className].join(' ')} data-cy={dataCy}>
        {actionType !== GenericModalActionEnum.OK &&
          actionType !== GenericModalActionEnum.OKCANCEL &&
          !noCross && (
            <CrossIcon
              bgColor="TRANSPARENT"
              color="PRIMARY"
              width={30}
              height={30}
              className={styles.topIcon}
              onClick={closeModal}
              dataCy="generic-modal-close-btn"
            />
          )}
        {hasLogo && (
          <img src={logo} className={styles.firmName} alt="logo urbanease" />
        )}
        {title && <h2>{title}</h2>}

        <div className={styles.content}>
          {hasIcon && (
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={getCssTheme(modalType).icon} />
            </div>
          )}
          <div className={styles.body}>{children}</div>
        </div>

        {actionType !== GenericModalActionEnum.NONE && (
          <div className={styles.buttonGrp}>
            {actionType === GenericModalActionEnum.OKCANCEL && (
              <div
                className={styles.buttonCancel}
                onClick={handleCancelClick}
                data-cy="generic-modal-button-cancel"
              >
                {cancelButtonContent ?? 'Annuler'}
              </div>
            )}
            <div
              className={styles.buttonOk}
              onClick={handleOkClick}
              data-cy="generic-modal-button-ok"
            >
              {okButtonContent}
            </div>
          </div>
        )}
      </div>
    </GenericModalBackground>
  );
}
export default GenericModal;
