import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import useElementInDOM from '../../../../shared/hooks/useComponentInDom';

const ZoomButtonsMap = () => {
  const { isInDOM, interruptObserver } = useElementInDOM([
    'zoom-in-button',
    'zoom-out-button',
  ]);
  const map = useMap();

  // Create a custom zoom-in button
  useEffect(() => {
    // if all elements are in dom
    if (isInDOM) {
      const zoomInButton = document.getElementById('zoom-in-button');
      const zoomOutButton = document.getElementById('zoom-out-button');

      // Function to handle the button click event
      zoomInButton?.addEventListener('click', function () {
        map.zoomIn();
      });
      zoomOutButton?.addEventListener('click', function () {
        map.zoomOut();
      });

      // disconnect the MutationObserver
      interruptObserver();
    }
  }, [isInDOM]);

  return null;
};

export default memo(ZoomButtonsMap);
