import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { georisquesActions, getGeorisqueState } from '../../georisqueSlice';
import styles from './georisqueLegend.module.scss';
interface ISectionCheckboxContainerProps {
  checkbox: GeorisqueLegendSectionCheckbox;
  title: string;
}

function SectionCheckboxRow({ checkbox, title }: ISectionCheckboxContainerProps) {
  const { displayedLayers } = useAppSelector(getGeorisqueState);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = () => {
    dispatch(georisquesActions.checkRow(`${checkbox.layer}/${title}`));
  };

  // useEffect(() => {
  //   dispatch(
  //     georisquesActions.checkAll({
  //       layer: `${checkbox.layer}/${title}`,
  //       checked: isAllChecked,
  //     })
  //   );
  // }, [isAllChecked]);

  useEffect(() => {
    const checked = displayedLayers.includes(`${checkbox.layer}/${title}`);

    setIsChecked(checked);
  }, [displayedLayers]);

  return (
    <div className={styles.sectionCheckboxRow}>
      <div className={styles.rowHeader}>
        <input
          type="checkbox"
          name={`${checkbox.layer}/${title}`}
          checked={isChecked}
          onChange={handleChange}
        />
        <label htmlFor={checkbox.layer}>{checkbox.libelle}</label>
      </div>
      <div className={styles.rowLegend}>
        {isChecked &&
          (checkbox.legend ? (
            <img src={checkbox.legend} alt="" />
          ) : (
            <p>Aucune légende disponible</p>
          ))}
      </div>
    </div>
  );
}

export default SectionCheckboxRow;
