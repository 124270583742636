import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../App/store';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as RightArrowSmall } from '../../../../assets/images/right-arrow-small.svg';
import ButtonGeneric from '../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import SupportMailButton from '../../../../shared/components/buttons/supportMailButton/SupportMailButton';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { getAppState } from '../../../app/appSlice';
import useSubscriptionAccess from '../../../company/subscription/useSubscriptionAccess';
import { getModalsState } from '../../../modals/modalsSlice';
import styles from './noUrbaneaseAccountModal.module.scss';
interface INoUrbaneaseAccountModalProps {
  isLogged: boolean;
}
function NoUrbaneaseAccessModal({ isLogged }: INoUrbaneaseAccountModalProps) {
  const { displayNoUrbaneaseAccessModal } = useAppSelector(getAppState);

  const { connectionProcess } = useAppSelector(getModalsState);
  const navigate = useNavigate();
  const { isPdfLandOnly, isPdfInterfaceOnly } = useSubscriptionAccess();

  const pdfRedirect = () => {
    navigate(
      isPdfLandOnly
        ? '/pdf-urbanease-terrain'
        : isPdfInterfaceOnly
          ? '/pdf-urbanease-interface'
          : '/'
    );
  };

  const handleCloseModal = () => {};

  if (!displayNoUrbaneaseAccessModal || connectionProcess) return null;

  return (
    <GenericModal
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      okButtonContent="Retourner au PDF"
      noCross
      hasIcon={false}
      dataCy="no-urbanease-account-modal"
      closeModal={handleCloseModal}
      title="Accès restraint"
      hasLogo
    >
      <div className={styles.noUrbaneaseAccountModal}>
        <div className={styles.body}>
          <p>
            Votre abonnement actuel ne vous permet pas d’accéder à toute la richesse
            d’Urbanease
          </p>
          <ul>
            <li>Carte de prospection</li>
            <li>Stratégies de prospection</li>
            <li>Informations propriétaires</li>
            <li>Documents d’urbanisme</li>
            <li>Application mobile</li>
            <li>...</li>
          </ul>
        </div>
        <div className={styles.btnContainer}>
          <ButtonGeneric
            content={
              <div className={styles.pdfBtnContent}>
                <span>Retour vers l&apos;interface PDF</span> <RightArrowSmall />
              </div>
            }
            containerClassName={styles.button}
            onClick={pdfRedirect}
          />
          <ButtonGeneric
            content={
              <div className={styles.supportBtnContent}>
                <FontAwesomeIcon icon={faUser} className={styles.accountIcon} />
                <span>Gérer Mon Compte Admin</span>
              </div>
            }
            containerClassName={styles.button}
            onClick={() => navigate('/account')}
          />
          <SupportMailButton
            className={styles.button}
            textContent="Améliorer mon abonnement"
          />
        </div>
      </div>
    </GenericModal>
  );
}

export default NoUrbaneaseAccessModal;
