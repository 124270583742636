import { nanoid } from '@reduxjs/toolkit';
import { useContext, useState } from 'react';
import { GenericPopupContext } from './MarkerPopupGeneric';
import styles from './markerPopupGeneric.module.scss';

interface IMarkerPopupGenericTabsProps {
  tabs: string[];
}

function MarkerPopupGenericTabs({ tabs }: IMarkerPopupGenericTabsProps) {
  const { state, update } = useContext(GenericPopupContext);
  const [startIndexTab, setStartIndexTab] = useState(0);
  const [parsedTab] = useState(tabs.map((f, i) => i + '_' + f));

  const handleRollTab = (value: number) => {
    const v = startIndexTab + value;

    if (v >= 0 && v < parsedTab.length - 2) {
      setStartIndexTab(startIndexTab + value);
    }
  };

  const handleClick = (value: string) => {
    update(parsedTab.indexOf(value));
  };

  return (
    <div className={styles.genericPopupTabs}>
      {parsedTab.length > 3 && (
        <p
          className={`${styles.buttons} ${
            startIndexTab === 0 ? styles.disabled : ''
          }`}
          // disabled={startIndexTab === 0}
          onClick={() => handleRollTab(-1)}
        >
          {'<'}
        </p>
      )}
      <div>
        {parsedTab
          .filter((f, i) => i >= startIndexTab && i < startIndexTab + 3)
          .map((t) => (
            <p
              key={nanoid()}
              className={t === parsedTab[state] ? styles.selected : ''}
              onClick={() => handleClick(t)}
            >
              {t.split('_')[1]}
            </p>
          ))}
      </div>{' '}
      {parsedTab.length > 3 && (
        <p
          className={`${styles.buttons} ${
            startIndexTab === parsedTab.length - 3 ? styles.disabled : ''
          }`}
          // disabled={startIndexTab === parsedTab.length - 3}
          onClick={() => handleRollTab(1)}
        >
          {'>'}
        </p>
      )}
    </div>
  );
}

export default MarkerPopupGenericTabs;
