import { nanoid } from '@reduxjs/toolkit';
import { ReactNode, memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { getMapState } from '../../../map/mapSlice';
import fetchProspecSimilariesThunk from '../../services/thunks/landPDF/fetchProspecSimilariesThunk';
import styles from '../styles/prospecForm.module.scss';
import useProspecDatas from '../useProspecDatas';
import { priceComparisonsTabs } from '../utils';
import ProspecSimilatTypeTab from './ProspecSimilatTypeTab';
import SimilarAdsBody from './SimilarAdsBody';

interface IProps {
  similarAds: ProspecGetSimilars | null;
}
function ProspecSimilarAdsContainer() {
  const { watch, setValue } = useFormContext<IExternalPdfLandForm>();
  const { isCorseOrDom, prospecCityId, comparableAds } = useProspecDatas();
  const { geolocDatas } = useAppSelector(getMapState);
  const [canReload, setCanReload] = useState(true);
  // LOCAL STATES
  const [selectedTab, setSelectedTab] = useState<SimilarTabType>(
    priceComparisonsTabs[0]
  );
  const dispatch = useAppDispatch();

  const loadDatas = () => {
    // load data at componant init
    dispatch(
      fetchProspecSimilariesThunk({
        prospecCityId: prospecCityId.result,

        area: watch('landArea'),
        price: watch('estimatedPrice'),
      })
    );
  };

  useEffect(() => {
    // Enable if estimatedPrice change
    if (!canReload) {
      setCanReload(true);
    }
  }, [watch('estimatedPrice')]);

  useEffect(() => {
    if (!comparableAds.result) {
      loadDatas();
      setCanReload(false);
    }
  }, []);

  const handleReloadClick = () => {
    setCanReload(false);
    setValue('cheaperAds', []);
    setValue('equalAds', []);
    setValue('higherAds', []);
    loadDatas();
  };

  useEffect(() => {
    if (geolocDatas) {
      handleReloadClick();
    }
  }, [geolocDatas]);

  const getCount = (key: SimilarAdsType) =>
    comparableAds.result ? comparableAds.result[key]?.total ?? 0 : 0;

  const ReloadButton = () => (
    <button onClick={handleReloadClick} disabled={!canReload}>
      Recharger les annonces similaires
    </button>
  );

  const SimilarErrorComponent = (props: { children: ReactNode }) => {
    return (
      <div className={styles.prospecSimilarAdsContainer}>
        <ReloadButton />
        <div className={styles.errorMessage}>{props.children}</div>
      </div>
    );
  };

  if (isCorseOrDom) {
    return (
      <div className={styles.prospecSimilarAdsContainer}>
        <p className={styles.errorMessageSimilar}>
          Nous n’avons aucune annonce de biens similaires à la vente sur les 6
          derniers mois dans la commune en question.
        </p>
      </div>
    );
  }

  if (comparableAds.apiStatus === APIStatus.REJECTED) {
    let mes = '';
    switch (comparableAds.error.message) {
      case 'price error':
        return (
          <SimilarErrorComponent>
            <p>Le prix estimé ne peut être de 0 €.</p>
            <p>Modifiez le et rechargez les annonces.</p>
          </SimilarErrorComponent>
        );

      case 'area error':
        return (
          <SimilarErrorComponent>
            <p>La surface du terrain ne peut être de 0 €.</p>
            <p>Modifiez la et rechargez les annonces.</p>
          </SimilarErrorComponent>
        );
      default:
        return (
          <SimilarErrorComponent>
            <p>Erreur de chargement des annonces similaires.</p>
          </SimilarErrorComponent>
        );
    }
  }

  return (
    <div className={styles.prospecSimilarAdsContainer}>
      <ReloadButton />

      <p className={styles.checkIndication}>
        Sélectionnez un maximum de 3 annonces par catégorie de prix.
      </p>
      {/* header tab */}
      <div className={styles.tabsContainer}>
        {priceComparisonsTabs.map((m) => (
          <ProspecSimilatTypeTab
            isLoading={comparableAds.apiStatus === APIStatus.PENDING}
            key={nanoid()}
            ActiveTab={selectedTab.qtyKey === m.qtyKey}
            tab={m}
            onChange={() => setSelectedTab(m)}
            count={getCount(m.qtyKey)}
          />
        ))}
      </div>

      {/* tab content */}
      {comparableAds.apiStatus === APIStatus.PENDING ? (
        <div className={styles.loaderContainer}>
          <IndividualCircularLoader size={200} />
        </div>
      ) : (
        <SimilarAdsBody
          selectedTab={selectedTab}
          contents={comparableAds.result ?? null}
        />
      )}
    </div>
  );
}

export default memo(ProspecSimilarAdsContainer);
