import { LatLngTuple } from 'leaflet';
import { useAppSelector } from '../../../../../../../../App/store';
import { getMapState } from '../../../../../../../../features/map/mapSlice';
import EnvRiskContent from './EnvRiskContent';

interface IEnvRisksProps {
  envs: IParcelleEnv[];
  inseeCode: string;
  prefix: string;
  section: string;
  number: string;
}

// const createPdfUrlLink = (
//   latlng: number[],
//   inseeCode: string,
//   postalCode: string,
//   city: string,
//   prefix: string,
//   section: string,
//   number: string
// ) => {
//   return `https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi/rapport?form-parcelle=true&isCadastre=true&ign=false&lon=${latlng[1].toFixed(
//     6
//   )}&lat=${latlng[0].toFixed(
//     6
//   )}&codeInsee=${inseeCode}&CGU-parcelle=on&parcelle-commune=${postalCode}+${city}&parcelle-prefixe=${prefix}&parcelle-section=${section}&parcelle-numero=${number}#`;
// };

export default function EnvRisks(props: IEnvRisksProps): JSX.Element {
  const { plotDatas, geolocDatas } = useAppSelector(getMapState);
  return (
    <EnvRiskContent
      postalCode={geolocDatas?.postalCode}
      city={geolocDatas?.city}
      latlng={plotDatas?.markerLatLng as LatLngTuple}
      address={geolocDatas?.address}
      {...props}
    />
  );
}
