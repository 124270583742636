import { memo, useContext, useState } from 'react';
import { InputTabsContext } from '../../../../../../App/Layout';
import { useAppSelector } from '../../../../../../App/store';
import { getAuthState } from '../../../../../../features/auth/authSlice';
import BuildPermitsLegend from '../../../../../../features/buildPermits/components/buildPermitsLegend/BuildPermitsLegend';
import { getMapState } from '../../../../../../features/map/mapSlice';
import DeniedAccess from '../../../../../../shared/components/noRights/DeniedAccess';
import NoCity from '../../../../../../shared/components/noRights/NoCity';
import BuiltCaracteristicsLegend from '../../../../../builtCaracteristics/components/BuiltCaracteristicsLegend';
import EnvLegend from '../../../../../envs/components/envLegend/EnvLegend';
import GeorisqueLegend from '../../../../../georisques/components/legend/GeorisqueLegend';
import NationalServitudesLegend from '../../../../../nationalServitudes/components/nationalServitudesLegend/NationalServitudesLegend';
import '../../styles/menuTabs.scss';
import ArrowTab from '../arrowTab/ArrowTab';

const Environment = (): JSX.Element => {
  const { environmentSelectedCategory, setEnvironmentSelectedCategory } =
    useContext(InputTabsContext);
  const { deniedZone } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const [selected, setSelected] = useState<number>(environmentSelectedCategory);

  const handleChangeSelectedTab = (id: number): void => {
    setSelected(id);
    setEnvironmentSelectedCategory(id);
  };

  if (deniedZone) {
    return <DeniedAccess />;
  }

  if (!geolocDatas?.city) {
    return <NoCity />;
  }
  return (
    <div className="urba-env-content">
      <div className="tabs-wrapper">
        <ArrowTab
          label1={`Contraintes &`}
          label2="environnement"
          onClick={() => handleChangeSelectedTab(1)}
          selected={selected === 1}
        />
        <ArrowTab
          label1="Autorisations"
          label2={`d'urbanisme`}
          onClick={() => handleChangeSelectedTab(2)}
          selected={selected === 2}
        />
        <ArrowTab
          label1="DPE"
          // label2={`des bâtiments`}
          onClick={() => handleChangeSelectedTab(3)}
          selected={selected === 3}
        />
        <ArrowTab
          label1="Servitudes"
          label2={`Nationales`}
          onClick={() => handleChangeSelectedTab(4)}
          selected={selected === 4}
        />
        <ArrowTab
          label1="Géorisques"
          onClick={() => handleChangeSelectedTab(5)}
          selected={selected === 5}
        />
      </div>

      <div className="content-wrapper">
        {selected === 1 && <EnvLegend />}
        {selected === 2 && <BuildPermitsLegend />}
        {selected === 3 && <BuiltCaracteristicsLegend />}
        {selected === 4 && <NationalServitudesLegend />}
        {selected === 5 && <GeorisqueLegend />}
      </div>
    </div>
  );
};

export default memo(Environment);
