import { useAppSelector } from '../../../../../App/store';
import UpdatePasswordForm from '../../../../../shared/components/common/formComponents/updatePasswordForm/UpdatePasswordForm';
import { getAuthState } from '../../../../auth/authSlice';

import Footer from './Footer';

interface IUserCardProps {
  user: User;
  canUpdate?: boolean;
  authIdIri?: string;
}

function UserCard({ user }: IUserCardProps) {
  const authState = useAppSelector(getAuthState);

  return (
    <div key={user.idIri} className="card">
      <div className="card-header">
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <p className="header-email">{user.email}</p>
        <p>
          {user.isAdmin
            ? 'Administrateur'
            : user?.isManager
              ? 'Manager'
              : 'Prospecteur'}
        </p>
      </div>
      <p>
        <span>{user.plotStudyCount}</span> parcelles étudiées
        <br />
        <span>{user.procedureCount}</span> actions de prospection
      </p>

      <UpdatePasswordForm user={user} />

      {authState.isManager && <Footer user={user} />}
    </div>
  );
}

export default UserCard;
