import { GoogleSearchContryEnum } from '../features/app/appSlice';

const SessionStorageService = (function () {
  function _setDashboardTab(index: DashboardTab) {
    try {
      if (index || index === 0) {
        sessionStorage.setItem('DashboardTab', index);
      }
    } catch (error) {
      sessionStorage.setItem('DashboardTab', 'directory');
    }
  }

  function _getDashboardTab(): DashboardTab {
    try {
      const index = sessionStorage.getItem('DashboardTab');
      if (!index) {
        return 'directory';
      }

      return index as DashboardTab;
    } catch (err) {
      return 'directory';
    }
  }

  function _setAccountTab(index: AccountTab) {
    try {
      if (index || index === 0) {
        sessionStorage.setItem('AccountTab', index);
      }
    } catch (error) {
      sessionStorage.setItem('AccountTab', 'abonnement');
    }
  }

  function _getAccountTab(): AccountTab {
    try {
      const index = sessionStorage.getItem('AccountTab');
      if (!index) {
        return 'abonnement';
      }
      return index as AccountTab;
    } catch (err) {
      return 'abonnement';
    }
  }

  function _setGoogleCountryChoice(choice: GoogleSearchContryEnum) {
    try {
      if (choice) {
        sessionStorage.setItem('googleCountryChoice', choice.toString());
      }
    } catch (error) {
      sessionStorage.setItem(
        'googleCountryChoice',
        GoogleSearchContryEnum.FRANCE.toString()
      );
    }
  }

  function _getGoogleCountryChoice(): GoogleSearchContryEnum {
    try {
      const index = sessionStorage.getItem('googleCountryChoice');
      if (!index) {
        return GoogleSearchContryEnum.FRANCE;
      }
      return index as GoogleSearchContryEnum;
    } catch (err) {
      return GoogleSearchContryEnum.FRANCE;
    }
  }

  function _setSelectedCompanyIdIri(idIri: string | null) {
    try {
      if (idIri) {
        sessionStorage.setItem('selectedCompanyIdIri', idIri);
      }
    } catch (error) {
      sessionStorage.removeItem('selectedCompanyIdIri');
    }
  }

  function _removeSelectedCompanyIdIri() {
    sessionStorage.removeItem('selectedCompanyIdIri');
  }

  function _getSelectedCompanyIdIri() {
    try {
      const index = sessionStorage.getItem('selectedCompanyIdIri');
      if (!index) {
        return null;
      }
      return index;
    } catch (err) {
      return null;
    }
  }

  return {
    setDashboardTab: _setDashboardTab,
    getDashboardTab: _getDashboardTab,
    setAccountTab: _setAccountTab,
    getAccountTab: _getAccountTab,
    setGoogleCountryChoice: _setGoogleCountryChoice,
    getGoogleCountryChoice: _getGoogleCountryChoice,
    setSelectedCompanyIdIri: _setSelectedCompanyIdIri,
    getSelectedCompanyIdIri: _getSelectedCompanyIdIri,
    removeSelectedCompanyIdIri: _removeSelectedCompanyIdIri,
  };
})();

export default SessionStorageService;
