import { useFormContext } from 'react-hook-form';
import CheckboxFormPdfErrialCustom from '../../shared/components/form/CheckboxFormPdfErrialCustom';
import NumberInputFormPdfErrialCustom from '../../shared/components/rightPanelModalExternalPdfErrial/NumberInputFormPdfErrialCustom';
import styles from '../../shared/styles/pdfRightPanelModal.module.scss';

function LandInformations() {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h3>Informations sur le terrain</h3>
      <div className={styles.formSectionLandInfo}>
        <div className={styles.leftSectionBloc}>
          <CheckboxFormPdfErrialCustom name="drainage" label="Drainage" />
          <CheckboxFormPdfErrialCustom name="fence" label="Cloturé" />
          <CheckboxFormPdfErrialCustom name="roadAccess" label="Accès voirie" />
          <CheckboxFormPdfErrialCustom
            name="servicedLand"
            label="Terrain viabilisé"
          />
        </div>

        <div className={styles.rightSectionBloc}>
          <CheckboxFormPdfErrialCustom name="sewerAccess" label="Accès égouts" />
          <CheckboxFormPdfErrialCustom
            name="gasAndElectricityAccess"
            label="Accès gaz/électricité"
          />
          <CheckboxFormPdfErrialCustom
            name="waterAccess"
            label="Accès eau courante"
          />
        </div>
      </div>

      <div className={styles.rowInput}>
        <p>Taxe foncière</p>
        <NumberInputFormPdfErrialCustom
          name="propertyTax"
          error={Boolean(errors.propertyTax)}
          errorMsg={errors.propertyTax?.message as string}
          onFocus={(e) => e.target.select()}
          hasValueZeroNotNull
        />
      </div>
      <div className={styles.rowInput}>
        <p>Charges annuelles</p>
        <NumberInputFormPdfErrialCustom
          name="annualCharges"
          error={Boolean(errors.annualCharges)}
          errorMsg={errors.annualCharges?.message as string}
          onFocus={(e) => e.target.select()}
          hasValueZeroNotNull
        />
      </div>
    </div>
  );
}

export default LandInformations;
