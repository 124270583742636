import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useAppDispatch } from '../../App/store';
import Logo from '../../assets/images/logo_urbanease_horizontal.svg';
import {
  firstPasswordThunk,
  resetPasswordThunk,
} from '../../features/auth/authThunks';
import { validationSchemaLoginForm } from '../../features/auth/components/loginModal/formValidation';
import FieldInputPwdFormCustom from '../../shared/components/common/formComponents/passwordComponents/FieldInputPwdFormCustom';
import TextFieldFormCustom from '../../shared/components/common/formComponents/TextFieldFormCustom';
import useQuery from '../../shared/hooks/useQuery';
import './passwordResetPage.scss';

// using login form validation for base
const validationSchemaForm = validationSchemaLoginForm.shape({
  passwordConfirm: Yup.string()
    .required('La confirmation du mot de passe est requise')
    .oneOf([Yup.ref('password'), ''], 'Les mots de passe ne correspondent pas'),
  token: Yup.string().required(),
});

type FormType = {
  email: string;
  password: string;
  passwordConfirm: string;
  token: string;
};

export default function PasswordResetPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { queries } = useQuery();

  useEffect(() => {
    if (queries && !queries.token_password) {
      navigate('/');
    }
  }, [queries]);

  const useFormValues = useForm<FormType>({
    defaultValues: {
      email: queries.email as string,
      password: '',
      passwordConfirm: '',
      token: queries.token_password as string,
    },
    resolver: yupResolver(validationSchemaForm),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormValues;

  const submitForm = (data: FormType): void => {
    switch (path) {
      case '/reset_password':
        dispatch(resetPasswordThunk({ ...data, navigate }));
        break;
      case '/init_first_password':
        dispatch(firstPasswordThunk({ ...data, navigate }));
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className="main-wrapper-password-reset">
      <div className="header-wrapper">
        <div className="header-col-left">
          <img src={Logo} alt="logo" />
        </div>
        <button onClick={handleClick}>Retour sur Urbanease</button>
      </div>
      <div className="body-wrapper">
        <div className="password-header">
          <h2>
            {path === '/premier-mot-de-passe'
              ? 'Création de mot de passe'
              : 'Changement de mot de passe'}
          </h2>
          <span>
            {path === '/premier-mot-de-passe'
              ? 'Je souhaite créer mon mot de passe'
              : 'Je souhaite changer mon mot de passe'}
          </span>
        </div>

        <FormProvider {...useFormValues}>
          <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
            <TextFieldFormCustom type="email" name="email" placeholder="Email" />
            <FieldInputPwdFormCustom name="password" placeholder="Mot de passe" />
            <FieldInputPwdFormCustom
              name="passwordConfirm"
              placeholder="Confirmation du mot de passe"
            />
            <button type="submit" className="submit-btn">
              ENVOYER
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
