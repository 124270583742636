import { useMap, useMapEvents } from 'react-leaflet';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import fetchPlotRequestBuildParams from '../../../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../../../redux/plot/actions';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { GenericModalEnum } from '../../../../shared/components/modals/genericModal/GenericModal';
import { PDF_URLS } from '../../../../shared/constants';
import { addrServProcessAsync } from '../../../../utils/addrServProcessAsync';
import { autocompleteActions } from '../../../autocomplete/autocompleteSlice';
import { getCompanyState } from '../../../company/companySlice';
import { getPdfState } from '../../../externalPdf/pdfSlice';
import { fetchPlotForMultiPlotPdfThunk } from '../../../externalPdf/services/thunks/fetchPlotForMultiPlotPdfThunk';
import { modalsActions } from '../../../modals/modalsSlice';
import { getMultiPlotSelectState } from '../../../multiPlotSelectFeature/multiPlotSelectSlice';
import { fetchPlotForMultiPlotThunk } from '../../../multiPlotSelectFeature/multiPlotSelectThunk';
import useRightPanel from '../../../panels/hooks/useRightPanel';
import { getPanelState } from '../../../panels/panelsSlice';
import { plotServitudesActions } from '../../../plotServitudes/plotServitudesSlice';
import { getMapState, mapActions } from '../../mapSlice';
import { getMapPluginsUrbaToolbarState } from '../../plugins/mapPluginSlice';

const EventLayer = () => {
  const dispatch = useAppDispatch();
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { companyId } = useAppSelector(getCompanyState);
  const { isMultiPlotSelector } = useAppSelector(getMultiPlotSelectState);
  const { plotCountPdf, isMultiPlotSelectorPdf, multiPlotsPdf } =
    useAppSelector(getPdfState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { closeRightPanel } = useRightPanel();
  const map = useMap();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  const noPlotAvailable = () => {
    map.getContainer().classList.remove('loading-plot');
    dispatch(
      modalsActions.alert({
        status: true,
        context: 'noPlotFounded',
        modalType: GenericModalEnum.INFO,
      })
    );
  };

  const newGeocodeResponseParser = (data: GeolocPointInfo | null) => {
    try {
      if (!data) {
        throw new Error('no-fr');
      }

      if (!isMultiPlotSelector && !isMultiPlotSelectorPdf) {
        dispatch(plotServitudesActions.reset());
        rightPanelDisplay && closeRightPanel();
        dispatch(autocompleteActions.setInputValue(data.address));
        const request = fetchPlotRequestBuildParams(data, null, map.getZoom());

        if (data?.inseeCode) {
          if (geolocDatas?.inseeCode && data?.inseeCode !== geolocDatas?.inseeCode) {
            dispatch({ type: 'app/changeInsee' });
          }
          dispatch(mapActions.geolocSet(data));
        }

        if (request) {
          // add shouldTriggerRightPanel to open right panel on manuel plot select
          dispatch(
            plotActions.fetchPlot.request({
              ...request,
              shouldTriggerRightPanel: true,
            })
          );
        } else {
          throw new Error('no-fr');
        }
      } else {
        const coords = data.coordinates as number[];
        if (pdfPath) {
          dispatch(
            fetchPlotForMultiPlotPdfThunk({
              lat: coords[0].toString(),
              lng: coords[1].toString(),
              geolocPointInfo: data,
            })
          );
        } else {
          dispatch(
            fetchPlotForMultiPlotThunk({
              lat: coords[0],
              lng: coords[1],
              department: data.department as string,
              companyId: companyId as number,
              cityName: data.city as string,
              mapAddress: data.address ?? null,
              zipCode: data.postalCode as string,
            })
          );
        }
      }
    } catch (error: any) {
      switch (error.message) {
        case 'no-fr':
        default:
          noPlotAvailable();
          break;
      }
    }
  };

  const clickHandler = async (lat: number, lng: number) => {
    try {
      map.getContainer().classList.add('loading-plot');
      const addr = await addrServProcessAsync(lat, lng);

      newGeocodeResponseParser(addr);
    } catch (error: any) {
      switch (error?.status) {
        case 403:
          dispatch(
            modalsActions.alert({
              status: true,
              context: 'notIncludedInSubscription',
              modalType: GenericModalEnum.INFO,
            })
          );
          break;
        default:
          noPlotAvailable();
          break;
      }
    }
  };

  useMapEvents({
    async click(e) {
      if (
        (isMultiPlotSelector && path === '/') ||
        (isMultiPlotSelectorPdf &&
          pdfPath &&
          plotCountPdf < 5 &&
          multiPlotsPdf.apiStatus !== APIStatus.PENDING)
      ) {
        clickHandler(e.latlng.lat, e.latlng.lng);
      }
    },
    async dblclick(e) {
      if (!isMultiPlotSelector && !activeToolbar && !isMultiPlotSelectorPdf) {
        clickHandler(e.latlng.lat, e.latlng.lng);
      }
    },
  });

  return null;
};

export default EventLayer;
