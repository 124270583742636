import logo from '../../assets/images/logo_urbanease_horizontal.svg';
import ConnectionProcessContent from '../../features/auth/components/connectionProcess/ConnectionProcessContent';
import { ConnectionProcessContextProvider } from '../../features/auth/components/connectionProcess/ConnextionProcessContext';
import FirmDisplay from '../../features/auth/components/connectionProcess/FirmDisplay';
import styles from './externalConnection.module.scss';

function ExternalConnection() {
  return (
    <div className={styles.externalConnection}>
      <div className={styles.externalConnectionContainer}>
        <img src={logo} alt="logo urbanease" className={styles.logoUrbanease} />

        <FirmDisplay />
        <ConnectionProcessContextProvider>
          <ConnectionProcessContent />
        </ConnectionProcessContextProvider>
      </div>
    </div>
  );
}

export default ExternalConnection;
