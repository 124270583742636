import { PMTiles } from 'pmtiles';
import * as protomapsL from 'protomaps-leaflet';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import usePLU from '../../../../shared/hooks/usePLU';
import { getMapState } from '../../../map/mapSlice';
import { MyPaintSymbolizerPlu } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';

function paintRules(layers: any[]) {
  return layers.map((l) => ({
    dataLayer: l.layer,
    symbolizer: new MyPaintSymbolizerPlu(),
    minzoom: 0,
    maxzoom: 22,
  }));
}

function labelRules(layers: any[]) {
  return layers.map((l) => ({
    dataLayer: l.layer,
    symbolizer: new PolygonLabelSymbolizer({
      label_props: (zoom: any, feature: any) => {
        feature.props.zone_name =
          feature.props.nom ??
          `${feature.props.zone ?? ''}${
            feature.props.zone_child ? `-${feature.props.zone_child}` : ''
          }`;
        return ['zone_name'];
      },
      fill: 'white',
      width: 2,
      stroke: 'red',
      font: '600 20px sans-serif',
    }),
    minzoom: 13,
    maxzoom: 22,
  }));
}

function PmTilesPlu() {
  const map = useMap();
  const { pluDisplayed, pluPmtiles } = usePLU();
  const { geolocDatas } = useAppSelector(getMapState);

  const resetLayer = () => {
    map.eachLayer((l: any) => {
      if (l.options.layer_group === 'plu') {
        map.removeLayer(l);
      }
    });
  };

  useEffect(() => {
    if (geolocDatas?.inseeCode && pluDisplayed && pluPmtiles) {
      // reset layer if an city allready displayed
      resetLayer();

      const p = new PMTiles(pluPmtiles);

      p.getMetadata().then(
        (m: any) => {
          const layer: any = protomapsL.leafletLayer({
            paintRules: paintRules(m.tilestats.layers),
            labelRules: labelRules(m.tilestats.layers),
            attribution: '',
            url: p.source.getKey(),
          });
          layer.options.layer_group = 'plu';
          layer.options.layer_pmtiles_group = 'plu_pmtiles';
          layer.options.zIndex = 3;
          layer.addTo(map);
        },
        (err: any) => console.log('err', err)
      );
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, pluDisplayed, pluPmtiles]);

  return null;
}

export default memo(PmTilesPlu);
