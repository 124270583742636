import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getPlotState } from '../../../../../../redux/plot/reducer';
import { mapActions } from '../../../../mapSlice';

function useMapCenterBtn() {
  const { parcelle } = useAppSelector(getPlotState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (url: string) => {
    if (parcelle) {
      const center = { lat: parcelle.lat, lng: parcelle.lng };
      dispatch(mapActions.setClicked3dPoint(center));
      navigate(url);
    }
  };

  return { handleClick };
}

export default useMapCenterBtn;
