import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import { memo, useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../../App/store';
import { PDF_URLS } from '../../../../../shared/constants';
import AdvancedSearchMarkers from '../../../../advancedPlotSearch/components/map/AdvancedSearchMarkers';
import AdvancedSearchZone from '../../../../advancedPlotSearch/components/map/AdvancedSearchZone';
import BrownfieldsOnMap from '../../../../brownfields/components/BrownfieldsOnMap';
import BuildPermitsOnMap from '../../../../buildPermits/components/buildPermitsOnMap/BuildPermitsOnMap';
import DpeMarkersClusterGroup from '../../../../builtCaracteristics/components/dpe/dpeOnMap/DpeMarkersClusterGroup';
import EntitiesDisplayLayer from '../../../../displayManager/components/DisplayManagerLayer';
import DvfMarker from '../../../../dvf/components/DvfMarker';
import { getPdfState } from '../../../../externalPdf/pdfSlice';
import FolderPlotStudiesFitbound from '../../../../folders/components/FolderPlotStudiesFitbound';
import GeorisqueLayer from '../../../../georisques/components/GeorisqueLayer';
import NationalServitudesLayer from '../../../../nationalServitudes/components/NationalServitudesLayer';
import MultiPlotSelectLayer from '../../../../plot/components/multiPlotSelectLayer/MultiPlotSelectLayer';
import PlotServitudesLayers from '../../../../plotServitudes/components/plotServitudesLayers';
import AltimetryLayer from '../../../plugins/altimetryPlugin/AltimetryLayer';
import DrawPlugin from '../../../plugins/drawPlugin/DrawPlugin';
import MeasureLayer from '../../../plugins/measurePlugin/components/MeasureLayer';
import ChangeView from '../ChangeView';
import ControlTooltips from '../ControlTooltips';
import EnvLayers from '../EnvLayers';
import EventLayer from '../EventLayer';
import FrancePolygon from '../FrancePolygon';
import PluLayer from '../PluLayer';
import SatelliteLayer from '../SatelliteLayer';
import TownHallMarker from '../TownHallMarker';
import ZoomButtonsMap from '../ZoomButtonsMap';
import ActivePlotBuildingMarkers from '../activePlot/ActivePlotBuildingMarkers';
import ActivePlotGeo from '../activePlot/ActivePlotGeo';
import ActivePlotMarker from '../activePlot/ActivePlotMarker';
import PmTilesCadastre from '../pmTilesCadastre';
import './leafletMap.scss';

const Map = () => {
  const { pdfRightPanelDisplay, isMultiPlotSelectorPdf } =
    useAppSelector(getPdfState);
  const [externalPdfErrial, setExternalPdfErrial] =
    useState<boolean>(pdfRightPanelDisplay);
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  useEffect(() => {
    setExternalPdfErrial(pdfRightPanelDisplay);
  }, [pdfRightPanelDisplay]);

  return (
    <MapContainer
      id="leafletMap"
      scrollWheelZoom={true}
      className={`urbn-map ${pdfPath && externalPdfErrial && 'external-pdf-errial'}`}
      zoomControl={false}
      doubleClickZoom={false}
    >
      {path === '/' && (
        <>
          <DrawPlugin />
          <MeasureLayer />
          <AltimetryLayer />
          <EnvLayers />
          <BuildPermitsOnMap />
          <TownHallMarker />
          <DpeMarkersClusterGroup />
          <BrownfieldsOnMap />
          <AdvancedSearchMarkers />
          <AdvancedSearchZone />
          <NationalServitudesLayer />
          <PlotServitudesLayers />
          <GeorisqueLayer />
          <DvfMarker />
          <PluLayer />
          <FolderPlotStudiesFitbound />
          <ActivePlotBuildingMarkers />
          <EntitiesDisplayLayer />
        </>
      )}

      <ChangeView />
      <EventLayer />
      <FrancePolygon />
      <SatelliteLayer />
      <PmTilesCadastre />
      {!isMultiPlotSelectorPdf && (
        <>
          <ActivePlotGeo />
          <ActivePlotMarker />
        </>
      )}
      <MultiPlotSelectLayer />
      <ControlTooltips />
      <ZoomButtonsMap />
    </MapContainer>
  );
};

export default memo(Map);
