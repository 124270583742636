import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { checkForNullValue } from '../../utils';
import { fontStyles } from '../styles/pdfStyles';
import InformationLine from './InformationLine';

interface IProps {
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
}

function GeneralInformations({ formDatas, isOrpi }: IProps) {
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <View>
      <View style={styles.title}>
        <Text style={styles.titleText}>Informations générales</Text>
      </View>
      <InformationLine label="Drainage" value={formDatas.drainage} isOrpi={isOrpi} />
      <InformationLine label="Clôturé" value={formDatas.fence} isOrpi={isOrpi} />
      <InformationLine
        label="Accès voirie"
        value={formDatas.roadAccess}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Accès gaz/électricité"
        value={formDatas.gasAndElectricityAccess}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Accès eau courante"
        value={formDatas.waterAccess}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Accès égouts"
        value={formDatas.sewerAccess}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Taxe foncière"
        value={checkForNullValue(formDatas.propertyTax)}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Charges annuelles"
        value={checkForNullValue(formDatas.annualCharges)}
        isOrpi={isOrpi}
      />
    </View>
  );
}

export default GeneralInformations;

const commonStyles = { title: { marginBottom: '20px' } };

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  titleText: {
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  titleText: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
  },
});
