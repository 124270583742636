import { MouseEvent } from 'react';
import XCross from '../../../../../assets/images/xicon-white.png';
import styles from '../toolbars.module.scss';
interface IToolbarCancelBtnProps {
  onClick: (e: MouseEvent<HTMLDivElement>, type: IconType) => void;
  type: IconType;
  optionsContainer?: React.ReactNode;
  tooltip: React.ReactNode;
}

function ToolbarCancelBtn({
  type,
  onClick,
  optionsContainer,
  tooltip,
}: IToolbarCancelBtnProps) {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick(e, type);
  };
  return (
    <div className={styles.urbaToolbarBtnContainer}>
      <div
        className={`${styles.iconContainer} ${styles.cancelButton}`}
        onClick={(e) => handleClick(e)}
      >
        <img src={XCross} />
        {tooltip}
      </div>

      {optionsContainer}
    </div>
  );
}

export default ToolbarCancelBtn;
