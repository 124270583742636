import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import useQuery from '../../../../shared/hooks/useQuery';
import useCompany from '../../../company/hooks/useCompany';
import OwnerInfos from '../../../dashboardOwnerDatas/components/OwnerInfos';
import CityOwnerTable from '../../../dashboardOwnerDatas/components/ownerTable/CityOwnerTable';
import PlotStudiesTable from '../../../dashboardOwnerDatas/components/plotTable/PlotStudiesTable';
import {
  OwnerDatasSearchTypeEnum,
  getDashboardOwnerDatasState,
} from '../../../dashboardOwnerDatas/dashboardOwnerDatasSlice';
import {
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
} from '../../../dashboardOwnerDatas/dashboardOwnerDatasThunk';

import { Paper } from '@mui/material';
import { useNavigate } from 'react-router';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import DataOwnerSearchEngine from '../../../dashboardOwnerDatas/components/DataOwnerSearchEngine';
import DashboardLoader from '../dashboardLoader/DashboardLoader';
import './dataOwnersTab.scss';

export default function DataOwnersTab() {
  const { queries } = useQuery();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useCompany();
  const { searchType, ownersFromInseeCode, plotsFromSiren } = useAppSelector(
    getDashboardOwnerDatasState
  );

  const displayLoader =
    ownersFromInseeCode.apiStatus === APIStatus.PENDING ||
    plotsFromSiren.apiStatus === APIStatus.PENDING;

  useEffect(() => {
    if (queries.siren) {
      dispatch(
        fetchOwnerInfosFromSirenThunk({
          siren: queries.siren,
        })
      );
      dispatch(
        fetchOwnerPlotFromSirenThunk({
          siren: queries.siren,
          page: 1,
          itemsPerPage: 15,
        })
      );
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="tab-data-owners">
      <div className="tab-inner" data-cy="tab-data-owners-title">
        <h1>Data Propriétaires</h1>
      </div>
      <div className="tab-body">
        <DataOwnerSearchEngine />

        {searchType === OwnerDatasSearchTypeEnum.PERSON && <OwnerInfos />}

        {displayLoader ? (
          <DashboardLoader />
        ) : (
          <Paper className="paper-table">
            {searchType === OwnerDatasSearchTypeEnum.CITY ? (
              <CityOwnerTable />
            ) : (
              <PlotStudiesTable />
            )}
          </Paper>
        )}
      </div>
    </div>
  );
}
