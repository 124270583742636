import useStudy from '../../../hooks/useStudy';
import ProcedureCardMap from './ProcedureCardMap';

interface IProcedureListProps {
  updateAllowed: boolean;
}

function StudyProcedureList({ updateAllowed }: IProcedureListProps): JSX.Element {
  const { studyProcedures } = useStudy({ loadStudyProcedures: true });

  return (
    <div className="procedure-list">
      <ProcedureCardMap
        updateAllowed={updateAllowed}
        studyProcedureList={studyProcedures}
      />
    </div>
  );
}

export default StudyProcedureList;
