import { Navigate, useLocation } from 'react-router';
import useSubscriptionAccess from '../../features/company/subscription/useSubscriptionAccess';
import PdfLandingPage from '../../pages/pdfLandingPage/PdfLandingPage';

function PdfInterfaceAccessOnly() {
  const location = useLocation();
  const { isPdfInterfaceOnly } = useSubscriptionAccess();

  return isPdfInterfaceOnly ? (
    <PdfLandingPage type="URBANEASE_PDF_INTERFACE" />
  ) : (
    <Navigate
      to={'/'}
      replace
      state={{
        from: location,
      }}
    />
  );
}

export default PdfInterfaceAccessOnly;
