import { useAppSelector } from '../../../App/store';
import LoginModal from '../../auth/components/loginModal/LoginModal';
import SignUpModal from '../../auth/components/signUpModal/SignUpModal';
import UrbaneaseIntroModal from '../../auth/components/urbaneaseIntroModal/UrbaneaseIntroModal';
import { getModalsState } from '../modalsSlice';

function AuthModalContainer() {
  const { signup, signin, urbaneaseIntro } = useAppSelector(getModalsState);
  return (
    <>
      {signup && <SignUpModal open={signup} />}
      {signin && <LoginModal />}
      {urbaneaseIntro && <UrbaneaseIntroModal />}
    </>
  );
}

export default AuthModalContainer;
