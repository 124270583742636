import { nanoid } from '@reduxjs/toolkit';
import { indexableEntitiesParser } from '../../../../../../../utils/indexableEntityParser';
import FolderPlot from './FolderPlot';
import styles from './folderPlot.module.scss';

interface IFolderPlotsProps {
  folder: IFolder;
  color: string;
}

const FolderPlots = ({ folder, color }: IFolderPlotsProps) => {
  return (
    <div className={styles.folderPlots}>
      {indexableEntitiesParser(folder.plotStudies)?.map((p) => {
        return (
          <FolderPlot
            key={nanoid()}
            isSub={Boolean(folder.parent)}
            plotStudy={p.entity}
            color={color}
            isDashboard={false}
            index={p?.index}
          />
        );
      })}
    </div>
  );
};

export default FolderPlots;
