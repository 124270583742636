import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { flexStyles, fontStyles } from '../styles/pdfStyles';
import LogoUrbaneaseFooterSvg from './svg/LogoUrbaneaseFooterSvg';
import OrpiLogo from './svg/OrpiLogo';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
}

function Footer({ agency, formDatas, isOrpi }: IProps) {
  return (
    <View
      style={[
        styles.footerContainer,
        flexStyles.flexStartCenterRow,
        fontStyles.fs10,
      ]}
      fixed
    >
      {isOrpi ? (
        <OrpiLogo red height="40" width="100" />
      ) : (
        <LogoUrbaneaseFooterSvg />
      )}
      <View style={styles.line} />
      {agency ? (
        <>
          <View style={styles.agencyInfos}>
            {agency && (
              <>
                <Text style={fontStyles.b700}>{agency.name}</Text>
                <Text>{agency.address}</Text>
                <Text style={fontStyles.cOrpi}>{agency.mail}</Text>
              </>
            )}
          </View>
          <View style={[styles.agencyNotice, fontStyles.fs8]}>
            {agency && <Text>{agency.legalNotice}</Text>}
          </View>
        </>
      ) : (
        <>
          <View style={styles.footerAgencyWithoutPH}>
            <Text>Agence : {formDatas?.companyName ?? ''}</Text>
          </View>
          <View style={styles.agencyNotice}>
            <Text>
              Agent : {`${formDatas?.firstName ?? ''} ${formDatas?.lastName ?? ''}`}
            </Text>
            <Text>Email : {formDatas?.email ?? ''}</Text>
            <Text>Téléphone : {formDatas?.phone ?? ''}</Text>
          </View>
        </>
      )}
    </View>
  );
}

export default Footer;

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    height: '50px',
    left: '30px',
    right: '30px',
    fontSize: '10px',
    paddingTop: '10px',
    marginBottom: '5px',
    borderTop: '1px solid #000',
  },
  line: {
    height: '80%',
    borderLeft: '1px solid #000',
    marginHorizontal: '10px',
  },
  agencyInfos: {
    width: '200px',
    height: '100%',
    paddingHorizontal: '10px',
    marginTop: '-10px',
  },
  agencyNotice: { paddingHorizontal: '10px', width: '300px', marginTop: '-10px' },
  footerAgencyWithoutPH: {
    width: '200px',
    height: '100%',
    paddingHorizontal: '10px',
    marginTop: '-10px',
    display: 'flex',
    justifyContent: 'center',
  },
});
