import { Image, Page, Text, View } from '@react-pdf/renderer';
import NoImage from '../../../../assets/images/orpiPdf/imageNotAvailable.png';
import LogoUrbaneasePricehubbleVerticalSvg from '../../landPDF/pdfFiles/components/svg/LogoUrbaneasePricehubbleVerticalSvg';
import HomeFooterCommon from './components/HomeFooterCommon';
import RedPolygonCommonPage1 from './components/svg/RedPolygonCommonPage1';
import { pdfStyles } from './styles/pdfStylesCommon';

interface IPageOneProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  ownerName: string | { name: string }[];
  plotIds: string[];
  user: {
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
  };
  companyName: string;
  homePagePicture: string;
}

function PageOne(datas: IPageOneProps) {
  const getCustomerLine = (line: number, formDatas: { name: string }[]): string =>
    formDatas
      .slice(line === 1 ? 0 : 2, line === 1 ? 2 : undefined)
      .map((owner) => owner.name)
      .join(', ');

  return (
    <Page size="A4" style={pdfStyles.pageHome}>
      <View style={pdfStyles.homeLogoAndTitle}>
        <LogoUrbaneasePricehubbleVerticalSvg />
        <View style={pdfStyles.homeTopTitleSection}>
          <RedPolygonCommonPage1
            style={pdfStyles.homeTopTitleSectionImage}
            width={400}
            height={200}
          />
          <View>
            <Text style={pdfStyles.homeTopTitle}>Dossier Synthèse</Text>
            <Text style={pdfStyles.homeTopSubTitle}>Urbanisme et environnement</Text>
          </View>
        </View>
      </View>

      <View style={pdfStyles.homePlotIdsSection}>
        <Text style={pdfStyles.homePlotIdsTitle}>Parcelle(s) étudiée(s)</Text>
        <Text style={pdfStyles.homePlotIdsText}>{datas.plotIds.join(', ')}</Text>
      </View>

      {datas.homePagePicture ? (
        <Image
          src={`data:image/jpeg;base64,${datas.homePagePicture}`}
          style={pdfStyles.homeMapImage}
        />
      ) : (
        <Image src={NoImage} style={pdfStyles.homeMapImage} />
      )}

      <View style={pdfStyles.homeOwnerAndAddressBloc}>
        <View style={pdfStyles.homeOwnerSection}>
          <Text>Étude réalisée pour:</Text>
          {typeof datas.ownerName === 'string' ? (
            <Text style={pdfStyles.homeOwnerData}>{datas.ownerName}</Text>
          ) : (
            <View>
              <Text style={pdfStyles.homeOwnerData}>
                {getCustomerLine(1, datas.ownerName)}
              </Text>
              <Text style={pdfStyles.homeOwnerData}>
                {getCustomerLine(2, datas.ownerName)}
              </Text>
            </View>
          )}
        </View>
        <View style={pdfStyles.homeAddressSection}>
          <Text>{datas.address.addressLine2}</Text>
          <Text>{datas.address.addressLine1}</Text>
        </View>
      </View>

      <View style={pdfStyles.homeTitleBloc}>
        <Text style={pdfStyles.homeTitle}>Dossier Estimation</Text>
        <Text style={pdfStyles.byUrbanease}>Par Urbanease</Text>
        <Text style={pdfStyles.date}>{`Edité le ${datas.date}`}</Text>
      </View>

      <HomeFooterCommon {...datas} />
    </Page>
  );
}

export default PageOne;
