import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import image1 from '../../../../assets/images/orpiPdf/rightPriceImage1.png';
import image2 from '../../../../assets/images/orpiPdf/rightPriceImage2.png';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import { pageStyles } from '../../shared/styles/pdfStyle';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function RightPricePage({ agency, formDatas, isOrpi, address, date }: IProps) {
  const headerLines = ['Déterminer le juste prix', 'Une étape clé'];

  return (
    <Page size="A4" style={[pageStyles.page, fontStyles.ffOrpi]}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}
      <Header lines={headerLines} isOrpi={isOrpi} />
      <View style={styles.bloc1}>
        <Text>
          Déterminer de façon judicieuse le prix de vente d&apos;un bien constitue
          une étape incontournable et déterminante pour la réussite de votre projet
          immobilier.
        </Text>
        <Text style={[fontStyles.b700]}>
          En effet, 95% des acquéreurs potentiels refusent de visiter un bien estimé
          20 % au-dessus du marché.
        </Text>
      </View>
      <View style={styles.bloc2}>
        <Text style={styles.title}>
          L&apos;importance d&apos;un bien au prix du marché
        </Text>
        <Image src={image1} />
      </View>
      <View style={styles.bloc3}>
        <Text style={styles.title}>Volume des visites</Text>
        <Text style={styles.bloc3Text}>
          Lors de la mise en vente de votre bien, les 4 premières semaines sont
          fondamentales : c’est durant cette période que la visibilité sur internet
          et les visites seront les plus importantes et que votre bien aura le plus
          de chance d’être vendu. Il est donc important d’afficher un prix juste dès
          la publication de l’annonce.
        </Text>
        <Image src={image2} />
      </View>

      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default RightPricePage;

const styles = StyleSheet.create({
  title: {
    marginBottom: '10px',
    ...fontStyles.b700,
    ...fontStyles.fs21,
  },
  bloc1: {
    marginTop: '10px',
    padding: '20px',
    borderRadius: 10,
    backgroundColor: '#FFEDDF',
    borderColor: '#EFD8CA',
    borderWidth: 1,
    ...fontStyles.fs14,
  },
  bloc2: {
    marginTop: '15px',
    paddingBottom: '15px',
    borderBottomColor: '#D0D0D0',
    borderBottomWidth: 1,
  },
  bloc3: {
    marginTop: '15px',
  },
  bloc3Text: {
    ...fontStyles.fs11,
    marginBottom: '20px',
  },
});
