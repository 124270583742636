import { dateFormat } from '../../../lib/formats/dataFormat';
import { euroFormat } from '../../../utils/jsFunctions';

interface popupBodyContent {
  transaction: ITransaction;
}
const PopupBodyContent = ({ transaction }: popupBodyContent): JSX.Element => {
  const nullable = 'Non renseigné';

  return (
    <div className="dvf-popup-body-transaction">
      <p>
        Date de mutation :
        <span>
          {transaction.mutationDate !== nullable
            ? dateFormat(transaction.mutationDate)
            : nullable}
        </span>
      </p>
      <p>
        Valeur :
        <span>
          {transaction.landValue !== nullable
            ? euroFormat(parseInt(transaction.landValue, 10))
            : nullable}
        </span>
      </p>
      <p>
        Type :<span>{transaction.localType}</span>
      </p>
      <p>
        Superficie :
        <span>
          {transaction.area !== nullable ? `${transaction.area} m²` : nullable}
        </span>
      </p>
      <p>
        Prix au m² :
        <span>
          {transaction.sqmPrice !== nullable
            ? euroFormat(parseInt(transaction.sqmPrice, 10))
            : nullable}
        </span>
      </p>
      {transaction.cntRoom !== '0' && (
        <p>
          Nbre de pièces : <span>{transaction.cntRoom}</span>
        </p>
      )}
    </div>
  );
};

export default PopupBodyContent;
