import { Outlet } from 'react-router';
import useSubscriptionAccess from '../../features/company/subscription/useSubscriptionAccess';

function HasUrbaneaseAccess() {
  const { hasUrbaneaseAccess } = useSubscriptionAccess();

  return hasUrbaneaseAccess ? <Outlet /> : null;
}

export default HasUrbaneaseAccess;
