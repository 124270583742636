import { Popup } from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import PopupBodyContent from './PopupBodyContent';

const DvfMarkerPopup = ({ transaction }: any): JSX.Element => {
  const { parcelleId } = transaction;

  return (
    <Popup>
      <div className="dvf-popup-content">
        <h2 className="dvf-popup-header">{`Parcelle: ${parcelleId}`}</h2>
        <div className="dvf-popup-body">
          {transaction &&
            transaction.transactions.map((t: ITransaction) => (
              <PopupBodyContent key={uuidv4()} transaction={t} />
            ))}
        </div>
      </div>
    </Popup>
  );
};

export default DvfMarkerPopup;
