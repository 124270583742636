import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  foldersActions,
  getFoldersState,
} from '../../../features/folders/foldersSlice';
import { getMapPluginsUrbaToolbarState } from '../../../features/map/plugins/mapPluginSlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { sectorActions } from '../../../features/sectors/sectorSlice';
import TrashIcon from '../icons/TrashIcon';
import { IIconActionProps } from './ItemActions';
import styles from './itemActions.module.scss';

function GarbageIcon<T>({ type, item }: IIconActionProps<T>) {
  const [action, setAction] = useState<string>('');
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { folders } = useAppSelector(getFoldersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === 'folder') {
      if (item.parent) {
        setAction('Supprimer sous-dossier');
      } else {
        setAction('Supprimer dossier');
      }
    } else {
      if (item.parent) {
        setAction('Supprimer sous-secteur');
      } else {
        setAction('Supprimer secteur');
      }
    }
  }, []);

  const handleDeleteModal = () => {
    if (!activeToolbar) {
      if (type === 'folder' && folders) {
        dispatch(foldersActions.folderForActionSet(item as IFolder));
        dispatch(modalsActions.folderDelete(true));
      } else {
        dispatch(sectorActions.sectorForActionSet(item as ISector));
        dispatch(modalsActions.sectorDelete(true));
      }
    }
  };
  return (
    <TrashIcon
      dataCy="garbage-icon"
      tooltipText={action}
      width={37}
      height={37}
      bgColor="ERROR"
      color="WHITE"
      isRoundIcon
      onClick={handleDeleteModal}
      className={[styles.icon, styles.garbageIcon, styles.redIcon].join(' ')}
    />
  );
}

export default GarbageIcon;
