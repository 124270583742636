import { useEffect, useState } from 'react';
import styles from './urbaGpt/urbaGpt.module.scss';

function TypingAnimation({ text }: { text: string }) {
  const [displayedText, setDisplayedText] = useState('');
  const [blinkAnimation, setBlinkAnimation] = useState(true);

  useEffect(() => {
    let i = 0;
    const timerId = setInterval(() => {
      if (i <= text.length) {
        setDisplayedText(text.substring(0, i));
        i++;
      } else {
        clearInterval(timerId);
        setBlinkAnimation(false);
      }
    }, 25);

    return () => {
      clearInterval(timerId);
    };
  }, [text]);

  return (
    <>
      {blinkAnimation ? (
        <div className={styles.response}>
          <div dangerouslySetInnerHTML={{ __html: displayedText }} />
          <span className={styles.blink}></span>
        </div>
      ) : (
        <div
          className={styles.response}
          dangerouslySetInnerHTML={{ __html: displayedText }}
        />
      )}
    </>
  );
}

export default TypingAnimation;
