import { useAppSelector } from '../../../../../App/store';
import useSubscriptionAccess from '../../../../company/subscription/useSubscriptionAccess';
import DashboarButton from '../../../../dashboard/components/dashboardButton/DashboardButton';
import { getPanelState } from '../../../../panels/panelsSlice';
import RightBottomButtons from './rightBottomButtons/RightBottomButtons';

export default function ButtonsOnMap(): JSX.Element {
  const { rightButtons } = useAppSelector(getPanelState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();

  return (
    <>
      {rightButtons && hasUrbaneaseAccess && <DashboarButton />}
      <RightBottomButtons />
    </>
  );
}
