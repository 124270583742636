import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Marker, Polygon, useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getMapState } from '../../../features/map/mapSlice';
import MarkerIcon from '../../../shared/components/map/markerIconWithIndex/MarkerIconWithIndex';
import { SECTOR_OWNER_COLOR } from '../../../shared/constants';
import { mapActions } from '../../map/mapSlice';
import { getDisplayManagerState } from '../displayManagerSlice';
import calcEntitiesbounds from '../utils/calcEntitiesBounds';

function DisplayManagerLayer() {
  const { entities } = useAppSelector(getDisplayManagerState);
  const { franceLayerDisplay } = useAppSelector(getMapState);
  const map = useMap();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (franceLayerDisplay && !isEmpty(entities.result)) {
      dispatch(mapActions.franceLayerDisplaySet(false));
    }
    const bounds = calcEntitiesbounds(entities.result);
    if (bounds && !isEmpty(bounds)) {
      map.fitBounds(bounds, { maxZoom: 17 });
    }
  }, [entities]);

  return (
    <>
      {entities.result.map((m) => (
        <div key={nanoid()}>
          {m.polygon ? (
            <Polygon
              fillOpacity={m.isSub ? 0.4 : 0.2}
              weight={m.isSub ? 1 : 2}
              positions={m.polygon}
              color={m.color ?? SECTOR_OWNER_COLOR}
            />
          ) : null}
          <Marker
            position={[m.coordinates.lat, m.coordinates.lng]}
            icon={MarkerIcon(m.index, m.color ?? 'red')}
          />
        </div>
      ))}
    </>
  );
}

export default DisplayManagerLayer;
