import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getPlotState } from '../../../redux/plot/reducer';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getAppState } from '../../app/appSlice';
import useFolder from '../../folders/hooks/useFolder';
import { fetchSyntheseFolderThunk } from '../../folders/services/thunks/foldersThunk';
import { modalsActions } from '../../modals/modalsSlice';
import { getUsersState } from '../../users/usersSlice';
import { checkAndUpdatePlotStudyDatas } from '../services/checkAndUpdatePlotStudyDatas';
import fetchStudyContactsThunk from '../services/thunks/fetchStudyContactsThunk';
import fetchStudyProceduresThunk from '../services/thunks/fetchStudyProceduresThunk';
import {
  checkExistingPlotStudyThunk,
  constructStudyDatasThunk,
} from '../services/thunks/studyThunks';
import { getStudyParamsState } from '../slices/studyParamsSlice';
import { getStudyState, studyActions } from '../slices/studySlice';

interface IUseStudy {
  loadStudyProcedures?: boolean;
  loadStudyContacts?: boolean;
  initialLoad?: boolean;
}
export default function useStudy({ loadStudyProcedures, initialLoad }: IUseStudy) {
  const { folders } = useFolder({});
  const studyParams = useAppSelector(getStudyParamsState);
  const { parcelle } = useAppSelector(getPlotState);
  const { contactRoles, plotStudyStatuses, procedureTypes } =
    useAppSelector(getAppState);
  const { users } = useAppSelector(getUsersState);
  const { study } = useAppSelector(getStudyState);
  const { studyPlotStudies, studyProcedures, studyContacts } =
    useAppSelector(getStudyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      studyParams.idIri &&
      users &&
      contactRoles.result &&
      plotStudyStatuses.result &&
      procedureTypes.result
    ) {
      // load study rocedures
      if (
        loadStudyProcedures &&
        !studyProcedures.result &&
        studyProcedures.apiStatus === APIStatus.IDLE
      ) {
        dispatch(fetchStudyProceduresThunk());
      }
      if (
        loadStudyProcedures &&
        !studyContacts.result &&
        studyContacts.apiStatus === APIStatus.IDLE
      ) {
        dispatch(fetchStudyContactsThunk());
      }

      // load construct study datas
      if (studyParams.type && !study.result && folders) {
        dispatch(
          constructStudyDatasThunk({
            type: studyParams.type,
            idIri: studyParams.idIri,
          })
        );
      }
    }
  }, [
    studyParams.idIri,
    users,
    contactRoles.result,
    plotStudyStatuses.result,
    procedureTypes.result,
    folders,
    loadStudyProcedures,
  ]);

  const checkPsDatas = async (ps: IPlotStudy, p: IPlot) => {
    if (
      (p.zone.zone !== ps.zone ||
        p.zone.zoneChild !== ps.zoneChild ||
        parseInt(p.theoricCapacity) !== ps.area) &&
      study.apiStatus === APIStatus.IDLE
    ) {
      const newPs = await checkAndUpdatePlotStudyDatas({
        ps,
        zones: p.zone,
        surface: +p.theoricCapacity,
      });

      dispatch(studyActions.studySet(newPs));
    }
  };

  useEffect(() => {
    if (initialLoad && studyPlotStudies.result) {
      dispatch(fetchSyntheseFolderThunk({ plotStudies: studyPlotStudies.result }));
    }
  }, [studyPlotStudies.result]);

  useEffect(() => {
    if (study.result && parcelle) {
      const ps = study.result as IPlotStudy;

      if (ps.fullPlotId === parcelle.parcelleId) {
        checkPsDatas(ps, parcelle);
      }
    }
  }, [study.result, parcelle, initialLoad]);

  useEffect(() => {
    if (studyParams.type === 'plotStudy' && parcelle && folders && initialLoad) {
      if (!studyParams.noCheckExisting && !study.result) {
        dispatch(checkExistingPlotStudyThunk({ fullPlotId: parcelle.parcelleId }));
      }

      if (study.result) {
        const ps = study.result as IPlotStudy;

        if (ps.fullPlotId === parcelle.parcelleId) {
          checkPsDatas(ps, parcelle);
        }
      }
    }
  }, [parcelle, studyParams, folders, initialLoad]);

  const deleteStudyProcedure = (procedureIdIri: string) =>
    dispatch(
      modalsActions.deleteStudyProcedureConfirmationModal({
        display: true,
        idIri: procedureIdIri,
      })
    );

  return {
    study: study.result,
    studyParams,
    studyProcedures: studyProcedures.result,
    studyContacts: studyContacts.result,
    deleteStudyProcedure,
  };
}
