import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { PDF_COLOR_PRIMARY } from '../../../shared/constants';

const style = StyleSheet.create({
  bloc: {
    border: '1px solid #679CC6' + PDF_COLOR_PRIMARY,
    color: '#679CC6',
    width: '112px',
    height: '88px',
    margin: '4px 0 4px 4px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  blocMulti: {
    border: '1px solid #679CC6' + PDF_COLOR_PRIMARY,
    color: '#679CC6',
    width: '112px',
    height: '88px',
    margin: '4px 0 4px 4px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  data: {
    fontSize: '15px',
    fontWeight: 500,
  },
  dataMulti: {
    fontSize: '12px',
    fontWeight: 500,
    margin: 0,
    padding: 0,
  },

  label: {
    fontSize: '12px',
  },
  labelMulti: {
    fontSize: '12px',
  },
});

interface IPlotDetailInfoBlocProps {
  data: string | string[];
  label: string[];
  isMultiPlot?: boolean;
}
function PlotDetailInfoBloc({ data, label, isMultiPlot }: IPlotDetailInfoBlocProps) {
  return (
    <View style={isMultiPlot ? style.blocMulti : style.bloc}>
      <View>
        {isMultiPlot ? (
          (data as string[]).map((m) => (
            <Text key={m} style={style.dataMulti}>
              {m}
            </Text>
          ))
        ) : (
          <Text style={style.data}>{data}</Text>
        )}
      </View>
      <View>
        {label.map((l) => {
          return (
            <Text
              key={nanoid()}
              style={isMultiPlot ? style.labelMulti : style.label}
            >
              {l}
            </Text>
          );
        })}
      </View>
    </View>
  );
}

export default PlotDetailInfoBloc;
