import { memo } from 'react';
import { TileLayer } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import { getMapState } from '../../mapSlice';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const SatelliteLayer = memo(() => {
  const { satelliteDisplay } = useAppSelector(getMapState);

  return (
    <>
      {satelliteDisplay ? (
        <TileLayer
          url={
            'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=' + GOOGLE_KEY
          }
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          key="satellite"
          opacity={1}
          maxNativeZoom={19}
          maxZoom={22}
        />
      ) : (
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          opacity={1}
          key="plain_map"
          maxNativeZoom={19}
          maxZoom={22}
        />
      )}
    </>
  );
});

SatelliteLayer.displayName = 'SatelliteLayer';

export default SatelliteLayer;
