import {
  GoogleMap,
  StreetViewPanorama,
  useJsApiLoader,
} from '@react-google-maps/api';
import use3DMapCenter from '../../features/map/hooks/use3DMapContainer';
import ViewPageContainer from '../../shared/components/viewPageContainer/ViewPageContainer';
import styles from '../../shared/components/viewPageContainer/viewPageContainer.module.scss';
import './streetviewPage.scss';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_KEY;

function StreetviewPage() {
  const { center } = use3DMapCenter();

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-streetview-script',
    googleMapsApiKey: GOOGLE_API_KEY ?? '',
  });

  return (
    <ViewPageContainer>
      {isLoaded && center ? (
        <GoogleMap mapContainerClassName={styles.content} center={center} zoom={17}>
          <StreetViewPanorama
            options={{
              showRoadLabels: true,
              visible: true,
              position: center,
              imageDateControl: true,
              // adress panel display
              addressControl: true,
              // bottom right buttons
              disableDefaultUI: true,
              // full screen icon button
              fullscreenControl: false,
              // back arrow at top left
              enableCloseButton: false,
              // tehoric old image date display
              linksControl: true,
              // orientation
              pov: {
                // 0 = north, 90 = east, 180 = south, 270 = west
                heading: 0,
                // > 0 : top direction, < 0 bottom direction
                pitch: 14,
              },
            }}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
    </ViewPageContainer>
  );
}

export default StreetviewPage;
