import { useAppDispatch } from '../../../App/store';
import { foldersActions } from '../../../features/folders/foldersSlice';
import { fetchFolderPlotStudiesThunk } from '../../../features/folders/services/thunks/foldersThunk';

import { sectorActions } from '../../../features/sectors/sectorSlice';
import ExpandIcon from '../icons/ExpandIcon';
import styles from './itemActions.module.scss';

interface IArrowIconProps<T> {
  isOpen: boolean;
  isDashboard?: boolean;
  type: string;
  item: IFolderSector<T>;
}
function ArrowIcon<T>({ type, isOpen, item, isDashboard }: IArrowIconProps<T>) {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    const newStatus = !isOpen;
    if (newStatus) {
      if (type === 'folder') {
        dispatch(
          fetchFolderPlotStudiesThunk({
            folder: item as IFolder,
            isDashboard,
          })
        );

        if (item.parent) {
          dispatch(foldersActions.deployedSubFolderSet(item as IFolder));
        } else {
          dispatch(foldersActions.deployedSubFolderReset());
          dispatch(foldersActions.deployedFolderSet(item as IFolder));
        }
      } else {
        dispatch(sectorActions.deployedSectorSet(item as ISector));
      }
    } else {
      if (type === 'folder') {
        dispatch(foldersActions.deployedSubFolderReset());
        if (!item.parent) {
          dispatch(foldersActions.deployedFolderReset());
        }
      } else {
        dispatch(sectorActions.deployedSectorReset());
      }
    }
  };
  return (
    <ExpandIcon
      className={[styles.dropDownIcon, isOpen ? styles.close : ''].join(' ')}
      onClick={handleClick}
    />
  );
}

export default ArrowIcon;
