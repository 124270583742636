import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import TextFieldFormCustom from '../../../../shared/components/common/formComponents/TextFieldFormCustom';
import { authActions, getAuthState } from '../../authSlice';

import { classicLoginThunk } from '../../authThunks';

import { Checkbox, FormControlLabel } from '@mui/material';
import FieldInputPwdFormCustom from '../../../../shared/components/common/formComponents/passwordComponents/FieldInputPwdFormCustom';
import { ILoginContentProps } from '../loginContent/LoginContent';
import {
  initialLoginForm,
  validationSchemaLoginForm,
} from '../loginModal/formValidation';
import styles from './loginForm.module.scss';

function LoginForm({ isSignupModal }: ILoginContentProps) {
  const { error } = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();

  const userFormOptions = useForm<ILoginParams>({
    defaultValues: initialLoginForm,
    resolver: yupResolver(validationSchemaLoginForm),
  });
  const { handleSubmit } = userFormOptions;

  const submitForm = (data: ILoginParams): void => {
    dispatch(classicLoginThunk(data));
  };

  const handleInputChange = () => {
    if (error) {
      dispatch(authActions.resetError());
    }
  };

  return (
    <FormProvider {...userFormOptions}>
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit(submitForm)}
        autoComplete="off"
      >
        <TextFieldFormCustom
          type="email"
          name="email"
          placeholder="Email"
          onChange={handleInputChange}
          dataCy="email-input"
          errorTooltip
        />

        <FieldInputPwdFormCustom
          isLoginForm
          name="password"
          placeholder="Mot de passe"
          onChange={handleInputChange}
          dataCy="password-input"
          errorTooltipPlacment="bottom"
        />

        {error && (
          <div
            className={[
              styles.error,
              isSignupModal ? styles.withBackground : '',
            ].join(' ')}
          >
            <p>{error.message}</p>
          </div>
        )}

        <FormControlLabel
          classes={{
            root: [styles.rememberMe, isSignupModal ? '' : styles.colorBlue].join(
              ' '
            ),
          }}
          control={
            <Checkbox
              defaultChecked
              classes={{
                root: [
                  styles.rememberMe,
                  isSignupModal ? '' : styles.colorBlue,
                ].join(' '),
              }}
            />
          }
          label="Se souvenir de moi"
        />

        <button
          type="submit"
          className={[
            styles.submitButton,
            isSignupModal ? styles.lightBlue : '',
          ].join(' ')}
        >
          SE CONNECTER
        </button>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
