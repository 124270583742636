import { delay, isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import SessionStorageService from '../../../../services/SessionStorageService';
import { companyActions, getCompanyState } from '../../../company/companySlice';
import { fetchCompanyThunk } from '../../../company/companyThunks';
import ChooseCompanyContent from '../../../company/components/chooseCompanyModal/ChooseCompanyContent';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import {
  CompanyProcessStepEnum,
  ConnectionWorkFlowEnum,
} from '../../utils/connexionProcessEnums';
import AuthError from './AuthError';
import { CONNEXION_PROCESS_DELAY } from './ConnectionProcessContent';
import { useConnectionProcessContext } from './ConnextionProcessContext';
import styles from './connectionProcess.module.scss';

const displayLoader = (step: CompanyProcessStepEnum) => {
  let text = '';
  switch (step) {
    case CompanyProcessStepEnum.PROCESS_INIT:
      text = 'Initialisation de la compagnie';
      break;

    default:
      break;
  }

  return (
    <>
      {text !== '' && (
        <>
          <h3>{text}</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
    </>
  );
};

function CompanyProcess() {
  const { decodedToken, setWorkflowState, handleErrorClick, processDatas } =
    useConnectionProcessContext();
  const [internalStep, setInternalStep] = useState<CompanyProcessStepEnum>(
    CompanyProcessStepEnum.IDLE
  );
  const { company } = useAppSelector(getCompanyState);
  const [selectedCompany, setSelectedCompany] = useState<TokenCompany | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (company.result) {
      setInternalStep(CompanyProcessStepEnum.COMPANY_LOADED);
    }
  }, [company]);

  // company init process
  const handleCompanyChoice = (
    company: TokenCompany,
    step?: CompanyProcessStepEnum
  ) => {
    setSelectedCompany(company);
    dispatch(
      companyActions.companyInit({
        companyId: company.id,
        companyIdIri: company.idIri,
        firm: company.firm?.name ?? null,
      })
    );
    dispatch(
      fetchCompanyThunk({
        companyIdIri: company.idIri,
        userId: decodedToken?.userId as number,
      })
    );

    if (step) {
      setInternalStep(step);
    }
  };

  useEffect(() => {
    switch (internalStep) {
      case CompanyProcessStepEnum.IDLE: {
        if (decodedToken) {
          setInternalStep(CompanyProcessStepEnum.PROCESS_INIT);
        } else {
          setInternalStep(CompanyProcessStepEnum.ERROR_TOKEN);
        }
        break;
      }
      case CompanyProcessStepEnum.PROCESS_INIT: {
        const companies = decodedToken?.companies ?? [];
        if (isEmpty(companies)) {
          setInternalStep(CompanyProcessStepEnum.ERROR_NO_COMPANY);
        } else {
          const ssCompanyIdIri = SessionStorageService.getSelectedCompanyIdIri();
          dispatch(companyActions.setCompanies(companies));

          const chooseCompanyProcess = () => {
            //check 1 or more company
            if (companies.length === 1) {
              handleCompanyChoice(
                companies[0],
                CompanyProcessStepEnum.COMPANY_CHOSEN
              );
            } else {
              setInternalStep(CompanyProcessStepEnum.COMPANY_CHOICE);
            }
          };

          delay(() => {
            if (ssCompanyIdIri) {
              const cc = companies.find((f) => f.idIri === ssCompanyIdIri);
              if (cc) {
                handleCompanyChoice(cc, CompanyProcessStepEnum.COMPANY_CHOSEN);
              } else {
                SessionStorageService.removeSelectedCompanyIdIri();
                chooseCompanyProcess();
              }
            } else {
              chooseCompanyProcess();
            }
          }, CONNEXION_PROCESS_DELAY);
        }
        break;
      }
      case CompanyProcessStepEnum.COMPANY_CHOICE: {
        break;
      }
      case CompanyProcessStepEnum.COMPANY_CHOSEN: {
        break;
      }
      case CompanyProcessStepEnum.COMPANY_LOADED: {
        delay(() => {
          setInternalStep(CompanyProcessStepEnum.END_PROCESS);
        }, CONNEXION_PROCESS_DELAY);
        break;
      }
      case CompanyProcessStepEnum.END_PROCESS: {
        setWorkflowState(ConnectionWorkFlowEnum.START_USER_PROCESS);
        break;
      }
      default:
        break;
    }
  }, [decodedToken, internalStep]);

  return (
    <div className={styles.companyProcess}>
      {internalStep === CompanyProcessStepEnum.COMPANY_CHOICE && (
        <ChooseCompanyContent
          companies={decodedToken?.companies ?? []}
          onCompanyChoice={handleCompanyChoice}
        />
      )}
      {internalStep === CompanyProcessStepEnum.COMPANY_CHOSEN && (
        <>
          <h3>Chargement de la compagnie ...</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
      {(internalStep === CompanyProcessStepEnum.COMPANY_LOADED ||
        internalStep === CompanyProcessStepEnum.END_PROCESS) && (
        <>
          <p>
            Compagnie <span>{selectedCompany?.name}</span>
          </p>
        </>
      )}

      {internalStep === CompanyProcessStepEnum.ERROR_NO_COMPANY && (
        <AuthError
          title="Aucune compagnie trouvée"
          lines={[
            "l'utilisateur chargé n'appartient à aucune compagnie.",
            'Veuillez contacter urbanease.',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}

      {displayLoader(internalStep)}
    </div>
  );
}

export default memo(CompanyProcess);
