import { useAppSelector } from '../../../../../App/store';
import { getMailshotsState } from '../../../../mailshots/mailshotsSlice';
import { MailshotsSelectionTypeEnum } from '../../../../mailshots/types/mailshotsEnums';
import ProspectionContainer from '../../../../panels/components/rightPanelModal/tabsContent/bodys/components/prospection/ProspectionContainer';
import StudyContactContent from './StudyContactContent';

interface IProps {
  study: IStudy;
  updateAllowed: boolean;
  studyType: StudyParamsType | null;
}
function StudyContactContainer({ study, updateAllowed, studyType }: IProps) {
  const { selectType } = useAppSelector(getMailshotsState);
  const handleScroll = (offset: number) => {
    window.scrollTo(0, offset);
  };
  return (
    <ProspectionContainer
      title="Contacts"
      isOpen={
        studyType === 'plotStudy'
          ? selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT
          : selectType ===
            MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
      }
    >
      <StudyContactContent
        studyIdIri={study.idIri}
        onScroll={handleScroll}
        updateAllowed={updateAllowed}
      />
    </ProspectionContainer>
  );
}

export default StudyContactContainer;
