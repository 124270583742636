import { nanoid } from '@reduxjs/toolkit';
import L from 'leaflet';
import { memo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import MarkerPopupGeneric from '../../../../shared/components/markerPopupGeneric/MarkerPopupGeneric';
import { getBuildPermitsState } from '../../buildPermitsSlice';
import { buildPermitsForMapParser } from '../../builtPermitsParser';
import { dynamicMarkerClassName } from '../../utils/utils';
import BuildPermitPopupContent from './BuildPermitPopupContent';

const BuildPermitsOnMap = () => {
  const {
    buildPermitsVisible,
    buildPermits,
    displayedTypes,
    displayedFilters,
    dateFilter,
    housingFilter,
  } = useAppSelector(getBuildPermitsState);

  return (
    <>
      {buildPermitsVisible &&
        buildPermitsForMapParser(
          buildPermits.result ?? [],
          displayedTypes,
          displayedFilters,
          dateFilter,
          housingFilter
        ).map((plot) => {
          return (
            <Marker
              key={nanoid()}
              position={[plot.lat, plot.lng]}
              icon={L.divIcon({
                className: dynamicMarkerClassName(
                  plot.content,
                  plot.state ?? '',
                  plot.isRecent
                ),

                html: ReactDOMServer.renderToString(
                  <div>{plot.state === 'Annulé' ? 'x' : ''}</div>
                ),
              })}
            >
              <Popup closeOnClick={false}>
                <MarkerPopupGeneric
                  tabs={plot.tabs}
                  title={`Parcelle ${plot.plotId}`}
                  contents={plot.content}
                >
                  <BuildPermitPopupContent content={undefined as never} />
                </MarkerPopupGeneric>
              </Popup>
            </Marker>
          );
        })}
    </>
  );
};

export default memo(BuildPermitsOnMap);
