import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import FilterSortBloc from '../../../../shared/components/filterSortBloc/FilterSortBloc';
import { genericEntitySort } from '../../../../shared/utils/sortTools';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { sortableFolderPlotStudies } from '../../utils/sortableFolderPlotStudies';
import DashboardFolderPlotContainer from './DashboardFolderPlotContainer';
import { folderPlotStudiesForSelect } from './datas';

interface IDashboardFolderPlotsProps {
  folder: IFolder;
  color: string;
  plotSort?: string;
  isDashboard: boolean;
  isSub?: boolean;
}

function DashboardFolderPlots({ folder, isSub }: IDashboardFolderPlotsProps) {
  const [displayedPlotStudies, setDisplayedPlotStudies] =
    useState<SortablePlotStudies>();
  const [order, setOrder] = useState<Order>('asc');
  const [sortKey, setSortKey] = useState<string>('');

  useEffect(() => {
    const parsed = sortableFolderPlotStudies(folder.plotStudies);

    const sorted = genericEntitySort<SortablePlotStudy>(parsed ?? [], {
      key: sortKey as keyof SortablePlotStudy,
      order,
    });

    setDisplayedPlotStudies(sorted);
  }, [order, sortKey, folder.plotStudies]);

  return (
    <div>
      {!displayedPlotStudies ? (
        <IndividualCircularLoader size={75} className="folder-plots-loader" />
      ) : isEmpty(displayedPlotStudies) ? (
        <p className="no-plot-studies p-max">Aucune parcelle trouvée</p>
      ) : (
        <>
          <div className="filter-container-plot">
            <FilterSortBloc
              inputPlaceHolder="Recherche de parcelle"
              selectItems={folderPlotStudiesForSelect}
              setOrder={setOrder}
              setSortKey={setSortKey}
              selectPlaceHolder="Trier parcelles par"
            />
          </div>
          <DashboardFolderPlotContainer
            plotStudies={displayedPlotStudies}
            isSub={isSub}
          />
        </>
      )}
    </div>
  );
}

export default DashboardFolderPlots;
