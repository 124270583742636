import { nanoid } from '@reduxjs/toolkit';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import { getPlotState } from '../../../redux/plot/reducer';
import { parserService } from '../../../services/ParserService';
import { getDvfState } from '../dvfSlice';
import DvfMarkerPopup from './DvfMarkerPopup';
import './styles.scss';

const DvfMarker = (): JSX.Element => {
  const [parsedTransactions, setParsedTransactions] = useState<IDvfPopup[]>([]);
  const { parcelle } = useAppSelector(getPlotState);
  const { dvfVisible } = useAppSelector(getDvfState);

  useEffect(() => {
    if (!isEmpty(parcelle?.transactions)) {
      setParsedTransactions(
        parserService.dvf.popupMarkerDisplay(
          parcelle?.transactions?.filter((f) => !f.selectedParcelle) ?? []
        )
      );
    }
  }, [parcelle?.transactions]);

  return (
    <>
      {dvfVisible &&
        parsedTransactions?.map((t) => {
          return (
            <Marker
              key={nanoid()}
              position={[t.lat, t.lng]}
              icon={L.divIcon({
                className: 'dvf-icon',
              })}
            >
              <DvfMarkerPopup transaction={t} />
            </Marker>
          );
        })}
    </>
  );
};

export default DvfMarker;
