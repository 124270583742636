import { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import '../../../../shared/components/filterSortBloc/filterSortBloc.scss';
import GenericTabContainer from '../../../../shared/components/genericTab/GenericTabContainer';
import { appActions } from '../../../app/appSlice';
import { getAuthState } from '../../../auth/authSlice';
import useRightPanel from '../../../panels/hooks/useRightPanel';
import { dashboardTabsValues } from '../../constants';
import './dashboardTabs.scss';

interface IDashboardTabsProps {
  selectedTab: DashboardTab;
}

const DashboardTabs = ({ selectedTab }: IDashboardTabsProps): JSX.Element => {
  const { isManager } = useAppSelector(getAuthState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();
  const dispatch = useAppDispatch();
  const tabLabels = dashboardTabsValues
    .filter((elt) => isManager || elt.role.includes('user'))
    .map((elt) => ({
      id: elt.id,
      name: elt.libelle,
    }));
  const selected = tabLabels.find((f) => f.id === selectedTab);

  const handleTabChange = (tab: DashboardTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    dispatch(appActions.setDashboardTab(tab));
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={`dashboard-tabs`}>
      <GenericTabContainer
        tabs={tabLabels}
        selectedDefault={selected ?? null}
        onSelect={(entity) => handleTabChange(entity.id as DashboardTab)}
        dataCyBase="dashboard-tab"
      />
    </div>
  );
};

export default memo(DashboardTabs);
