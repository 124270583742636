import { useAppDispatch } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import ConnectionProcessContent from './ConnectionProcessContent';
import { ConnectionProcessContextProvider } from './ConnextionProcessContext';

function ConnexionProcessModal() {
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(modalsActions.connectionProcess(false));
  };

  return (
    <GenericModal
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      title="Connexion"
      closeModal={handleCloseModal}
      hasIcon={false}
      hasLogo
      dataCy="connexion-process-modal"
    >
      <ConnectionProcessContextProvider>
        <ConnectionProcessContent />
      </ConnectionProcessContextProvider>
    </GenericModal>
  );
}

export default ConnexionProcessModal;
