import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import { getAdvancedPlotSearchState } from '../../advancedPlotSearchSlice';
import styles from './bodaccModal.module.scss';

export default function BodaccModal() {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector(getAdvancedPlotSearchState);

  const handleClose = () => {
    dispatch(modalsActions.bodaccModal(false));
  };

  const handleSearch = () => {
    dispatch(
      modalsActions.advancedSearchResult({ status: true, context: formData })
    );
    handleClose();
  };

  return (
    <GenericModal
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.OKCANCEL}
      okButtonContent="Affiner ma recherche"
      cancelButtonContent="Poursuivre la recherche"
      title="Recherche & Calculs"
      okCallback={handleClose}
      cancelCallback={handleSearch}
      closeModal={handleClose}
      hasIcon={true}
    >
      <p className={styles.bodaccModal}>
        Conseil: vous n’avez renseigné qu’un seul critère, pour obtenir de plus
        amples résultats, ou des résultats plus précis, nous vous conseillons
        d’ajouter d’autres critères de recherche.
      </p>
    </GenericModal>
  );
}
