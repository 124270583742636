import { Autocomplete, TextField } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { memo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { fetchAllSubscriptionCities } from '../subscriptionRequests';

const PATTERN_LENGTH = 3;

interface IAutocompleteInputCitiesProps {
  className?: string;
  selectedCity: AutocompleteCity | AutocompleteCity[] | null;
  onChange: (city: AutocompleteCity | AutocompleteCity[] | null) => void;
  multiple?: boolean;
}

const useStyles = makeStyles()({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
  },
});

const AutocompleteInputCities = ({
  className,
  selectedCity,
  onChange,
  multiple,
}: IAutocompleteInputCitiesProps) => {
  const { subscription } = useAppSelector(getCompanyState);
  const [cities, setCities] = useState<AutocompleteCities>([]);
  const { classes } = useStyles();

  const filterOptions = (
    options: AutocompleteCities,
    { inputValue }: { inputValue: string }
  ) => {
    return matchSorter(options, inputValue, {
      keys: [
        (item) => item.name.replace(/\s|-/g, ' '),
        (item) => item.name.replace(/\s|-/g, '-'),
        (item) => item.inseeCode,
        (item) => item.postalCode,
      ],
    });
  };

  const searchPattern = async (pattern: string) => {
    if (pattern.length >= PATTERN_LENGTH) {
      try {
        const result = await fetchAllSubscriptionCities(
          subscription?.idIri ?? null,
          {
            name: pattern,
          }
        );

        setCities(result);
      } catch (error) {
        setCities([]);
      }
    } else {
      setCities([]);
    }
  };

  const handleChange = (
    selectedCity: AutocompleteCity | AutocompleteCity[] | null
  ) => {
    onChange(selectedCity);
  };

  return (
    <Autocomplete
      multiple={multiple}
      className={className}
      filterOptions={filterOptions}
      filterSelectedOptions
      options={cities}
      value={selectedCity}
      noOptionsText="Aucune ville trouvée"
      getOptionLabel={(option: AutocompleteCity) =>
        `${option.name} (${option.postalCode})`
      }
      onChange={(evt, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.root}
          onChange={(e) => searchPattern(e.target.value)}
          variant="outlined"
          placeholder="Recherche par nom ou code postal"
          InputLabelProps={{
            shrink: true,
          }}
          data-cy="autocomplete-input-cities"
        />
      )}
    />
  );
};

export default memo(AutocompleteInputCities);
