import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../../App/store';
import { getNationalServitudesState } from '../../nationalServitudesSlice';
import styles from './nationalServitudesContent.module.scss';
import ServitudeRow from './NationalServitudesRow';

interface INationalServitudesContainerProps {
  isTitle?: boolean;
}
function NationalServitudesContainer({
  isTitle,
}: INationalServitudesContainerProps) {
  const { servitudesChecked } = useAppSelector(getNationalServitudesState);

  return (
    <div className={styles.servitudesContainer}>
      {servitudesChecked.childs?.map((s) => {
        return <ServitudeRow key={nanoid()} servitude={s} isTitle />;
      })}
    </div>
  );
}

export default NationalServitudesContainer;
