import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { GenericModalEnum } from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import createMailshotModel from '../createMailshotModel';

const createMailshotModelThunk = createAsyncThunk(
  'mailshot/createMailshotModelThunk',
  async (params: { model: CreateMailshotModelType }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { companyIdIri } = state.company;

      const result = await createMailshotModel({
        model: { ...params.model, content: { ops: [] } },
        companyIdIri,
      });

      return result;
    } catch (error) {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'createMailshotModelError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default createMailshotModelThunk;
