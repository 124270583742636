import { TableCell, TableRow } from '@mui/material';
import { memo } from 'react';
import { useAppDispatch } from '../../../../App/store';
import {
  dashboardOwnerDatasActions,
  OwnerDatasSearchTypeEnum,
} from '../../../../features/dashboardOwnerDatas/dashboardOwnerDatasSlice';
import {
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
} from '../../../../features/dashboardOwnerDatas/dashboardOwnerDatasThunk';

interface ICityOwnerTableRow {
  company: OwnerFromInseeCode;
}
function CityOwnerTableRow({ company }: ICityOwnerTableRow) {
  const dispatch = useAppDispatch();

  const handleSirenClick = (siren: string) => {
    dispatch(
      dashboardOwnerDatasActions.onOwnerSirenClick({
        siren,
        searchType: OwnerDatasSearchTypeEnum.PERSON,
      })
    );

    const query = {
      siren,
      page: 1,
      itemsPerPage: 15,
      sort: 'city_name',
      order: 'asc' as OwnerOrder,
    };

    dispatch(fetchOwnerInfosFromSirenThunk({ siren }));
    dispatch(fetchOwnerPlotFromSirenThunk(query));
  };

  return (
    <TableRow className="row">
      <TableCell>{company.companyName}</TableCell>
      <TableCell
        onClick={() => handleSirenClick(company.siren)}
        className="clickable"
        data-cy="owner-siren"
      >
        {company.siren}
      </TableCell>
      <TableCell>{company.plotCount}</TableCell>
      <TableCell>{company.isBodacc ? 'oui' : 'RAS'}</TableCell>
    </TableRow>
  );
}

export default memo(CityOwnerTableRow);
