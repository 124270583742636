import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../../../../App/store';
import usePloStudyStatus from '../../../../../../../../app/hooks/usePlotStudyStatus';
import { RecursiveSubFolderUpdatedElement } from '../../../../../../../../study/utils/studyEnums';

import SelectCustom from '../../../../../../../../../shared/components/common/formComponents/SelectCustom';
import { studyActions } from '../../../../../../../../study/slices/studySlice';

interface IPlotStatusSelectProps {
  studyType: PanelParamsType | null;
  status: StudyStatus | null;
  disabled?: boolean;
  className?: string;
  onUpdate: (params: UpdateStudyManagmentProps) => void;
}

export default function PlotStatusSelect({
  studyType,
  status,
  disabled,
  className,
  onUpdate,
}: IPlotStatusSelectProps) {
  const { statusForSelect, findPlotStudyStatus } = usePloStudyStatus();
  const [statusState, setStatusState] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // actual study status => local state
    if (status) {
      setStatusState(status.idIri);
    }
  }, [status]);

  const handleChange = (e: ChangeEventCustom) => {
    const statusIdIri = e.target.value as string;

    setStatusState(statusIdIri);
    dispatch(
      studyActions.setRecursiveSubFolderUpdatedElement(
        RecursiveSubFolderUpdatedElement.STATUS
      )
    );
    onUpdate({ status: findPlotStudyStatus(statusIdIri) ?? null });
  };

  return (
    <div className={className}>
      <label>
        {studyType === 'folder' ? 'Etat sous dossier' : 'Etat parcelle'} :
      </label>
      <SelectCustom
        items={statusForSelect()}
        name="status"
        value={statusState ?? ''}
        displayEmpty
        noValue="Statut"
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}
