import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';

import { getMapState } from '../../../../features/map/mapSlice';
import useCompany from '../../../company/hooks/useCompany';
import { modalsActions } from '../../../modals/modalsSlice';
import { getPluState } from '../../pluSlice';

import { getAuthState } from '../../../auth/authSlice';
import postAskForPlu from '../../services/postAskForPlu';
import styles from './askPluModal.module.scss';
import { resultValues } from './constants';

export enum PluAskActionEnum {
  AJOUT = 'ajouter_plu',
  MAJ = 'mettre_a_jour_plu',
  NONE = '',
}

function AskPluModal() {
  const { getCompanyName } = useCompany();
  const { user } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { pluUrls } = useAppSelector(getPluState);
  const [choice, setChoice] = useState(PluAskActionEnum.AJOUT);
  const [comment, setComment] = useState<{ text: string; isError: boolean }>({
    text: '',
    isError: false,
  });
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const hasPluLayer = pluUrls.result?.mapboxUrl || pluUrls.result?.pmtilesUrl;

  useEffect(() => {
    //set ask subject on plu existing condition
    setChoice(hasPluLayer ? PluAskActionEnum.MAJ : PluAskActionEnum.AJOUT);
  }, [pluUrls.result]);

  const request = async (action: PluAskActionEnum, comment: string) => {
    try {
      const company = getCompanyName();

      if (action !== PluAskActionEnum.NONE && company && geolocDatas?.inseeCode) {
        const result = await postAskForPlu(
          company.name,
          company.postalCode ?? '',
          company.id,
          geolocDatas?.inseeCode,
          user?.firstName ?? null,
          user?.lastName ?? null,
          action + '---' + comment
        );

        setResultMessage(resultValues[result.status]);
      }
    } catch (error) {
      setResultMessage(resultValues[0]);
    }
  };

  const handleCloseModal = () => {
    dispatch(modalsActions.pluDemand(false));
  };

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setComment({ isError: false, text: value });
  };

  const handleRequestOk = () => {
    if (!isEmpty(comment.text)) {
      request(choice, comment.text);
    } else {
      setComment({ ...comment, isError: true });
    }
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <>
      {!resultMessage ? (
        <GenericModal
          modalType={GenericModalEnum.INFO}
          actionType={GenericModalActionEnum.OKCANCEL}
          okButtonContent="Envoyer"
          cancelButtonContent="Annuler"
          title="Demande PLU"
          okCallback={handleRequestOk}
          cancelCallback={handleCancel}
          closeModal={handleCloseModal}
        >
          <div className={styles.askPluModalContent}>
            {choice === PluAskActionEnum.AJOUT ? (
              <p>
                Votre demande concerne la récupération du PLU de cette commune pour
                vos besoins de prospection?
              </p>
            ) : (
              <p>
                Votre demande concerne la mise à jour des éléments de PLU/PLUI pour
                cette commune suite à une modification récente?
              </p>
            )}

            <form>
              <div className={styles.commentSection}>
                <label htmlFor="comment">
                  Veuillez préciser votre demande svp :
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  cols={30}
                  rows={10}
                  value={comment.text}
                  onChange={handleCommentChange}
                  className={comment.isError ? styles.error : ''}
                ></textarea>
                {comment.isError && (
                  <p className={styles.isError}>Le commentaire est obligatoire</p>
                )}
              </div>
            </form>
          </div>
        </GenericModal>
      ) : (
        <GenericModal
          modalType={GenericModalEnum.INFO}
          actionType={GenericModalActionEnum.OK}
          okButtonContent="Ok"
          title="Demande PLU"
          okCallback={handleCloseModal}
          closeModal={handleCloseModal}
        >
          <div className={styles.askPluModalContent}>
            <p>{resultMessage}</p>
          </div>
        </GenericModal>
      )}
    </>
  );
}

export default AskPluModal;
