import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { checkForNullValue } from '../../utils';
import { fontStyles } from '../styles/pdfStyles';
import InformationLine from './InformationLine';

interface IProps {
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
}
function LocationInformationPdf({ formDatas, isOrpi }: IProps) {
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <View>
      <View style={styles.title}>
        <Text style={styles.titleText}>Bien en location</Text>
      </View>

      <InformationLine
        label="Bail en cours"
        value={formDatas.currentLease}
        isOrpi={isOrpi}
      />
      <InformationLine
        label="Loyer"
        value={checkForNullValue(formDatas.actualRent)}
        isOrpi={isOrpi}
      />
    </View>
  );
}

export default LocationInformationPdf;

const commonStyles = { title: { marginBottom: '20px' } };

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  titleText: {
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  titleText: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
  },
});
