import Houses from '../../../../assets/images/houses.png';

export default function SignupContent(): JSX.Element {
  const handleHubspotClick = () => {
    window.open('https://go.pricehubble.com/urbanease-contact-us-page-2');
  };

  return (
    <div className="hubspot">
      <h2>Pas encore client ?</h2>
      <div>
        <img src={Houses} alt="img" className="houses-img-wrapper" />

        <button type="submit" className="submit-btn" onClick={handleHubspotClick}>
          Nous contacter
        </button>
      </div>
      <p>
        Nous aurons le plaisir de vous présenter nos solutions de prospection qui ont
        su convaincre plus de 10000 professionnels du secteur immobilier en France.
      </p>
    </div>
  );
}
