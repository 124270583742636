import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS } from '../../../../shared/constants';
import { getMapState } from '../../../map/mapSlice';
import fetchProspecAdsStatsThunk from '../../services/thunks/landPDF/fetchProspecAdsStatsThunk';
import TextAreaInputFormPdfErrial from '../../shared/components/rightPanelModalExternalPdfErrial/TextAreaInputFormPdfErrial';
import styles from '../styles/prospecForm.module.scss';
import useProspecDatas from '../useProspecDatas';
import ProspecPriceEstimateField from './ProspecPriceEstimateField';
import ProspecPriceStatsForks from './ProspecPriceStatsForks';

export interface IProspecPriceStatsProps {
  stats: ProspecGetAdsStats | null;
}
function ProspecPriceStats() {
  const {
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext();
  const { prospecCityId, adsStats } = useProspecDatas();
  const { geolocDatas } = useAppSelector(getMapState);
  const [error, setError] = useState<'min' | 'max' | 'fork' | null>(null);

  const watchedValues = watch(['minSellPrice', 'maxSellPrice', 'estimatedPrice']);
  const dispatch = useAppDispatch();

  const getErrors = () => {
    // errors checks
    if ((watchedValues[0] !== 0 && watchedValues[1] !== 0) || watchedValues[2] > 0)
      if (watchedValues[0] > watchedValues[1]) {
        setError('fork');
      } else if (watchedValues[0] > watchedValues[2]) {
        setError('min');
      } else if (watchedValues[1] < watchedValues[2]) {
        setError('max');
      } else {
        setError(null);
      }
  };

  useEffect(() => {
    const checkAndSetValue = (fieldName: string, value: number | undefined) => {
      const fieldValue = watch(fieldName);
      if (!fieldValue || fieldValue === 0) {
        setValue(fieldName, value ?? 0);
      }
    };
    if (adsStats.result) {
      const fields = [
        { name: 'minSellPrice', value: adsStats.result.sellMinPrice },
        { name: 'avgSellPrice', value: adsStats.result.sellAveragePrice },
        { name: 'maxSellPrice', value: adsStats.result.sellMaxPrice },
      ];

      fields.forEach(({ name, value }) => checkAndSetValue(name, value));
    }

    getErrors();
  }, [adsStats.result]);

  useEffect(() => {
    // display errors whel values are updated
    getErrors();
  }, [watchedValues]);

  useEffect(() => {
    if (geolocDatas && prospecCityId.result && adsStats.result) {
      resetField('minSellPrice');
      resetField('avgSellPrice');
      resetField('maxSellPrice');
      dispatch(
        fetchProspecAdsStatsThunk({
          cityId: prospecCityId.result,
          area: watch('landArea'),
        })
      );
    }
  }, [geolocDatas]);

  useEffect(() => {
    // load datas if no exists
    if (prospecCityId.result && !adsStats.result) {
      dispatch(
        fetchProspecAdsStatsThunk({
          cityId: prospecCityId.result,
          area: watch('landArea'),
        })
      );
    }
  }, []);

  return (
    <div className={styles.prospecPriceStats}>
      <ProspecPriceStatsForks stats={adsStats.result} error={error} />

      <hr />

      <div className={styles.estimationPriceContainer}>
        <ProspecPriceEstimateField error={error} />

        <div
          className={`${styles.commentContainer} ${
            errors.priceComment?.message ? styles.error : ''
          }`}
        >
          <p>
            <span>
              Commentaire sur la stratégie d&apos;estimation (
              {PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS} caractères max)
            </span>
          </p>
          <TextAreaInputFormPdfErrial
            name="priceComment"
            rowsMax={6}
            rowsMin={3}
            placeholder="Commentaire ..."
            className={errors.priceComment?.message ? styles.error : ''}
          />
        </div>
      </div>
      <hr />
    </div>
  );
}

export default memo(ProspecPriceStats);
