import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getCompanyState } from '../companySlice';

interface IUseCompanyProps {
  loadCompany: boolean;
}
export default function useCompany(params?: IUseCompanyProps) {
  const dispatch = useAppDispatch();

  const { company, companyIdIri, companyId, companies, firm } =
    useAppSelector(getCompanyState);

  const getCompanyName = () => {
    const co = companies.find((c) => c.idIri === companyIdIri);
    if (co) {
      return co;
    } else {
      return null;
    }
  };

  return { company, companyIdIri, companyId, companies, firm, getCompanyName };
}
