import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAnnouncementState } from '../../announcement/announcementSlice';
import {
  fetchContactRolesThunk,
  fetchPlotStudyStatusesThunk,
  fetchProcedureTypesThunk,
} from '../../app/appThunks';
import { getAuthState } from '../../auth/authSlice';
import useSubscriptionAccess from '../../company/subscription/useSubscriptionAccess';
import { modalsActions } from '../../modals/modalsSlice';
import { appActions, getAppState } from '../appSlice';

export default function useApp() {
  const {
    plotStudyStatuses,
    procedureTypes,
    contactRoles,
    accountTab,
    dashboardTab,
    displayNoUrbaneaseAccessModal,
  } = useAppSelector(getAppState);
  const { isLogged } = useAppSelector(getAuthState);
  const { announcement } = useAppSelector(getAnnouncementState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogged) {
      dispatch(appActions.init(hasUrbaneaseAccess));

      if (hasUrbaneaseAccess) {
        if (!plotStudyStatuses.result) {
          dispatch(fetchPlotStudyStatusesThunk());
        }
        if (!procedureTypes.result) {
          dispatch(fetchProcedureTypesThunk());
        }
        if (!contactRoles.result) {
          dispatch(fetchContactRolesThunk());
        }
      }

      dispatch(modalsActions.closeAllAuthModal());
    }
  }, [isLogged, hasUrbaneaseAccess]);

  useEffect(() => {
    if (isLogged) {
      if (!isEmpty(announcement.result)) {
        dispatch(modalsActions.announcement(true));
      }
    }
  }, [isLogged, announcement]);

  return {
    plotStudyStatuses,
    procedureTypes,
    contactRoles,
    accountTab,
    dashboardTab,
  };
}
