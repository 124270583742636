import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../App/store';
import Logo from '../../assets/images/logo_urbanease_horizontal.svg';
import { ReactComponent as RightArrowSmall } from '../../assets/images/right-arrow-small.svg';
import useApp from '../../features/app/hooks/useApp';
import AgendaTab from '../../features/dashboard/components/agendaTab/AgendaTab';
import DashboardTabs from '../../features/dashboard/components/dashboardTabs/DashboardTabs';
import DataOwnersTab from '../../features/dashboard/components/dataOwnersTab/DataOwnersTab';
import DirectoryTab from '../../features/dashboard/components/directoryTab/DirectoryTab';
import FoldersTab from '../../features/dashboard/components/foldersTab/FoldersTab';
import ReportingTab from '../../features/dashboard/components/reportingTab/ReportingTab';
import { mailshotsActions } from '../../features/mailshots/mailshotsSlice';
import '../../shared/components/filterSortBloc/filterSortBloc.scss';
import './dashboardPage.scss';

export default function DashboardPage() {
  const { dashboardTab } = useApp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    navigate('/');
  };

  useEffect(() => {
    return () => {
      // reset mailshots if leaving dashboard
      dispatch(mailshotsActions.resetWithoutModels());
    };
  }, []);

  return (
    <div className="dashboard">
      <div className="main-wrapper">
        <div className="header">
          <div className="header-col-left">
            <img src={Logo} alt="logo" />
          </div>
          <button onClick={handleClick}>
            Retour sur Urbanease <RightArrowSmall />
          </button>
        </div>

        <div className="body">
          <DashboardTabs selectedTab={dashboardTab} />
          <div className="body-wrapper">
            {dashboardTab === 'directory' && <DirectoryTab />}
            {dashboardTab === 'agenda' && <AgendaTab />}
            {dashboardTab === 'reporting' && <ReportingTab />}
            {dashboardTab === 'folders' && <FoldersTab />}
            {dashboardTab === 'dataowner' && <DataOwnersTab />}
          </div>
        </div>
      </div>
    </div>
  );
}
