import { useAppSelector } from '../../../App/store';
import { getActivePlotCityDatasState } from '../activePlotSlice';

function useCityDatas() {
  const { apiStatus, result, error } = useAppSelector(getActivePlotCityDatasState);
  const { parkingPackages, socialCriteria } = result;

  return {
    cityDatasApiStatus: apiStatus,
    cityDatasError: error,
    parkingPackages,
    socialCriteria,
  };
}

export default useCityDatas;
