import { useEffect, useState } from 'react';
import logo_orpi from '../../../../assets/images/logo_orpi.webp';
import styles from '../../../../pages/externalConnection/externalConnection.module.scss';
import useQuery from '../../../../shared/hooks/useQuery';
import { decodeToken } from '../../utils/utils';

function firmLogoSwitch(firm: string) {
  const parsedFirm = firm.toLowerCase();

  switch (parsedFirm) {
    case 'orpi':
      return logo_orpi;
    default:
      return null;
  }
}

function FirmDisplay() {
  const { queries } = useQuery();
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const firm = decodedToken?.firm?.name;

  useEffect(() => {
    if (queries && queries.token) {
      const dt: DecodedToken | null = decodeToken(queries.token);

      if (dt) {
        setDecodedToken(dt);
      }
    }
  }, [queries]);

  return (
    <>
      {firm === 'Orpi' && (
        <div className={styles.firmDisplay}>
          <img src={firmLogoSwitch(firm) || ''} />
          <p>
            pour le groupe <span>{firm}</span>
          </p>
        </div>
      )}
    </>
  );
}

export default FirmDisplay;
