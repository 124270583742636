import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { getAuthState } from '../../../../../features/auth/authSlice';
import { modalsActions } from '../../../../../features/modals/modalsSlice';
import { userUpdateThunk } from '../../../../../features/users/services/usersThunks';
import { userActions } from '../../../../../features/users/usersSlice';
import FieldInputPwdFormCustom from '../passwordComponents/FieldInputPwdFormCustom';
import styles from './updatePasswordForm.module.scss';
import {
  IPasswordTabForm,
  initialPasswordForm,
  passwordValidationSchema,
} from './updatePasswordValidation';

interface IPasswordFormProps {
  user: User;
}
function UpdatePasswordForm({ user }: IPasswordFormProps) {
  const authState = useAppSelector(getAuthState);

  const useFormOptions = useForm<IPasswordTabForm>({
    defaultValues: initialPasswordForm,
    resolver: yupResolver(passwordValidationSchema),
  });
  const {
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useFormOptions;
  const dispatch = useAppDispatch();

  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      dispatch(
        userUpdateThunk({
          userIdIri: user.idIri,
          user: { plainPassword: getValues('password') },
        })
      );
      if (user.id !== authState.user?.id) {
        // display message without logout
        dispatch(userActions.setEditingUser(true));
      } else {
        dispatch(modalsActions.passwordChangeResult(true));
      }

      reset();
    }
  };
  return (
    <FormProvider {...useFormOptions}>
      <form
        className={styles.passwordForm}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className={styles.field}>
          <FieldInputPwdFormCustom
            name="password"
            placeholder="Nouveau mot de passe"
            className={styles.muiInputField}
            disabled={!authState.isManager}
            hasComplexErrorMessage
            onResetError={() => clearErrors()}
          />
        </div>

        <div className={styles.field}>
          <FieldInputPwdFormCustom
            name="verifyPassword"
            placeholder="Confirmation du mot de passe"
            className={styles.muiInputField}
            disabled={!authState.isManager}
            errorTooltipPlacment="bottom"
          />
        </div>

        <button
          type="submit"
          className={styles.submitBtn}
          disabled={!authState.isManager}
        >
          VALIDER
        </button>
      </form>
    </FormProvider>
  );
}

export default UpdatePasswordForm;
