import { Path, Svg } from '@react-pdf/renderer';

function LogoUrbaneaseFooterSvg() {
  return (
    <Svg height="40" width="100" viewBox="102 0 125 78">
      <Path
        d="m129.3 51.6-2.8-1 2.8-1 .2.5-1.5.6 1.5.5zM178.4 33l-2.2-.8-1.7.7-.2-.5 1.9-.7 2.4.9zm-12 2.9-.2-.5 4.1-1.6.2.5zm20.2.2-4.1-1.6.2-.5 4.1 1.6zM158.2 39l-.2-.5 4.1-1.6.2.5zm36.6.2-4.1-1.6.2-.5 4.1 1.6zM150 42.1l-.2-.5 4.1-1.6.2.5zm53 .2-4.1-1.6.2-.5 4.1 1.6zm-61.2 2.9-.2-.5 4.1-1.6.2.5zm69.3.3-4.1-1.6.2-.5 4.1 1.6zm-77.4 2.9-.2-.5 4.1-1.6.2.5zm85.6.2-4.1-1.6.2-.5 4.1 1.6zm3.4 3-.2-.5 1.5-.5-1.5-.6.2-.5 2.8 1zm-85.1 4-4.1-1.6.2-.5 4.1 1.6zm77.8.2-.2-.5 4.1-1.6.2.5zm-69.7 2.9-4.1-1.6.2-.5 4.1 1.6zm61.5.2-.2-.5 4.1-1.6.2.5zm-53.3 3-4.1-1.6.2-.5 4.1 1.6zm45.1.2-.2-.5L203 60l.2.5zM162.1 65l-4.1-1.6.2-.5 4.1 1.6zm28.8.2-.2-.5 4.1-1.6.2.5zm-20.6 2.9-4.1-1.6.2-.5 4.1 1.6zm12.4.2-.2-.5 4.1-1.6.2.5zm-6 2.3-2.4-.9.2-.5 2.2.8 1.7-.7.2.5z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#d41031"
      />
      <Path
        d="M153.5 69.6c-35.6-13.5-14.4-5.5-47.6-18l47.3-18 47.6 18c-38.7 14.7-24.1 9.1-47.3 18M202 49.9l-48.3-18.3c-.4-.2-.9-.2-1.3 0l-47.9 18.3c-.7.3-1.1.9-1.1 1.6s.4 1.4 1.1 1.6c48.5 18.4-.4-.2 48.2 18.3.2.1.4.1.6.1s.4 0 .6-.1c48.3-18.4-.3.1 47.9-18.3.7-.3 1.1-.9 1.1-1.6.3-.7-.2-1.3-.9-1.6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#6199c2"
      />
      <Path
        d="M161.8 56.7c-35.6-13.5-14.4-5.5-47.6-18l47.3-18 47.6 18c-38.7 14.7-24 9.1-47.3 18M210.4 37l-48.3-18.3c-.4-.2-.9-.2-1.3 0L113 37c-.7.3-1.1.9-1.1 1.6s.4 1.4 1.1 1.6c48.5 18.4-.4-.2 48.2 18.3.2.1.4.1.6.1s.4 0 .6-.1c48.3-18.4-.3.1 47.9-18.3.7-.3 1.1-.9 1.1-1.6.1-.7-.3-1.3-1-1.6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#4e55a2"
      />
      <Path
        d="M171.8 18.4c0 5.9-4.8 10.7-10.8 10.7-5.9 0-10.8-4.8-10.8-10.7S155 7.7 161 7.7c5.9 0 10.8 4.8 10.8 10.7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#fff"
      />
      <Path
        d="M161 27.6c-5.1 0-9.2-4.1-9.2-9.2s4.1-9.2 9.2-9.2 9.2 4.1 9.2 9.2-4.1 9.2-9.2 9.2M161 0h-.7c-10.2.4-18.2 8.9-17.8 19 0 .9.1 1.9.3 2.8 0 .2.1.4.1.7q.45 1.8 1.2 3.6c1.9 4.4 6.9 13.8 15.7 21.1.7.6 1.5.6 2.1.1 6.9-6.8 13.5-16 15.8-21.1.5-1.2 1-2.4 1.2-3.7.1-.4.1-.6.1-.6.2-1.2.3-2.3.3-3.5C179.5 8.2 171.2 0 161 0"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#d2112f"
      />
      <Path
        d="M161.1 14c-2.4 0-4.4 1.9-4.4 4.3s2 4.4 4.4 4.4 4.4-1.9 4.4-4.4c0-2.4-2-4.3-4.4-4.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#d2112f"
      />
    </Svg>
  );
}

export default LogoUrbaneaseFooterSvg;
