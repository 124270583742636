import { StyleSheet, Text, View } from '@react-pdf/renderer';

import EnvironmentGradualScore from '../../../errialPDF/pdfFiles/components/EnvironmentGradualScore';
import ClayIconSvg from '../../../errialPDF/pdfFiles/components/svg/ClayIconSvg';
import ErosionIconSvg from '../../../errialPDF/pdfFiles/components/svg/ErosionIconSvg';
import RadonIconSvg from '../../../errialPDF/pdfFiles/components/svg/RadonIconSvg';
import SeismicIconSvg from '../../../errialPDF/pdfFiles/components/svg/SeismicIconSvg';
import { EnvIconTypeEnum } from '../../../shared/constants';
import { fontStyles } from '../styles/pdfStyles';

interface IEnvironmentalConstraintProps {
  type: EnvIconTypeEnum;
  title: string;
  value?: number;
  max?: number;
  yesNo?: boolean;
  description?: string;
  children?: React.ReactNode;
  isOrpi?: boolean;
}

function EnvironmentalConstraintLand({
  type,
  title,
  value,
  max,
  yesNo,
  description,
  children,
  isOrpi,
}: IEnvironmentalConstraintProps) {
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <View style={styles.environmentalConstraint}>
      <View style={styles.logoContainer}>
        {type === EnvIconTypeEnum.SEISMIC && (
          <SeismicIconSvg color={isOrpi ? '#000' : '#514E9A'} />
        )}
        {type === EnvIconTypeEnum.CLAY && (
          <ClayIconSvg color={isOrpi ? '#000' : '#514E9A'} />
        )}
        {type === EnvIconTypeEnum.RADON && (
          <RadonIconSvg color={isOrpi ? '#000' : '#514E9A'} />
        )}
        {type === EnvIconTypeEnum.EROSION && (
          <ErosionIconSvg color={isOrpi ? '#000' : '#514E9A'} />
        )}
      </View>
      <View style={styles.infoContainer}>
        <View style={styles.titleContainer}>
          <Text>{`${title} `} </Text>
          {value && (
            <Text style={styles.valueText}>
              {value}/{max}
            </Text>
          )}
        </View>

        {value ? (
          <View style={styles.infosTextContainer}>
            {description ? (
              <Text style={styles.description}>{description}</Text>
            ) : (
              children
            )}
            <EnvironmentGradualScore type={type} value={value} max={max} />
          </View>
        ) : (
          <Text>Absence de contraintes connues</Text>
        )}
      </View>
    </View>
  );
}

export default EnvironmentalConstraintLand;

const commonStyles = {
  environmentalConstraint: {
    minHeight: '100px',
    width: '100%',
    display: 'flex' as const,
    flexDirection: 'row' as const,
    fontSize: '12px',
  },
  logoContainer: {
    display: 'flex' as const,
    justifyContent: 'center' as const,
    width: '90px',
    borderRight: '1px solid #D0D0D0',
    marginRight: '15px',
  },
  logo: {
    width: '64px',
    height: '64px',
  },
  infoContainer: {
    paddingVertical: '10px',
    height: '100%',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
  },
  valueText: {},
  infosTextContainer: { width: '82%' },
  description: { fontSize: '12px', fontWeight: 300 },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  infoContainer: {
    ...commonStyles.infoContainer,
    width: '100%',
  },
  titleContainer: {
    ...commonStyles.titleContainer,
    ...fontStyles.b700,
    ...fontStyles.fs14,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  logoContainer: {
    ...commonStyles.logoContainer,
    width: '15%',
    minHeight: '100px',
    borderRight: '1px solid #679CC6',
  },
  titleContainer: {
    ...commonStyles.titleContainer,
    ...fontStyles.fs14,
    ...fontStyles.ffUrbanease,
  },
  valueText: { ...fontStyles.fs12 },
  description: {
    ...commonStyles.description,
    ...fontStyles.ffUrbanease,
  },
});
