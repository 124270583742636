import { nanoid } from '@reduxjs/toolkit';
import { LatLngExpression } from 'leaflet';
import { useEffect } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { PDF_URLS } from '../../../../shared/constants';
import { getPdfState } from '../../../externalPdf/pdfSlice';
import MarkerNumber from '../../../map/components/leaflet/common/MarkerNumber';
import { getMultiPlotSelectState } from '../../../multiPlotSelectFeature/multiPlotSelectSlice';
import MarkerPopup from './MarkerPopup';

const jsonPlotStyle = {
  weight: 1,
  fillOpacity: 0.2,
  fillColor: 'red',
  color: 'red',
};

const jsonBuildingStyle = {
  ...jsonPlotStyle,
  fillColor: 'red',
  color: 'red',
};

function MultiPlotSelectLayer() {
  const map = useMap();
  const { plots } = useAppSelector(getMultiPlotSelectState);
  const { multiPlotsPdf } = useAppSelector(getPdfState);

  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  const plotCoordTab = pdfPath
    ? multiPlotsPdf.result?.map((p) => ({
        index: p.index,
        plotId: p.fullPlotId,
        centroid: [p.lat, p.lng],
        geometry: p.plotGeometry,
        buildings: p.buildingsGeometry,
      }))
    : plots.result?.map((p) => ({
        index: p.index,
        plotId: p.fullPlotId,
        centroid: [p.lat, p.lng],
        geometry: p.plotGeometry,
        buildings: p.buildingsGeometry,
      }));

  useEffect(() => {
    if (
      plots.apiStatus !== APIStatus.PENDING &&
      multiPlotsPdf.apiStatus !== APIStatus.PENDING
    ) {
      map.getContainer().classList.remove('loading-plot');
    }
  }, [plots.apiStatus, multiPlotsPdf.apiStatus]);

  return (
    <>
      {plotCoordTab?.map((pct, i) => (
        <div key={nanoid()}>
          <MarkerNumber
            number={i + 1}
            markerLatLng={pct.centroid as LatLngExpression}
            markerColor="cyan"
            zIndexOffset={750}
          >
            <MarkerPopup
              index={pct.index}
              plotId={pct.plotId.substring(5)}
              externalPdfErrial={pdfPath}
            />
          </MarkerNumber>

          <GeoJSON style={jsonPlotStyle} data={pct.geometry} />

          <GeoJSON key={nanoid()} style={jsonBuildingStyle} data={pct.buildings} />
        </div>
      ))}
    </>
  );
}

export default MultiPlotSelectLayer;
