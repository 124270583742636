import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../assets/images/burger-icon.svg';
import styles from './dropdownMenu.module.scss';

type DropdownMenuItem = {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  visible?: boolean;
  dataCy?: string;
};

type DropdownMenuProps = {
  items: DropdownMenuItem[];
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference to the dropdown container

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false); // Close dropdown if click is outside
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick); // Cleanup on unmount
    };
  }, [isOpen]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button
        className={styles.dropdownButton}
        onClick={toggleDropdown}
        data-cy="account-dropdown-menu-button"
      >
        <BurgerIcon className={styles.burgerIcon} />
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {items
            .filter((item) => item.visible !== false) // Default to visible if not explicitly false
            .map((item) => (
              <li
                key={nanoid()}
                className={styles.dropdownItem}
                onClick={() => {
                  item.onClick();
                  toggleDropdown(); // Close the dropdown when an item is clicked
                }}
                data-cy={item.dataCy}
              >
                {item.icon} {item.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
