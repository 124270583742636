import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Switch } from '@mui/material';
import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { ReactComponent as LogoIA } from '../../../../../assets/images/logo-ai-white.svg';
import editIcon from '../../../../../assets/images/toolbar-edit.png';

import { APIStatus } from '../../../../../services/axiosFiles/apiTypes';
import CrossIcon from '../../../../../shared/components/icons/CrossIcon';
import { getAuthState } from '../../../../auth/authSlice';
import useCompany from '../../../../company/hooks/useCompany';

import updateContact from '../../../../contacts/services/updateContact';
import { modalsActions } from '../../../../modals/modalsSlice';
import { studyContactContext } from '../../../../panels/components/rightPanelModal/tabsContent/bodys/Prospection';
import { emailContact } from '../../../../panels/components/rightPanelModal/tabsContent/constant';
import { getUrbaGptState, urbaGptActions } from '../../../../urbaGpt/urbaGptSlice';
import { urbaGptQueryThunk } from '../../../../urbaGpt/urbaGptThunk';
import deleteStudyContactThunk from '../../../services/thunks/deleteStudyContactThunk';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import { studyActions } from '../../../slices/studySlice';
import { questions } from '../../../utils/urbaGPTQuestions';
import styles from './contact.module.scss';
import { ForceOpenContext } from './StudyContactContent';

interface IContactProps {
  studyContact: IStudyContact;
  parcelle: IPlot | null;
  address: string | null;
  city: string | null;
  updateAllowed: boolean;
}

function StudyContactCard({
  studyContact,
  parcelle,
  address,
  city,
  updateAllowed,
}: IContactProps) {
  const { update } = useContext(ForceOpenContext);
  const studyContactCtx = useContext(studyContactContext);
  const { user } = useAppSelector(getAuthState);
  const { type } = useAppSelector(getStudyParamsState);
  const { getCompanyName, company } = useCompany();
  const [openUrbaGptAction, setOpenUrbaGptAction] = useState(false);
  const [isSentAgent, setIsSentAgent] = useState(false);
  const [isSentProspector, setIsSentProspector] = useState(false);
  const { urbaGptIsVisible, conversation } = useAppSelector(getUrbaGptState);
  const dispatch = useAppDispatch();
  const contactFullName = `${studyContact.contact.firstName ?? ''} ${
    studyContact.contact.lastName
  }`;
  // Includes the .trim().replace(/\s+/g, ' ') to clean up any extra spaces in the final address string.
  const contactAddress = studyContact.contact.address
    ? `${studyContact.contact.address.addressBuilding || ''} 
       ${studyContact.contact.address.addressExtra || ''} 
       ${studyContact.contact.address.addressFloor || ''} 
       ${studyContact.contact.address.addressStreet || ''} 
       ${studyContact.contact.address.city || ''} 
       ${studyContact.contact.address.country || ''} 
       ${studyContact.contact.address.postalCode || ''}`
        .trim()
        .replace(/\s+/g, ' ')
    : '';

  const handleUpdate = (): void => {
    dispatch(studyActions.setStudyContactToUpdate(studyContact));
    update(true);
  };

  const handleDelete = () => {
    dispatch(deleteStudyContactThunk({ studyContactIdIri: studyContact.idIri }));
  };

  const handleUrbaGpt = (type: 'agent' | 'prospector') => {
    const question = questions(
      type,
      studyContact,
      (address = ''),
      parcelle as IPlot,
      user as User,
      company.result as Company,
      getCompanyName()?.name ?? ''
    );
    if (!urbaGptIsVisible) {
      dispatch(
        urbaGptActions.setConversation({
          conversationUuid: '',
          datelog: new Date().toLocaleDateString(),
          id: 0,
          question,
          response: '',
          isAnim: false,
        })
      );
      dispatch(urbaGptQueryThunk({ question }));
    }
  };

  const handleSwitchToBeContacted = async () => {
    try {
      // only update contactno role here
      const contactResponse = await updateContact(studyContact.contact.idIri, {
        allowedToBeContacted: !studyContact.contact.allowedToBeContacted,
      });

      dispatch(studyActions.updateContact(contactResponse));
    } catch (error) {
      dispatch(modalsActions.studyContactModal(true));
    }
  };

  return (
    <div className={styles.contactCard}>
      <div className={styles.leftBlock}>
        <p className={styles.contactName}>{contactFullName}</p>
        {studyContact.contact.addressOld &&
          !studyContact.contact.address.addressStreet && (
            <p>{studyContact.contact.addressOld}</p>
          )}
        {contactAddress && <p>{contactAddress}</p>}

        {studyContact.contact.phone && <p>{studyContact.contact.phone}</p>}
        {studyContact.contact.email && (
          <p>
            <a
              href={`mailto:${studyContact.contact.email}${emailContact(
                user,
                parcelle?.parcelleId || 'indéfini',
                city || 'indéfini',
                address || 'indéfini'
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              {studyContact.contact.email}
            </a>
          </p>
        )}
        {studyContact.role?.label && <p>{studyContact.role?.label}</p>}
      </div>
      <div className={styles.centerBlock}>
        <p>{studyContact.contact.description}</p>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.contactActions}>
          {updateAllowed && (
            <>
              <div className={styles.buttonContactActions}>
                <img
                  src={editIcon}
                  alt="edit"
                  onClick={() => handleUpdate()}
                  data-cy="study-contact-card-button-edit"
                  height={20}
                />
                <CrossIcon
                  onClick={() => handleDelete()}
                  bgColor="TRANSPARENT"
                  color="PRIMARY"
                  width={22}
                  height={22}
                  noPadding
                  dataCy="study-contact-card-button-delete"
                />
              </div>
              <div className={styles.switchContact}>
                <p> Accepte d&apos;être contacté</p>
                <Switch
                  color="primary"
                  checked={studyContact.contact.allowedToBeContacted}
                  required
                  onChange={() => handleSwitchToBeContacted()}
                  name="check"
                  data-cy="study-contact-card-switch-allowed-to-be-contacted"
                />
              </div>
            </>
          )}
        </div>
        {type === 'plotStudy' && (
          <div className={styles.urbaGpt}>
            {openUrbaGptAction ? (
              <>
                <ArrowBackIcon
                  onClick={() => setOpenUrbaGptAction(false)}
                  className={styles.arrowBack}
                />
                <button
                  onClick={() => {
                    if (!isSentAgent) handleUrbaGpt('agent');
                    dispatch(urbaGptActions.setUrbaGptIsVisible(!urbaGptIsVisible));
                    setIsSentAgent(true);
                  }}
                  className={`${styles.urbaGptBtn} ${
                    conversation.apiStatus === APIStatus.PENDING &&
                    styles.urbaGptBtnDisabled
                  }`}
                  disabled={conversation.apiStatus === APIStatus.PENDING}
                >
                  En tant qu&apos;agent immobilier
                </button>
                <button
                  onClick={() => {
                    if (!isSentProspector) handleUrbaGpt('prospector');
                    dispatch(urbaGptActions.setUrbaGptIsVisible(!urbaGptIsVisible));
                    setIsSentProspector(true);
                  }}
                  className={`${styles.urbaGptBtn} ${
                    conversation.apiStatus === APIStatus.PENDING &&
                    styles.urbaGptBtnDisabled
                  }`}
                  disabled={conversation.apiStatus === APIStatus.PENDING}
                >
                  En tant que prospecteur foncier
                </button>
              </>
            ) : (
              <button
                onClick={() => setOpenUrbaGptAction(true)}
                className={styles.urbaGptBtnLogo}
              >
                Générer un courrier de prospection
                <LogoIA className={styles.urbaGptLogo} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StudyContactCard;
