import { FormControl, MenuItem, Select } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import styles from '../../styles/pdfRightPanelModal.module.scss';
import TickSquare from '../tickSquare/TickSquare';

interface ISelectInputFormPdfErrialCustom {
  name: string;
  control: any;
  items: ISelectItem[];
  onChange?: ChangeEventCustom;
  disabled?: boolean;
  className?: string;
  displayEmpty?: boolean;
  noValue?: string;
  error?: boolean;
  errorMsg?: string;
  noChoiceDisabled?: boolean;
  onFocus?: () => void;
}

export default function SelectInputFormPdfErrialCustom({
  name,
  control,
  items = [],
  onChange,
  disabled,
  className,
  displayEmpty,
  noValue,
  error,
  errorMsg,
  onFocus,
  noChoiceDisabled = true,
}: ISelectInputFormPdfErrialCustom) {
  const onChangeIntern = (
    e: ChangeEventCustom,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    field.onChange(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value } }) => (
        <div className={styles.inputForm}>
          <FormControl
            variant="outlined"
            disabled={disabled}
            className={className}
            error={error}
          >
            <Select
              {...field}
              labelId={name}
              onChange={(e) => onChangeIntern(e, field)}
              displayEmpty={displayEmpty}
              disabled={disabled}
              error={error}
              onFocus={handleFocus}
            >
              <MenuItem
                key={nanoid()}
                className="select-menu-item"
                value=""
                disabled={noChoiceDisabled}
              >
                {noValue}
              </MenuItem>
              {items.map((elt) => (
                <MenuItem
                  key={nanoid()}
                  value={elt.value}
                  className="select-menu-item"
                >
                  {elt.display}
                </MenuItem>
              ))}
            </Select>
            {error && <p>{errorMsg}</p>}
          </FormControl>
          <TickSquare color={value ? 'Green' : 'Grey'} />
        </div>
      )}
    />
  );
}
