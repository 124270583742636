import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import EstimationPriceGraduation from './components/EstimationPriceGraduation';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageContainer from './components/ImageContainer';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
  landType: string;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function EstimatePage({
  formDatas,
  plotDatas,
  agency,
  landType,
  isOrpi,
  address,
  date,
}: IProps) {
  const joinedCustomers = formDatas.owners.map((m) => m.name).join(', ');
  const headerLines = isOrpi
    ? ['Bien estimer votre bien', `Etude préparée pour ${joinedCustomers}`]
    : ['Estimation de votre bien', `Etude préparée pour ${joinedCustomers}`];
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <Page size="A4" style={styles.page}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}
      <Header lines={headerLines} isOrpi={isOrpi} />
      <View style={styles.analysisBloc}>
        <Text style={fontStyles.b700}>
          {isOrpi
            ? 'L’analyse des différents paramètres comme:'
            : 'Cette estimation se base sur les critères suivants:'}
        </Text>
        <View style={styles.ulPadding}>
          <Text style={styles.analysisText}>
            <Text>-</Text>
            {isOrpi
              ? 'Les caractéristiques spécifiques de votre bien et de son environnement'
              : 'Analyse de l’évolution du marché immobilier local'}
          </Text>
          <Text style={styles.analysisText}>
            <Text>-</Text>
            {isOrpi
              ? 'L’évaluation des tendances du marché'
              : 'Caractéristiques spécifiques de votre terrain'}
          </Text>
          <Text style={styles.analysisText}>
            <Text>-</Text>
            {isOrpi
              ? 'L’analyses de la base de données Orpi et des biens vendus dans le secteur'
              : 'Etude des transactions réalisées'}
          </Text>
          {isOrpi ? null : (
            <Text>
              Une analyse complémentaire des droits à bâtir et contraintes
              d&#39;urbanisme nous permettrait d&#39;affiner cette estimation
            </Text>
          )}
        </View>
      </View>
      <Text style={styles.addressBloc}>
        nous amènent à vous présenter aujourd’hui une estimation de la valeur de
        votre terrain, situé :{' '}
        <Text
          style={fontStyles.b700}
        >{`${formDatas.addressStreet} ${formDatas.addressCity}`}</Text>
      </Text>
      <View style={styles.imageBloc}>
        <View style={styles.imageContainer}>
          <ImageContainer
            width="280px"
            height="200px"
            borderRadius="15px"
            localImg={`data:image/jpeg;base64,${plotDatas.parcelsOnCadastrePicture}`}
          />
        </View>
        <View>
          <Text>Type - {landType}</Text>
          <Text>Surface de la parcelle: {addSpaceToNumber(plotDatas.area)} m²</Text>
        </View>
      </View>
      <EstimationPriceGraduation
        maxPrice={formDatas.maxSellPrice}
        minPrice={formDatas.minSellPrice}
        price={formDatas.estimatedPrice}
        area={formDatas.landArea}
        isOrpi={isOrpi}
      />
      <View style={styles.observationBloc}>
        <Text style={[fontStyles.b700, { marginBottom: '5px' }]}>Observations</Text>
        <Text style={styles.description}>{formDatas.priceComment}</Text>
        <Text>
          Cette estimation, non contractuelle, est un avis de valeur qui ne peut se
          substituer à une expertise immobilière.Elle reflète un état du marché à
          date, et a une durée de validité limitée dans le temps.
        </Text>
      </View>
      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default EstimatePage;

const commonStyles = {
  ulPadding: { paddingLeft: '20px' },
  analysisBloc: { marginTop: '10px' },
  analysisText: { paddingVertical: '3px' },
  addressBloc: { marginTop: '20px' },
  imageBloc: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    marginTop: '20px',
  },
  description: { paddingBottom: '10px' },
  imageContainer: { marginRight: '20px' },
  observationBloc: { marginTop: '20px' },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  page: { ...pageStyles.page, ...fontStyles.ffOrpi, ...fontStyles.fs14 },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
    ...fontStyles.fs12,
  },
});
