import { nanoid } from '@reduxjs/toolkit';
import { memo, useState } from 'react';
import PaginationCustom from '../../../../shared/components/common/paginationCustom/PaginationCustom';
import FolderPlot from '../../../panels/components/leftPanelModal/menuTabs/prospection/folderPlots/FolderPlot';
import styles from '../../../panels/components/leftPanelModal/menuTabs/prospection/folderPlots/folderPlot.module.scss';
interface IDashboardFolderPlotContainerProps {
  plotStudies: SortablePlotStudies;
  isSub?: boolean;
}

function DashboardFolderPlotContainer({
  plotStudies,
  isSub,
}: IDashboardFolderPlotContainerProps) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const hasPagination = plotStudies
    ? plotStudies?.length > itemsPerPage ||
      plotStudies?.length !==
        plotStudies?.filter(
          (f, i: number) => i >= itemsPerPage * (page - 1) && i < page * itemsPerPage
        ).length
    : false;

  const handleChangeIndex = (index: number): void => {
    const newPage = Math.ceil(index / itemsPerPage) + 1;

    setPage(newPage);
  };
  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value = e.target.value as number;
    setItemsPerPage(value);
  };

  return (
    <div className={styles.folderPlots}>
      {plotStudies
        ?.filter(
          (f, i: number) => i >= itemsPerPage * (page - 1) && i < page * itemsPerPage
        )
        .map((p) => (
          <FolderPlot
            key={nanoid()}
            plotStudy={p.entity}
            color={'color'}
            isDashboard={true}
            isSub={isSub}
          />
        ))}
      {hasPagination && (
        <PaginationCustom
          itemPerPageLabel="Parcelles par page"
          elementsQty={plotStudies?.length ?? 0}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          onMinIndexTab={handleChangeIndex}
          forceDisplay={true}
        />
      )}
    </div>
  );
}

export default memo(DashboardFolderPlotContainer);
