import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { Popup } from 'react-leaflet';
import RowContentTag from '../../../shared/components/RowContentTag';
import styles from './brownfields.module.scss';
interface IBrownfieldPopupProps {
  content: Brownfield;
}

function BrownfieldPopup({ content }: IBrownfieldPopupProps) {
  return (
    <Popup>
      <div className={`${styles.brownfieldPopupContainer}`}>
        <div className={styles.tabContent}>Informations sur la friche</div>

        <div className={styles.brownfieldContent}>
          <RowContentTag
            label={'Activité site'}
            value={content.bfActivity}
            type="p"
          />
          <RowContentTag
            label={'Nbr Bâtiments'}
            value={content.builtCount}
            type="p"
          />
          <RowContentTag
            label={'Date dernière mutation'}
            value={content.lastMutationDate}
            type="p"
          />
          {isEmpty(content.ownerNames) ? (
            <RowContentTag label={'Nom Propriétaire(s)'} value={null} type="p" />
          ) : (
            <>
              <p>Propriétaire(s)</p>
              <ul>
                {content.ownerNames.map((m) => (
                  <li key={nanoid()}>{m}</li>
                ))}
              </ul>
            </>
          )}{' '}
          <RowContentTag
            label={'Typologie propriétaires'}
            value={content.OwnerTypology}
            type="p"
          />
          <RowContentTag
            label={'Friche identifiée le'}
            value={content.bfIdentity}
            type="p"
          />
          <RowContentTag label={'Nom Friche'} value={content.bfName} type="p" />
          {/* <p>
            Site info Friche:&nbsp;
            {content.bfInfoUrl ? (
              <a href={content.bfInfoUrl} target="_blank" rel="noopener noreferrer">
                lien
              </a>
            ) : (
              <span>Indéfini</span>
            )}
          </p> */}
          <RowContentTag label={'Status Friche'} value={content.bfStatus} type="p" />
          <p>
            Url info Friche :&nbsp;
            {content.bfUrl ? (
              <a href={content.bfUrl} target="_blank" rel="noopener noreferrer">
                lien
              </a>
            ) : (
              <span>Indéfini</span>
            )}
          </p>
          <RowContentTag label={'Source Friche'} value={content.bfSource} type="p" />
        </div>
      </div>
    </Popup>
  );
}

export default BrownfieldPopup;
