import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericTabContainer from '../../../../shared/components/genericTab/GenericTabContainer';
import { appActions } from '../../../app/appSlice';
import { getAuthState } from '../../../auth/authSlice';
import useSubscriptionAccess from '../../../company/subscription/useSubscriptionAccess';
import {
  getMailshotsState,
  mailshotsActions,
} from '../../../mailshots/mailshotsSlice';
import useRightPanel from '../../../panels/hooks/useRightPanel';
import { AccountTabsValues } from '../../constants';
import styles from './accountTabs.module.scss';

interface IAccountTabsProps {
  selectedTab: AccountTab;
}

const AccountTabs = ({ selectedTab }: IAccountTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isManager } = useAppSelector(getAuthState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();
  const { updatingQuill } = useAppSelector(getMailshotsState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();
  const tabLabels = AccountTabsValues.filter((elt) => {
    // manager and full app access : all tabs
    if (isManager && hasUrbaneaseAccess) return true;
    // manager and only pdf access : selected tabs tabs
    if (isManager && !hasUrbaneaseAccess) return !elt.onlyUrbaAccount;

    // user access with all or restricted app access
    return elt.role.includes('user');
  }).map((elt) => ({
    id: elt.id,
    name: elt.title,
  }));
  const selected = tabLabels.find((f) => f.id === selectedTab);

  const handleTabChange = (value: AccountTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    if (selectedTab === 'mailshots' && updatingQuill) {
      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          accountTab: value,
          content: null,
        })
      );
    } else {
      dispatch(appActions.setAccountTab(value));
    }
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={styles.accountTabs}>
      <GenericTabContainer
        tabs={tabLabels}
        selectedDefault={selected ?? null}
        onSelect={(entity) => handleTabChange(entity.id as AccountTab)}
        dataCyBase="account-tab"
        isAccountTabs
      />
    </div>
  );
};

export default AccountTabs;
