import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, TextareaAutosize } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../App/store';
import { getStudyParamsState } from '../../../../../../../../study/slices/studyParamsSlice';
import { ProspectionTabBlocsProps } from '../../../Prospection';
import styles from './constructComment.module.scss';
import { constructFormInitialState, constructFormValidationSchema } from './utils';

import { updateStudyThunk } from '../../../../../../../../study/services/thunks/studyThunks';
import ConstructInputAndLabel from './ConstructInputAndLabel';

export default function ConstructComment({
  study,
  updateAllowed,
}: ProspectionTabBlocsProps) {
  // ***** REDUX ACCESS *****
  const { type } = useAppSelector(getStudyParamsState);
  const dispatch = useAppDispatch();

  // ***** HOOKS *****
  const useFormOptions = useForm<ConstructFormType>({
    defaultValues: constructFormInitialState,
    resolver: yupResolver(constructFormValidationSchema),
  });

  // ***** CONSTANTS *****
  const isNotSector = type !== 'sector';
  const { register, setValue, handleSubmit } = useFormOptions;

  if (!study) return null;

  useEffect(() => {
    const { comment, askingPrice, expectedRevenue, buildableArea } =
      study as IPlotStudy;
    // set valus to form when study props change
    if (comment) setValue('comment', comment);
    if (askingPrice) setValue('askingPrice', askingPrice);
    if (expectedRevenue) setValue('expectedRevenue', expectedRevenue);
    if (buildableArea) setValue('buildableArea', buildableArea);
  }, [type, study]);

  // update study if form is valid
  const handleUpdate = (values: ConstructFormType) => {
    if (study) {
      dispatch(
        updateStudyThunk({
          // if not plot study, only comment
          body: isNotSector
            ? {
                comment: values.comment ?? '',
                askingPrice: values.askingPrice ?? 0,
                buildableArea: values.buildableArea ?? 0,
                expectedRevenue: values.expectedRevenue ?? 0,
              }
            : { comment: values.comment },
        })
      );
    }
  };

  return (
    <FormProvider {...useFormOptions}>
      <form
        className={styles.constructCommentContainer}
        onSubmit={handleSubmit(handleUpdate)}
      >
        {isNotSector && (
          <>
            {/* asking price field */}
            <ConstructInputAndLabel
              name="askingPrice"
              updateAllowed={updateAllowed}
              label="Prix de vente souhaité (€) : "
              dataCy="datacy-plotStudy-askingPrice"
            />

            {/* expected revenue field  */}
            <ConstructInputAndLabel
              name="expectedRevenue"
              updateAllowed={updateAllowed}
              label="CA prévu pour l' opération (€):"
              dataCy="datacy-plotStudy-expectedRevenue"
            />

            {/* buildable area field */}
            <ConstructInputAndLabel
              name="buildableArea"
              updateAllowed={updateAllowed}
              label="Surface constructible projet (m²) :"
              dataCy="datacy-plotStudy-buildableArea"
            />
          </>
        )}

        {/* text area field */}
        <FormControl variant="outlined">
          <TextareaAutosize
            {...register('comment')}
            placeholder="Commentaire ..."
            minRows={8}
            maxRows={8}
            className={styles.constructComment}
            disabled={!updateAllowed}
            onFocus={(e) => e.target.select()}
            data-cy="datacy-plotStudy-comment"
          />
        </FormControl>

        {/* global submit button */}
        <button
          type="submit"
          disabled={!updateAllowed || !study}
          className={styles.saveButton}
        >
          Enregistrer les modifications
        </button>
      </form>
    </FormProvider>
  );
}
