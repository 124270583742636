import { TextField } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import styles from '../advancedPlotSearchContainer.module.scss';

interface IInputGroupElementProps {
  name: keyof AdvancedPlotSearchForm;
  placeHolder?: string;
  errorName: AdvancedSearchFieldToTrigger;
  keysToTrigger: AdvancedSearchFieldsToTrigger;
}

function InputGroupElement({
  name,
  errorName,
  placeHolder,
  keysToTrigger,
}: IInputGroupElementProps): React.ReactElement {
  const { control, trigger } = useFormContext();
  const { errors } = useFormState({ control });
  const maxError = errors[errorName];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <GenericTooltip
          text={maxError?.message as string}
          bgColor="ERROR"
          isOpen
          disableHoverListener
        >
          <div>
            <NumericFormat
              value={value}
              className={`${styles.inputField} ${maxError ? styles.hasError : ''}`}
              variant="outlined"
              onValueChange={(values) => onChange(values.floatValue)}
              onFocus={(e) => e.target.select()}
              onBlur={async () => await trigger(keysToTrigger)}
              placeholder={placeHolder}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(maxError)}
              thousandSeparator=" "
              customInput={TextField}
              autoComplete="off"
            />
          </div>
        </GenericTooltip>
      )}
    />
  );
}

export default InputGroupElement;
