import usePLU from '../../../../shared/hooks/usePLU';
import styles from './pluLegend.module.scss';
/* 
city exemples with plu status for text
  Clarens 65150 CC
  Nauviale 12171 RNU
  Ardoix 07013 POS
*/

function PluStatus() {
  const { getPluStatusText } = usePLU();

  return (
    <div className={[styles.pluLegend, styles.pluStatus].join(' ')}>
      <h4>Aucun plan de zone disponible</h4>
      <p>Document d&rsquo;urbanisme</p>
      {/* if status is not null or not INCONNU, display the status */}
      <p className={styles.status}>{getPluStatusText()}</p>
    </div>
  );
}

export default PluStatus;
