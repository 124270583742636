import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  companyUserUpdateThunk,
  fetchCompanyUserThunk,
  fetchUsersThunk,
  userCreateThunk,
  userUpdateThunk,
} from './services/usersThunks';

const initialState: UsersState = {
  users: null,
  activeUsers: [],
  userCount: 0,
  selectedUser: null,
  isEditingUser: false,
  apiStatus: APIStatus.IDLE,
  apiError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEditingUser: (state, action: PayloadAction<boolean>) => {
      state.isEditingUser = action.payload;
    },
    resetApiError: (state) => {
      state.apiStatus = APIStatus.IDLE;
      state.apiError = null;
    },
    setSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    resetSelectedUser: (state) => {
      state.selectedUser = null;
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // FETCH USERS
      .addCase(fetchUsersThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(fetchUsersThunk.fulfilled, (state, action: PayloadAction<Users>) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
        state.users = action.payload;
        state.activeUsers = action.payload.filter((elt) => elt.isActive);
        state.userCount = action.payload.length;
      })
      .addCase(fetchUsersThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.users = [];
        state.activeUsers = [];
        state.userCount = 0;
        state.apiError = action.error;
      });
    // POST USER
    builder
      .addCase(userCreateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(userCreateThunk.fulfilled, (state, action: PayloadAction<User>) => {
        state.apiStatus = APIStatus.IDLE;
        state.apiError = null;
        state.users = state.users
          ? state.users.concat(action.payload)
          : [action.payload];
        if (action.payload.isActive) {
          state.activeUsers = state.activeUsers
            ? state.activeUsers.concat(action.payload)
            : [action.payload];
        }
      })
      .addCase(userCreateThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.error;
      });

    // UPDATE USER
    builder
      .addCase(userUpdateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(
        userUpdateThunk.fulfilled,
        (state, action: PayloadAction<{ user: User; isPwdUpdate: Boolean }>) => {
          state.apiStatus = APIStatus.IDLE;
          state.apiError = null;

          const ap = action.payload;

          if (!ap.isPwdUpdate) {
            state.users = (state.users as Users).map((u) => {
              return u.idIri === ap.user.idIri ? ap.user : u;
            });
            state.activeUsers = (state.users as Users)
              .map((u) => {
                return u.idIri === ap.user.idIri ? ap.user : u;
              })
              .filter((elt) => elt.isActive);
          }
        }
      )
      .addCase(userUpdateThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.error;
      });
    builder
      .addCase(fetchCompanyUserThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(
        fetchCompanyUserThunk.fulfilled,
        (state, action: PayloadAction<CompanyUser>) => {
          state.apiStatus = APIStatus.IDLE;
          state.apiError = null;
          if (state.users) {
            const ap = action.payload;

            const isManager =
              ap.roles?.some((f: string) => f === 'ROLE_MANAGER') ?? false;
            const isAdmin =
              ap.roles?.some((f: string) => f === 'ROLE_ADMIN') ?? false;

            const newUsers = (state.users as Users).map((u) => {
              return u.idIri === ap.userIdIri
                ? {
                    ...u,
                    roles: ap.roles ?? u.roles,
                    isManager: ap.roles ? isManager : u.isManager,
                    isAdmin: ap.roles ? isAdmin : u.isAdmin,
                    isActive:
                      ap.userIsActive === undefined ? u.isActive : ap.userIsActive,
                  }
                : u;
            });

            state.users = newUsers;
            state.activeUsers = newUsers.filter((elt) => elt.isActive);
          }
        }
      )
      .addCase(fetchCompanyUserThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.error;
      });
    // UPDATE USER COMPANY (roles and isActive in company)
    builder
      .addCase(companyUserUpdateThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(
        companyUserUpdateThunk.fulfilled,
        (state, action: PayloadAction<CompanyUser>) => {
          state.apiStatus = APIStatus.IDLE;
          state.apiError = null;

          const ap = action.payload;

          const isManager =
            ap.roles?.some((f: string) => f === 'ROLE_MANAGER') ?? false;
          const isAdmin = ap.roles?.some((f: string) => f === 'ROLE_ADMIN') ?? false;

          const newUsers = (state.users as Users).map((u) => {
            return u.idIri === ap.userIdIri
              ? {
                  ...u,
                  roles: ap.roles ?? u.roles,
                  isManager: ap.roles ? isManager : u.isManager,
                  isAdmin: ap.roles ? isAdmin : u.isAdmin,
                  isActive:
                    ap.userIsActive === undefined ? u.isActive : ap.userIsActive,
                }
              : u;
          });

          state.users = newUsers;
          state.activeUsers = newUsers.filter((elt) => elt.isActive);
        }
      )
      .addCase(companyUserUpdateThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.error;
      });
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
export const getUsersState = (state: RootState) => state.users;
