import { useAppDispatch } from '../../../../../App/store';
import { modalsActions } from '../../../../../features/modals/modalsSlice';
import SupportEmailLink from '../../../../../shared/components/common/SupportEmailLink';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../../shared/components/modals/genericModal/GenericModal';

function StudyContactModal() {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.studyContactModal(false));
  };

  return (
    <>
      <GenericModal
        closeModal={handleCloseModal}
        modalType={GenericModalEnum.ERROR}
        actionType={GenericModalActionEnum.OK}
        okButtonContent="OK"
        okCallback={handleCloseModal}
        title={'Modifier un contact'}
      >
        <>
          <p>Une erreur est survenue et le contact n&#039;a pas pu être modifié.</p>
          <p>
            Veuillez réessayer ultérieurement ou contacter notre support{' '}
            <SupportEmailLink />
          </p>
        </>
      </GenericModal>
    </>
  );
}

export default StudyContactModal;
