import { dateFormat } from '../../../../../lib/formats/dataFormat';
import { PROSPECTION_PRIORITY_LIST } from '../../../../../shared/constants';
import { exportExcel } from '../../../../../utils/exportExcel';

export const handleExportExcelUserPlotStudies = ({
  plotStudies,
  username,
  plotStudyStatuses,
}: {
  plotStudies: PlotStudies;
  username: string;
  plotStudyStatuses: StudyStatuses | null;
}): void => {
  if (plotStudies && plotStudyStatuses) {
    const worksheetColumns = [
      { header: 'Commune', key: 'city' },
      { header: 'Parcelle', key: 'fullPlotId' },
      { header: 'Surface parcelle', key: 'area' },
      { header: 'Zonage PLU', key: 'zone' },
      { header: 'Zonage PLU enfant', key: 'zoneChild' },
      { header: 'Dossier', key: 'folder' },
      { header: 'Prix de vente souhaité', key: 'askingPrice' },
      { header: "CA prévu pour l'opération", key: 'expectedRevenue' },
      { header: 'Surface constructible projet', key: 'buildableArea' },
      { header: 'Statut de l’opportunité', key: 'status' },
      { header: 'Priorité', key: 'priority' },
      { header: 'Date de création', key: 'createdAt' },
      { header: 'Adresse postale', key: 'mapAddress' },
    ];
    const worksheetRow = plotStudies.map((elt: IPlotStudy) => {
      const priorityLabel = PROSPECTION_PRIORITY_LIST.find(
        (f) => f.value === elt.priority
      )?.display;
      const createdAtParse = elt.createdAt ? dateFormat(elt.createdAt) : null;

      return {
        ...elt,
        zone: elt.zone ?? ' - ',
        zoneChild: elt.zoneChild ?? ' - ',
        city: elt.city ?? ' - ',
        fullPlotId: elt.plotId ?? ' - ',
        folder: elt.parent?.name ?? ' - ',
        area: elt.area ?? ' - ',
        status: elt.status?.label ?? ' - ',
        askingPrice: elt.askingPrice ?? ' - ',
        expectedRevenue: elt.expectedRevenue ?? ' - ',
        buildableArea: elt.buildableArea ?? ' - ',
        priority: priorityLabel ?? ' - ',
        createdAt: createdAtParse ?? ' - ',
      };
    });

    exportExcel(`rapport de ${username}`, worksheetColumns, worksheetRow);
  }
};
