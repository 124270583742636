import { useAppSelector } from '../../../App/store';
import { getMapPluginsUrbaToolbarState } from '../../../features/map/plugins/mapPluginSlice';
import { ToolbarTypeEnum } from '../../../features/map/plugins/toolbars/urbaToolbar/types/ToolbarBtnEnums';
import GenericTooltip from '../../../shared/components/tooltips/GenericTooltip';

interface ITooltipDisableMapToolbarProps {
  children: React.ReactElement;
}

function TooltipDisableMapToolbar({ children }: ITooltipDisableMapToolbarProps) {
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const mapToolbar =
    activeToolbar !== ToolbarTypeEnum.DRAW && activeToolbar !== null;

  return (
    <GenericTooltip
      disableHoverListener={!mapToolbar}
      text={'Veuillez désactiver les outils de la carte'}
    >
      {children}
    </GenericTooltip>
  );
}

export default TooltipDisableMapToolbar;
