import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../auth/authSlice';
import useCompany from '../../../company/hooks/useCompany';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { modalsActions } from '../../../modals/modalsSlice';
import { getUsersState } from '../../../users/usersSlice';
import './teamTab.scss';
import UserCardContainer from './UserCardContainer';

export default function TeamTab(): JSX.Element {
  const dispatch = useAppDispatch();
  const { company } = useCompany();
  const { users, userCount } = useAppSelector(getUsersState);
  const { userIdIri, isManager } = useAppSelector(getAuthState);

  const accountLeft = company.result?.userLimit
    ? company.result?.userLimit - userCount
    : 0;

  const onAddUser = () => {
    if (accountLeft <= 0) {
      dispatch(modalsActions.subscriptionLimit(true));
    } else {
      dispatch(modalsActions.addUser(true));
    }
  };

  return (
    <>
      {userIdIri && (
        <div className="tab-team">
          <div className="tab-inner">
            <h1>Mon équipe de prospection</h1>
            <div>
              <Button onClick={onAddUser}>Ajouter un utilisateur</Button>
              <p>
                <strong>
                  {accountLeft} compte{accountLeft > 1 ? 's' : ''} restant
                </strong>{' '}
                sur {company.result?.userLimit} disponibles
              </p>
            </div>
          </div>

          {!isEmpty(users) ? (
            <UserCardContainer
              users={users ? [...users] : []}
              canUpdate={isManager}
              authIdIri={userIdIri}
            />
          ) : (
            <IndividualCircularLoader size={200} />
          )}
        </div>
      )}
    </>
  );
}
