import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  prospecDatas: FormatedProspecDatasType;
  avgPriceImg: string;
  avgPriceAreaImg: string;
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function PriceAnalysisPage({
  agency,
  prospecDatas,
  avgPriceImg,
  avgPriceAreaImg,
  formDatas,
  isOrpi,
  address,
  date,
}: IProps) {
  const stats = prospecDatas.adsStats;
  const headerLines = isOrpi
    ? ['L’analyse des prix', 'Abordez la vente avec toutes les clés']
    : ['Analyse du Marché'];
  const titlePage = isOrpi
    ? 'Abordez la vente avec toutes les clés'
    : 'Synthèse de l’offre sur l’année écoulée';
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <Page style={styles.page}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}
      <Header lines={headerLines} fixed isOrpi={isOrpi} />
      <Text style={styles.titlePage}>{titlePage}</Text>
      <Text>
        Pour la typologie des terrains similaires au vôtre (
        <Text style={fontStyles.b700}>{prospecDatas.type?.text}</Text>
        ), sur la base de(s){' '}
        <Text style={fontStyles.b700}>{stats?.soldExplotableStock}</Text>{' '}
        transaction(s) et de(s){' '}
        <Text style={fontStyles.b700}>{stats?.sellExplotableStock} terrain(s)</Text>{' '}
        à la vente observé(s) au cours des{' '}
        <Text style={fontStyles.b700}>12 derniers mois</Text>, nous constatons les
        données suivantes:
      </Text>
      <View style={styles.list}>
        <Text style={styles.listLine}>
          - le prix moyen des biens en vente est de{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellAveragePrice)} €
          </Text>{' '}
          ( mini :{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellMinPrice)} €
          </Text>{' '}
          / Maxi :{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellMaxPrice)} €
          </Text>
          )
        </Text>
        <Text style={styles.listLine}>
          - la surface moyenne des biens à la vente est de{' '}
          <Text style={fontStyles.b700}>
            {(stats && addSpaceToNumber(stats?.sellAverageArea)) ?? '-'} m²
          </Text>
        </Text>
        <Text style={styles.listLine}>
          - le prix moyen au m² des biens à la vente est de{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellAveragePriceArea)} €/m²
          </Text>
        </Text>
      </View>
      <View style={styles.chartContainer}>
        <View>
          <Text style={styles.graphTitle}>
            Prix moyen des terrains sur les 12 derniers mois
          </Text>
          <Image src={avgPriceImg} style={styles.graph} />
        </View>
        <View>
          <Text style={styles.graphTitle}>
            Prix moyen au m² des terrains sur les 12 derniers mois
          </Text>
          <Image src={avgPriceAreaImg} style={styles.graph} />
        </View>
      </View>
      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default PriceAnalysisPage;

const commonStyles = {
  title: {
    marginBottom: '15px',
    width: '100%',
    textAlign: 'center' as const,
  },
  chartContainer: {
    display: 'flex' as const,
    alignItems: 'center' as const,
    marginTop: '30px',
  },
  graphTitle: { fontWeight: 700 },
  graph: {
    width: '300px',
    height: '142px',
  },
  list: {
    marginTop: '20px',
  },
  listLine: { paddingVertical: '5px' },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  page: { ...pageStyles.page, ...fontStyles.ffOrpi },
  titlePage: {
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
    ...commonStyles.title,
  },
  graph: {
    ...commonStyles.graph,
    marginVertical: '40px',
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
  },
  titlePage: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
    ...commonStyles.title,
  },
  graph: {
    ...commonStyles.graph,
    marginVertical: '30px',
  },
});
