import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import SVGIconComponent from '../SVGIconComponent';
import styles from './markerIconWithIndex.module.scss';

const MarkerIcon = (indexId: number, color: string) => {
  return L.divIcon({
    className: styles.markerIconWithNumber,
    html: ReactDOMServer.renderToString(
      <div>{SVGIconComponent(indexId, color)}</div>
    ),
  });
};

export default MarkerIcon;
