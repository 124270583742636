import { CalendarOptions, GoogleCalendar } from 'datebook';
import { useAppSelector } from '../../../../../App/store';
import calendar from '../../../../../assets/images/calendar-days-solid.svg';
import arrow from '../../../../../assets/images/horizontal-arrow.svg';
import trait from '../../../../../assets/images/procedure-trait.svg';
import { dateFormat } from '../../../../../lib/formats/dataFormat';
import CrossIcon from '../../../../../shared/components/icons/CrossIcon';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import { firstNameAndInitialParser } from '../../../../../utils/jsFunctions';
import useStudy from '../../../hooks/useStudy';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import ProcedureType from './ProcedureType';

interface IProcedureProps {
  procedure: IStudyProcedure;
  updateAllowed: boolean;
}

const calendarConfig = (procedure: IStudyProcedure) => {
  const config: CalendarOptions = {
    title: procedure.responsable
      ? `${procedure.type?.label} - ${procedure.responsable.firstName ?? ''} ${
          procedure.responsable?.lastName ?? ''
        }`
      : procedure.type?.label,
    description: procedure.comment,
    start: new Date(procedure.date),
    end: new Date(procedure.date),
    attendees: [
      {
        name: `${procedure.responsable?.firstName ?? ''} ${
          procedure.responsable?.lastName ?? ''
        }`,
        email: procedure.responsable?.email,
      },
    ],
  };

  return new GoogleCalendar(config);
};

export default function ProcedureCard({
  procedure,
  updateAllowed,
}: IProcedureProps) {
  const { deleteStudyProcedure } = useStudy({});
  const { type } = useAppSelector(getStudyParamsState);

  return (
    <div className="procedure-card">
      <div className="left-block">
        <div className="header">
          <span>{dateFormat(procedure.date)}</span>
          <img src={trait} alt="trait" />
          <span>
            {procedure.responsable
              ? firstNameAndInitialParser(
                  procedure.responsable.firstName ?? null,
                  procedure.responsable.lastName ?? null
                )
              : 'Indéfini'}
          </span>
          {type !== 'sector' && (
            <>
              <img src={arrow} alt="trait" />
              <span>
                {procedure?.contact
                  ? `${procedure.contact.firstName} ${procedure.contact.lastName}`
                  : 'Indéfini'}
              </span>
            </>
          )}

          <ProcedureType type={procedure.type} className="procedure-icon" tooltip />
        </div>
        <p className="body">{procedure.comment}</p>
      </div>
      {updateAllowed && (
        <div className="right-block">
          <GenericTooltip text="Exporter RDV dans mon agenda personnel">
            <a
              href={calendarConfig(procedure).render()}
              target="_blank"
              rel="noreferrer"
            >
              <img src={calendar} alt="calendar" />
            </a>
          </GenericTooltip>
          <CrossIcon
            onClick={() => deleteStudyProcedure(procedure.idIri)}
            bgColor="TRANSPARENT"
            color="PRIMARY"
            width={22}
            height={22}
            noPadding
          />
        </div>
      )}
    </div>
  );
}
