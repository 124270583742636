import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import { postEntity } from '../../../../services/axiosFiles/genericCrud';
import { pdfActions } from '../../pdfSlice';

export const fetchPlotForMultiPlotPdfThunk = createAsyncThunk(
  'multiPlotSelect/fetchPlotForMultiPlotPdfThunk',
  async (
    params: {
      geolocPointInfo: GeolocPointInfo;
      lat: string;
      lng: string;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const existingPlots = state.pdf.multiPlotsPdf.result ?? [];
      const goodInsee = existingPlots[0]?.inseeCode ?? '';

      const { lat, lng, geolocPointInfo } = params;

      if (
        existingPlots.length > 0 &&
        goodInsee !== (geolocPointInfo.inseeCode as string)
      ) {
        dispatch(
          pdfActions.setInfoModalDisplay({
            display: true,
            plotIds: null,
            errorMessage: null,
            type: 'errorInsee',
          })
        );
        return rejectWithValue({ error: 'invalid insee' });
      } else {
        const response: any = await postEntity({
          endpoint:
            '/pdf_urbanease/get_parcel_id_and_area_from_coordinates_and_insee/',

          body: { insee: geolocPointInfo.inseeCode as string, lat: lat, lon: lng },
        });

        if (!isEmpty(response)) {
          const parsedResponse = {
            ...response,
          } as IFetchParcelFromCoordinatesAndInseeResponse;

          const parsedResult: Omit<IMultiPlotsPdf, 'index'> = {
            fullPlotId: parsedResponse.parcel_id,
            plotId: parsedResponse.parcel_id.substring(5),
            plotGeometry: JSON.parse(parsedResponse.geom),
            buildingsGeometry:
              parsedResponse.buildings &&
              parsedResponse.buildings[0] &&
              parsedResponse.buildings
                .filter((f: any) => f.geom !== null)
                .map((m: any) => JSON.parse(m.geom)),
            lat: parsedResponse.lat,
            lng: parsedResponse.lon,
            areaBuilt: parsedResponse.area_built ?? null,
            areaFree: parsedResponse.area_free ?? null,
            realCapacity: parsedResponse.real_capacity ?? null,
            theoricCapacity: parsedResponse.theoric_capacity ?? null,

            inseeCode: geolocPointInfo.inseeCode as string,
            city: geolocPointInfo.city,
            postalCode: geolocPointInfo.postalCode,
            address: geolocPointInfo.address,
            houseNumber: geolocPointInfo.houseNumber,
            streetName: geolocPointInfo.streetName,
          };

          return parsedResult;
        } else {
          return null;
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
