import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { paramsNotFound } from '../../services/axiosFiles/axiosUtils';
import entityListFromNestedEntities from '../../utils/entityListFromNestedEntities';
import { IFetchPlotDataFromCoordinatesProps } from '../plot/plotRequests';
import { fetchGetPlotDataFromCoordinates } from '../plot/services/fetchGetParcelDataFromCoordinates';
import { checkForExistingPlotStudy } from '../study/services/checkForExistingPlotStudy';

interface IFetchPlotForMultiPlotThunkProps
  extends IFetchPlotDataFromCoordinatesProps {
  mapAddress: string | null;
  zipCode: string;
  cityName: string;
}
export const fetchPlotForMultiPlotThunk = createAsyncThunk(
  'multiPlotSelect/fetchPlotForMultiPlotThunk',
  async (
    params: IFetchPlotForMultiPlotThunkProps,
    { rejectWithValue, getState }
  ) => {
    try {
      const { cityName, zipCode, mapAddress } = params;
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;
      const { userIdIri } = state.auth;
      if (users && plotStudyStatuses.result && userIdIri) {
        const result = await fetchGetPlotDataFromCoordinates({
          ...params,
          gap: 'multiselect',
        });

        const coord = result.plotCentroid as number[];

        const parsedResult: PlotMultiPlot = {
          fullPlotId: result.plotId,
          plotGeometry: result.plotGeometry,
          buildingsGeometry: result.buildingsGeometry,
          lat: coord[0],
          lng: coord[1],
          area: result.plotDatas?.theoricCapacity ?? 0,
          areaBuilt: result.plotDatas?.areaBuilt ?? null,
          areaFree: result.plotDatas?.areaFree ?? null,
          realCapacity: result.plotDatas?.realCapacity ?? null,
          city: cityName ?? null,
          inseeCode: result.inseeCode ?? null,
          address: mapAddress ?? '',
          postalCode: zipCode ?? null,
          zone: result.zone?.zone ?? null,
          zoneChild: result.zone?.zoneChild ?? null,
          plotStudyId: null,
          responsable: userIdIri,
          folder: null,
          index: 0,
        };

        return parsedResult;
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const checkForDuplicatePlotThunk = createAsyncThunk(
  'multiPlotSelect/checkForDuplicatePlotThunk',
  async (
    params: { plotIds: { fullPlotId: string }[] | null },
    { rejectWithValue, getState }
  ) => {
    try {
      const { plotIds } = params;
      if (plotIds) {
        const state = getState() as RootState;
        const companyIdIri = state.company.companyIdIri;
        const { folders } = state.folders;
        const { users } = state.users;
        const { plotStudyStatuses } = state.app;

        if (folders.result) {
          const folderList = entityListFromNestedEntities(folders.result) as Folders;

          // check existing request
          const result = await Promise.all(
            plotIds.map((m) =>
              checkForExistingPlotStudy(
                m.fullPlotId,
                companyIdIri,
                users,
                plotStudyStatuses.result,
                folderList
              )
            )
          );

          // remove null results
          const duplicatePlots = result.filter((f) => f !== null) as PlotStudies;

          return duplicatePlots;
        } else {
          return null;
        }
      } else {
        return rejectWithValue({ status: 0, message: 'state plots is null' });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
