import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import TextFieldFormCustom from '../../../../shared/components/common/formComponents/TextFieldFormCustom';
import FieldInputPwdFormCustom from '../../../../shared/components/common/formComponents/passwordComponents/FieldInputPwdFormCustom';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { getCompanyState } from '../../../company/companySlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { userCreateThunk, userUpdateThunk } from '../../services/usersThunks';
import { getUsersState, userActions } from '../../usersSlice';
import './addUserModal.scss';
import {
  IAddUserForm,
  initialForm,
  validationAddUserSchema,
  validationEditUserSchema,
} from './addUserModalValidation';

export default function AddUserModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const { selectedUser, isEditingUser } = useAppSelector(getUsersState);
  const { companyIdIri } = useAppSelector(getCompanyState);

  const formOptions = useForm<IAddUserForm>({
    defaultValues:
      isEditingUser && selectedUser
        ? {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
          }
        : initialForm,
    resolver: yupResolver(
      selectedUser ? validationEditUserSchema : validationAddUserSchema
    ),
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = formOptions;

  useEffect(() => {
    if (selectedUser) {
      setValue('firstName', selectedUser.firstName);
      setValue('lastName', selectedUser.lastName);
      setValue('email', selectedUser.email);
    }
  }, [selectedUser]);

  const onCloseModal = () => {
    reset();
    dispatch(modalsActions.addUser(false));
    dispatch(userActions.resetSelectedUser());
    dispatch(userActions.setEditingUser(false));
  };

  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      if (selectedUser) {
        dispatch(
          userUpdateThunk({
            userIdIri: selectedUser.idIri,
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
            },
          })
        );
      } else {
        dispatch(
          userCreateThunk({
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
              plainPassword: getValues('password'),
              roles: ['ROLE_USER'],
            },
            companyIdIri,
          })
        );
      }
      onCloseModal();
    }
  };

  return (
    <GenericModal
      className="add-user-modal"
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.NONE}
      closeModal={onCloseModal}
      hasIcon={false}
      title={isEditingUser ? "Modifier l'utilisateur" : 'Ajouter un utilisateur'}
    >
      <div className="addUserModalContainer">
        <FormProvider {...formOptions}>
          <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="textField">
              <TextFieldFormCustom name="lastName" label="Nom" errorTooltip />
            </div>
            <div className="textField">
              <TextFieldFormCustom name="firstName" label="Prénom" errorTooltip />
            </div>
            <div className="textField">
              <TextFieldFormCustom
                type="email"
                name="email"
                label="Email"
                errorTooltip
              />
            </div>
            {!isEditingUser && (
              <>
                <div className="textField">
                  <FieldInputPwdFormCustom
                    name="password"
                    label="Mot de passe"
                    hasComplexErrorMessage
                    onResetError={() => clearErrors()}
                  />
                </div>
                <div className="textField">
                  <FieldInputPwdFormCustom
                    name="verifyPassword"
                    label="Confirmation du mot de passe"
                  />
                </div>
              </>
            )}
            <Button type="submit" classes={{ root: 'addUserFormSubmit' }}>
              {selectedUser ? "Modifier l'utilisateur" : 'Créer l’utilisateur'}
            </Button>
          </form>
        </FormProvider>
      </div>
    </GenericModal>
  );
}
