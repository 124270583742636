import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './actionFailed.module.scss';

interface IActionFailedProps {
  callback: () => void;
}
function ActionFailed({ callback }: IActionFailedProps) {
  return (
    <div className={styles.noDatasError}>
      <FontAwesomeIcon icon={faXmark} className={styles.crossIcon} />

      <p>
        Erreur lors du chargement <br />
        des données
      </p>
      <button onClick={callback}>Fermer</button>
    </div>
  );
}

export default ActionFailed;
