import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { ChangeEvent, memo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import usePLU from '../../../../shared/hooks/usePLU';
import { getMapState } from '../../../map/mapSlice';
import { pluActions } from '../../pluSlice';
import AskForPlu from './AskForPlu';
import PluError from './PluError';
import PluLoading from './PluLoading';
import PluStatus from './PluStatus';
import styles from './pluLegend.module.scss';

const useStyles = makeStyles()({
  root: { paddingBottom: '3px', paddingTop: '3px' },
  label: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
});

const PluLegend = (): JSX.Element => {
  // ****** CUSTOM HOOKS ******
  const { classes } = useStyles();
  const {
    pluDisplayed,
    noPluUrl,
    pluUrlApiStatus,
    pluStatus,
    pluBotIntegrationDate,
  } = usePLU();

  // ****** REDUX STATES ******
  const { geolocDatas } = useAppSelector(getMapState);

  // ****** CONSTANT ******
  const dispatch = useAppDispatch();

  // ****** HANDLES ******
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleButtonClick();
  };
  const handleButtonClick = () => {
    dispatch(pluDisplayed ? pluActions.pluLayerHide() : pluActions.pluLayerShow());
  };

  // ***** RETURNS *****
  if (pluUrlApiStatus === APIStatus.PENDING) {
    return <PluLoading />;
  }
  if (pluUrlApiStatus === APIStatus.REJECTED) {
    // if any error on pluUrl request
    return <PluError />;
  }
  if (pluStatus) {
    // priority than url
    // if plu status, present, display it
    return <PluStatus />;
  }

  if (noPluUrl) {
    // if no status and no url, this city is not integrated
    // display ask button
    return <AskForPlu />;
  }

  return (
    <div className={styles.pluLegend}>
      <Tooltip
        title={
          Boolean(geolocDatas?.city && geolocDatas?.postalCode) && noPluUrl
            ? "Le PLU n'est pas disponible pour cette commune"
            : 'Vous devez sélectionner une ville'
        }
        placement="top"
        disableHoverListener={
          Boolean(geolocDatas?.city && geolocDatas?.postalCode) && Boolean(!noPluUrl)
        }
      >
        <div className={styles.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={pluDisplayed}
                onChange={onChange}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={!(geolocDatas?.city && geolocDatas?.postalCode)}
                data-cy="plu-checkbox"
              />
            }
            classes={classes}
            label="Afficher le plan de zone"
            labelPlacement={'end'}
          />
        </div>
      </Tooltip>
      {/* // need folder commune S3 date instead of pluBotIntegrationDate */}
      {/* {pluDisplayed && pluBotIntegrationDate && (
        <p>Dernière actualisation: {dateFormat(pluBotIntegrationDate)}</p>
      )} */}
      <Button className={styles.pluButton} onClick={handleButtonClick}>
        Afficher sur la carte
      </Button>
    </div>
  );
};

export default memo(PluLegend);
