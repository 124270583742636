import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../App/store';

import LocationPin from '../../../../assets/images/transparent_pin.png';
import LoginContent from '../loginContent/LoginContent';

import { useEffect } from 'react';
import CloseCross from '../../../../shared/components/modals/closeCross/CloseCross';
import { authErrorMessages } from '../../../../shared/errorMessages';
import { modalsActions } from '../../../modals/modalsSlice';
import { getAuthState } from '../../authSlice';
import SignupContent from './SignupContent';
import './signUpModal.scss';

interface ISignupModalProps {
  open: boolean;
}

const SignUpModal = ({ open }: ISignupModalProps) => {
  const { error } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();
  const isExpired =
    authErrorMessages.expiredSubscription.displayMessage === error?.message;

  const handleClose = () => {
    dispatch(modalsActions.signup(false));
  };

  useEffect(() => {
    if (isExpired) {
      handleClose();
      dispatch(modalsActions.expiredSubscriptionModalOpen());
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="sign-up-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="modal-box-wrapper">
          <div className="modal-login-panel">
            <div className="title-pin">
              <img src={LocationPin} alt="img" />
            </div>

            <LoginContent isSignupModal />
          </div>

          <div className="modal-right-panel">
            <CloseCross onClick={handleClose} />

            <SignupContent />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpModal;
