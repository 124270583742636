import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import individualCityDatasExtraReducer from './individualCityDatasExtraReducer';
import fetchCityDatasLaucherThunk from './services/thunks/fetchCityDatasLaucherThunk';

// Only datas updated with inseeCode change
export const cityDatasInitialState: CityDatas = {
  parkingPackages: { apiStatus: APIStatus.IDLE, result: null },
  socialCriteria: { apiStatus: APIStatus.IDLE, result: null },
};

const initialState: ActivePlotState = {
  cityDatas: { apiStatus: APIStatus.IDLE, result: cityDatasInitialState },
};

const activePlotSlice = createSlice({
  name: 'activePlot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCityDatasLaucherThunk.pending, (state) => {
        state.cityDatas.apiStatus = APIStatus.PENDING;
        state.cityDatas.error = undefined;
      })
      .addCase(fetchCityDatasLaucherThunk.fulfilled, (state) => {
        state.cityDatas.apiStatus = APIStatus.IDLE;
        state.cityDatas.error = undefined;
      })
      .addCase(fetchCityDatasLaucherThunk.rejected, (state, action) => {
        state.cityDatas.apiStatus = APIStatus.REJECTED;
        state.cityDatas.error = action.error;
      });
    individualCityDatasExtraReducer(builder);
  },
});

export default activePlotSlice.reducer;
export const activePlotActions = activePlotSlice.actions;
export const getActivePlotState = (state: RootState) => state.activePlot;
export const getActivePlotCityDatasState = (state: RootState) =>
  state.activePlot.cityDatas;
