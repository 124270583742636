import { memo } from 'react';
import IndividualCircularLoader from '../../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import styles from '../dpe.module.scss';
import DpeLegendContainer from './DpeLegendContainer';

function DpeLoaderMessage() {
  return (
    <DpeLegendContainer className={`${styles.dpeLegend} `}>
      <IndividualCircularLoader size={100} />
    </DpeLegendContainer>
  );
}

export default memo(DpeLoaderMessage);
