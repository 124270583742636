import { jwtDecode } from 'jwt-decode';
import LocalStorageService from '../../../services/LocalStorageService';
import { UserRoleEnum } from '../authSlice';

export const decodeToken = (token: string): DecodedToken | null => {
  try {
    const decodedToken: any = jwtDecode(token);

    const parsedToken: DecodedToken = {
      companies: decodedToken.companies.map((c: any) => ({
        id: c.id,
        idIri: '/companies/' + c.id,
        name: c.name,
        postalCode: c.zipCode,
        firm: c.firm ? { name: c.firm.name, id: c.firm.id } : null,
      })),
      roles: decodedToken.roles as UserRoleEnum[],
      userId: decodedToken.user_id,
      userIdIri: '/users/' + decodedToken.user_id,
      userEmail: decodedToken.username,
      lastAuthAt: decodedToken?.last_auth_at?.date ?? null,
      firm: decodedToken.firm ?? null,
    };

    return parsedToken;
  } catch (error) {
    return null;
  }
};

export const clearStorages = () => {
  sessionStorage.clear();
  LocalStorageService.clear();
};

export const setTokensIntoLocalStorage = (token: string, refreshToken: string) => {
  LocalStorageService.setToken(token);
  LocalStorageService.setRefreshToken(refreshToken);
};
