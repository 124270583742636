import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import styles from './pluLegend.module.scss';

function PluLoading() {
  return (
    <div className={`${styles.pluLegend} ${styles.loading}`}>
      <p>Chargement des données</p>
      <p>du PLU</p>
      <IndividualCircularLoader size={30} />
    </div>
  );
}

export default PluLoading;
