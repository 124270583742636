import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../App/store';
import arrayFilter from '../../lib/arrayFilter';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { DisplayedDpeTypesEnum } from './dpeEnums';
import { dpeExtraReducers } from './dpeExtraReducers';

const initialState: DpeState = {
  inseeCode: null,
  postalCode: null,
  dpeVisible: false,
  dpeGFVisible: false,
  isRecentOnly: false,
  displayedDpeTypes: [],
  dpePoints: { apiStatus: APIStatus.IDLE, result: null },

  dpeAdvancedSearchPoints: { apiStatus: APIStatus.IDLE, result: null },
  dpesForMap: { apiStatus: APIStatus.IDLE, result: null },
  dateFilter: { start: null, end: null },
  generateDisplay: false,
  dpeAdvancedSearchFormData: null,
};

const dpeSlice = createSlice({
  name: 'dpe',
  initialState,
  reducers: {
    setBaseData: (
      state,
      action: PayloadAction<{ inseeCode: string | null; postalCode: string | null }>
    ) => ({
      ...initialState,
      postalCode: action.payload.postalCode,
      inseeCode: action.payload.inseeCode,
    }),

    setGenerateDisplay: (state, action: PayloadAction<boolean>) => {
      state.generateDisplay = action.payload;
    },
    showDpe: (state) => {
      state.dpeVisible = true;
      state.dpeGFVisible = false;
      state.displayedDpeTypes = Object.values(DisplayedDpeTypesEnum);
    },
    hideDpe: (state) => {
      state.dpeVisible = false;
      state.dpeGFVisible = false;
      state.isRecentOnly = false;
      state.displayedDpeTypes = [];
      state.dateFilter = { start: null, end: null };
    },
    showDpeGF: (state) => {
      state.dpeVisible = false;
      state.dpeGFVisible = true;

      state.displayedDpeTypes = [DisplayedDpeTypesEnum.G, DisplayedDpeTypesEnum.F];
    },
    hideDpeGF: (state) => {
      state.dpeGFVisible = false;
      state.dpeVisible = false;
      state.isRecentOnly = false;
      state.displayedDpeTypes = [];
      state.dateFilter = { start: null, end: null };
    },
    isRecentShow: (state) => {
      // reset date filter
      state.dateFilter = { start: null, end: null };

      //select all dpe types
      if (isEmpty(state.displayedDpeTypes)) {
        state.dpeVisible = true;
        state.displayedDpeTypes = Object.values(DisplayedDpeTypesEnum);
      }

      state.isRecentOnly = true;
    },
    isRecentHide: (state) => {
      state.isRecentOnly = false;
    },
    setDisplayedDpeTypes: (state, action: PayloadAction<DisplayedDpeTypesEnum>) => {
      const tab = arrayFilter<DisplayedDpeTypesEnum>(
        state.displayedDpeTypes,
        action.payload
      );

      state.displayedDpeTypes = tab;

      if (isEmpty(tab)) {
        state.dpeVisible = false;
      } else {
        state.dpeVisible = true;
      }
    },
    setDateFilter: (state, action: PayloadAction<DateFilterType>) => {
      //uncheck recent if date added
      state.isRecentOnly = false;

      //display all dpe
      if (!state.dpeVisible && !state.dpeGFVisible) {
        state.dpeVisible = true;
        state.dpeGFVisible = false;
        state.displayedDpeTypes = Object.values(DisplayedDpeTypesEnum);
      }

      //add dates for filter
      state.dateFilter = action.payload;
    },
    setDpeAdvancedSearchFormData: (
      state,
      action: PayloadAction<IDpeAdvancedSearchForm | null>
    ) => {
      state.dpeAdvancedSearchFormData = action.payload;
    },

    resetDpeAdvancedSearchPoints: (state) => {
      state.dpeAdvancedSearchPoints = initialState.dpeAdvancedSearchPoints;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    dpeExtraReducers(builder);
  },
});

export default dpeSlice.reducer;
export const dpeActions = dpeSlice.actions;
export const getDpeState = (state: RootState) => state.dpe;
