import { useAppSelector } from '../../../../../../App/store';
import { getPlotState } from '../../../../../../redux/plot/reducer';
import PLUButton from '../../../../../plu/components/PluButton';
import UrbanismeContent from './components/urbanisme/UrbanismeContent';

export default function UrbanismePlot() {
  const { parcelle } = useAppSelector(getPlotState);

  return (
    <div className="urbanisme-body">
      <div className="urbanisme-body-title">
        <h2>Information d’urbanisme</h2>
        <PLUButton />
      </div>

      <UrbanismeContent plot={parcelle} />
    </div>
  );
}
