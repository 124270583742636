// Import style
import { useAppSelector } from '../../../../App/store';
import UpdatePasswordForm from '../../../../shared/components/common/formComponents/updatePasswordForm/UpdatePasswordForm';

import { getAuthState } from '../../../auth/authSlice';

import styles from './passwordTab.module.scss';

function PasswordTab(): JSX.Element {
  const { user } = useAppSelector(getAuthState);

  return (
    <div className={styles.passwordTab}>
      <h1>Modifier mon mot de passe</h1>

      <div className={styles.formContainer}>
        <UpdatePasswordForm user={user!} />
      </div>
    </div>
  );
}

export default PasswordTab;
