import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChangeEvent, memo, useContext, useState } from 'react';
import { useAppDispatch } from '../../../../App/store';
import { plotServitudesActions } from '../../plotServitudesSlice';
import { GroupCheckedContext } from './PlotServitudesContent';

import styles from './plotServitudesContent.module.scss';
interface IGroupRowProps {
  libelle: string;
  children: React.ReactNode;
  idTab: string[];
}

function GroupRow({ libelle, children, idTab }: IGroupRowProps) {
  const dispatch = useAppDispatch();
  const { state, update } = useContext(GroupCheckedContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    if (value) {
      update(state.concat(e.target.name));
    } else {
      update(state.filter((f) => f !== e.target.name));
    }
    dispatch(plotServitudesActions.setDisplayedGroup({ checked: value, idTab }));
  };

  const handleOpenClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.groupRow}>
      <div className={styles.groupHeader}>
        <input
          type="checkbox"
          name={libelle}
          onChange={handleChange}
          checked={state.includes(libelle)}
        />
        <label htmlFor={libelle}>
          {libelle?.replaceAll('\\n', ' - ') ?? 'Aucun libellé'}
        </label>
        <ExpandMoreIcon
          className={`${styles.expandIcon} ${isOpen ? styles.isOpen : ''}`}
          onClick={handleOpenClick}
        />
      </div>
      <div
        className={`${styles.groupRowChildContainer} ${
          isOpen ? styles.isVisible : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default memo(GroupRow);
