import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as ArrowCloseMultipleSelectPlots } from '../../../../assets/images/arrow-close-multiple-select-plots.svg';
import { ReactComponent as ArrowOpenMultipleSelectPlots } from '../../../../assets/images/arrow-open-multiple-select-plots.svg';
import { getPlotState } from '../../../../redux/plot/reducer';

import { useLocation } from 'react-router';
import useSubscriptionAccess from '../../../company/subscription/useSubscriptionAccess';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { getPdfState, pdfActions } from '../../pdfSlice';
import styles from '../styles/pdfRightPanelModal.module.scss';
import NoPlotContent from './NoPlotContent';

function PdfRightPanelModalWrapper({ children }: IChildren) {
  const { pdfRightPanelDisplay } = useAppSelector(getPdfState);
  const { parcelle, isActivePlotLoading } = useAppSelector(getPlotState);
  const { isUrbaneaseOnly } = useSubscriptionAccess();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isUrbaneaseOnly) {
      if (!parcelle && pdfRightPanelDisplay) {
        dispatch(pdfActions.setPdfRightPanelDisplay(false));
      }
      if (
        parcelle &&
        !pdfRightPanelDisplay &&
        pathname !== '/pdf-urbanease-terrain'
      ) {
        dispatch(pdfActions.setPdfRightPanelDisplay(true));
      }
    }
  }, [isUrbaneaseOnly, parcelle]);

  const handlePanel = (): void => {
    dispatch(pdfActions.setPdfRightPanelDisplay(!pdfRightPanelDisplay));
  };

  return (
    <div
      className={`${styles.rightPanelModal} ${
        pdfRightPanelDisplay ? styles.displayed : ''
      }`}
    >
      <div className={styles.arrowWrapper} onClick={handlePanel}>
        {pdfRightPanelDisplay ? (
          <ArrowCloseMultipleSelectPlots className={styles.arrow} />
        ) : (
          <ArrowOpenMultipleSelectPlots className={styles.arrow} />
        )}
      </div>
      <div className={styles.rightPanelModalContentHeader}>
        <h2>Synthèse Document</h2>
      </div>
      <div className={styles.rightPanelModalContent}>
        {isActivePlotLoading ? (
          <>
            <IndividualCircularLoader size={100} />
          </>
        ) : (
          <>
            {!parcelle && <NoPlotContent />}
            {Boolean(parcelle) && children}
          </>
        )}
      </div>
    </div>
  );
}

export default PdfRightPanelModalWrapper;
