import { useAppDispatch, useAppSelector } from '../../../../App/store';
import styles from '../../../map/plugins/toolbars/toolbars.module.scss';
import CreateToolbarBtn from '../../../map/plugins/toolbars/urbaToolbar/CreateToolbarBtn';
import { modalsActions } from '../../../modals/modalsSlice';
import { getMultiPlotSelectState } from '../../multiPlotSelectSlice';

function AddPlotsToolbarBtn() {
  const { isMultiPlotSelector, plotCount, plots } = useAppSelector(
    getMultiPlotSelectState
  );
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (plotCount > 0) {
      dispatch(
        modalsActions.multiPlotAdd({
          status: true,
          plots: plots.result as ICreatePlotStudies,
        })
      );
    }
  };

  const Tooltip = () => (
    <p className={styles.btnTooltip}>Creation multiple d&#39;études</p>
  );

  return (
    <>
      {isMultiPlotSelector && (
        <CreateToolbarBtn
          icon="SAVE_MULTIPLOT"
          onClick={handleClick}
          isPrimaryBg
          isMultiplotSave
          tooltip={<Tooltip />}
        />
      )}
    </>
  );
}

export default AddPlotsToolbarBtn;
