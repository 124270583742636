import { TextField } from '@mui/material';
import { FocusEvent } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormState,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import GenericTooltip from '../../tooltips/GenericTooltip';
import styles from './formComponents.module.scss';

interface INumericFormatFormCustom {
  name: string;
  control: any;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  dataCy?: string;
  onChange?: ChangeEventCustom;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}
export default function NumericFormatFormCustom({
  name,
  control,
  label,
  disabled,
  className,
  readOnly = false,
  required,
  placeholder,
  InputProps,
  dataCy,
  onChange,
  onFocus,
  onBlur,
}: INumericFormatFormCustom) {
  const { errors } = useFormState({ control });
  const error = errors[name];

  const handleChange = (
    field: ControllerRenderProps<FieldValues, string>,
    floatValue?: number
  ) => {
    field.onChange(floatValue);

    if (onChange) {
      onChange(floatValue);
    }
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    e.target.select();
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <GenericTooltip
          text={error?.message as string}
          isOpen
          disableHoverListener
          bgColor="ERROR"
        >
          <div>
            <NumericFormat
              value={field.value}
              variant="outlined"
              disabled={disabled}
              label={label}
              error={Boolean(error)}
              placeholder={placeholder}
              className={`${className} ${error ? styles.error : ''}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
              InputProps={{ readOnly, ...InputProps }}
              onValueChange={(values) => handleChange(field, values.floatValue)}
              required={required}
              data-cy={dataCy}
              customInput={TextField}
              thousandSeparator=" "
              autoComplete="off"
            />
          </div>
        </GenericTooltip>
      )}
    />
  );
}
