import { useAppSelector } from '../../../App/store';
import SubscriptionLimitModal from '../../subscription/components/subscriptionLimitModal/SubscriptionLimitModal';
import AddUserModal from '../../users/components/addUserModal/AddUserModal';
import AddUserResultModal from '../../users/components/AddUserResultModal';
import PasswordChangeResultModal from '../../users/components/PasswordChangeResultModal';
import { getModalsState } from '../modalsSlice';

function MyAccountModalContainer() {
  const { addUser, addUserResult, passwordChangeResult, subscriptionLimit } =
    useAppSelector(getModalsState);

  return (
    <>
      {addUser && <AddUserModal />}
      {addUserResult.isOpen && <AddUserResultModal />}
      {passwordChangeResult && <PasswordChangeResultModal />}
      {subscriptionLimit && <SubscriptionLimitModal />}
    </>
  );
}

export default MyAccountModalContainer;
