import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../App/store';
import { multiPlotSelectActions } from '../../multiPlotSelectSlice';
import DuplicatePlotsRow from './DuplicatePlotsRow';
import styles from './multiSelectPlotModal.module.scss';

interface IDuplicatePlotsContentProps {
  duplicatePlots: PlotStudies;
  duplicatePlotCount: number;
  onClose: () => void;
}
function DuplicatePlotsContent({
  duplicatePlots,
  duplicatePlotCount,
  onClose,
}: IDuplicatePlotsContentProps) {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(multiPlotSelectActions.resetDuplicatePlots());
    onClose();
  };

  return (
    <div className={styles.duplicatePlotContent}>
      <h3>
        {duplicatePlotCount > 1 ? 'Parcelles dupliquées' : 'Parcelle dupliquée'}
      </h3>
      <p>
        {duplicatePlotCount > 1
          ? duplicatePlotCount +
            ` parcelles sont déjà sauvegardées dans des dossiers :`
          : `1 parcelle est déjà sauvegardée dans un dossier : `}
      </p>
      <ul className={styles.duplicatePlotsContainer}>
        {duplicatePlots.map((m) => (
          <DuplicatePlotsRow key={nanoid()} plot={m} />
        ))}
      </ul>
      <p className={styles.indications}>
        Il est impossible d&#039;enregistrer une parcelle dans deux dossiers
        différents.
        <br />
        Merci de supprimer les parcelles concernées de la sélection, ou bien de vous
        rendre sur les parcelles concernées pour modifier le dossier dans lesquels
        elles sont rangées.
      </p>
      <button onClick={handleClose}>Fermer</button>
    </div>
  );
}

export default DuplicatePlotsContent;
