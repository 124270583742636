import { MeasureActionEnum } from '../types/measureActionEnum';
import { IMeasureInterfaceProps } from './MeasurePluginInterface';
import styles from './measureToolbar/measureToolbar.module.scss';
import StartSectionRow from './StartSectionRow';
import SurfaceDrawSectionText from './SurfaceDrawSectionText';

function SurfaceDrawSection({ onClick }: IMeasureInterfaceProps) {
  return (
    <div className={styles.areaStart}>
      <h3>Mesurer les distances et superficies</h3> <hr />
      <SurfaceDrawSectionText />
      <hr />
      <ul className={styles.areaStartOptions}>
        <StartSectionRow
          text="Annuler"
          onClick={() => onClick(MeasureActionEnum.CANCEL_DRAW_SURFACE)}
          isRed
        />

        <StartSectionRow
          text="Finir la mesure"
          onClick={() => onClick(MeasureActionEnum.END_DRAW_SURFACE)}
        />
      </ul>
    </div>
  );
}

export default SurfaceDrawSection;
