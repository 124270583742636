import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { pageStyles } from '../../shared/styles/pdfStyle';
import BuildPermitsItem from './components/BuildPermitsItem';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageContainer from './components/ImageContainer';
import { fontStyles } from './styles/pdfStyles';

interface IProps {
  builtPermits: PDFLandBuildPermits | null;
  agencyDatas: PHAgencyAndAgentDatas | null;
  formDatas: IExternalPdfLandForm;
}

function BuildPermitsPage({ builtPermits, agencyDatas, formDatas }: IProps) {
  const headerLines = ['Votre bien', '... et son environnement.'];

  return (
    <Page size="A4" style={[pageStyles.page, fontStyles.ffOrpi]}>
      <Header lines={headerLines} isOrpi />

      <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
        Permis de construire
      </Text>

      <View style={styles.radiusView}>
        <Text style={styles.radiusText}>5 km de rayon</Text>
      </View>

      <ImageContainer
        localImg={
          builtPermits?.picture
            ? `data:image/jpeg;base64,${builtPermits.picture}`
            : ''
        }
        width="100%"
        height="250px"
        borderRadius="15px"
      />

      <View style={styles.permitsContainer}>
        {builtPermits?.permits.map((elt) => (
          <BuildPermitsItem {...elt} key={nanoid()} />
        ))}
      </View>

      <Footer agency={agencyDatas?.agency ?? null} formDatas={formDatas} isOrpi />
    </Page>
  );
}

export default BuildPermitsPage;

const styles = StyleSheet.create({
  title: { fontSize: '18px', fontWeight: 700, marginBottom: '10px' },
  image: { width: '100%', objectFit: 'contain', marginBottom: '15px' },
  radiusView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(64, 147, 226, 0.15)',
    borderRadius: '25px',
    width: '100px',
    padding: '5px',
    marginVertical: '10px',
  },
  radiusText: {
    color: 'rgb(6, 106, 190)',
  },
  permitsContainer: {
    marginTop: '10px',
  },
});
