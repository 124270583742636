import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  lines: string[];
  fixed?: boolean;
  isOrpi?: boolean;
}
function Header({ lines, fixed, isOrpi }: IProps) {
  return (
    <View
      style={[
        isOrpi ? styles.headerContainerOrpi : styles.headerContainer,
        isOrpi ? fontStyles.cOrpi : fontStyles.cUrbanease,
      ]}
      fixed={fixed}
    >
      <Text style={[fontStyles.b700, isOrpi ? fontStyles.fs24 : fontStyles.fs20]}>
        {lines[0]}
      </Text>
      <Text style={isOrpi ? fontStyles.fs18 : fontStyles.fs16}>{lines[1]}</Text>
    </View>
  );
}

export default Header;

const styles = StyleSheet.create({
  headerContainer: {
    paddingBottom: '5px',
    borderBottomColor: '#D0D0D0',
    borderBottomWidth: 1,
    marginTop: '2px',
    marginBottom: '5px',
  },
  headerContainerOrpi: {
    paddingBottom: '5px',
    borderBottomColor: '#D0D0D0',
    borderBottomWidth: 1,
    marginTop: '10px',
    marginBottom: '10px',
  },
});
