import { postEntityNew } from '../../../services/axiosFiles/genericCrud';

interface IProps {
  inseeCode: string;
}

async function fetchSocialCriteria({ inseeCode }: IProps): Promise<string | null> {
  try {
    const result = await postEntityNew({
      endpoint: '/get_critere_social/',
      body: { insee: inseeCode },
      hasPythonProxy: true,
    });

    // parse result
    const parsed = result.zone_geographique
      ? parseInt(result.zone_geographique, 10).toString()
      : null;

    return parsed;
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export default fetchSocialCriteria;
