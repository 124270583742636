import { Dialog, DialogContent } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import CloseCross from '../../../../shared/components/modals/closeCross/CloseCross';
import { modalsActions } from '../../../modals/modalsSlice';
import { getProceduresState, procedureActions } from '../../proceduresSlice';
import { deleteProcedureThunk } from '../../proceduresThunk';
import ActionFailed from '../actionFailed/ActionFailed';
import './deleteProcedureModal.scss';

const DeleteProcedureModal = ({ open }: { open: boolean }): JSX.Element => {
  const { procedures } = useAppSelector(getProceduresState);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.procedureDelete(false));
  };

  const handleDeleteProcedure = () => {
    dispatch(deleteProcedureThunk());
  };

  useEffect(() => {
    if (procedures.apiStatus === APIStatus.FULFILLED) {
      dispatch(procedureActions.ApiStatusReset());
      handleCloseModal();
    }
  }, [procedures.apiStatus]);

  return (
    <Dialog
      className="delete-procedure-modal"
      fullWidth={true}
      maxWidth="xs"
      open={open}
      aria-labelledby="edit-procedure-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent className="edit-procedure-modal-content">
        <CloseCross onClick={handleCloseModal} />

        {!procedures.error ? (
          <>
            <div className="title">Suppresion de RDV</div>

            <div className="form-field">La suppression du RDV sera définitive</div>
            <button className="valid-btn" onClick={handleDeleteProcedure}>
              Supprimer
            </button>
          </>
        ) : (
          <ActionFailed callback={handleCloseModal} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteProcedureModal;
