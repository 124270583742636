import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { useAppSelector } from '../../../../../App/store';
import { getMapState } from '../../../mapSlice';
import PolygonBase from '../common/PolygonBase';

const ActivePlotGeo = memo(() => {
  const { plotDatas } = useAppSelector(getMapState);

  return (
    <>
      {plotDatas?.parcelleLayer?.parcelle && (
        <PolygonBase
          className="plot-layer"
          color="blue"
          wkb={plotDatas?.parcelleLayer.parcelle}
        />
      )}

      {plotDatas?.parcelleLayer?.buildings?.map((b: any) => (
        <PolygonBase key={nanoid()} className="building-layer" color="red" wkb={b} />
      ))}
    </>
  );
});

ActivePlotGeo.displayName = 'ActivePlotGeo';

export default ActivePlotGeo;
