import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { GenericModalEnum } from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import {
  initialMultiPlotContent,
  initialSinglePlotContent,
} from '../../utils/quill/constants';
import createMailshotModel from '../createMailshotModel';
import fetchMailshotModels from '../fetchMailshotModels';

const fetchMailshotModelsThunk = createAsyncThunk(
  'mailshots',
  async (params: void, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { isManager } = state.auth;
      const { companyIdIri } = state.company;

      const result = await fetchMailshotModels(companyIdIri);

      // if no models in base, create 2 empty default models
      if (result.length === 0 && isManager) {
        let bodyBase = {
          isActive: true,
          isDefault: true,
          company: companyIdIri,
        };
        const promises = Promise.all([
          createMailshotModel({
            model: {
              name: 'Propriétaire multi parcelle',
              isActive: true,
              isDefault: true,
              modelType: 'subfolder',
              content: initialMultiPlotContent,
            },
            companyIdIri,
          }),
          createMailshotModel({
            model: {
              name: 'Propriétaire parcelle unique',
              isActive: true,
              isDefault: true,
              modelType: 'plot',
              content: initialSinglePlotContent,
            },
            companyIdIri,
          }),
        ]);

        return await promises;
      } else {
        return result;
      }
    } catch (error) {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'fetchMailshotModelError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default fetchMailshotModelsThunk;
