import { Navigate } from 'react-router';
import { useAppSelector } from '../App/store';
import UrbaLogo from '../assets/images/logo_urbanease_horizontal.svg';
import { getAppState } from '../features/app/appSlice';
import styles from './styles/maintenancePage.module.scss';

function MaintenancePage() {
  const { maintenancePageOnly } = useAppSelector(getAppState);

  if (!maintenancePageOnly) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className={styles.maintenancePage}>
      <div className={styles.content}>
        <img src={UrbaLogo} />

        <div className={styles.body}>
          <p>Chers utilisateurs,</p>
          <p>
            Nous procédons actuellement à à une mise à jour et une optimisation de
            vos outils ainsi que des bases de données.
          </p>
          <p>Pas de panique, Urbanease revient très vite ! </p>
          <p>
            Fin de maintenance prévue :{' '}
            <strong>Dimanche 26 janvier 2025 à 14H00</strong>
          </p>
          <p>Merci de votre compréhension.</p>
          <p>Votre équipe Urbanease.</p>
        </div>
      </div>
    </div>
  );
}

export default MaintenancePage;
