import { useContext, useEffect } from 'react';
import { InputTabsContext } from '../../../../../App/Layout';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { advancedPlotSearchActions } from '../../../../../features/advancedPlotSearch/advancedPlotSearchSlice';
import AdvancedPlotSearchContainer from '../../../../../features/advancedPlotSearch/components/advancedPlotSearchContainer';
import { getAuthState } from '../../../../../features/auth/authSlice';
import { panelsActions } from '../../../../../features/panels/panelsSlice';
import useSubscriptionAccess from '../../../../company/subscription/useSubscriptionAccess';
import { mapPluginsActions } from '../../../../map/plugins/mapPluginSlice';
import '../styles/menuTabs.scss';
import Environment from './environment/Environment';
import ConnectComponent from './menuTabsComponents/ConnectComponent';
import MenuTabsHeader from './menuTabsComponents/MenuTabsHeader';
import Prospection from './prospection/Prospection';
import Urbanisme from './urbanisme/Urbanisme';

interface IMenuTabsProps {
  isCollapsed: boolean;
}

const MenuTabs = ({ isCollapsed }: IMenuTabsProps) => {
  const { menuTab, setMenuTab } = useContext(InputTabsContext);
  const { isLogged } = useAppSelector(getAuthState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();
  const dispatch = useAppDispatch();

  const handleSelectedTab = (tab: string) => {
    if (isCollapsed && tab !== '') {
      dispatch(panelsActions.leftPanelUnCollapse());
    }
    setMenuTab(tab);
  };

  useEffect(() => {
    if (menuTab !== 'RECHERCHE & CALCULS') {
      dispatch(mapPluginsActions.drawStop());
      dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
    }
  }, [menuTab]);

  useEffect(() => {
    if (!hasUrbaneaseAccess && menuTab !== 'URBANISME') {
      setMenuTab('URBANISME');
    }
  }, [hasUrbaneaseAccess]);

  return (
    <div className="menu-tabs-wrapper">
      <div className={`tabs-buttons ${isCollapsed && 'collapse-tabs'}`}>
        <MenuTabsHeader
          isCollapsed={isCollapsed}
          onSelectedTab={handleSelectedTab}
          menuTab={menuTab}
        />
      </div>
      {isLogged && !isCollapsed ? (
        <div>
          {menuTab === 'URBANISME' && <Urbanisme />}
          {menuTab === 'RECHERCHE & CALCULS' && <AdvancedPlotSearchContainer />}
          {menuTab === 'ENVIRONNEMENT' && <Environment />}
          {menuTab === 'MA PROSPECTION' && <Prospection />}
        </div>
      ) : (
        !isCollapsed && !isLogged && <ConnectComponent />
      )}
    </div>
  );
};

export default MenuTabs;
