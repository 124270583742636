import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import useApp from '../../features/app/hooks/useApp';
import { getAuthState } from '../../features/auth/authSlice';
import NoUrbaneaseAccessModal from '../../features/auth/components/noUrbaneaseAccessModal/NoUrbaneaseAccessModal';
import FavAndHistoric from '../../features/favoritePlaces/components/favAndHistoric/FavAndHistoric';
import LeafletMapContainer from '../../features/map/components/leaflet/LeafletMapContainer';
import { mapActions } from '../../features/map/mapSlice';
import AuthModalContainer from '../../features/modals/components/AuthModalContainer';
import LeftPanelModal from '../../features/panels/components/leftPanelModal/LeftPanelModal';
import useQuery from '../../shared/hooks/useQuery';
import './homePage.scss';

const HomePage = (): JSX.Element => {
  const { queries } = useQuery();
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector(getAuthState);
  useApp();

  useEffect(() => {
    if (isLogged && queries.lat && queries.lon) {
      // if gps position in query string
      dispatch(
        mapActions.viewSet({
          zoom: 17,
          mapCenter: [parseFloat(queries.lat), parseFloat(queries.lon)],
        })
      );
    }
  }, [isLogged]);

  return (
    <div className="home-container">
      <LeftPanelModal />
      <FavAndHistoric />
      <LeafletMapContainer />
      <AuthModalContainer />
      <NoUrbaneaseAccessModal isLogged={isLogged} />
    </div>
  );
};

export default HomePage;
