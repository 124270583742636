import { paramsNotFound } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import { autocompleteCitiesApiToStoreParser } from './subscriptionParser';

export const fetchAllSubscriptionCities = async (
  subscriptinIdIri: string | null,
  params: QueryParams
) => {
  try {
    if (subscriptinIdIri && params) {
      const result = await fetchDatas(`${subscriptinIdIri}/get_all_cities`, params);

      const parsedCities = autocompleteCitiesApiToStoreParser(
        result['hydra:member']
      );

      return parsedCities;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
