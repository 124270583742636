import Xion from '../../../../assets/images/xicon.png';
import './closeCross.scss';

interface CloseCrossProps {
  onClick: () => void;
}

export default function CloseCross({ onClick }: CloseCrossProps): JSX.Element {
  return (
    <div className="xicon-wrapper" onClick={onClick}>
      <img src={Xion} alt="xion" />
    </div>
  );
}
