import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import useApp from '../../features/app/hooks/useApp';
import useSubscriptionAccess from '../../features/company/subscription/useSubscriptionAccess';
import PdfRightPanelModal from '../../features/externalPdf/errialPDF/PdfRightPanelModal';
import PdfLandRightPanelModal from '../../features/externalPdf/landPDF/PdfLandRightPanelModal';
import { getPdfState } from '../../features/externalPdf/pdfSlice';
import ErrialInfoModal from '../../features/externalPdf/shared/components/errialInfoModal/ErrialInfoModal';
import RightBottomButtons from '../../features/map/components/leaflet/buttonsOnMap/rightBottomButtons/RightBottomButtons';
import MapComponent from '../../features/map/components/leaflet/leafletMap/LeafletMap';
import { getMapState, mapActions } from '../../features/map/mapSlice';
import LeftPanelModal from '../../features/panels/components/leftPanelModal/LeftPanelModal';
import NavigationBanner from '../../shared/components/navigationBanner/NavigationBanner';
import styles from './pdfLandingPage.module.scss';

export interface IPdfLandingPageProps {
  type: PdfType;
}

const PdfLandingPage = ({ type }: IPdfLandingPageProps): JSX.Element => {
  // **** hooks ****
  useApp();

  // **** redux selector & dispatch ****
  const { infoModalDisplay } = useAppSelector(getPdfState);
  const { mapCenter, franceLayerDisplay } = useAppSelector(getMapState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      mapActions.mapCenterSet({
        latLng: mapCenter,
        zoom: franceLayerDisplay ? 6 : 17,
      })
    );
  }, []);

  return (
    <div className={styles.externalPdfErrialContainer}>
      <NavigationBanner />
      <LeftPanelModal />

      {type === 'ORPI_LAND' || type === 'URBANEASE_LAND' ? (
        // if land is ok and company is authorized
        <PdfLandRightPanelModal type={type} />
      ) : (
        // else go to orpi or generic pdf page
        <PdfRightPanelModal type={type} />
      )}
      <RightBottomButtons />
      <MapComponent />
      {infoModalDisplay.display && <ErrialInfoModal />}
    </div>
  );
};

export default PdfLandingPage;
