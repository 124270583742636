import { memo } from 'react';
import { useAppSelector } from '../../../../App/store';
import { getLoadersState } from '../../loaderSlice';
import IndividualCircularLoader from '../individualCircularLoader/IndividualCircularLoader';
import styles from './fullScreenLoader.module.scss';

function FullScreenLoader() {
  const { loaderSpinner } = useAppSelector(getLoadersState);

  return loaderSpinner ? (
    <div className={styles.loaderContainer}>
      <IndividualCircularLoader size={200} className={styles.loader} />
    </div>
  ) : null;
}

export default memo(FullScreenLoader);
