import { useAppSelector } from '../../../../../../App/store';
import { getStudyState } from '../../../../../../features/study/slices/studySlice';

export default function SectorHeader() {
  const { study } = useAppSelector(getStudyState);

  return (
    <div className="header-central-bloc-plot sector">
      {study.result && (
        <p className="title">{(study.result as ISectorStudy).name}</p>
      )}
    </div>
  );
}
