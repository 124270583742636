import NoDatasError from '../../../../shared/components/noDatasError/NoDatasError';
import styles from './pluLegend.module.scss';

function PluError() {
  return (
    <div className={styles.pluLegend}>
      <NoDatasError />
    </div>
  );
}

export default PluError;
