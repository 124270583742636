import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import {
  contactRolesParser,
  plotStudyStatusesParser,
  procedureTypesParser,
} from './appParser';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPlotStudyStatusesThunk = createAsyncThunk(
  'app/fetchPlotStudyStatusesThunk',
  async (params: void, { rejectWithValue }) => {
    try {
      const promise = fetchDatas(`${API_URL}/statuses`);

      return promise.then(
        (response) => {
          const parsedResponse = plotStudyStatusesParser(response['hydra:member']);
          return parsedResponse;
        },
        (err) => {
          return rejectWithValue(err);
        }
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchContactRolesThunk = createAsyncThunk(
  'app/fetchContactRolesThunk',
  async (params: void, { rejectWithValue }) => {
    try {
      const promise = fetchDatas(`${API_URL}/contact_roles`);

      return promise.then((response) => {
        const parsedResponse = contactRolesParser(response['hydra:member']);
        return parsedResponse;
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProcedureTypesThunk = createAsyncThunk(
  'app/fetchProcedureTypesThunk',
  async (params: void, { rejectWithValue }) => {
    try {
      const promise = fetchDatas(`${API_URL}/procedure_types`);

      return promise.then((response) => {
        const parsedResponse = procedureTypesParser(response['hydra:member']);
        return parsedResponse;
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
