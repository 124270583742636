import { Navigate, useLocation } from 'react-router';
import useSubscriptionAccess from '../../features/company/subscription/useSubscriptionAccess';
import PdfLandingPage from '../../pages/pdfLandingPage/PdfLandingPage';

function PdfLandAccessOnly() {
  const location = useLocation();
  const { hasPdfLandAccess } = useSubscriptionAccess();

  return hasPdfLandAccess ? (
    <PdfLandingPage type="URBANEASE_LAND" />
  ) : (
    <Navigate
      to={'/'}
      replace
      state={{
        from: location,
      }}
    />
  );
}

export default PdfLandAccessOnly;
