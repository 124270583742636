import { Popup } from 'react-leaflet';
import { useAppDispatch } from '../../../../App/store';
import { pdfActions } from '../../../externalPdf/pdfSlice';
import { multiPlotSelectActions } from '../../../multiPlotSelectFeature/multiPlotSelectSlice';
import styles from './multiPlotSelectLayer.module.scss';
interface IMarkerPopupProps {
  plotId: string;
  index: number;
  externalPdfErrial?: boolean;
}
function MarkerPopup({ index, plotId, externalPdfErrial }: IMarkerPopupProps) {
  const dispatch = useAppDispatch();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (externalPdfErrial) {
      dispatch(pdfActions.removeMultiPlotsPdfFromSelection(index));
    } else {
      dispatch(multiPlotSelectActions.removePlotFromSelection(index));
    }
  };

  return (
    <Popup>
      <div className={styles.markerPopup}>
        <h3>Parcelle {plotId}</h3>
        <button onClick={(e) => handleClick(e)}>Supprimer</button>
      </div>
    </Popup>
  );
}

export default MarkerPopup;
