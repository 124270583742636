import { useAppSelector } from '../../../../../App/store';
import { getStudyParamsState } from '../../../../../features/study/slices/studyParamsSlice';

import HeaderTabs from '../HeaderTabs';
import FolderHeader from './headers/FolderHeader';
import './headers/headers.scss';
import PlotHeader from './headers/PlotHeader';
import SectorHeader from './headers/SectorHeader';

export default function TabsContentHeader(): JSX.Element {
  const { type } = useAppSelector(getStudyParamsState);

  return (
    <>
      <div className="header-central-bloc">
        {type === 'plotStudy' && <PlotHeader />}
        {type === 'folder' && <FolderHeader />}
        {type === 'sector' && <SectorHeader />}
      </div>

      <HeaderTabs />
    </>
  );
}
