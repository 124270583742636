import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import AtlasAziList from '../../errialPDF/pdfFiles/components/AtlasAziList';
import AtlasList from '../../errialPDF/pdfFiles/components/AtlasList';
import ContaminatedAreas from '../../shared/components/pdf/ContaminatedAreas';

import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import GenericRisksTab from '../../errialPDF/pdfFiles/components/GenericRisksTab';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import EnvironmentalConstraintsLand from './components/EnvironmentalConstraintsLand';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  date: string;
  agency: PdfLandOrpiAgency | null;
  seismic: number;
  clay: number;
  radon: number;
  coastalErosion: boolean;
  contaminatedAreas: ContaminatedAreas;
  environmentRisksDetails: CityEnvironmentDatas;
  catNats: Catnat[];
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
}
const GeoriskExpositionPage = ({
  agency,
  seismic,
  clay,
  radon,
  coastalErosion,
  contaminatedAreas,
  environmentRisksDetails,
  catNats,
  formDatas,
  isOrpi,
  address,
  date,
}: IProps) => {
  const headerLinesEnvironmental = isOrpi
    ? ['Votre terrain', 'Exposition au géorisques']
    : ['Votre terrain'];
  const headerLines = ['Votre terrain', 'Exposition au géorisques'];
  const titleText = isOrpi
    ? "L'urbanisme de votre terrain"
    : 'Contraintes Environnementales';

  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <>
      <Page size="A4" style={styles.page}>
        {!isOrpi && address && date && (
          <PageHeader date={date} address={address} fixed isOrpi={false} />
        )}
        <Header lines={headerLinesEnvironmental} fixed isOrpi={isOrpi} />

        <Text style={styles.titleText}>{titleText}</Text>
        <EnvironmentalConstraintsLand
          seismic={seismic}
          clay={clay}
          radon={radon}
          coastalErosion={coastalErosion}
          isOrpi={isOrpi}
        />

        {isOrpi ? (
          <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
        ) : (
          <PageFooter fixed />
        )}
      </Page>

      <Page size="A4" style={styles.page}>
        {!isOrpi && address && date && (
          <PageHeader date={date} address={address} fixed isOrpi={false} />
        )}
        <Header lines={headerLines} fixed isOrpi={isOrpi} />

        <View>
          <Text style={styles.subTitleTextAtlas}>
            &gt; Liste des Atlas et Documents de gestion des risques existants sur la
            commune :
          </Text>
          <AtlasList
            title="• Plan de Prévention des Risques Naturels (PPRN)"
            datas={environmentRisksDetails.pprn}
            borderColor={isOrpi ? '#000' : '#679CC6'}
            isFirst
          />
          <AtlasList
            title="• Plan de prévention des risques environnementaux et technologiques ( PPRT)"
            datas={environmentRisksDetails.pprt}
            borderColor={isOrpi ? '#000' : '#679CC6'}
            wrap
          />
          <AtlasList
            title="• Plan de prévention des risques Miniers (PPRM)"
            datas={environmentRisksDetails.pprm}
            borderColor={isOrpi ? '#000' : '#679CC6'}
            wrap
          />
          <AtlasAziList
            title="• Atlas des zones inondables (AZI)"
            datas={environmentRisksDetails.azi}
            borderColor={isOrpi ? '#000' : '#679CC6'}
            wrap
          />
        </View>

        <View wrap={false}>
          <Text style={styles.subTitleTextSoils}>
            &gt; Sites et sols pollués – dans un périmètre de 500 m par rapport au
            bien étudiés
          </Text>
          <ContaminatedAreas areas={contaminatedAreas} />
        </View>

        {isOrpi ? (
          <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
        ) : (
          <PageFooter fixed />
        )}
      </Page>
      <Page size="A4" style={styles.page}>
        {!isOrpi && address && date && (
          <PageHeader date={date} address={address} fixed isOrpi={false} />
        )}
        <Header lines={headerLines} fixed isOrpi={isOrpi} />

        <Text style={styles.subTitleTextAnnex}>
          ANNEXE – Liste des arrêtés de Catégories Naturelles sur la Commune
        </Text>
        {isEmpty(catNats) ? (
          <Text>Aucune donnée recensée</Text>
        ) : (
          catNats.map((elt) => (
            <View key={nanoid()} wrap={false}>
              <Text>{`• Risque : ${elt.libRisqueJo}`}</Text>

              <GenericRisksTab risks={elt.catnatDatas} isOrpi={isOrpi} />
            </View>
          ))
        )}
        {isOrpi ? (
          <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
        ) : (
          <PageFooter fixed />
        )}
      </Page>
    </>
  );
};

export default GeoriskExpositionPage;

const commonStyles = {
  titleContainer: {
    marginVertical: '10px',
  },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  page: { ...pageStyles.page, ...fontStyles.ffOrpi },
  titleText: {
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
    marginVertical: '10px',
  },
  subTitleTextAtlas: {
    ...fontStyles.fs16,
    ...fontStyles.cOrpi,
  },
  subTitleTextSoils: {
    ...fontStyles.fs16,
    ...fontStyles.cOrpi,
  },
  subTitleTextAnnex: {
    ...fontStyles.fs16,
    ...fontStyles.cOrpi,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
  },
  titleText: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
    marginVertical: '5px',
  },
  subTitleTextAtlas: {
    ...fontStyles.fs12,
    ...fontStyles.cUrbanease,
    marginVertical: '10px',
    ...fontStyles.b700,
  },
  subTitleTextSoils: {
    ...fontStyles.fs12,
    ...fontStyles.cUrbanease,
    ...fontStyles.b700,
  },
  subTitleTextAnnex: {
    ...fontStyles.fs16,
    ...fontStyles.cUrbanease,
    marginVertical: '10px',
  },
});
