import { memo } from 'react';
import createProspectionTabCtx from '../../../../../App/contexts/ProspectionTabContext';
import useStudy from '../../../../study/hooks/useStudy';
import TabsContentBody from './TabsContentBody';
import TabsContentHeader from './TabsContentHeader';

const [ctx, ProspectionTabProvider] = createProspectionTabCtx();
export const ProspectionTabContext = ctx;

function TabContentContainer() {
  useStudy({ initialLoad: true });

  return (
    <ProspectionTabProvider>
      <div className="right-panel-modal-header">
        <TabsContentHeader />
      </div>

      <div
        className="right-panel-modal-body"
        data-cy="right-panel-modal-prospection"
      >
        <TabsContentBody />
      </div>
    </ProspectionTabProvider>
  );
}

export default memo(TabContentContainer);
