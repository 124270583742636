import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { useAppDispatch } from '../../../../App/store';
import { servitudeActions } from '../../nationalServitudesSlice';
import LegendContainer from '../legend/LegendContainer';
import styles from './nationalServitudesContent.module.scss';

interface IServitudeRowProps {
  servitude: Servitude;
  isTitle?: boolean;
}

function ServitudeRow({ servitude, isTitle }: IServitudeRowProps) {
  const dispatch = useAppDispatch();

  const handleCheckChange = (
    servitude: Servitude,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    dispatch(servitudeActions.setServitudesChecked({ servitude, checked }));
  };

  const handleOpenClick = () => {
    dispatch(
      servitudeActions.setServitudeOpen({ servitude, value: !servitude.isOpen })
    );
  };

  return (
    <div className={styles.servitudeRow}>
      <div
        className={`${styles.header} ${servitude.isOpen ? styles.openedHeader : ''}`}
      >
        <input
          type="checkbox"
          name={servitude.layer}
          onChange={(e) => handleCheckChange({ ...servitude }, e)}
          checked={servitude.checked}
        />
        <label htmlFor={servitude.layer} className={isTitle ? styles.isTitle : ''}>
          {servitude.label}
        </label>
        <ExpandMoreIcon
          className={`${styles.expandIcon} ${servitude.isOpen ? styles.isOpen : ''}`}
          onClick={handleOpenClick}
        />
      </div>

      <div className={styles.childs}>
        {servitude.isOpen &&
          servitude.childs?.map((c) => (
            <ServitudeRow key={nanoid()} servitude={c} />
          ))}
        {servitude.isOpen && servitude.legend && (
          <LegendContainer legends={servitude.legend} />
        )}
      </div>
    </div>
  );
}

export default ServitudeRow;
