import getEntityFromIdIri from '../../lib/parsersTools/getEntityFromIdIri';

import { fillColors, statusGroupBy } from './utils/utils';

const DEFAULT_COLOR = '#a4a4a4';

export async function individualUserPlotsStatus(
  ps: PlotStudies,
  plotStudyStatuses: StudyStatuses
) {
  const dataGroup = statusGroupBy(ps);

  const parsedResponse = Object.entries(dataGroup).map((elt) => ({
    status: elt[0],
    plots: elt[1].count,
    label: elt[1].status?.label ?? 'Non renseigné',
    color:
      fillColors[
        plotStudyStatuses.findIndex((e: StudyStatus) => e.idIri === elt[0])
      ] ?? DEFAULT_COLOR,
  }));

  return parsedResponse;
}

export const reportingUsersStatsApiToStoreParser = (datas: any, users: Users) => {
  const parsedDatas = datas.map((m: any) => reportingUsersStatsSingleRow(m, users));

  return parsedDatas;
};

const reportingUsersStatsSingleRow = (data: any, users: Users) => {
  const plotStudyCount = data.plot_studies_count;
  const userLogCount = data.user_logs_count;
  const valid: number =
    typeof plotStudyCount !== 'number'
      ? plotStudyCount.status_1 + plotStudyCount.status_7
      : 0;
  const authenticationCounter =
    typeof userLogCount !== 'number'
      ? userLogCount.total_auth_success + userLogCount.total_external_connection
      : 0;

  const user = getEntityFromIdIri(`/users/${data.user_id}`, users) as User;

  const temp = {
    authenticationCounter,
    plotStudiesCounter: plotStudyCount?.total ?? 0,
    plotStudiesValidCounter: valid,
    proceduresCounter: data.procedures_count ?? 0,
    plotStudiesValidRate:
      (plotStudyCount?.total ?? 0) > 0 ? (valid / plotStudyCount.total) * 100 : 0,
  };

  const parsedData: ReportingUserStats = {
    sortValues: temp,
    user,
    ...temp,
  };

  return parsedData;
};
