import { APIStatus } from '../../../../../services/axiosFiles/apiTypes';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import IndividualCircularLoader from '../../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';

interface IContactCountCellProps {
  folderContactsCount: number | null;
  contactCount: number;
  index: number;
  invalidCount: number;
  hasDuplicate: boolean;
}
function ContactCountCell({
  folderContactsCount,
  index,
  contactCount,
  invalidCount,
  hasDuplicate,
}: IContactCountCellProps) {
  const { apiActionStatus, selectType } = useMailshotsSelection();

  const isPending = apiActionStatus.status === APIStatus.PENDING;
  const inRange =
    folderContactsCount === null || folderContactsCount === 0
      ? index < 10
      : Math.floor((index + 1) * (folderContactsCount ?? 0)) <= 10;
  ``;

  if (selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER) {
    return null;
  }

  return (
    <>
      <td>
        {isPending ? (
          <IndividualCircularLoader size={10} />
        ) : (
          <GenericTooltip
            text={
              contactCount === 0
                ? 'Aucun contact, parcelle non éditable'
                : contactCount > 0 && invalidCount === 0
                  ? 'Parcelle prête à être éditée'
                  : invalidCount > 0
                    ? `${contactCount - invalidCount} adresses formatée / ${contactCount} adresse(s)`
                    : ''
            }
          >
            <div className={styles.contactInfoColumn}>
              {invalidCount > 0 && (
                <span>
                  <span className={styles.invalidContactCount}>
                    {contactCount - invalidCount}
                  </span>
                  /
                </span>
              )}
              <span
                className={[
                  styles.contactCount,
                  contactCount === 0 ? styles.noContact : '',
                  contactCount > 0 && invalidCount === 0
                    ? styles.allAddressesOk
                    : '',
                ].join(' ')}
              >
                {contactCount}
              </span>
            </div>
          </GenericTooltip>
        )}
      </td>
    </>
  );
}

export default ContactCountCell;
