import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import PlotDetails from '../../errialPDF/pdfFiles/components/PlotDetails';
import UrbanismeInfos from '../../errialPDF/pdfFiles/components/UrbanismeInfos';
import Footer from './components/Footer';
import Header from './components/Header';
import PdfSeparateLine from './components/PdfSeparateLine';
import PlotDetailsLand from './components/PlotDetailsLand';
import UrbanismeInfosLand from './components/UrbanismeInfosLand';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  formDatas: IExternalPdfLandForm;
  agency: PdfLandOrpiAgency | null;
  plotIds: string[];
  pluZone: string;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function YourGoodPage({
  plotDatas,
  plotIds,
  pluZone,
  agency,
  formDatas,
  isOrpi,
  address,
  date,
}: IProps) {
  const headerLines = isOrpi
    ? ['Votre bien', 'Parlons peu, parlons bien']
    : ['Votre bien'];

  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <Page size="A4" style={styles.page}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}

      <Header lines={headerLines} isOrpi={isOrpi} />

      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>Le cadastre de votre terrain</Text>
        {isOrpi && (
          <Text style={styles.address}>
            Adresse: {`${formDatas.addressStreet} ${formDatas.addressCity}`}
          </Text>
        )}
      </View>

      {isOrpi ? (
        <PlotDetailsLand {...plotDatas} plotIds={plotIds} />
      ) : (
        <PlotDetails {...plotDatas} plotIds={plotIds} />
      )}

      <View style={styles.plotInfosBloc}>
        <View style={styles.textDatas}>
          <Text style={styles.textDatasLine}>
            Surface du terrain :{' '}
            <Text style={fontStyles.b700}>{`${addSpaceToNumber(
              formDatas.landArea
            )} m²`}</Text>
          </Text>
          <Text style={styles.textDatasLine}>
            Terrain viabilisé:{' '}
            <Text style={fontStyles.b700}>
              {formDatas.servicedLand ? 'Oui' : 'Non'}
            </Text>
          </Text>
        </View>

        <View style={styles.descriptionBloc}>
          <Text style={styles.descriptionTitle}>Description du terrain:</Text>
          <Text style={fontStyles.fs11}>{formDatas.description}</Text>
        </View>
      </View>

      <PdfSeparateLine marginBottom="5px" marginTop="10px" />

      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>L&#039;urbanisme de votre terrain</Text>
      </View>

      {isOrpi ? (
        <UrbanismeInfosLand
          zone={pluZone}
          parcelsOnZonePicture={plotDatas.parcelsOnZonePicture}
        />
      ) : (
        <UrbanismeInfos
          zone={pluZone}
          parcelsOnZonePicture={plotDatas.parcelsOnZonePicture}
        />
      )}

      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default YourGoodPage;

const commonStyles = {
  plotInfosBloc: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    width: '100%' as const,
    marginTop: '10px',
  },
  textDatas: {
    width: '220px',
    display: 'flex' as const,
    flexDirection: 'column' as const,
    justifyContent: 'center' as const,
    alignItems: 'flex-start' as const,
  },
  textDatasLine: { paddingVertical: '3px' },
  descriptionBloc: {
    flex: 1,
    padding: '5px',
    backgroundColor: '#f1f1f7',
    minHeight: '100px',
  },
  address: { ...fontStyles.fs14, paddingTop: '10px' },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  titleContainer: {
    marginBottom: '10px',
  },
  titleText: {
    ...fontStyles.cOrpi,
    ...fontStyles.b700,
    ...fontStyles.fs20,
  },
  page: { ...pageStyles.page, ...fontStyles.ffOrpi },
  textDatasLine: {
    paddingVertical: '3px',
  },
  descriptionTitle: { fontWeight: 700 },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  titleContainer: {
    marginBottom: '10px',
  },
  titleText: {
    ...fontStyles.fs16,
  },
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
  },
  textDatasLine: {
    ...fontStyles.fs12,
    paddingVertical: '3px',
  },
  descriptionTitle: {
    fontWeight: 700,
  },
});
