import { useAppDispatch } from '../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../modals/modalsSlice';
import { authActions } from '../authSlice';
import styles from './loginModal/loginModal.module.scss';

function ExpiredSubscriptionModal() {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(authActions.resetError());
    dispatch(modalsActions.expiredSubscriptionModalClose());
  };
  const handleOk = () => {
    handleClose();
    window.open('https://go.pricehubble.com/urbanease-contact-us-page-2');
  };

  return (
    <GenericModal
      title="Souscription expirée"
      modalType={GenericModalEnum.WARNING}
      actionType={GenericModalActionEnum.CROSS}
      closeModal={handleClose}
      okButtonContent="Contacter nos services"
      okCallback={handleOk}
    >
      <div className={styles.expiredError}>
        <p>Votre souscription a expirée ou votre abonnement a été résilié.</p>
        <p>
          Si vous souhaitez poursuivre votre utilisation d’Urbanease, merci de
          contacter nos services
        </p>
      </div>
    </GenericModal>
  );
}

export default ExpiredSubscriptionModal;
