import * as Cesium from 'cesium';
import { delay } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { getPlotState } from '../../../../../redux/plot/reducer';
import styles from '../../../../../shared/components/viewPageContainer/viewPageContainer.module.scss';
import { modalsActions } from '../../../../modals/modalsSlice';
import { getMapState, mapActions } from '../../../mapSlice';
import addOrUpdateStreetViewEntityToMap from '../../../utils/cesium/addOrUpdateStreetViewEntityToMap';
import {
  createCesiumTerrainProvider,
  getCesiumTerrainInfosFromLatLng,
} from '../../../utils/cesium/cesiumTerrainProviderfunctions';
import compassInit from '../../../utils/cesium/compassInit';
import createCesiumClickHandler from '../../../utils/cesium/createCesiumClickHandler';
import initialCesiumFlyTo from '../../../utils/cesium/initialCesiumFlyTo';
import insertPlotEntityToCesium from '../../../utils/cesium/insertPlotEntityToCesium';
import CesiumFatalError from '../cesiumFatalError/CesiumFatalError';
import CesiumFooter from '../cesiumFooter/CesiumFooter';
import './cesium3DContainer.scss';

Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_ACCESS_TOKEN ?? '';
const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

const tilesetUrl = 'https://tile.googleapis.com/v1/3dtiles/root.json?key=' + API_KEY;

function Cesium3DContainer() {
  const { clicked3dPoint, plotDatas } = useAppSelector(getMapState);
  const { parcelle } = useAppSelector(getPlotState);
  const [displayedError, setdisplayedError] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const clickCallback = useCallback(
    (viewer: Cesium.Viewer, pos: StreetViewLatLngType) => {
      addOrUpdateStreetViewEntityToMap(viewer, pos);
      dispatch(mapActions.setClicked3dPoint(pos));
    },
    []
  );

  const handleErrorDisplay = (error: string) => {
    if (!displayedError.includes(error)) {
      setdisplayedError(displayedError.concat(error));
    }
  };

  const init = useCallback(async () => {
    dispatch(modalsActions.cesiumHelperModal(true));
    if (clicked3dPoint && parcelle) {
      createCesiumTerrainProvider().then(
        (terrainProvider) => {
          // viewer creation
          const cesiumViewer = new Cesium.Viewer('cesiumViewerContainer', {
            sceneMode: Cesium.SceneMode.SCENE3D,
            scene3DOnly: true,
            terrainProvider,
            homeButton: false,
            sceneModePicker: false,
            navigationHelpButton: false,
            geocoder: false,
            animation: false,
            timeline: false,
            baseLayerPicker: false,
            fullscreenButton: false,
            infoBox: false,
            useBrowserRecommendedResolution: true,
          });

          Cesium.Cesium3DTileset.fromUrl(tilesetUrl).then((tileset) => {
            tileset.maximumScreenSpaceError = 24; // detail trigger lvl. higher number = lower trigger. 16 is the best definition
            cesiumViewer.scene.primitives.add(tileset);

            // TODO ADD tileset load/error/ event

            // get plot height
            getCesiumTerrainInfosFromLatLng(
              terrainProvider,
              parcelle.lat,
              parcelle.lng
            ).then(
              (pointDatas) => {
                // implement handlers
                createCesiumClickHandler(cesiumViewer, clickCallback).then(
                  (res) => {},
                  (err) => {
                    handleErrorDisplay(
                      'Sélection position Street View indisponible'
                    );
                  }
                );

                initialCesiumFlyTo(cesiumViewer, pointDatas, 1);

                if (plotDatas) {
                  // add leaflet selected plot to cesium viewer
                  insertPlotEntityToCesium(
                    cesiumViewer,
                    plotDatas.parcelleLayer.parcelle,
                    pointDatas
                  ).then(
                    (res) => {},
                    (addPlotEntityErr) => {
                      handleErrorDisplay(
                        'Affichage parcelle urbanease indisponible'
                      );
                    }
                  );
                }
              },
              (getCesiumTerrainInfosFromLatLngErr) => {
                handleErrorDisplay('Localisation parcelle indisponible');
              }
            );

            // get clicked3dPoint height
            getCesiumTerrainInfosFromLatLng(
              terrainProvider,
              clicked3dPoint.lat,
              clicked3dPoint.lng
            ).then(
              (res) => {
                // Add initial Street View location
                addOrUpdateStreetViewEntityToMap(cesiumViewer, res).then(
                  (addOrUpdateRes) => {},
                  (err) => {
                    handleErrorDisplay(
                      'Affichage marqueur Street View indisponible'
                    );
                  }
                );

                // calculate streetvew camera position after first 1s flyto
                delay(() => {
                  initialCesiumFlyTo(cesiumViewer, res, 1);
                }, 1100);
              },
              (err) => {
                handleErrorDisplay('Affichage marqueur Street View indisponible');
              }
            );

            // compass implementation
            compassInit(cesiumViewer).then(
              (res) => {},
              (compassErr) => {
                handleErrorDisplay('Boussole indisponible');
              }
            );
          });
        },
        (terrainProviderErr) => {
          handleErrorDisplay('Affichage 3D impossible');
        }
      );
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  if (displayedError.includes('Affichage 3D impossible')) {
    return (
      <div className={styles.content}>
        <CesiumFatalError />
      </div>
    );
  }

  return (
    <>
      <div id="cesiumViewerContainer" className={styles.content} />
      <CesiumFooter displayedErrors={displayedError} />
    </>
  );
}

export default Cesium3DContainer;
