import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getMapState, mapActions } from '../../../../mapSlice';
import './rightBottom.scss';

const SatelliteViewButtons = () => {
  const { satelliteDisplay } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();

  const handleSatelliteDisplay = (e: boolean): void => {
    dispatch(mapActions.satelliteDisplaySet(e));
  };

  return (
    <div className="satellite-buttons">
      <div className="buttons">
        <Button
          className={`left-btn ${satelliteDisplay ? '' : 'selected'}`}
          variant="contained"
          onClick={(): void => handleSatelliteDisplay(false)}
          color={satelliteDisplay ? undefined : 'primary'}
        >
          Carte
        </Button>
        <Button
          className={`right-btn ${satelliteDisplay ? 'selected' : ''}`}
          variant="contained"
          onClick={(): void => handleSatelliteDisplay(true)}
          color={satelliteDisplay ? 'primary' : undefined}
        >
          Satellite
        </Button>
      </div>
    </div>
  );
};

export default SatelliteViewButtons;
