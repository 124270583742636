import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { TileLayer } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import { getLayerEnvState } from '../../../envs/envSlice';
import PmTilesPluEnv from '../../../plu/components/pmTiles/PmTilesPluEnv';

// cat 1 : Données sismiques
// cat 2 : Quartiers prioritaires
// cat 3 : Monuments historiques
// cat 4 : PPRI - Zones inondables
// cat 5 : Argiles Datas
// cat 6 : PPRN
// cat 7 : Exposition au bruit
// cat 8 : Sites patrimoniaux remarquables
// cat 9 : Prescriptions Archéologiques
// cat 10 : Trait littoral - Érosion côtière

const EnvLayers = memo(() => {
  const { envLayers } = useAppSelector(getLayerEnvState);

  return (
    <>
      {envLayers.result
        ?.filter((e) => e.displayed && !e.pmtilesFileUrl)
        .map((env) => {
          return (
            <TileLayer
              key={nanoid()}
              maxZoom={22}
              zIndex={2}
              opacity={0.6}
              url={env.url ?? ''}
            />
          );
        })}
      <PmTilesPluEnv />
    </>
  );
});

EnvLayers.displayName = 'EnvLayers';

export default EnvLayers;
