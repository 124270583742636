import { Paper } from '@mui/material';
import { memo } from 'react';
import { useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import DashboardLoader from '../../dashboard/components/dashboardLoader/DashboardLoader';
import { getDashboardOwnerDatasState } from '../dashboardOwnerDatasSlice';

function OwnerInfos() {
  const { ownerInfos } = useAppSelector(getDashboardOwnerDatasState);
  const data = ownerInfos.result ?? null;

  // Loading datas
  if (ownerInfos.apiStatus === APIStatus.PENDING) {
    return <DashboardLoader />;
  }

  return (
    <Paper className="owner-infos">
      <div className="bloc">
        <p className="info-line" data-cy="owner-infos">
          <span>Nom :</span> {data?.companyName}
        </p>

        <p className="info-line">
          <span>SIREN : </span>
          {data?.siren}
        </p>
      </div>
      <div className="bloc">
        <p className="info-line">
          <span>Nombre propriétés : </span>
          {data?.plotCount}
        </p>
        <p className="info-line">
          <span>Situation financière : </span>
          {data?.isBodacc
            ? data.ownerInformations
              ? data.ownerInformations.bodaccSituation
              : 'RAS'
            : ' - '}
        </p>
        <p className="info-line">
          {ownerInfos.apiStatus === APIStatus.FULFILLED &&
            (data?.siren?.toLowerCase().includes('u') ? (
              ' - '
            ) : (
              <a
                href={`https://www.pappers.fr/recherche?q=${data?.siren}`}
                target="_blank"
                rel="noreferrer"
              >{`Plus d'infos sur la personne morale`}</a>
            ))}
        </p>
      </div>
    </Paper>
  );
}

export default memo(OwnerInfos);
