import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import styles from '../advancedPlotSearchContainer.module.scss';

interface IBuildingTextFieldProps {
  name: keyof AdvancedPlotSearchForm;
  label: string;
  placeHolder?: string;
  isLabelOther?: boolean;
  keysToTrigger: AdvancedSearchFieldsToTrigger;
}

function BuildingTextField({
  name,
  label,
  placeHolder,
  isLabelOther,
  keysToTrigger,
}: IBuildingTextFieldProps): React.ReactElement {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <div className={styles.field}>
      <div className={`${styles.label} ${isLabelOther ? styles.isLabelHover : ''}`}>
        {label}
        {isLabelOther && (
          <InfoOutlinedIcon titleAccess="Basé sur le bâtiment le plus haut de la parcelle" />
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <GenericTooltip text={error?.message as string} isOpen bgColor="ERROR">
            <div>
              <NumericFormat
                value={value}
                className={error && styles.hasError}
                variant="outlined"
                onValueChange={(values) => onChange(values.floatValue)}
                onFocus={(e) => e.target.select()}
                onBlur={async () => await trigger(keysToTrigger)}
                placeholder={placeHolder}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(error)}
                thousandSeparator=" "
                customInput={TextField}
                autoComplete="off"
              />
            </div>
          </GenericTooltip>
        )}
      />
    </div>
  );
}

export default BuildingTextField;
