import { Checkbox } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../App/store';
import {
  dvfActions,
  getDvfState,
} from '../../../../../../../../features/dvf/dvfSlice';
import { genericEntitySort } from '../../../../../../../../shared/utils/sortTools';
import Transaction from './Transaction';

interface ITransactionAccordionProps {
  transactions: nullable<ITransaction[]>;
}
export default function TransactionAccordion({
  transactions,
}: ITransactionAccordionProps) {
  const thisParcelle = transactions?.filter((f) => f.selectedParcelle);
  const { dvfVisible } = useAppSelector(getDvfState);
  const dispatch = useAppDispatch();

  const handleDvfDisplayChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.checked;
    dispatch(dvfActions.setDvfOnMap(value));
  };
  const hasNeighborDvf = transactions?.some((f) => !f.selectedParcelle);

  return (
    <div>
      <h2>Cette parcelle</h2>
      {thisParcelle?.length === 0 ? (
        <p className="no-transaction-message">
          Absence de ventes répertoriées sur cette parcelle
        </p>
      ) : (
        <div className="transaction-container">
          {thisParcelle?.map((t) => <Transaction key={nanoid()} transaction={t} />)}
        </div>
      )}

      <h2>Parcelles aux alentours</h2>

      {hasNeighborDvf ? (
        <>
          <div className="parcelle-display">
            <p> Afficher les parcelles concernées sur la carte</p>
            <Checkbox checked={dvfVisible} onChange={handleDvfDisplayChange} />
          </div>
          <div className="transaction-container">
            {genericEntitySort(
              transactions?.filter((f) => !f.selectedParcelle),
              { order: 'desc', key: 'mutationDate' }
            )?.map((t: ITransaction) => (
              <Transaction key={nanoid()} transaction={t} />
            ))}
          </div>
        </>
      ) : (
        <p className="transaction-container-no-data">
          Absence de ventes répertoriées sur les parcelles aux alentours
        </p>
      )}
    </div>
  );
}
