import RowContentTag from '../../../../shared/components/RowContentTag';
import styles from '../buildPermits.module.scss';

interface IPopupContentProps {
  content: BuildPermit;
}

const BuildPermitPopupContent = ({ content }: IPopupContentProps) => {
  return (
    <div className={styles.popupMarkerContent}>
      <RowContentTag label="Propriétaire" value={content.applicant} type="p" />
      <RowContentTag label="SIREN" value={content.applicantSiren} type="p" />
      <RowContentTag label="Adresse" value={content.postalAddress} type="p" />
      <RowContentTag label="Typologie" value={content.typology} type="p" />
      <p>Autorisation :</p>
      <ul>
        <RowContentTag label="Type" value={content.autorisationType} type="li" />
        <RowContentTag label="N°" value={content.autorisationNumero} type="li" />
        <RowContentTag label="Date" value={content.autorisationDate} type="li" />
      </ul>
      <RowContentTag label="Etat" value={content.state} type="p" />
      <RowContentTag
        label="Date achèvement"
        value={content.workAchievmentDate}
        type="p"
      />
      <p>Nombre de Logements:</p>
      <ul>
        <RowContentTag
          label="Total"
          value={content.totalLots >= 0 ? content.totalLots : 'Non renseigné'}
          type="li"
        />
        <RowContentTag
          label="Sociaux"
          value={
            content.totalSocialLots >= 0 ? content.totalSocialLots : 'Non renseigné'
          }
          type="li"
        />
      </ul>
      <RowContentTag label="Surface" value={content.surface} type="p" unit="m²" />
      <RowContentTag label="Nombre de niveaux" value={content.levels} type="p" />
    </div>
  );
};

export default BuildPermitPopupContent;
