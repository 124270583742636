import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getCompanyState } from '../../company/companySlice';
import {
  fetchSubscriptionCitiesThunk,
  fetchSubscriptionDepartmentsThunk,
} from '../subscriptionThunks';

interface IUseSubscriptionsProps {
  loadCities?: boolean;
  loadDepartments?: boolean;
}
const useSubscription = ({
  loadCities,
  loadDepartments,
}: IUseSubscriptionsProps) => {
  const { subscription } = useAppSelector(getCompanyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subscription?.idIri && loadCities) {
      if (
        loadCities &&
        !subscription?.cities.result &&
        subscription?.cities.apiStatus === APIStatus.IDLE
      ) {
        dispatch(
          fetchSubscriptionCitiesThunk({
            subscriptionIdIri: subscription.idIri,
            itemsPerPage: 5,
            page: 1,
          })
        );
      }
    }
    if (
      subscription?.idIri &&
      loadDepartments &&
      !subscription?.departments.result &&
      subscription?.departments.apiStatus === APIStatus.IDLE
    ) {
      dispatch(
        fetchSubscriptionDepartmentsThunk({
          subscriptionIdIri: subscription.idIri,
        })
      );
    }
  }, [subscription]);

  return subscription;
};

export default useSubscription;
