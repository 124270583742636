import { PMTiles } from 'pmtiles';
import * as protomapsL from 'protomaps-leaflet';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import { getLayerEnvState } from '../../../envs/envSlice';
import { getMapState } from '../../../map/mapSlice';
import { MyPaintSymbolizerPluEnv } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';

// Create a comparison function based on the custom order
const customSortCat2 = (a: any, b: any) => {
  // Define the custom order
  const customOrder = [
    '2_500m',
    '2_300m',
    '2_QPV2015',
    '2_QPV2015modif',
    '2_QPV2024',
  ];
  const indexA = customOrder.indexOf(a.layer);
  const indexB = customOrder.indexOf(b.layer);

  if (indexA !== -1 || indexB !== -1) return indexA - indexB;
  return a.layer.localeCompare(b.layer);
};

function paintRules(layers: any[]) {
  return layers.map((l) => ({
    dataLayer: l.layer,
    symbolizer: new MyPaintSymbolizerPluEnv(),
    minzoom: 0,
    maxzoom: 22,
  }));
}

function labelRules(layers: any[]) {
  return layers.map((l) => ({
    dataLayer: l.layer,
    symbolizer: new PolygonLabelSymbolizer({
      label_props: (zoom: any, feature: any) => {
        feature.props.libelle =
          feature.props.cat === '2'
            ? `Quartier Prioritaire - ${feature.props.cat_child}`
            : feature.props.cat_child;
        return ['libelle'];
      },
      fill: 'white',
      width: 1.5,
      stroke: 'red',
      font: '600 14px sans-serif',
      lineHeight: 1.2,
    }),
    minzoom: 10,
    maxzoom: 22,
  }));
}

function PmTilesPluEnv() {
  const map = useMap();
  const { envLayers } = useAppSelector(getLayerEnvState);
  const { geolocDatas } = useAppSelector(getMapState);

  const resetLayer = () => {
    envLayers.result.map((envLayer) => {
      if (envLayer.pmtilesFileUrl && !envLayer.displayed) {
        map.eachLayer((layer: any) => {
          if (layer.options.layer_group === `plu_env_${envLayer.cat}`) {
            map.removeLayer(layer);
          }
        });
      }
    });
    const groupPrefixes = ['plu_env_pmtiles_'];

    // Iterate over all layers on the map
    map.eachLayer((layer: any) => {
      if (layer.options.layer_pmtiles_group) {
        const isEnvLayer = groupPrefixes.some((prefix) =>
          layer.options.layer_pmtiles_group.startsWith(prefix)
        );
        const isInEnvLayers = envLayers.result.find(
          (l) => layer.options.layer_pmtiles_group === `plu_env_pmtiles_${l.cat}`
        );
        // Remove the layer if it matches
        if (isEnvLayer && !isInEnvLayers) {
          map.removeLayer(layer);
        }
      }
    });
  };

  useEffect(() => {
    if (geolocDatas?.inseeCode && envLayers.result) {
      // reset layer if a city is already displayed
      resetLayer();

      envLayers.result.map((envLayer) => {
        if (envLayer.pmtilesFileUrl && envLayer.displayed) {
          const p = new PMTiles(envLayer.pmtilesFileUrl);

          p.getMetadata().then((m: any) => {
            // Check if a layer already exists
            let layerExists = false;
            map.eachLayer((l: any) => {
              if (l.options.layer_group === `plu_env_${envLayer.cat}`) {
                layerExists = true;
              }
            });

            // Add the layer if it doesn't exist yet
            if (!layerExists) {
              const sortLayers = m.tilestats.layers.sort((a: any, b: any) => {
                // Check if the category is 'Quartiers prioritaires' because it's multi layers; if true, return 0 to preserve order
                envLayer.cat === 2 ? 0 : customSortCat2(a, b);
              });

              const layer: any = protomapsL.leafletLayer({
                paintRules: paintRules(sortLayers),
                labelRules: labelRules(sortLayers),
                attribution: '',
                url: p.source.getKey(),
              });

              // Set layer options and add it to the map
              layer.options.layer_group = `plu_env_${envLayer.cat}`;
              layer.options.layer_pmtiles_group = `plu_env_pmtiles_${envLayer.cat}`;
              layer.options.zIndex = 3;
              layer.options.opacity = 0.7;
              layer.addTo(map);
            }
          });
        }
      });
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, envLayers]);

  return null;
}

export default memo(PmTilesPluEnv);
