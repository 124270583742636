import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getDashboardOwnerDatasState } from '../../dashboardOwnerDatasSlice';
import GenericSelectSortOwnerDatas from './GenericSelectSortOwnerDatas';
import SortOrderOwnerDatas from './SortOrderOwnerDatas';
import styles from './filterSortBloc.module.scss';

interface ISortComponentProps {
  sortItems: ISelectItem[];
  placeHolder: string;
  sortValue: string | null;
  orderValue: OwnerOrder;
  onSortChange: (value: SortComponentState) => void;
}

function SortComponent({
  sortItems,
  placeHolder,
  sortValue,
  orderValue,
  onSortChange,
}: ISortComponentProps) {
  const [state, setState] = useState<SortComponentState>({
    sort: null,
    order: null,
  });
  const [disabled, setDisabled] = useState<boolean>(false);
  const { ownerInfos, plotsFromSiren } = useAppSelector(getDashboardOwnerDatasState);

  const handleSortChange = (value: string | OwnerOrder, type: string) => {
    if (type === 'order') {
      const data = { ...state, order: value as OwnerOrder };
      setState(data);
      onSortChange(data);
    } else {
      const data = { ...state, sort: value as string };
      setState(data);
      onSortChange(data);
    }
  };

  useEffect(() => {
    setState({
      sort: sortValue,
      order: orderValue,
    });
  }, [sortValue, orderValue]);

  useEffect(() => {
    if (
      plotsFromSiren.apiStatus === APIStatus.PENDING ||
      ownerInfos.apiStatus === APIStatus.PENDING
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [plotsFromSiren, ownerInfos]);

  return (
    <div className={styles.rightBloc}>
      <GenericSelectSortOwnerDatas
        items={sortItems ?? []}
        noValue={placeHolder}
        sort={state.sort ?? ''}
        onSortChange={handleSortChange}
        disabled={disabled}
      />
      <SortOrderOwnerDatas
        order={state.order}
        onOrderChange={handleSortChange}
        disabled={disabled}
      />
    </div>
  );
}

export default SortComponent;
