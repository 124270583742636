import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { updateEntity } from '../../../../services/axiosFiles/genericCrud';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { sectorToEntityDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';
import { mapPluginsActions } from '../../../map/plugins/mapPluginSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { sectorParser } from '../../sectorParser';

const sectorUpdateThunk = createAsyncThunk(
  'sectors/sectorUpdateThunk',
  async (
    params: {
      idIri: string;
      name: string;
      wkbGeometry: FeatureCollection;
      indexId: number;
    },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { users } = state.users;
      const { userIdIri } = state.auth;
      const { idIri, name, wkbGeometry, indexId } = params;

      if (name && wkbGeometry && users) {
        const result = await updateEntity({
          idIri,
          body: {
            name,
            wkbGeometry: JSON.stringify(wkbGeometry),
          },
        });

        const parsed = sectorParser(result, users);
        thunkOptions.dispatch(modalsActions.sectorEdit(false));
        thunkOptions.dispatch(mapPluginsActions.resetDraw());
        thunkOptions.dispatch(
          displayManagerActions.entitiesAdd([
            sectorToEntityDisplay({ ...parsed, indexId }, userIdIri),
          ])
        );
        return parsed;
      } else {
        return thunkOptions.rejectWithValue(paramsNotFound('sectorCreateThunk'));
      }
    } catch (error) {
      return thunkOptions.rejectWithValue(error);
    }
  }
);

export default sectorUpdateThunk;
