import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import {
  foldersActions,
  getFoldersState,
} from '../../../../../../features/folders/foldersSlice';
import TabInfosContent from './components/plotInfos/TabInfosContent';

export default function FolderInfos(): JSX.Element {
  const { folderRightPanelDatas } = useAppSelector(getFoldersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(foldersActions.allFoldersOnMapHide());
    };
  }, []);

  return (
    <TabInfosContent
      plot={folderRightPanelDatas.result?.folderInfosTab.plot}
      ownersPlots={folderRightPanelDatas.result?.folderInfosTab.ownersPlots}
      envRiskDatas={folderRightPanelDatas.result?.folderInfosTab.envRiskDatas}
    />
  );
}
