import { useContext } from 'react';
import { searchTypeEnum } from '../../../../../App/contexts/InputTabsContext';
import { InputTabsContext } from '../../../../../App/Layout';
import GenericTabContainer from '../../../../../shared/components/genericTab/GenericTabContainer';

const TAB_LABELS = [
  { id: searchTypeEnum.Adresse as string, name: searchTypeEnum.Adresse as string },
  { id: searchTypeEnum.Parcelle as string, name: searchTypeEnum.Parcelle as string },
];

function Tabs() {
  const { inputTab, setInputTab } = useContext(InputTabsContext);

  const selected = TAB_LABELS.find((f) => f.id === inputTab);

  return (
    <GenericTabContainer
      tabs={TAB_LABELS}
      selectedDefault={selected ?? null}
      onSelect={(entity) => setInputTab(entity.id as searchTypeEnum)}
    />
  );
}

export default Tabs;
