import { isString } from 'lodash';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { getUsersState } from '../../users/usersSlice';
import { getSectorState } from '../sectorSlice';
import sectorsFetchFilteredThunk from '../services/thunks/sectorsFetchFilteredThunk';
import sectorsFetchThunk from '../services/thunks/sectorsFetchThunk';

interface IUseSectorProps {
  forceLoad?: boolean;
  forceLoadFiltered?: boolean;
}

export default function useSector({
  forceLoad,
  forceLoadFiltered,
}: IUseSectorProps) {
  const { sectors, filteredSectors } = useAppSelector(getSectorState);
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { users } = useAppSelector(getUsersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((forceLoad || !sectors) && companyIdIri && users) {
      dispatch(sectorsFetchThunk({}));
    }
  }, [users, companyIdIri]);

  useEffect(() => {
    if ((forceLoadFiltered || !filteredSectors) && companyIdIri && users) {
      dispatch(sectorsFetchFilteredThunk({}));
    }
  }, [users, companyIdIri]);

  const selectedSector = (idIri: string | null): ISector | null => {
    if (!idIri) {
      return null;
    }

    let singleArray: ISector[] = [];
    if (sectors) {
      sectors.result?.forEach((s) => {
        singleArray.push(s);
        if (s.subs) {
          singleArray = singleArray.concat(s.subs);
        }
      });
    }

    return singleArray?.find((s) => s.idIri === idIri) ?? null;
  };

  const isSubSector = (data: nullable<string | ISector>) => {
    const id = isString(data) ? data : data?.idIri;
    const sector = sectors.result?.find((f) => f.idIri === id);
    return sector?.parent ? true : false;
  };

  return {
    sectors: sectors.result,
    filteredSectors: filteredSectors.result,
    isSubSector,
    selectedSector,
  };
}
