import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  label: string;
  value: boolean | string;
  isOrpi?: boolean;
}
const getStringValue = (value: boolean) => {
  return value ? 'Oui' : 'Non';
};

function InformationLine({ label, value, isOrpi }: IProps) {
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  return (
    <View style={styles.infoLine}>
      <Text>{label} :</Text>
      <View style={styles.badge}>
        <Text>{typeof value === 'boolean' ? getStringValue(value) : value}</Text>
      </View>
    </View>
  );
}

export default InformationLine;

const commonStyles = {
  infoLine: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    marginRight: '10px',
    marginBottom: '3px',
  },
  badge: {
    paddingHorizontal: '7px',
    height: '20px',
    display: 'flex' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    borderRadius: '15px',
    color: '#fff',
  },
};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  badge: {
    ...commonStyles.badge,
    ...fontStyles.bcOrpi,
  },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  infoLine: {
    ...commonStyles.infoLine,
    ...fontStyles.fs12,
  },
  badge: {
    ...commonStyles.badge,
    ...fontStyles.bcUrbanease,
  },
});
