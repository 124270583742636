import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../styles/pdfRightPanelModal.module.scss';
import TickSquare from '../tickSquare/TickSquare';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';

function MultiCustomerLand() {
  const { watch, control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'owners',
  });

  return (
    <div className={styles.multiCustomers}>
      <p>Identité des clients à indiquer sur le document</p>
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
              <TextInputFormPdfErrialCustom
                name={`owners.${index}.name` as const}
                hasTickSquare={false}
                placeholder="Nom prénom du client"
              />

              <button type="button" onClick={() => remove(index)}>
                Retirer
              </button>

              <TickSquare color={watch('owners')[index].name ? 'Green' : 'Grey'} />
            </li>
          );
        })}
      </ul>
      {fields.length < 4 && (
        <button
          type="button"
          onClick={() => {
            append({ name: '' });
          }}
        >
          Ajouter un client
        </button>
      )}
    </div>
  );
}

export default MultiCustomerLand;
