import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import useProcedureTypes from '../../../../app/hooks/useProcedureTypes';

interface IProcedureTypeProps {
  type?: ProcedureType | string;
  className?: string;
  tooltip?: boolean;
}

const ProcedureType = ({ type, className, tooltip }: IProcedureTypeProps) => {
  const { getIcon, findProcedureType } = useProcedureTypes();
  let procedureType: any = '';

  if (typeof type === 'string') {
    procedureType = findProcedureType(type);
  } else {
    procedureType = type;
  }

  if (!tooltip) {
    return (
      <div>
        <img
          src={getIcon(procedureType?.idIri)}
          alt="type icon"
          className={className}
        />
        <span>{procedureType?.label}</span>
      </div>
    );
  }

  return (
    <GenericTooltip text={procedureType?.label ?? ' '}>
      <img
        src={getIcon(procedureType?.idIri)}
        alt="type icon"
        className={className}
      />
    </GenericTooltip>
  );
};

export default ProcedureType;
