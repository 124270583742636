import React from 'react';
import MailshotLauncherBtn from '../../../features/mailshots/components/MailshotLauncherBtn';
import { MailshotsSelectionTypeEnum } from '../../../features/mailshots/types/mailshotsEnums';
import GenericInputResearch from './GenericInputResearch';
import GenericSelectSort from './GenericSelectSort';
import SortOrder from './SortOrder';

interface IFilterSortBlocProps {
  setSearchPattern?: React.Dispatch<React.SetStateAction<string>>;
  setSortKey?: React.Dispatch<React.SetStateAction<string>>;
  setOrder?: React.Dispatch<React.SetStateAction<Order>>;
  selectItems?: ISelectItem[];
  inputPlaceHolder?: string;
  selectPlaceHolder?: string;
  valueSortKey?: string;
  valueOrder?: Order;
  fullWidth?: boolean;
  hasSortPlaceholder?: boolean;
  hasMailShots?: boolean;
}

export default function FilterSortBloc({
  setSearchPattern,
  setSortKey,
  selectItems,
  setOrder,
  inputPlaceHolder,
  selectPlaceHolder,
  valueSortKey,
  valueOrder,
  fullWidth,
  hasSortPlaceholder,
  hasMailShots,
}: IFilterSortBlocProps) {
  return (
    <div className="filter-sort-bloc">
      {setSearchPattern && (
        <GenericInputResearch
          setSearchPattern={setSearchPattern}
          placeHolder={inputPlaceHolder}
        />
      )}
      {setSortKey && setOrder && selectItems && (
        <div className={`right-bloc ${fullWidth && 'full-width'}`}>
          {hasMailShots && (
            <MailshotLauncherBtn
              type={MailshotsSelectionTypeEnum.DASHBOARD_FOLDER}
              withRightMargin
            />
          )}
          <GenericSelectSort
            setSortKey={setSortKey}
            items={selectItems}
            noValue={selectPlaceHolder}
            valueSortKey={valueSortKey}
            hasSortPlaceholder={hasSortPlaceholder}
          />
          <SortOrder setOrder={setOrder} initOrder={valueOrder} />
        </div>
      )}
    </div>
  );
}
