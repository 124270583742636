import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { ImageToUploadType, UploadResultType } from './UploadImageModal';
import styles from './uploadModal.module.scss';
interface IImageToUploadProps {
  image: ImageToUploadType | null;
  uploadResult: UploadResultType;
  height?: number;
}

function ImageToUpload({ image, uploadResult, height }: IImageToUploadProps) {
  if (!image) return null;

  return (
    <>
      {image && (
        <>
          <h4>{image.name}</h4>
          <img src={image.url} alt="Uploaded" height={height} />

          {uploadResult.state === APIStatus.REJECTED && (
            <p className={styles.textError}>{uploadResult.message}</p>
          )}
        </>
      )}
    </>
  );
}

export default ImageToUpload;
