import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { dateFormat } from '../../../../../lib/formats/dataFormat';

const TITLES = [
  'Code national CATNAT',
  'Début le',
  'Fin le',
  'Arrété du',
  'sur le JO du',
];

interface IGenericRisksTabProps {
  risks: CatnatDatas[];
  isOrpi?: boolean;
}

function GenericRisksTab({ risks, isOrpi }: IGenericRisksTabProps) {
  const styles = isOrpi ? landStyles : errialStyles;
  return (
    <View>
      <View style={styles.cont}>
        <View style={styles.tabTitles}>
          {TITLES.map((m) => {
            return (
              <View key={nanoid()} style={styles.tabTitle}>
                <Text style={{ textAlign: 'center' }}>{m}</Text>
              </View>
            );
          })}
        </View>
        <View>
          {risks?.map((m) => (
            <View key={nanoid()} style={styles.tabRows}>
              <View style={styles.rowCase}>
                <Text>{m.catnat}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.start)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.end)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.stop)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.jo)}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.endLine}></View>
    </View>
  );
}

export default GenericRisksTab;

const commonStyles = {
  cont: {
    marginLeft: 0,
    width: '100%',
    display: 'flex' as const,
    justifyContent: 'center' as const,
  },
  title: {
    fontWeight: 300,
  },
  tabTitles: {
    width: '100%',
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    borderBottom: '1px solid',
  },
  tabTitle: {
    width: '20%',
    height: '30px',
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    borderRight: '1px solid',
  },
  tabRows: {
    width: '100%',
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    fontWeight: 300,
    fontSize: '10px',
  },
  rowCase: {
    width: '20%',
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
  },
  endLine: {
    borderTop: '1px solid',
    margin: '10px 0',
    width: '100%',
  },
};

const errialStyles = StyleSheet.create({
  ...commonStyles,
  tabTitles: {
    ...commonStyles.tabTitles,
    borderLeft: '1px solid #679CC6',
    borderTop: '1px solid #679CC6',
    borderBottom: '1px solid #679CC6',
  },
  tabTitle: {
    ...commonStyles.tabTitle,
    borderRight: '1px solid #679CC6',
  },
  endLine: {
    ...commonStyles.endLine,
    borderTop: '1px solid #679CC6',
  },
});

const landStyles = StyleSheet.create({
  ...commonStyles,
  tabTitles: {
    ...commonStyles.tabTitles,
    borderLeft: '1px solid #000',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
  },
  tabTitle: {
    ...commonStyles.tabTitle,
    borderRight: '1px solid #000',
  },
  endLine: {
    ...commonStyles.endLine,
    borderTop: '1px solid #000',
  },
});
