import { memo, useContext } from 'react';

import PlotServitudesContent from '../../../../../features/plotServitudes/components/plotServitudesContent/PlotServitudesContent';
import FolderInfos from './bodys/FolderInfos';
import Geography from './bodys/Geography';
import PlotInfos from './bodys/PlotInfos';
import Prospection from './bodys/Prospection';
import SyntheseFolder from './bodys/SyntheseFolder';
import UrbanismeFolder from './bodys/UrbanismeFolder';
import UrbanismePlot from './bodys/UrbanismePlot';
import { ProspectionTabContext } from './TabContentContainer';

function TabsContentBody() {
  const { tabValue } = useContext(ProspectionTabContext);

  return (
    <div className="panel-content-body">
      {tabValue === 'geographie' && <Geography />}
      {tabValue === 'synthese' && <SyntheseFolder />}
      {tabValue === 'urbanismeplot' && <UrbanismePlot />}
      {tabValue === 'urbanismefolder' && <UrbanismeFolder />}
      {tabValue === 'infosplot' && <PlotInfos />}
      {tabValue === 'infosfolder' && <FolderInfos />}
      {tabValue === 'prospection' && <Prospection />}
      {tabValue === 'servitudeplot' && <PlotServitudesContent />}
    </div>
  );
}

export default memo(TabsContentBody);
