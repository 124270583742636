import DeleteIcon from '@mui/icons-material/Delete';
import { FormControl, TextareaAutosize, Tooltip } from '@mui/material';
import { KeyboardEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import send from '../../../assets/images/send.svg';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { modalsActions } from '../../modals/modalsSlice';
import { getUrbaGptState, urbaGptActions } from '../urbaGptSlice';
import { urbaGptQueryThunk } from '../urbaGptThunk';
import styles from './urbaGpt/urbaGpt.module.scss';

function UrbaGptForm() {
  const dispatch = useAppDispatch();
  const { showTutorial, conversation } = useAppSelector(getUrbaGptState);
  const [question, setQuestion] = useState<string>('');

  const handleDelete = () => {
    dispatch(modalsActions.urbaGptDelete(true));
  };

  const submitForm = () => {
    if (showTutorial) dispatch(urbaGptActions.setShowTutorial(false));
    dispatch(
      urbaGptActions.setConversation({
        conversationUuid: '',
        datelog: new Date().toLocaleDateString(),
        id: 0,
        question: question,
        response: '',
        isAnim: false,
      })
    );
    dispatch(urbaGptQueryThunk({ question }));
    setQuestion('');
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (question) submitForm();
    }
  };

  return (
    <div className={styles.urbaGptSearch}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <FormControl variant="outlined">
          <TextareaAutosize
            placeholder="Posez-moi une question, relative à l’urbanisme ou l’immobilier"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
            disabled={conversation.apiStatus === APIStatus.PENDING}
            onKeyPress={handleKeyPress}
            minRows={1}
            maxRows={4}
            className={styles.textarea}
          />
        </FormControl>
        <button
          type="submit"
          className={`${styles.btn} ${styles.searchBtn} ${
            conversation.apiStatus === APIStatus.PENDING && styles.btnDisabled
          }`}
          disabled={conversation.apiStatus === APIStatus.PENDING}
        >
          {conversation.apiStatus === APIStatus.PENDING ? (
            <div className={styles.loader}>
              <span>·</span>
              <span>·</span>
              <span>·</span>
            </div>
          ) : (
            <img src={send} alt="send icon" height="20" width="20" />
          )}
        </button>
      </form>
      <Tooltip title="Supprimer la conversation" placement="top" arrow>
        <button
          className={`${styles.btn} ${styles.deleteBtn} ${
            conversation.apiStatus === APIStatus.PENDING && styles.btnDisabled
          }`}
          onClick={() => handleDelete()}
          disabled={conversation.apiStatus === APIStatus.PENDING}
        >
          <DeleteIcon />
        </button>
      </Tooltip>
    </div>
  );
}

export default UrbaGptForm;
