import folderIcon from '../../../../../assets/images/folder-open.svg';
import styles from './selectFolderItemCustom.module.scss';

interface ISelectFolderItemCustomProps {
  value: string;
  isSubfolder?: boolean;
}
function SelectFolderItemCustom({
  value,
  isSubfolder,
}: ISelectFolderItemCustomProps) {
  return (
    <div
      className={`${styles.selectFolderItemCustom} ${
        isSubfolder ? styles.subfolder : ''
      }`}
    >
      <img src={folderIcon} alt="icone de dossier" />
      <p>{value}</p>
    </div>
  );
}

export default SelectFolderItemCustom;
