import { Dialog, DialogContent } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDefinitionList from 'remark-definition-list';
import remarkGemoji from 'remark-gemoji';
import remarkGfm from 'remark-gfm';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import Xion from '../../../../assets/images/xicon.png';
import {
  announcementActions,
  getAnnouncementState,
} from '../../../../features/announcement/announcementSlice';
import { IModalProps } from '../../../../features/modals/components/ModalContainer';
import { modalsActions } from '../../../../features/modals/modalsSlice';
import { dateFormat } from '../../../../lib/formats/dataFormat';
import './announcementModal.scss';

export default function AnnouncementModal({ open }: IModalProps) {
  const { announcement } = useAppSelector(getAnnouncementState);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.announcement(false));
    dispatch(announcementActions.reset());
  };

  useEffect(() => {
    if (isEmpty(announcement.result)) {
      dispatch(modalsActions.announcement(false));
    }
  }, [announcement]);

  return (
    <Dialog
      className="announcement-modal"
      fullWidth
      maxWidth="md"
      open={open}
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="announcement-modal-container">
          <div className="xicon-wrapper" onClick={handleCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <h2 className="announcement-modal-container-title">Mise à jour</h2>
          <div className="announcement-modal-container-content">
            {announcement.result.map((elt: Announcement) => (
              <div className="item" key={nanoid()}>
                <h3 className="item-title">{elt.title}</h3>
                <div className="item-content">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm, remarkGemoji, remarkDefinitionList]}
                  >
                    {elt.content}
                  </ReactMarkdown>
                </div>
                <p className="item-date">{dateFormat(elt.createdAt)}</p>
              </div>
            ))}
          </div>
          <button type="button" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
