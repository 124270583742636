import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { favoritePlacesActions } from '../../../../redux/favoritePlaces/actions';
import { getAuthState } from '../../../auth/authSlice';
import useHistoricCookie from '../../hooks/useHistoricCookie';

import styles from './favAndHistoric.module.scss';
import FavAndHistoricRow from './FavAndHistoricRow';

function FavAndHistoric() {
  const { cookie, removeCookie } = useHistoricCookie();
  const { favoritePlaces, windowDisplay } = useAppSelector(
    (state) => state.favoritePlacesReducer
  );
  const { userIdIri } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleResetHistory = () => {
    removeCookie();
  };

  useEffect(() => {
    if (isEmpty(favoritePlaces) && userIdIri && windowDisplay) {
      dispatch(favoritePlacesActions.fetchFavoriteSearches.request(userIdIri));
    }
  }, [userIdIri, windowDisplay]);

  return (
    <>
      {windowDisplay && (
        <div className={styles.favAndHistoric}>
          <div className={styles.favoriteContainer}>
            {favoritePlaces.map((m) => (
              <FavAndHistoricRow
                key={uuidv4()}
                rowData={m}
                isFavorite={true}
                favoriteCount={favoritePlaces.length}
              />
            ))}
          </div>
          <div className={styles.historyContainer}>
            {cookie?.map((m) => (
              <FavAndHistoricRow
                key={uuidv4()}
                rowData={m}
                isFavorite={false}
                favoriteCount={favoritePlaces.length}
              />
            ))}
          </div>

          <button className="button-base" onClick={handleResetHistory}>
            Effacer historique
          </button>
        </div>
      )}
    </>
  );
}

export default FavAndHistoric;
