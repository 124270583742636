import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import SelectCustom from '../formComponents/SelectCustom';
import './paginationCustom.scss';

interface ICustomPaginatinProps {
  elementsQty: number;
  itemsPerPage: number;
  onMinIndexTab?: (x: number) => void;
  onItemsPerPageChange: ChangeEventCustom;
  variant?: 'outlined' | 'text' | undefined;
  itemPerPageLabel: string;
  forceDisplay?: boolean;
}

function PaginationCustomDashboard({
  elementsQty,
  itemsPerPage,
  onMinIndexTab,
  onItemsPerPageChange,
  variant = 'outlined',
  itemPerPageLabel,
  forceDisplay,
}: ICustomPaginatinProps): JSX.Element {
  const [activePage, setActivePage] = useState<number>(1);
  const [pageQty, setPageQty] = useState<nullable<number>>(null);
  const [itemPerPageTab, setItemPerPageTab] = useState<number[]>([]);

  useEffect(() => {
    const tab = [5];
    let count = 5;
    if (elementsQty) {
      while (count < elementsQty && count <= 25) {
        count += 5;
        tab.push(count);
      }
      setItemPerPageTab(tab);
    }
  }, [elementsQty]);

  useEffect(() => {
    const newPageQty = Math.ceil(elementsQty / itemsPerPage);
    if (newPageQty !== elementsQty) {
      setPageQty(newPageQty);

      if (newPageQty === 0) {
        setActivePage(1);
      } else if (activePage > newPageQty) {
        setActivePage(newPageQty);
      }
    }
  }, [elementsQty, itemsPerPage]);

  useEffect(() => {
    if (onMinIndexTab) {
      onMinIndexTab(itemsPerPage * (activePage - 1));
    }
  }, [activePage]);

  const handleChange = (page: number): void => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {((pageQty || 0) > 1 || forceDisplay) && (
        <div className="pagination">
          <Pagination
            page={activePage}
            count={pageQty || 0}
            onChange={(evt, page) => handleChange(page)}
            variant={variant}
          />
          <div className="items-per-page">
            <span>{itemPerPageLabel}</span>
            <SelectCustom
              name="itemPerPage"
              value={itemsPerPage}
              items={itemPerPageTab.map((m) => ({
                value: m,
                display: m.toString(),
              }))}
              onChange={onItemsPerPageChange}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PaginationCustomDashboard;
