import { Checkbox, FormControlLabel } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../../../App/store';
import { envsActions } from '../../envSlice';
import styles from './envLegend.module.scss';

interface IEnvLegendRowProps {
  row: LayerEnv | null;
}

const useStyles = makeStyles()({
  root: {
    padding: '1px 1px',
  },
  label: {
    fontSize: '13px',
    paddingLeft: '2px',
  },
});

function EnvLegendRow({ row }: IEnvLegendRowProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleCheckedEnv = (item: LayerEnv): void => {
    dispatch(envsActions.displayEnv(item));
  };

  if (!row) {
    return null;
  }

  return (
    <>
      <FormControlLabel
        className={styles.environmentRow}
        classes={classes}
        label={row.name ? row.name : ''}
        key={nanoid()}
        control={
          <Checkbox
            classes={classes}
            name={row.name}
            size={'small'}
            color="primary"
            onChange={() => handleCheckedEnv(row)}
            checked={row.displayed}
          />
        }
      />
    </>
  );
}

export default EnvLegendRow;
