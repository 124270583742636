import { Checkbox } from '@mui/material';
// eslint-disable-next-line
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import TooltipDisableMapToolbar from '../../../../../shared/components/common/TooltipDisableMapToolbar';
import { DrawActionEnum } from '../../../../map/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsDrawState,
  getMapPluginsUrbaToolbarState,
  mapPluginsActions,
} from '../../../../map/plugins/mapPluginSlice';
import { ToolbarTypeEnum } from '../../../../map/plugins/toolbars/urbaToolbar/types/ToolbarBtnEnums';
import { advancedPlotSearchActions } from '../../../advancedPlotSearchSlice';
import styles from '../advancedPlotSearchContainer.module.scss';

function DrawCheckbox() {
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(getMapPluginsDrawState);
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const mapToolbar =
    activeToolbar !== ToolbarTypeEnum.DRAW && activeToolbar !== null;

  useEffect(() => {
    if (action === DrawActionEnum.DRAW_ADVANCED_SEARCH_CANCEL) {
      setValue('isDrawingZone', false);
    }
  }, [action]);

  const handleDrawingZoneChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: any
  ) => {
    const value = e.target.checked;
    onChange(e);
    if (value) {
      dispatch(mapPluginsActions.drawAdvancedSearch());
    } else {
      dispatch(mapPluginsActions.drawStop());
      setValue('polygon', undefined);
      dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
    }
  };

  return (
    <TooltipDisableMapToolbar>
      <div className={styles.checkContainer}>
        <Controller
          name="isDrawingZone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) => handleDrawingZoneChange(e, onChange)}
              checked={value}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              disabled={mapToolbar}
            />
          )}
        />
        <div className={styles.checkLabelContainer}>
          <p className={styles.checkLabel}>
            Rechercher dans un secteur géographique spécifique
          </p>
          <p className={styles.checkLabelSub}>
            Cochez l’option pour pouvoir tracer votre secteur de recherche
          </p>
        </div>
      </div>
    </TooltipDisableMapToolbar>
  );
}

export default DrawCheckbox;
