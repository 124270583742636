import { PredictionTypeEnum } from '../../../App/contexts/InputTabsContext';
import styles from './autocomplete/autocomplete.module.scss';

interface PredictionRowProps {
  prediction: Prediction;
  lastType: PredictionTypeEnum;
  index: number;
  onPredictionClick: (prediction: Prediction) => void;
}

function PredictionRow({
  prediction,
  lastType,
  index,
  onPredictionClick,
}: PredictionRowProps) {
  return (
    <>
      {lastType !== prediction.type && <h3>{prediction.type.split('-')[1]}</h3>}
      <p
        className={`${styles.predictionRow} ${index === 0 ? styles.focused : ''}`}
        onClick={() => onPredictionClick(prediction)}
      >
        {prediction.label}
      </p>
    </>
  );
}

export default PredictionRow;
