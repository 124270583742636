import { getStudyTypeFromIdIri } from '../../study/utils/getStudyType';

export const procedureEntityNameDisplay = (procedure: Procedure) => {
  const type = getStudyTypeFromIdIri(procedure.entity.idIri);
  const name = procedure.entity.name;

  switch (type) {
    case 'plotStudy':
      return `Parcelle n° ${name.substring(5)}`;
    case 'folder':
      return `Dossier ${name}`;
    default:
    case 'sector':
      return `Secteur ${name}`;
  }
};
