import { useAppSelector } from '../../../App/store';
import { getMapState } from '../../map/mapSlice';
import { getPdfState } from '../pdfSlice';

function useProspecDatas() {
  const { prospecDatas } = useAppSelector(getPdfState);
  const { geolocDatas } = useAppSelector(getMapState);

  const isCorseOrDom = ['20', '97'].includes(geolocDatas?.department ?? '');

  return {
    isCorseOrDom,
    prospecCityId: prospecDatas.prospecCityId,
    landType: prospecDatas.landType,
    area: prospecDatas.area,
    adsStats: prospecDatas.adsStats,
    comparableAds: prospecDatas.comparableAds,
  };
}

export default useProspecDatas;
