import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import plotActions from '../../../../redux/plot/actions';
import { getPlotState } from '../../../../redux/plot/reducer';
import { GenericModalEnum } from '../../../../shared/components/modals/genericModal/GenericModal';
import { addrServProcessAsync } from '../../../../utils/addrServProcessAsync';
import { mapActions } from '../../../map/mapSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import {
  ConnectionWorkFlowEnum,
  FetchPlotProcessStepEnum,
} from '../../utils/connexionProcessEnums';
import { useConnectionProcessContext } from './ConnextionProcessContext';

function FetchPlotProcess() {
  const { processDatas, setWorkflowState } = useConnectionProcessContext();
  const { parcelle } = useAppSelector(getPlotState);
  const [internalStep, setInternalStep] = useState<FetchPlotProcessStepEnum>(
    FetchPlotProcessStepEnum.IDLE
  );

  const dispatch = useAppDispatch();
  const { lat, lng, department } = processDatas;

  useEffect(() => {
    if (parcelle) {
      setInternalStep(FetchPlotProcessStepEnum.DATAS_FULFILLED);
    }
  }, [parcelle]);

  useEffect(() => {
    switch (internalStep) {
      case FetchPlotProcessStepEnum.IDLE: {
        if (lat && lng && department) {
          setInternalStep(FetchPlotProcessStepEnum.PROCESS_INIT);
        } else {
          if (lat || lng || department) {
            setInternalStep(FetchPlotProcessStepEnum.ERROR_NOT_FOUND);
          }
        }
        break;
      }
      case FetchPlotProcessStepEnum.PROCESS_INIT: {
        if (!lat || !lng) {
          // if no lat or lng, can't load plot but can geoloc
          setInternalStep(FetchPlotProcessStepEnum.ERROR_NOT_FOUND);
        } else {
          addrServProcessAsync(lat, lng).then(
            (response) => {
              if (response) {
                dispatch(mapActions.geolocSet(response));
                if (department) {
                  // if no department, can't load plot
                  dispatch(
                    plotActions.fetchPlot.request({
                      data: { lat, lon: lng, department },
                    })
                  );

                  setInternalStep(FetchPlotProcessStepEnum.END_PROCESS);
                }
              } else {
                setInternalStep(FetchPlotProcessStepEnum.ERROR_NOT_FOUND);
              }
            },
            () => {
              // if error
              setInternalStep(FetchPlotProcessStepEnum.ERROR_NOT_FOUND);
            }
          );

          setInternalStep(FetchPlotProcessStepEnum.DATAS_PENDING);
        }
        break;
      }
      case FetchPlotProcessStepEnum.DATAS_PENDING:
        break;
      case FetchPlotProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(FetchPlotProcessStepEnum.END_PROCESS);

        break;
      case FetchPlotProcessStepEnum.ERROR_FETCH_PLOT_PARAM:
        break;
      case FetchPlotProcessStepEnum.ERROR_NOT_FOUND:
        delay(() => {
          dispatch(
            modalsActions.alert({
              status: true,
              context: 'noPlotFounded',
              modalType: GenericModalEnum.INFO,
            })
          );
          setWorkflowState(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
        }, 1000);
        break;
      case FetchPlotProcessStepEnum.END_PROCESS:
        if (processDatas?.sbId) {
          if (processDatas.urlType === 'orpi-land') {
            setWorkflowState(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
          } else {
            setWorkflowState(ConnectionWorkFlowEnum.START_FETCH_PDF_DATA_PROCESS);
          }
        } else {
          setWorkflowState(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
        }
        break;
      default:
        break;
    }
  }, [processDatas, internalStep]);

  return (
    <div>
      {internalStep === FetchPlotProcessStepEnum.DATAS_PENDING && (
        <p>Chargement de la parcelle ...</p>
      )}

      {internalStep === FetchPlotProcessStepEnum.DATAS_FULFILLED && (
        <p>Parcelle chargée, vous allez etre redirigé à l&#039;accueil</p>
      )}

      {internalStep === FetchPlotProcessStepEnum.ERROR_NOT_FOUND && (
        <p>Parcelle non trouvée, vous allez etre redirigé à l&#039;accueil</p>
      )}
    </div>
  );
}

export default FetchPlotProcess;
