import { Button } from '@mui/material';
import { memo } from 'react';
import { useAppDispatch } from '../../../../../../App/store';
import Lock from '../../../../../../assets/images/lock.png';
import { modalsActions } from '../../../../../../features/modals/modalsSlice';
import '../../styles/connectComponent.scss';

function ConnectComponent() {
  const dispatch = useAppDispatch();

  const openSignInModal = () => {
    dispatch(modalsActions.signin(true));
  };

  const openSignUpModal = () => {
    dispatch(modalsActions.signup(true));
  };

  return (
    <div className="connector-tab">
      <div className="connector-lock-wrapper">
        <img src={Lock} alt="img" />
        <div className="connector-border-line"></div>
      </div>

      <p className="connector-text">
        Pour afficher les PLU, permis de construire, calques environnementaux, etc.{' '}
        <br />
        Merci de vous connecter
      </p>

      <Button className="connector-conneter-btn" onClick={openSignInModal}>
        Se connecter
      </Button>

      <div className="low-police">
        <p className="connector-bottom-text">Nouveau sur Urbanease ?</p>

        <p className="connector-red-text" onClick={openSignUpModal}>
          Inscrivez-vous
        </p>
      </div>
    </div>
  );
}

export default memo(ConnectComponent);
