import { usePDF } from '@react-pdf/renderer';
import { delay, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import genericObjectSort from '../../../utils/genericObjectSort';
import { loadersActions } from '../../loaders/loaderSlice';
import { getMapState } from '../../map/mapSlice';
import { pdfActions } from '../pdfSlice';
import errialPdfRequestLauncherThunk from '../services/thunks/errialPDF/errialPdfRequestLauncherThunk';
import OrpiButton from '../shared/components/OrpiButton';
import { typologyItems } from '../shared/components/rightPanelModalExternalPdfErrial/GoodDetailsSection';
import useRightPanelPDF from '../shared/hooks/useRightPanelPdf';
import { errialPDFDataBuilder } from '../shared/utils/dataBuilders';
import OrpiErrialPdfContainer from './OrpiErrialPdfContainer';

/* 
steps
  - launch all requests
  - servitudes loaded
  - plot datas loaded
  - parse datas
  - generate pdf
*/

const STEP_DELAY = 300;

interface IProps {
  disabled: boolean;
  type: PdfType;
}

const OrpiErrialButton = ({ disabled, type }: IProps) => {
  // ***** HOOKS *****
  const {
    // store
    parcelle,
    isMultiPlotSelectorPdf,
    pdfFormData,
    multiPlotsPdf,
    datasForPdfGeneration,
    servitudes,
    forceGenerate,
    adjacentPlotVerification,
    performStep1,
    click,
    setClick,
    disabledBtn,
  } = useRightPanelPDF();

  // ***** LOCAL STATES *****
  const link = useRef<HTMLAnchorElement>(null);
  const [datas, setDatas] = useState<PDFDatasType | null>(null);

  // **** redux selectors and dispatch ****
  const { plotDatas, geolocDatas } = useAppSelector(getMapState);
  const [instance, updateInstance] = usePDF({
    document: (
      <OrpiErrialPdfContainer datas={datas ?? null} isOrpi={type === 'ORPI'} />
    ),
  });

  const dispatch = useAppDispatch();
  const urbaneaseFileName = `synthese-${typologyItems.find((f) => f.value === pdfFormData?.propertyType)?.display ?? 'typologie'}-${geolocDatas?.city.replaceAll(' ', '-') ?? 'ville'}-Urbanease`;
  const orpiFileName = `synthese-${typologyItems.find((f) => f.value === pdfFormData?.propertyType)?.display ?? 'typologie'}-${geolocDatas?.city.replaceAll(' ', '-') ?? 'ville'}-Orpi`;

  // launch all requests
  const performStep2 = async () => {
    if (parcelle) {
      if (!isMultiPlotSelectorPdf) {
        if (plotDatas?.parcelleLayer) {
          dispatch(
            errialPdfRequestLauncherThunk({
              geo: plotDatas.parcelleLayer.parcelle,
              lat: parcelle.lat,
              lng: parcelle.lng,
              plots: [parcelle.parcelleId],
            })
          );
        }
      } else {
        if (multiPlotsPdf && !isEmpty(multiPlotsPdf)) {
          const plot = genericObjectSort(
            multiPlotsPdf,
            'theoricCapacity',
            'desc'
          )[0];

          dispatch(
            errialPdfRequestLauncherThunk({
              geo: plot.plotGeometry as any,
              lat: plot.lat,
              lng: plot.lng,
              plots: multiPlotsPdf.map((item) => item.fullPlotId),
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (forceGenerate) {
      dispatch(pdfActions.resetInfoModalDisplay());
      // start process
      performStep1(performStep2);
    }
  }, [forceGenerate]);

  useEffect(() => {
    // wait for request datas before launch step 2

    if (
      servitudes.result &&
      datasForPdfGeneration.result &&
      ((!isMultiPlotSelectorPdf && plotDatas?.parcelleLayer?.parcelle) ||
        (isMultiPlotSelectorPdf && multiPlotsPdf))
    ) {
      delay(() => {
        // parse datas
        dispatch(loadersActions.currentStepSet('Traitement des données'));
        if (
          pdfFormData &&
          parcelle &&
          datasForPdfGeneration.result &&
          servitudes.result
        ) {
          const datas = errialPDFDataBuilder(
            type,
            pdfFormData as IExternalPdf,
            parcelle,
            datasForPdfGeneration.result,
            multiPlotsPdf,
            servitudes.result
          );

          setDatas(datas);
        }
      }, STEP_DELAY);
    }

    if (datasForPdfGeneration.apiStatus === APIStatus.REJECTED) {
      setClick(false);
    }
  }, [
    servitudes,
    plotDatas?.parcelleLayer?.parcelle,
    multiPlotsPdf,
    datasForPdfGeneration,
  ]);

  useEffect(() => {
    // if datas and clisk are ok, launch react-pdf updateInstance
    if (datas && click) {
      // generate PDF
      delay(() => {
        dispatch(loadersActions.currentStepSet('Génération du PDF'));
        updateInstance(
          <OrpiErrialPdfContainer datas={datas ?? null} isOrpi={type === 'ORPI'} />
        );
      }, STEP_DELAY);
    }
  }, [datas]);

  useEffect(() => {
    // if updateInstance ended, launch real click for edit pdf
    // need delay with 1ms for nex js process step
    if (!instance.loading && click && datas)
      delay(() => {
        link.current?.click();
        setClick(false);
        dispatch(loadersActions.currentStepReset());
        dispatch(pdfActions.pdfGenerationReset());
      }, 1);
  }, [instance.loading]);

  return (
    <>
      <OrpiButton
        onClick={() => adjacentPlotVerification(performStep2)}
        disabled={disabled || disabledBtn}
        click={click}
      />

      <a
        ref={link}
        hidden={true}
        href={instance.url ?? undefined}
        download={`${type === 'ORPI' ? orpiFileName : urbaneaseFileName}.pdf`}
      >
        link
      </a>
    </>
  );
};

export default OrpiErrialButton;
