import { useAppSelector } from '../../../../../../App/store';
import { getMapState } from '../../../../../../features/map/mapSlice';
import RedDownloadButton from '../../../../../../shared/components/buttons/redDownloadButton/RedDownloadButton';

function ERRIALButton() {
  const { geolocDatas } = useAppSelector(getMapState);
  const handleClick = () => {
    window.open(
      `https://errial.georisques.gouv.fr/#/?adresse=${geolocDatas?.address}#/`,
      '_blank'
    );
  };
  return (
    <RedDownloadButton handleClick={handleClick} alt="ERRIAL link" link>
      Générer ERRIAL
    </RedDownloadButton>
  );
}

export default ERRIALButton;
