import blueGlass from '../../../../assets/images/magnifying-glass-solid-white.svg';
import styles from './glassButton.module.scss';

interface IGlassButtonProps {
  onClick: () => void;
}
function GlassButton({ onClick }: IGlassButtonProps) {
  return (
    <div className={styles.glassButton} onClick={onClick} data-cy="glass-button">
      <img src={blueGlass} alt="glass" />
    </div>
  );
}

export default GlassButton;
