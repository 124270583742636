import { memo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { InputTabsContext } from '../../../../App/Layout';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import BackArrow from '../../../../assets/images/fermerback.png';
import Logo from '../../../../assets/images/logo_urbanease_horizontal.svg';
import RightArrow from '../../../../assets/images/rightArrow.png';
import { getAuthState } from '../../../../features/auth/authSlice';
import LeftPanelErrialBloc from '../../../../features/externalPdf/shared/components/leftPanelModalExternalPDFErrial/LeftPanelErrialBloc';
import {
  getPanelState,
  panelsActions,
} from '../../../../features/panels/panelsSlice';
import { favoritePlacesActions } from '../../../../redux/favoritePlaces/actions';
import { PDF_URLS } from '../../../../shared/constants';
import useRightPanel from '../../hooks/useRightPanel';
import InputTabs from './inputTabs/InputTabs';
import MenuTabs from './menuTabs/MenuTabs';
import './styles/leftPanelModal.scss';

function LeftPanelModal() {
  // hooks
  const { closeRightPanel } = useRightPanel();
  const location = useLocation();

  // **** redux selector & dispatch ****
  const { leftPanelDisplay, rightPanelDisplay } = useAppSelector(getPanelState);
  const { user } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  // **** contexts ****s
  const { resetContext } = useContext(InputTabsContext);

  // **** constants ****
  const path = location.pathname;
  const pathHome = path === '/';
  const pdfPath = PDF_URLS.includes(path);

  useEffect(() => {
    if (!user) {
      resetContext();
    }
  }, [user]);

  // **** handles ****
  // collapse or uncollapse left panel
  // no collapse on pdf pages
  const handleCollapseIcon = () => {
    dispatch(
      leftPanelDisplay
        ? panelsActions.leftPanelCollapse()
        : panelsActions.leftPanelUnCollapse()
    );
    dispatch(favoritePlacesActions.windowDisplay.set(false));
    if (rightPanelDisplay) closeRightPanel();
  };

  return (
    <div
      className={`left-panel ${!leftPanelDisplay && !pdfPath && 'collapsed-panel'} ${
        pdfPath && 'external-pdf-errial'
      }`}
    >
      {pathHome && (
        <div
          className={`collapse-icon-container ${
            !leftPanelDisplay && 'collapsed-icon'
          }`}
          onClick={handleCollapseIcon}
        >
          <img src={!leftPanelDisplay ? RightArrow : BackArrow} alt="icon" />
        </div>
      )}

      {leftPanelDisplay || pdfPath ? (
        <>
          <div className="left-panel-search-container">
            {pathHome && (
              <div className="left-panel-header">
                <img src={Logo} alt="logo" data-cy="left-panel-urba-logo" />
              </div>
            )}
            <InputTabs />

            {pdfPath && <LeftPanelErrialBloc />}
          </div>

          {pathHome && <MenuTabs isCollapsed={!leftPanelDisplay} />}
        </>
      ) : (
        pathHome && <MenuTabs isCollapsed={!leftPanelDisplay} />
      )}
    </div>
  );
}

export default memo(LeftPanelModal);
