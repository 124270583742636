import MailshotIcon from '../../icons/MailshotIcon';
import ButtonGeneric from '../buttonGeneric/ButtonGeneric';
import styles from './supportMailButton.module.scss';

interface ISupportMailButtonProps {
  textContent: string;
  className: string;
  bgColor?: ColorType;
  color?: ColorType;
}

function SupportMailButton({
  textContent,
  className,
  bgColor,
  color,
}: ISupportMailButtonProps) {
  const handleClick = () => {
    window.open('mailto:support@urbanease.io', '_blank', 'noopener,noreferrer');
  };

  return (
    <ButtonGeneric
      bgColor={bgColor}
      color={color}
      content={
        <div className={styles.supportMailButtonContent}>
          <MailshotIcon noPadding width={32} bgColor={bgColor} color={color} />
          <span>{textContent}</span>
        </div>
      }
      containerClassName={className}
      onClick={handleClick}
    />
  );
}

export default SupportMailButton;
