import { useAppDispatch } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import ContactModalContent from './ContactModalContent';

export default function ContactSearchModal(): React.ReactElement {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.contactSearch(false));
  };

  return (
    <GenericModal
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      closeModal={handleCloseModal}
      title="Contacts"
      hasIcon={false}
      hasExtendWidth={true}
    >
      <ContactModalContent onCloseModal={handleCloseModal} />
    </GenericModal>
  );
}
