import { TableBody } from '@mui/material';
import { isEqual } from 'lodash';
import { memo, useState } from 'react';
import PaginationCustom from '../../../../shared/components/common/paginationCustom/PaginationCustom';
import TableRowFullColumn from '../../../../shared/components/common/tableRowFullColumn/TableRowFullColumn';
import { genericEntitySort } from '../../../../shared/utils/sortTools';
import '../../../dashboard/components/directoryTab/directoryTab.scss';
import DirectoryTableRow from './DirectoryTableRow';

interface IContactContainerProps {
  contacts: nullable<Contacts>;
  order: Order;
  sortKey: string;
  pattern: string;
}
function ContactContainer({
  contacts,
  order,
  sortKey,
  pattern,
}: IContactContainerProps) {
  const [indexPage, setIndexPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const sortContact = () => {
    return genericEntitySort<Contact>(contacts ?? [], {
      key: sortKey as keyof Contact,
      order,
    })?.filter((f: Contact) => {
      const pat = pattern.toLowerCase();
      return (
        f.firstName?.toLowerCase().includes(pat) ||
        f.lastName?.toLowerCase().includes(pat)
      );
    });
  };

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    setItemsPerPage(e.target.value);
  };

  const displayPagination = (sortContact()?.length || 0) > itemsPerPage;
  return (
    <>
      <TableBody>
        {sortContact()
          ?.filter(
            (f: Contact, i: number) => i >= indexPage && i < indexPage + itemsPerPage
          )
          .map((contact: Contact) => (
            <DirectoryTableRow key={contact.idIri} contact={contact} />
          ))}
        {displayPagination && (
          <TableRowFullColumn colSpan={6}>
            <PaginationCustom
              itemPerPageLabel="Contacts par page"
              elementsQty={sortContact()?.length || 0}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onMinIndexTab={handleChangeIndex}
            />
          </TableRowFullColumn>
        )}
      </TableBody>
    </>
  );
}

const propsAreEqual = (
  p: Readonly<IContactContainerProps>,
  n: Readonly<IContactContainerProps>
) => {
  let result = true;
  //if sort and search is updated
  if (p.order !== n.order || p.pattern !== n.pattern || p.sortKey !== n.sortKey) {
    result = false;
  } else if (!isEqual(p.contacts, n.contacts)) {
    result = false;
  }

  return result;
};

export default memo(ContactContainer, propsAreEqual);
