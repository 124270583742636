import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useRef } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { videos } from './init';

import { nanoid } from '@reduxjs/toolkit';
import { appActions, getAppState } from '../../../app/appSlice';

export default function UrbaneaseAcademySlider(): JSX.Element {
  const dispatch = useAppDispatch();
  const { accountFaqCurrentSlide } = useAppSelector(getAppState);
  const sliderRef: any = useRef();

  const handleSlideChange = (index: number) => {
    dispatch(appActions.setAccountFaqCurrentSlide(index));
  };

  const settings = {
    initialSlide: accountFaqCurrentSlide,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 898,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: handleSlideChange,
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef} className="slider">
        {videos.map((e, i) => (
          <div key={nanoid()} className="main-slides" data-cy={`video-${i}`}>
            <div className="col-1">
              <h4>{e.title}</h4>
              <div className="video">
                <ReactPlayer
                  controls
                  light
                  width="100%"
                  height="100%"
                  url={e.url}
                  data-cy={`video-player-${i}`}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="slider-actions">
        <ArrowBackIcon onClick={() => sliderRef.current.slickPrev()} />
        <ArrowForwardIcon onClick={() => sliderRef.current.slickNext()} />
      </div>
    </>
  );
}
