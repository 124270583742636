import { Text } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../../utils/jsFunctions';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  label?: string;
  price: number;
  sqrPrice: number;
  isOrpi?: boolean;
}

function EstimationPriceDisplay({ label, price, sqrPrice, isOrpi }: IProps) {
  return (
    <Text>
      {label}{' '}
      <Text
        style={[fontStyles.b700, { color: isOrpi ? '#000' : '#514E9A' }]}
      >{`${addSpaceToNumber(price)} € `}</Text>
      ({addSpaceToNumber(sqrPrice)} €/m²)
    </Text>
  );
}

export default EstimationPriceDisplay;
