import { cloneElement, ReactElement, useContext } from 'react';
import { GenericPopupContext } from './MarkerPopupGeneric';
import styles from './markerPopupGeneric.module.scss';

type ChildrenProps<T> = {
  content: T;
};

interface IMarkerPopupGenericContentProps<T> {
  contents: T[];
  children: React.ReactNode;
}

function MarkerPopupGenericContent<T>({
  children,
  contents,
}: IMarkerPopupGenericContentProps<T>) {
  const { state } = useContext(GenericPopupContext);

  const content: T = contents[state];
  return (
    <div className={styles.content}>
      {cloneElement(children as ReactElement<ChildrenProps<T>>, { content })}
    </div>
  );
}

export default MarkerPopupGenericContent;
