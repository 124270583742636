import styles from '../components/buildPermits.module.scss';

export const isRecentCalc = (date: string | null): boolean => {
  if (!date) {
    return false;
  } else {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() - 3);

    return maxDate <= new Date(date);
  }
};

export const dynamicMarkerClassName = (
  plots: BuildPermits,
  state: string,
  isRecent: boolean
): string => {
  let markerCss = ` ${styles.buildPermits}`;

  const type = plots[0].autorisationType;

  const isCancelled = state === 'Annulé';

  switch (type) {
    case 'PA':
      markerCss += ` ${styles.paMarker}`;
      break;
    case 'DP':
      markerCss += ` ${styles.dpMarker}`;
      break;
    case 'PC':
      markerCss += ` ${styles.pcMarker}`;
      break;

    default:
      break;
  }

  if (isCancelled) {
    markerCss += ` ${styles.cancelled}`;
  }

  if (isRecent) {
    markerCss += ` ${styles.recent}`;
  }

  return markerCss;
};
