import { useAppDispatch } from '../../../../App/store';
import UnLockIcon from '../../../../assets/images/lockopen.png';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import { authActions } from '../../authSlice';
import LoginContent from '../loginContent/LoginContent';
import styles from './loginModal.module.scss';

const LoginModal = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleOpenSignupModal = () => {
    dispatch(authActions.resetError());
    dispatch(modalsActions.signup(true));
  };

  return (
    <GenericModal
      title={''}
      closeModal={handleOpenSignupModal}
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.NONE}
      hasIcon={false}
    >
      <div className={styles.loginModalContent}>
        <img src={UnLockIcon} alt="img" className={styles.padlock} />

        <LoginContent />

        <div className={styles.footerWrapper}>
          <span className={styles.leftText}>Vous êtes nouveau sur URBANEASE ?</span>
          &nbsp;
          <span className={styles.rightText} onClick={handleOpenSignupModal}>
            Inscrivez-vous
          </span>
        </div>
      </div>
    </GenericModal>
  );
};

export default LoginModal;
