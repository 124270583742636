import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { FocusEvent } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import GenericTooltip from '../../tooltips/GenericTooltip';
import styles from './formComponents.module.scss';

interface ISelectFormCustom {
  name: string;
  items: ISelectItem[];
  onChange?: (e: SelectChangeEvent<any>) => void;
  label?: nullable<string>;
  disabled?: boolean;
  className?: string;
  displayEmpty?: boolean;
  noValue?: string;
  noChoiceDisabled?: boolean;
  onFocus?: () => void;
  errorTooltip?: boolean;
  dataCy?: string;
}

const selectUseStyles = makeStyles()({
  select: {
    '&.Mui-error': {
      backgroundColor: '#ffecfc',
    },
  },
});
const labelUseStyles = makeStyles()({
  root: {
    transform: 'translate(14px, 12px) scale(1)',
  },
});

function SelectFormCustom({
  name,
  items = [],
  onChange,
  label,
  disabled,
  className,
  displayEmpty,
  noValue,
  onFocus,
  noChoiceDisabled = true,
  errorTooltip,
  dataCy,
}: ISelectFormCustom): JSX.Element {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const labelClasses = labelUseStyles();
  const selectClasses = selectUseStyles();

  const onChangeIntern = (
    e: SelectChangeEvent<any>,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    field.onChange(e.target.value);
    onChange?.(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <div className={styles.selectContainer}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <GenericTooltip
            text={errorTooltip ? (errors[name]?.message as string) : ''}
            isOpen
            bgColor="ERROR"
          >
            <FormControl
              variant="outlined"
              disabled={disabled}
              className={`${className} ${errors[name] ? styles.error : ''}`}
              error={Boolean(errors[name])}
            >
              {label ? (
                <InputLabel classes={labelClasses.classes} id={name}>
                  {label}
                </InputLabel>
              ) : null}
              <Select
                classes={selectClasses.classes}
                {...field}
                value={field.value ?? ''}
                labelId={name}
                onChange={(e) => onChangeIntern(e, field)}
                displayEmpty={displayEmpty}
                disabled={disabled}
                error={Boolean(errors[name])}
                onFocus={handleFocus}
                data-cy={dataCy}
              >
                <MenuItem
                  key={nanoid()}
                  className={styles.selectMenuItem}
                  value=""
                  disabled={noChoiceDisabled}
                  data-cy={`${dataCy}-option-noValue`}
                >
                  {noValue}
                </MenuItem>
                {items.map((elt, i) => (
                  <MenuItem
                    key={nanoid()}
                    value={elt.value}
                    className={styles.selectMenuItem}
                    data-cy={`${dataCy}-option-${i}`}
                  >
                    {elt.display}
                  </MenuItem>
                ))}
              </Select>

              {errors[name] && !errorTooltip && (
                <FormHelperText>{errors[name]?.message as string}</FormHelperText>
              )}
            </FormControl>
          </GenericTooltip>
        )}
      />
    </div>
  );
}
export default SelectFormCustom;
