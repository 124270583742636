import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../auth/authSlice';
import useSubscriptionAccess from '../company/subscription/useSubscriptionAccess';
import { getUrbaGptState } from './urbaGptSlice';
import { urbaGptHistoryThunk } from './urbaGptThunk';

export default function useUrbaGpt() {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector(getAuthState);
  const { conversation } = useAppSelector(getUrbaGptState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();

  useEffect(() => {
    if (isEmpty(conversation.result) && userId && hasUrbaneaseAccess) {
      dispatch(urbaGptHistoryThunk());
    }
  }, [userId]);

  return {
    conversation,
  };
}
