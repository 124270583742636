import ContactForm from '../../../../contacts/components/contactForm/ContactForm';
import { useMailshotResumeContext } from '../../../contexts/mailshotResumeContext';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';

function MailshotContactFormModal() {
  const {
    contactToUpdate,
    setContactToUpdate,
    updateOpenedPlotContacts,
    openedPlot,
  } = useMailshotResumeContext();
  const { updateContactInActiveList } = useMailshotsSelection();

  const handleCancel = () => {
    setContactToUpdate(null);
  };

  const handleValid = (contact: Contact) => {
    const updated = { ...contactToUpdate, contact } as IStudyContact;
    updateContactInActiveList(updated);
    if (openedPlot) {
      updateOpenedPlotContacts(updated);
    }
    setContactToUpdate(null);
  };

  return (
    <div className={styles.contactFormModal}>
      <h3>Modifiction du contact</h3>
      <ContactForm
        validContent="Modifier"
        contact={contactToUpdate?.contact as Contact}
        onCancel={handleCancel}
        onValid={handleValid}
      />
    </div>
  );
}

export default MailshotContactFormModal;
