import { Button } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../App/store';
import plotActions from '../../../../../../../../../redux/plot/actions';
import { getPlotState } from '../../../../../../../../../redux/plot/reducer';
import styles from '../geography.module.scss';
import BuildingRow from './BuildingInfo';

interface IBuildingInfoContainerProps {
  buildings: IBuilding[];
}

export default function BuildingInfoContainer({
  buildings,
}: IBuildingInfoContainerProps) {
  const { displayBuildingCentroid } = useAppSelector(getPlotState);
  const dispatch = useAppDispatch();

  const handleDisplayBuilding = () => {
    dispatch(plotActions.buildingCentroid.set(!displayBuildingCentroid));
  };

  const filteredBuildings =
    buildings?.filter(
      (f) =>
        f.use1 ||
        f.height ||
        f.stageCount ||
        f.houseCount ||
        f.anneeConstructionBatiment ||
        f.nbLotGarpark ||
        f.nbLotTotal ||
        f.nbLotTertiaire ||
        f.nbLogements ||
        f.nomCopro
    ) ?? [];

  return (
    <div className={styles.buildingContainer}>
      {!isEmpty(filteredBuildings) && (
        <>
          <h3>Batiments</h3>

          <Button onClick={handleDisplayBuilding}>
            {displayBuildingCentroid ? 'Masquer' : 'Afficher'} les index sur la carte
          </Button>
        </>
      )}

      {filteredBuildings.map((m, i) => (
        <BuildingRow key={nanoid()} building={m} index={i + 1} />
      ))}
    </div>
  );
}
