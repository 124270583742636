import { Document } from '@react-pdf/renderer';
import { dateFormat } from '../../../lib/formats/dataFormat';
import AccessibilityPage from './pdfFiles/AccessibilityPage';
import AgencyPagePH from './pdfFiles/AgencyPagePH';
import BuildPermitsPage from './pdfFiles/BuildPermitsPage';
import ComparativeStudiesPage from './pdfFiles/ComparativeStudiesPage';
import EstimatePage from './pdfFiles/EstimatePage';
import GeoriskExpositionPage from './pdfFiles/GeoriskExpositionPage';
import MandatePage from './pdfFiles/MandatePage';
import NoisePage from './pdfFiles/NoisePage';
import PriceAnalysisPage from './pdfFiles/PriceAnalysisPage';
import RightPricePage from './pdfFiles/RightPricePage';
import SocioEconomicDataPage from './pdfFiles/SocioEconomicDataPage';
import StudyFolderPage from './pdfFiles/StudyFolderPage';
import YourGoodEnvironmentPage from './pdfFiles/YourGoodEnvironmentPage';
import YourGoodPage from './pdfFiles/YourGoodPage';

interface IProps {
  datas: PDFLandDataType | null;
}

function OrpiLandPdfContainer({ datas }: IProps) {
  if (datas) {
    return (
      <Document>
        <StudyFolderPage
          {...datas.pricehubbleDatas}
          formDatas={datas.formDatas as IExternalPdfLandForm}
          plotDatas={datas.plotDatas}
          isOrpi
        />
        {datas.pricehubbleDatas && (
          <AgencyPagePH
            agencyDatas={datas.pricehubbleDatas.agencyDatas}
            formDatas={datas.formDatas}
          />
        )}
        <YourGoodPage
          formDatas={datas.formDatas}
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          plotIds={datas.plotIds}
          plotDatas={datas.plotDatas}
          pluZone={datas.pythonDatas.zonePlu}
          isOrpi
        />
        <YourGoodEnvironmentPage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          pythonDatas={datas.pythonDatas}
          servitudes={datas.servitudes}
          formDatas={datas.formDatas}
          isOrpi
        />
        <GeoriskExpositionPage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          {...datas.pythonDatas.parcelsEnvironmentDatas}
          contaminatedAreas={datas.pythonDatas.contaminatedAreas}
          environmentRisksDetails={datas.pythonDatas.cityEnvironmentDatas}
          date={dateFormat(new Date().toISOString())}
          catNats={datas.pythonDatas.cityEnvironmentDatas.catnat}
          formDatas={datas.formDatas}
          isOrpi
        />
        {datas.pricehubbleDatas && (
          <>
            <AccessibilityPage
              {...datas.pricehubbleDatas}
              formDatas={datas.formDatas}
            />
            <NoisePage {...datas.pricehubbleDatas} formDatas={datas.formDatas} />
            <BuildPermitsPage
              {...datas.pricehubbleDatas}
              formDatas={datas.formDatas}
            />
            <SocioEconomicDataPage
              agency={datas.pricehubbleDatas.agencyDatas?.agency ?? null}
              socioEconomic={datas.pricehubbleDatas.socioEconomic}
              unemploymentRate={datas.unemploymentRateChart ?? ''}
              activePopulation={datas.activePopulationChart ?? ''}
              formDatas={datas.formDatas}
            />
          </>
        )}
        {!datas.prospecDatas.similariesError && (
          <ComparativeStudiesPage
            agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
            prospecDatas={datas.prospecDatas}
            formDatas={datas.formDatas}
            isOrpi
          />
        )}
        <RightPricePage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          formDatas={datas.formDatas}
          isOrpi
        />
        {datas.prospecDatas.adsStats && (
          <PriceAnalysisPage
            agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
            prospecDatas={datas.prospecDatas}
            avgPriceImg={datas.avgPriceChart ?? ''}
            avgPriceAreaImg={datas.avgPriceAreaChart ?? ''}
            formDatas={datas.formDatas}
            isOrpi
          />
        )}
        <EstimatePage
          agency={datas.pricehubbleDatas?.agencyDatas?.agency ?? null}
          formDatas={datas.formDatas}
          plotDatas={datas.plotDatas}
          landType={`Terrain ${datas?.prospecDatas?.type?.text.toLowerCase() ?? ''}`}
          isOrpi
        />
        <MandatePage agent={datas.pricehubbleDatas?.agencyDatas?.agent ?? null} />
      </Document>
    );
  } else {
    return <Document />;
  }
}

export default OrpiLandPdfContainer;
