import { Page, StyleSheet } from '@react-pdf/renderer';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import PageHeader from '../../errialPDF/pdfFiles/components/PageHeader';
import ComparativeStudiesTable from './components/ComparativeStudiesTable';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  prospecDatas: FormatedProspecDatasType;
  formDatas: IExternalPdfLandForm;
  isOrpi?: boolean;
  address?: { addressLine1: string; addressLine2: string };
  date?: string;
}

function ComparativeStudiesPage({
  agency,
  prospecDatas,
  formDatas,
  isOrpi,
  address,
  date,
}: IProps) {
  const headerLines = isOrpi
    ? ['L’analyse des biens comparables', 'Abordez la vente avec toutes les clés']
    : [
        'Présentation des biens comparables',
        'Biens comparables en surface sur le marché sur les 6 derniers mois',
      ];
  const styles = isOrpi ? orpiStyles : urbaneaseStyles;

  if (prospecDatas.similariesError) return null;

  return (
    <Page style={styles.page}>
      {!isOrpi && address && date && (
        <PageHeader date={date} address={address} fixed isOrpi={false} />
      )}
      <Header lines={headerLines} fixed isOrpi={isOrpi} />
      <ComparativeStudiesTable
        ads={prospecDatas.equalAds ?? []}
        title="Biens comparables prix similaires"
        isOrpi={isOrpi}
      />
      <ComparativeStudiesTable
        ads={prospecDatas.cheaperAds ?? []}
        title="Biens comparables prix inférieurs"
        cheap
        isOrpi={isOrpi}
      />
      <ComparativeStudiesTable
        ads={prospecDatas.higherAds ?? []}
        title="Biens comparables prix supérieurs"
        high
        isOrpi={isOrpi}
      />

      {isOrpi ? (
        <Footer agency={agency} formDatas={formDatas} isOrpi={isOrpi} />
      ) : (
        <PageFooter fixed />
      )}
    </Page>
  );
}

export default ComparativeStudiesPage;

const commonStyles = {};

const orpiStyles = StyleSheet.create({
  ...commonStyles,
  page: { ...pageStyles.page, ...fontStyles.ffOrpi },
});

const urbaneaseStyles = StyleSheet.create({
  ...commonStyles,
  page: {
    ...pageStyles.page,
    ...fontStyles.cUrbanease,
    ...fontStyles.ffUrbanease,
    paddingHorizontal: '15px',
  },
});
