import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import styles from './dashboardLoader.module.scss';

interface IDashboardLoaderProps {
  text?: string;
}
function DashboardLoader({ text }: IDashboardLoaderProps) {
  return (
    <div className={styles.dashboardLoader}>
      <IndividualCircularLoader size={100} className={styles.loader} />
      {text && <p>{text}</p>}
    </div>
  );
}

export default DashboardLoader;
