import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import AvatarComponent from './components/AvatarComponent';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageContainer from './components/ImageContainer';
import PdfSeparateLine from './components/PdfSeparateLine';
import { flexStyles, fontStyles, pageStyles } from './styles/pdfStyles';
interface IProps {
  agencyDatas: PHAgencyAndAgentDatas | null;
  formDatas: IExternalPdfLandForm;
}

function AgencyPagePH({ agencyDatas, formDatas }: IProps) {
  const headerLines = [
    'Votre agence Orpi',
    'Près de chez vous, donc proche de vos besoins',
  ];

  return (
    <Page style={[pageStyles.page, fontStyles.ffOrpi]}>
      <Header lines={headerLines} isOrpi />
      <View style={[flexStyles.flexBetweenStartRow, styles.agencyContainer]}>
        <View style={[styles.agencyInfos]}>
          <Text style={[fontStyles.cOrpi, fontStyles.b700, fontStyles.fs20]}>
            Votre Agence
          </Text>
          <Text style={[fontStyles.b700, fontStyles.fs18, styles.marginAddress]}>
            {agencyDatas?.agency?.name ?? ''}
          </Text>
          <Text style={styles.agencyTextPadding}>
            {agencyDatas?.agency?.address ?? ''}
          </Text>
          <Text style={styles.agencyTextPadding}>
            {agencyDatas?.agency?.mail ?? ''}
          </Text>
          <Text style={styles.agencyTextPadding}>
            {agencyDatas?.agency?.phone ?? ''}
          </Text>
        </View>
        <ImageContainer
          height="220px"
          width="360px"
          borderRadius="15px"
          localImg={agencyDatas?.agency?.photo}
        />
      </View>
      <PdfSeparateLine />
      <View style={[flexStyles.flexStartStartRow, styles.bottomBlock]}>
        <View style={styles.agentContainer}>
          <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
            Votre conseiller(e)
          </Text>

          <View style={[styles.agentContent]}>
            <View style={[styles.agentInfos]}>
              <View>
                <Text style={[fontStyles.b700, fontStyles.fs18]}>
                  {agencyDatas?.agent?.fullName ?? ''}
                </Text>
                <Text>{agencyDatas?.agent?.role ?? ''}</Text>
              </View>
              <View>
                <Text>{agencyDatas?.agent?.phone}</Text>
                <Text style={[fontStyles.cOrpi]}>
                  {agencyDatas?.agent?.mail ?? ''}
                </Text>
              </View>
            </View>

            <View style={[styles.imageContent]}>
              {agencyDatas?.agent?.photo ? (
                <ImageContainer
                  localImg={agencyDatas?.agent?.photo}
                  height="60px"
                  width="60px"
                  borderRadius="50px"
                />
              ) : (
                <AvatarComponent width={60} height={60} />
              )}
            </View>
          </View>
        </View>

        <View style={[styles.agencyInfosContainer]}>
          <Text style={[fontStyles.b700, fontStyles.fs20]}>Nos métiers</Text>
          <View>
            <View style={{ marginLeft: '10px', marginVertical: '10px' }}>
              {agencyDatas?.agency?.ORPIProfessions.map((m) => (
                <Text key={m} style={{ paddingVertical: '3px' }}>
                  {m}
                </Text>
              ))}
            </View>
            <View style={{ marginTop: '10px' }}>
              <ImageContainer
                key="gold"
                localImg={agencyDatas?.agency?.awards[0]}
                width="150px"
                objectFit="fill"
              />
              <ImageContainer
                key="platinum"
                localImg={agencyDatas?.agency?.awards[1]}
                width="150px"
                marginTop="5px"
                objectFit="fill"
              />
            </View>
          </View>
        </View>
      </View>
      <Footer agency={agencyDatas?.agency ?? null} formDatas={formDatas} isOrpi />
    </Page>
  );
}

export default AgencyPagePH;

const styles = StyleSheet.create({
  orpiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  agencyContainer: {
    marginTop: '30px',
  },
  agencyInfos: {
    width: '30%',
    paddingRight: '10px',
  },
  marginAddress: {
    paddingVertical: '20px',
  },
  agencyTextPadding: {
    paddingVertical: '3px',
  },
  bottomBlock: { height: '245px' },
  agentContainer: {
    width: '50%',
  },
  agentContent: {
    borderRadius: '15px',
    backgroundColor: '#EFD8CA',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: '30px',
    paddingHorizontal: '20px',
  },
  agentInfos: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  agencyInfosContainer: {
    marginLeft: '30px',
  },
  imageContent: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
  },
  agencySkillContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  title: { fontSize: '18px' },
  bold: { fontWeight: 700 },
  agencySkillBloc: { width: '50%' },
  agencySkillContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  professionText: { paddingBottom: '3px' },
});
