import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../App/store';
import { getPlotState } from '../../../../../redux/plot/reducer';
import MultiPlotSelectSynthesis from '../../../../../shared/components/multiPlotSelectSynthesis/MultiPlotSelectSynthesis';
import { getAutocompleteState } from '../../../../autocomplete/autocompleteSlice';
import { getPdfState, pdfActions } from '../../../pdfSlice';
import TickSquare from '../tickSquare/TickSquare';

function LeftPanelErrialBloc() {
  const { isMultiPlotSelectorPdf } = useAppSelector(getPdfState);
  const { inputValue } = useAppSelector(getAutocompleteState);
  const { parcelle } = useAppSelector(getPlotState);
  const dispatch = useDispatch();
  const onClickParcelleSelector = () => {
    dispatch(pdfActions.setIsMultiPlotSelectorPdf(!isMultiPlotSelectorPdf));
  };

  return (
    <>
      <div className="left-panel-multi-plots">
        <div className={`${inputValue ? '' : 'left-panel-multi-plots-empty'}`}>
          <TickSquare color={inputValue ? 'Green' : 'Grey'} />
          <p>
            Double-cliquez sur la ou les parcelles concernées pour pouvoir générer
            votre PDF d&apos;analyse
          </p>
        </div>
        <button
          onClick={onClickParcelleSelector}
          disabled={!parcelle}
          className={!parcelle ? 'disable' : ''}
        >
          {isMultiPlotSelectorPdf
            ? 'Annuler la multi-selection de parcelles'
            : 'Multi-selection de parcelles'}
        </button>
      </div>
      <MultiPlotSelectSynthesis />
    </>
  );
}

export default LeftPanelErrialBloc;
