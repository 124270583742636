import React, { memo, useEffect, useState } from 'react';
import SelectCustom from '../common/formComponents/SelectCustom';

interface IGenericSelectSortProps {
  setSortKey: React.Dispatch<React.SetStateAction<string>>;
  items: ISelectItem[];
  noValue?: string;
  valueSortKey?: string;
  hasSortPlaceholder?: boolean;
}
function GenericSelectSort({
  setSortKey,
  items,
  noValue,
  valueSortKey,
  hasSortPlaceholder = true,
}: IGenericSelectSortProps) {
  const [selectedValue, setselectedValue] = useState<string>(valueSortKey ?? '');

  useEffect(() => {
    setselectedValue(valueSortKey ?? '');
  }, [valueSortKey]);

  const handleChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    setselectedValue(value);

    setSortKey(value);
  };
  return (
    <div className="sort-select">
      <SelectCustom
        items={items}
        value={selectedValue}
        name="sort"
        noValue={hasSortPlaceholder ? noValue ?? 'Trier par' : ''}
        displayEmpty
        onChange={handleChange}
        dataCy="sort-select"
      />
    </div>
  );
}

export default memo(GenericSelectSort);
