import PriorityIcon from '../../../../../../../../../../shared/components/icons/priorityIcon/PriorityIcon';
import styles from '../management.module.scss';

interface IProps {
  element: ISelectItem;
}

function PrioritySelectContent({ element }: IProps) {
  return (
    <div className={styles.selectedValue}>
      <PriorityIcon level={element.value} className={styles.priorityIcon} />
      <span>{element.display}</span>
    </div>
  );
}

export default PrioritySelectContent;
