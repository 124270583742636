import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';
import { genericEntitySort } from '../../shared/utils/sortTools';

// #region Plot studies
export const plotStudyStatusMapping = {
  '@id': 'idIri',
  label: 'label',
  description: 'description',
  class: 'class',
};

export const plotStudyStatusesParser = (data: any[]) =>
  mapObjectParser(data, plotStudyStatusParser);

export const plotStudyStatusParser = (data: any): StudyStatus => {
  const parsed: StudyStatus = changeApiKeyForStoreKey(data, plotStudyStatusMapping);
  parsed.id = getIdFromIdIri(data['@id']);

  return parsed;
};
// #endregion

//#region Contact Roles
export const contactRoleMapping = {
  '@id': 'idIri',
  label: 'label',
};

export const contactRolesParser = (data: any[]) => {
  const contactRoles = mapObjectParser(data, contactRoleParser);

  // save "Autre" choice
  const other = contactRoles.find((f) => f.label === 'Autre');

  // remove "Autre" from list
  const filtered = contactRoles.filter((f) => f.label !== 'Autre');

  // sort ContactRole list
  const sorted = genericEntitySort<ContactRole>(filtered, {
    key: 'label',
  });

  // add "Autre" choce at the end
  if (other) {
    sorted?.push(other);
  }

  return sorted ?? [];
};
export const contactRoleParser = (data: any): ContactRole => {
  const parsed: ContactRole = changeApiKeyForStoreKey(data, contactRoleMapping);
  parsed.id = getIdFromIdIri(data['@id']);

  return parsed;
};

//#endregion

//#region Procedure Types
export const procedureTypeMapping = {
  '@id': 'idIri',
  label: 'label',
  description: 'description',
  icon: 'icon',
  isSectorType: 'isSectorType',
};

export const procedureTypesParser = (data: any[]) =>
  mapObjectParser(data, procedureTypeParser);
export const procedureTypeParser = (data: any): ProcedureType => {
  const parsed: ProcedureType = changeApiKeyForStoreKey(data, procedureTypeMapping);
  parsed.id = getIdFromIdIri(data['@id']);

  return parsed;
};

//#endregion

//#region Sector Actions
const sectorActionMapping = contactRoleMapping;

export const sectorActionsParser = (data: any[]) =>
  mapObjectParser(data, sectorActionParser);
export const sectorActionParser = (data: any): ProcedureType =>
  changeApiKeyForStoreKey(data, sectorActionMapping);
//#endregion
