import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../shared/components/modals/genericModal/GenericModal';
import { loadersActions } from '../../../loaders/loaderSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { getUrbaGptState, urbaGptActions } from '../../urbaGptSlice';
import { urbaGptDeleteThunk, urbaGptQueryThunk } from '../../urbaGptThunk';
import styles from './errorTokenUrbaGptModal.module.scss';

export default function ErrorTokenUrbaGptModal() {
  const dispatch = useAppDispatch();
  const { conversation } = useAppSelector(getUrbaGptState);
  const lastConversation = conversation.result[conversation.result.length - 1];

  const handleCloseModal = () => {
    dispatch(modalsActions.errorTokenUrbaGptModal(false));
  };

  const handleRequestOk = async () => {
    if (lastConversation && lastConversation.question) {
      await handleCloseModal();
      await dispatch(loadersActions.loaderShow());
      await dispatch(urbaGptDeleteThunk());
      await dispatch(loadersActions.loaderHide());
      dispatch(
        urbaGptActions.setConversation({
          conversationUuid: '',
          datelog: new Date().toLocaleDateString(),
          id: 0,
          question: lastConversation.question,
          response: '',
          isAnim: false,
        })
      );
      dispatch(urbaGptQueryThunk({ question: lastConversation.question }));
    }
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <GenericModal
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.OKCANCEL}
      okButtonContent="Valider"
      cancelButtonContent="Annuler"
      title="UrbaGPT"
      okCallback={handleRequestOk}
      cancelCallback={handleCancel}
      closeModal={handleCloseModal}
    >
      <div className={styles.errorTokenModal}>
        <p>
          Pour pouvoir envoyer de nouveaux messages, veuillez prendre un moment pour
          nettoyer l&apos;historique de notre conversation. Cela nous permettra de
          libérer de l&apos;espace pour de nouvelles interactions.
        </p>
      </div>
    </GenericModal>
  );
}
