import { memo, useContext } from 'react';
import { ProspectionDisplayEnum } from '../../../../../../App/contexts/InputTabsContext';
import { InputTabsContext } from '../../../../../../App/Layout';
import FolderContainer from '../../../../../../features/folders/components/folderContainer/FolderContainer';
import SectorContainer from '../../../../../../features/sectors/components/leftPanelProspection/SectorContainer';

import GenericTabContainer from '../../../../../../shared/components/genericTab/GenericTabContainer';
import styles from './prospection.module.scss';

const TAB_LABELS = [
  {
    id: ProspectionDisplayEnum.sectors as string,
    name: ProspectionDisplayEnum.sectors as string,
  },
  {
    id: ProspectionDisplayEnum.folders as string,
    name: ProspectionDisplayEnum.folders as string,
  },
];
const Prospection = (): JSX.Element => {
  const { prospectionTab, setProspectionTab } = useContext(InputTabsContext);

  const selected = TAB_LABELS.find((f) => f.id === prospectionTab);

  return (
    <div>
      <GenericTabContainer
        tabs={TAB_LABELS}
        selectedDefault={selected ?? null}
        onSelect={(entity) => setProspectionTab(entity.id as ProspectionDisplayEnum)}
        bigger
        dataCyBase="left-panel-prospection-tab"
      />
      <div className={styles.prospection}>
        {prospectionTab === ProspectionDisplayEnum.sectors && <SectorContainer />}

        {prospectionTab === ProspectionDisplayEnum.folders && <FolderContainer />}
      </div>
    </div>
  );
};

export default memo(Prospection);
