import { useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../auth/authSlice';
import RightBottomToolbar from '../../plugins/toolbars/rightBottomToolbar/RightBottomToolbar';
import RightTopToolbar from '../../plugins/toolbars/RightTopToolbar/RightTopToolbar';
import UrbaToolbar from '../../plugins/toolbars/urbaToolbar/UrbaToolbar';
import ButtonsOnMap from './buttonsOnMap/ButtonsOnMap';
import LeafletMap from './leafletMap/LeafletMap';

function LeafletMapContainer() {
  const { isLogged } = useAppSelector(getAuthState);
  return (
    <>
      <LeafletMap />
      {isLogged && (
        <>
          <ButtonsOnMap />
          <UrbaToolbar />
          <RightTopToolbar />
          <RightBottomToolbar />
        </>
      )}
    </>
  );
}

export default LeafletMapContainer;
