import { Button, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { InputTabsContext } from '../../../../../App/Layout';
import { searchTypeEnum } from '../../../../../App/contexts/InputTabsContext';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { getAppState } from '../../../../../features/app/appSlice';
import { getAuthState } from '../../../../../features/auth/authSlice';
import { autocompleteActions } from '../../../../../features/autocomplete/autocompleteSlice';
import { getMapState } from '../../../../../features/map/mapSlice';
import plotActions from '../../../../../redux/plot/actions';
import { getPlotState } from '../../../../../redux/plot/reducer';
import { PDF_URLS } from '../../../../../shared/constants';
import Autocomplete from '../../../../autocomplete/components/autocomplete/Autocomplete';
import FavoriteBtn from '../../../../favoritePlaces/components/FavoriteBtn';
import Tabs from './Tabs';
import styles from './inputTabs.module.scss';

function InputTabs() {
  const { inputTab, plotId, setPlotId, setInputValue, inputValue } =
    useContext(InputTabsContext);
  const { isLogged } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { activateFavorite } = useAppSelector(getAppState);
  const { parcelle } = useAppSelector(getPlotState);

  const location = useLocation();
  const path = location.pathname;
  const [valid, setValid] = useState<boolean>(true);
  const [dirty, setDirty] = useState<boolean>(false);
  const pdfPath = PDF_URLS.includes(path);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (geolocDatas?.address) {
      setInputValue(geolocDatas?.address);
      dispatch(autocompleteActions.setInputValue(geolocDatas?.address));
    }
  }, [geolocDatas?.address]);

  const handleSubmit = () => {
    if (plotId && geolocDatas?.inseeCode && geolocDatas?.postalCode && valid) {
      const payload = {
        plotId: geolocDatas.inseeCodeDistrict + plotId,
        postalCode: geolocDatas.postalCode,
        pdf: false,
      };
      dispatch(plotActions.fetchPlotByPlotId.request(payload));
    }
  };

  return (
    <>
      {isLogged && (
        <div
          className={`${styles.inputTabsWrapper} ${
            inputTab === searchTypeEnum.Adresse ? '' : styles.inputPlotVisible
          }`}
        >
          {pdfPath ? (
            <h2>Modifier l’adresse recherchée</h2>
          ) : (
            <h2>Que recherchez-vous ?</h2>
          )}

          {/* wraper for generic tab for specific context value and content */}
          <Tabs />

          <div className={styles.tabsContent}>
            <div className={styles.fieldContainer}>
              <div className={styles.autocompleteContainer}>
                <Autocomplete
                  isCities={Boolean(inputTab === searchTypeEnum.Parcelle)}
                  error={pdfPath && !parcelle && !inputValue}
                />
              </div>

              <div
                className={`${styles.plotInputContainer} ${
                  inputTab === searchTypeEnum.Adresse ? '' : styles.visible
                }`}
              >
                <TextField
                  className={`${styles.plotInput} ${
                    !valid && dirty && Boolean(plotId) ? styles.hasError : ''
                  }`}
                  name="postal"
                  value={plotId}
                  placeholder="000BZXXXX"
                  type="text"
                  variant="outlined"
                  size="small"
                  disabled={!isLogged}
                  fullWidth={true}
                  onChange={(e) => {
                    const reg = /^\d{3}[A-Z0]{2}\d{4}$/i;
                    setValid(reg.test(e.target.value));
                    setDirty(true);

                    setPlotId(e.target.value.toUpperCase().replace(/ /g, ''));
                  }}
                  error={!valid && dirty && Boolean(plotId)}
                  helperText={
                    !valid && plotId.length < 9 && plotId
                      ? "L'identifiant de la parcelle est trop court"
                      : !valid && plotId.length > 9 && plotId
                        ? "L'identifiant de la parcelle est trop long"
                        : !valid && plotId.length === 9 && plotId
                          ? 'Le format de la parcelle est incorrect'
                          : ''
                  }
                />
                <Button className={styles.submitBtn} onClick={handleSubmit}>
                  Rechercher &amp; Afficher sur la carte
                </Button>
              </div>
            </div>

            {activateFavorite && <FavoriteBtn className={styles.iconContainer} />}
          </div>
        </div>
      )}
    </>
  );
}

export default InputTabs;
