import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import BgImage from '../../../../assets/images/orpiPdf/bg-intro-pdf-land.png';
import { dateFormat } from '../../../../lib/formats/dataFormat';
import Footer from './components/Footer';
import ImageContainer from './components/ImageContainer';
import OrpiHorizontal from './components/svg/OrpiHorizontal';
import { fontStyles } from './styles/pdfStyles';

interface IProps {
  agencyDatas?: PHAgencyAndAgentDatas | null;
  deal?: PDFLandDeal;
  formDatas: IExternalPdfLandForm;
  plotDatas: PDFPlotDatas;
  isOrpi?: boolean;
}
function StudyFolderPage({
  agencyDatas,
  deal,
  formDatas,
  plotDatas,
  isOrpi,
}: IProps) {
  const dealUrl = deal ? (isEmpty(deal.images) ? null : deal.images[0]) : null;

  const getCustomerLine = (line: number) => {
    const tab =
      line === 1 ? formDatas.owners.slice(0, 2) : formDatas.owners.slice(2);

    return tab.map((m) => m.name).join(', ');
  };
  // see for plain width if image is in portrait mode
  return (
    <Page size="A4" style={[styles.page, fontStyles.ffOrpi]}>
      <Image src={BgImage} style={styles.background} />

      <View style={styles.flexEnd}>
        <OrpiHorizontal />
        <Text style={[fontStyles.fs48, fontStyles.b700]}>Dossier d&#39;Etude</Text>
        <Text style={[fontStyles.fs36]}>pour votre bien</Text>

        <View
          style={[styles.leadAddressContainer, fontStyles.cOrpi, fontStyles.b700]}
        >
          <Text>{formDatas.addressStreet}</Text>
          <Text>{formDatas.addressCity}</Text>
        </View>
        <Text style={fontStyles.fs12}>Terrain</Text>
      </View>

      <ImageContainer
        localImg={dealUrl ?? `data:image/jpeg;base64,${plotDatas.homePagePicture}`}
        width="100%"
        height="315px"
        marginTop="15px"
        borderRadius="15px"
      />

      <View style={styles.customerAndAgentContainer}>
        <View style={styles.customerContainer}>
          <Text style={fontStyles.fs12}>Etude réalisée pour:</Text>
          <Text style={[fontStyles.fs14, fontStyles.b700]}>
            {getCustomerLine(1)}
          </Text>
          <Text style={[fontStyles.fs14, fontStyles.b700]}>
            {getCustomerLine(2)}
          </Text>
          <Text style={fontStyles.fs12}>
            le {dateFormat(new Date().toISOString())}
          </Text>
        </View>

        <View style={styles.agentContainer}>
          {agencyDatas && (
            <ImageContainer
              localImg={agencyDatas.agent?.photo}
              width="60px"
              height="60px"
              borderRadius="50%"
            />
          )}

          <View style={[styles.agentInfos, fontStyles.fs12]}>
            {agencyDatas && (
              <>
                <Text style={[fontStyles.b700, fontStyles.fs14]}>
                  {`${formDatas?.firstName ?? ''} ${formDatas?.lastName ?? ''}`}
                </Text>
                <Text style={styles.agentInfosText}>{formDatas?.phone ?? ''}</Text>
                <Text style={fontStyles.cOrpi}>{formDatas?.email ?? ''}</Text>
              </>
            )}
          </View>
        </View>
      </View>
      <Footer
        agency={agencyDatas?.agency ?? null}
        formDatas={formDatas}
        isOrpi={isOrpi}
      />
    </Page>
  );
}

export default StudyFolderPage;
1;

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '841px',
    right: 0,
  },
  page: {
    paddingHorizontal: '30px',
    fontFamily: 'OrpiFamily',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: '35px',
  },
  titleAndAddress: { marginTop: '20px' },
  leadAddressContainer: {
    marginTop: '15px',
  },
  imageContainer: {
    height: 300,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
  customerAndAgentContainer: {
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  customerContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  customerList: {
    width: '30%',
  },
  agentContainer: {
    width: '50%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  agentImgContainer: {
    width: 150,
    height: 180,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  agentImg: { width: '100%' },
  agentInfos: { marginLeft: '20px' },
  agentInfosText: { paddingVertical: '1px' },
});
