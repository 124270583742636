import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../App/store';
import { InternalError } from '../../../services/axiosFiles/axiosUtils';
import { postEntityNew } from '../../../services/axiosFiles/genericCrud';
import { GenericModalEnum } from '../../../shared/components/modals/genericModal/GenericModal';
import { classicErrorMessage } from '../../../shared/errorMessages';
import { authActions } from '../../auth/authSlice';
import { loadersActions } from '../../loaders/loaderSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { companyUserApiToStoreParser, userApiToStoreParser } from '../usersParser';
import {
  companyUserUpdate,
  fetchCompanyUser,
  fetchUsers,
  userUpdate,
} from './usersRequest';

/**
 * Fetch all users of one company and update user store
 * @param {string} companyIdIri - Company id
 * @returns {Users} array of users
 */
const fetchUsersThunk = createAsyncThunk(
  'users/fetchUsersThunk',
  async (params: { companyIdIri: string | null }) => {
    try {
      if (!params.companyIdIri) throw new Error(classicErrorMessage.noCompanyId);

      const result = await fetchUsers(params.companyIdIri);

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
/**
 * Create user datas and add it in the user store
 * @param {string} companyIdIri - company hydra idIri in witch user must be added
 * @param {Pick<User,'firstName' | 'lastName' | 'email' | 'plainPassword' | 'roles'>} user  -  user values
 * @returns {User} - user created
 */
const userCreateThunk = createAsyncThunk(
  'users/createUserThunk',
  async (
    params: {
      user: Pick<
        User,
        'firstName' | 'lastName' | 'email' | 'plainPassword' | 'roles'
      > | null;
      companyIdIri: string | null;
    },
    { dispatch }
  ) => {
    const { user, companyIdIri } = params;

    if (user && companyIdIri) {
      try {
        dispatch(loadersActions.loaderShow());

        // create user without company
        const result = await postEntityNew({
          endpoint: `/users`,
          body: { ...user, isActive: true },
        });
        const parsedUser = await userApiToStoreParser(result);

        // add user to selected company
        await postEntityNew({
          endpoint: `/company_users`,
          body: {
            company: companyIdIri,
            user: parsedUser.idIri,
            roles: parsedUser.roles,
          },
        });

        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return parsedUser;
      } catch (error) {
        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return Promise.reject(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());
      return Promise.reject({
        status: 422,
        message: 'comanyIdIri param missing or user value missing',
      });
    }
  }
);
/**
 * Update user datas and replace it in the user store
 * @param {string} userIdIri - user hydra id
 * @param {Pick<User, 'firstName' | 'lastName' | 'email'> | Pick<User, 'plainPassword'>} user  - some user values to update
 * @returns {User} - user updated
 */
const userUpdateThunk = createAsyncThunk(
  'users/updateUserThunk',
  async (
    params: {
      userIdIri: string;
      user:
        | Pick<User, 'firstName' | 'lastName' | 'email'>
        | Pick<User, 'plainPassword'>;
    },
    { dispatch }
  ) => {
    const { userIdIri, user } = params;

    if (user && userIdIri) {
      try {
        const result = await userUpdate(userIdIri, user);

        dispatch(
          modalsActions.addUserResult({
            isEdit: true,
            isOpen: true,
          })
        );

        return {
          user: result,
          isPwdUpdate: Boolean((params.user as any).plainPassword),
        };
      } catch (error) {
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return Promise.reject(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());

      return Promise.reject({
        status: 422,
        message: 'user value missing',
      });
    }
  }
);
/**
 * Fetch user datas for this company
 * @param {number} userId  - user id
 * @param {number} companyId - compny id
 * @param {boolean?} isAuth - true if auth user rights must be updated
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
const fetchCompanyUserThunk = createAsyncThunk(
  'user/fetchCompanyUserThunk',
  async (
    params: { userId: number; companyId: number; isAuth?: boolean },
    { dispatch }
  ) => {
    try {
      const result = await fetchCompanyUser(params.userId, params.companyId);

      if (params.isAuth) {
        dispatch(authActions.updateRightAccess(result));
      }
      return result;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
);
/**
 * Update user role or isActive datas for this company
 * @param {string} idIri  - user idIri
 * @param {string} companyIdIri - compny idIri
 * @param {?UserRoleTypes} roles - user role array
 * @param {?boolean} userIsActive - isActive user value
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
const companyUserUpdateThunk = createAsyncThunk(
  'user/updateCompanyUser',
  async (params: Omit<IUpdateCompanyUserProps, 'companyIdIri'>, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;

      const response: UserUpdateResponse = await companyUserUpdate({
        ...params,
        companyIdIri,
      });

      const parsed = companyUserApiToStoreParser(response);

      return parsed;
    } catch (error) {
      thunkOptions.dispatch(
        modalsActions.alert({
          status: true,
          context: 'updateCompanyUserError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export {
  companyUserUpdateThunk,
  fetchCompanyUserThunk,
  fetchUsersThunk,
  userCreateThunk,
  userUpdateThunk,
};
