import { useFormContext } from 'react-hook-form';
import styles from '../styles/prospecForm.module.scss';

interface IProps {
  id: string;
  adType: SimilarAdsType;
}
function SimilaryAdCheckbox({ adType, id }: IProps) {
  const { watch, setValue } = useFormContext();
  const ads = watch(`${adType}Ads`) as string[];

  const checked = () => {
    return ads?.includes(id);
  };

  const onChange = () => {
    if (ads.includes(id)) {
      setValue(
        `${adType}Ads`,
        ads.filter((f) => f !== id)
      );
    } else {
      if (ads.length <= 2) {
        setValue(`${adType}Ads`, ads.concat(id));
      }
    }
  };
  return (
    <input
      type="checkbox"
      checked={checked()}
      onChange={onChange}
      className={styles.adCheckbox}
    />
  );
}

export default SimilaryAdCheckbox;
