import { delay } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { authActions, getAuthState } from '../../authSlice';
import { fetchAuthUserThunk } from '../../authThunks';
import {
  ConnectionWorkFlowEnum,
  UserProcessStepEnum,
} from '../../utils/connexionProcessEnums';
import AuthError from './AuthError';
import { CONNEXION_PROCESS_DELAY } from './ConnectionProcessContent';
import { useConnectionProcessContext } from './ConnextionProcessContext';
import styles from './connectionProcess.module.scss';

const displayLoader = (step: UserProcessStepEnum) => {
  let text = '';
  switch (step) {
    case UserProcessStepEnum.PROCESS_INIT:
      text = "Initialisation de l'utilisateur";
      break;
    case UserProcessStepEnum.DATAS_PENDING:
      text = "Chargement de l'utilisateur";
      break;
    default:
      break;
  }

  return (
    <>
      {text !== '' && (
        <>
          <h3>{text}</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
    </>
  );
};

function UserProcess() {
  const { decodedToken, setWorkflowState, handleErrorClick, processDatas } =
    useConnectionProcessContext();
  const { apiStatus, user, error } = useAppSelector(getAuthState);
  const [internalStep, setInternalStep] = useState<UserProcessStepEnum>(
    UserProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      setInternalStep(UserProcessStepEnum.DATAS_FULFILLED);
    } else {
      switch (apiStatus) {
        case APIStatus.PENDING:
          setInternalStep(UserProcessStepEnum.DATAS_PENDING);
          break;
        case APIStatus.REJECTED:
          setInternalStep(UserProcessStepEnum.DATAS_REJECTED);
          break;
        default:
          break;
      }
    }
  }, [apiStatus, user]);

  useEffect(() => {
    switch (internalStep) {
      case UserProcessStepEnum.IDLE: {
        if (decodedToken) {
          setInternalStep(UserProcessStepEnum.PROCESS_INIT);
        } else {
          setInternalStep(UserProcessStepEnum.ERROR_TOKEN);
        }
        break;
      }
      case UserProcessStepEnum.PROCESS_INIT: {
        delay(() => {
          dispatch(fetchAuthUserThunk({ userIdIri: decodedToken?.userIdIri }));
        }, CONNEXION_PROCESS_DELAY);

        break;
      }

      case UserProcessStepEnum.DATAS_PENDING: {
        break;
      }
      case UserProcessStepEnum.DATAS_FULFILLED: {
        if (!user?.isActive) {
          setInternalStep(UserProcessStepEnum.ERROR_INACTIVE_USER);
        } else {
          setInternalStep(UserProcessStepEnum.END_PROCESS);
        }

        break;
      }
      case UserProcessStepEnum.DATAS_REJECTED: {
        switch (error?.status) {
          case 401:
            setInternalStep(UserProcessStepEnum.ERROR_NOT_AUTHORIZED);
            break;
          case 403:
            setInternalStep(UserProcessStepEnum.ERROR_FORBIDDEN);
            break;
          case 404:
            setInternalStep(UserProcessStepEnum.ERROR_NOT_FOUND);
            break;
          default:
            setInternalStep(UserProcessStepEnum.ERROR_SERVER);
            break;
        }

        break;
      }
      case UserProcessStepEnum.END_PROCESS: {
        dispatch(authActions.setIsLogged(true));
        setWorkflowState(ConnectionWorkFlowEnum.START_FETCH_ANNOUNCEMENT_PROCESS);
        break;
      }
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div className={styles.userProcess}>
      {displayLoader(internalStep)}
      {(internalStep === UserProcessStepEnum.DATAS_FULFILLED ||
        internalStep === UserProcessStepEnum.END_PROCESS) && (
        <p className={styles.hiUser} data-cy="welcome-message">
          Bonjour <span>{user?.fullName}</span>
        </p>
      )}

      {internalStep === UserProcessStepEnum.ERROR_TOKEN && (
        <AuthError
          title="Token de connexion defectueux"
          lines={[
            "L'application va être réinitialisée.",
            'Vous devrez vous reconnecter',
          ]}
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}
      {internalStep === UserProcessStepEnum.ERROR_INACTIVE_USER && (
        <AuthError
          title="Utilisateur désactivé"
          lines={[
            'Cet utilisateur est désactivé',
            'Contactez le manager de la compagnie.',
          ]}
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}

      {internalStep === UserProcessStepEnum.ERROR_NOT_FOUND && (
        <AuthError
          title="Utilisateur non trouvé"
          lines={[
            "L'utilisateur n'a pas été trouvé.",
            'Vous devez vous reconnecter.',
            'Si le problème persiste, veuillez contacter urbanease.',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}

      {internalStep === UserProcessStepEnum.ERROR_NOT_AUTHORIZED && (
        <AuthError
          title="Accès non autorisé"
          lines={[
            "Vous n'avez pas l'autorisation de charger les données de cet utilisateur.",
            'Ou celui-ci est désactivé.',
            'Pour toutes questions :',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}

      {internalStep === UserProcessStepEnum.ERROR_FORBIDDEN && (
        <AuthError
          title="Accès interdit"
          lines={["Vous n'avez pas accès aux données de cet utilisateur."]}
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}

      {internalStep === UserProcessStepEnum.ERROR_SERVER && (
        <AuthError
          title="Erreur serveur"
          lines={[
            'Une erreur technique est survenue.',
            'Veuillez contacter urbanease.',
            `message : ${error?.message}.`,
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}
    </div>
  );
}

export default memo(UserProcess);
