import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { ERROR_NO_DOM_AND_CORSE_DATAS } from '../../../../shared/constants';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import styles from '../styles/prospecForm.module.scss';
import useProspecDatas from '../useProspecDatas';
import PriceStatSection from './PriceStatSection';
import { IProspecPriceStatsProps } from './ProspecPriceStats';

interface IProps extends IProspecPriceStatsProps {
  error: 'min' | 'max' | 'fork' | null;
}

function ProspecPriceStatsForks({ stats, error }: IProps) {
  const { adsStats } = useProspecDatas();
  const loading = adsStats.apiStatus === APIStatus.PENDING;

  return (
    <div>
      <div className={styles.stateContainer}>
        {loading ? (
          <IndividualCircularLoader size={60} />
        ) : (
          <PriceStatSection
            title="Prix minimum"
            name="minSellPrice"
            error={error === 'min' || error === 'fork'}
          />
        )}
        {loading ? (
          <IndividualCircularLoader size={50} />
        ) : (
          <PriceStatSection
            title="Prix moyen"
            name="avgSellPrice"
            displaySqm={adsStats.result?.sellAveragePriceArea}
            disabled
          />
        )}
        {loading ? (
          <IndividualCircularLoader size={50} />
        ) : (
          <PriceStatSection
            title="Prix maximum"
            name="maxSellPrice"
            error={error === 'max' || error === 'fork'}
          />
        )}
      </div>
      {adsStats.error?.message === ERROR_NO_DOM_AND_CORSE_DATAS && (
        <p className={styles.errorMessage}>
          Les données Provenant de DOM ou Corse ne sont pas encore disponibles.
        </p>
      )}
      {error === 'fork' && (
        <p className={styles.errorMessage}>
          La fourchette minimum est supérieure à la fourchette maximum. <br />
          Modifiez les valeurs.
        </p>
      )}
      {!stats && adsStats.apiStatus !== APIStatus.PENDING && (
        <p className={styles.errorMessage}>
          En l’absence de données de marché pour des biens comparables sur cette
          commune, nous ne pouvons vous indiquer de prix. Attention à bien compléter
          les champs ci-dessus si vous souhaitez faire apparaître des valeurs sur
          votre document d’estimation.
        </p>
      )}
    </div>
  );
}

export default ProspecPriceStatsForks;
