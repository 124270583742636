import Cesium3DContainer from '../features/map/components/cesium/cesium3DContainer/Cesium3DContainer';
import ViewPageContainer from '../shared/components/viewPageContainer/ViewPageContainer';

function View3dPage() {
  return (
    <ViewPageContainer>
      <Cesium3DContainer />
    </ViewPageContainer>
  );
}

export default View3dPage;
