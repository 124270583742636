import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../../../../../App/store';
import SelectCustom from '../../../../../../../../../../shared/components/common/formComponents/SelectCustom';
import { PROSPECTION_PRIORITY_LIST } from '../../../../../../../../../../shared/constants';
import { studyActions } from '../../../../../../../../../study/slices/studySlice';
import { RecursiveSubFolderUpdatedElement } from '../../../../../../../../../study/utils/studyEnums';
import PrioritySelectContent from './PrioritySelectContent';

interface IProps {
  className?: string;
  value: number | string;
  onUpdate: (params: UpdateStudyManagmentProps) => void;
  dataCy?: string;
  displayEmpty?: boolean;
  displayLabel?: boolean;
  disabled?: boolean;
  noValuePriority?: boolean;
}

function PrioritySelect({
  className,
  value,
  onUpdate,
  dataCy,
  displayEmpty,
  displayLabel = true,
  disabled,
  noValuePriority,
}: IProps) {
  const [list, setList] = useState<SelectItems>([]);
  const [priority, setPriority] = useState<number | string>(
    noValuePriority ? 'none' : 0
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const l: {
      value: number | string;
      display: JSX.Element;
    }[] = [];

    if (noValuePriority)
      l.push({
        value: 'none',
        display: (
          <PrioritySelectContent
            element={{ value: 0, display: 'Absence de priorité' }}
          />
        ),
      });

    l.push(
      ...PROSPECTION_PRIORITY_LIST.map((m) => {
        return {
          value: m.value,
          display: <PrioritySelectContent element={m} />,
        };
      })
    );

    setList(l);
  }, []);

  useEffect(() => {
    setPriority(value);
  }, [value]);

  const handleChange = (e: ChangeEventCustom) => {
    const value = e.target.value;

    setPriority(value);
    dispatch(
      studyActions.setRecursiveSubFolderUpdatedElement(
        RecursiveSubFolderUpdatedElement.PRIORITY
      )
    );

    onUpdate({ priority: value });
  };

  return (
    <div className={className}>
      {displayLabel && <label>Priorité :</label>}
      <SelectCustom
        name="priority"
        items={list}
        displayEmpty={displayEmpty}
        value={priority}
        onChange={handleChange}
        dataCy={dataCy}
        disabled={disabled}
      />
    </div>
  );
}

export default PrioritySelect;
