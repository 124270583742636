import { useNavigate } from 'react-router';
import useProcedures from '../../../../procedures/hooks/useProcedures';
import styles from '../toolbars.module.scss';
import BellAlert from '../urbaToolbar/BellAlert';
import CreateToolbarBtn from '../urbaToolbar/CreateToolbarBtn';

function AccountMapBtn() {
  const { proceduresCount } = useProcedures();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/account');
  };

  return (
    <CreateToolbarBtn
      icon="ACCOUNT"
      onClick={handleClick}
      isFontAwesomeIcon
      tooltip={
        <p className={[styles.btnTooltip, styles.left].join(' ')}>
          Accéder à mon compte
        </p>
      }
      hasNotification={proceduresCount > 0}
      notification={<BellAlert />}
    />
  );
}

export default AccountMapBtn;
