import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../../../App/store';
import { PDF_URLS } from '../../../../../../shared/constants';
import { getAuthState } from '../../../../../auth/authSlice';
import useSubscriptionAccess from '../../../../../company/subscription/useSubscriptionAccess';
import { getPdfState } from '../../../../../externalPdf/pdfSlice';
import { getPanelState } from '../../../../../panels/panelsSlice';
import UrbaGptMapButton from '../../../../../urbaGpt/components/UrbaGptMapButton';
import CadastreButton from './CadastreButton';
import SatelliteViewButtons from './SatelliteViewButtons';
import ZoomButtons from './ZoomButtons';
import './rightBottom.scss';

export default function RightBottomButtons() {
  const { pdfRightPanelDisplay } = useAppSelector(getPdfState);
  const { isLogged } = useAppSelector(getAuthState);
  const { rightButtons } = useAppSelector(getPanelState);
  const { hasUrbaneaseAccess } = useSubscriptionAccess();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  return (
    <div
      className={`bottom-right-buttons ${
        pdfPath && pdfRightPanelDisplay && 'external-pdf-errial'
      }`}
    >
      {path === '/' && rightButtons && isLogged && hasUrbaneaseAccess && (
        <>
          <UrbaGptMapButton />
        </>
      )}

      <>
        <CadastreButton />
        <div>
          <ZoomButtons />
          <SatelliteViewButtons />
        </div>
      </>
    </div>
  );
}
