import { memo } from 'react';
import { GeoJSON } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import { getMapState } from '../../mapSlice';
import { frJson } from './frGeo/fr';

const jsonStyle = {
  fillColor: 'blue',
  weight: 4,
  color: 'blue',
  fillOpacity: 0.2,
};

const FrancePolygon = memo((): JSX.Element => {
  const { franceLayerDisplay } = useAppSelector(getMapState);

  return (
    <>
      {franceLayerDisplay && (
        <div className="france">
          <GeoJSON style={jsonStyle} data={frJson} />
        </div>
      )}
    </>
  );
});

FrancePolygon.displayName = 'FrancePolygon';

export default FrancePolygon;
