import {
  fetchDataById,
  fetchDatas,
  updateEntity,
} from '../../../services/axiosFiles/genericCrud';
import { classicErrorMessage } from '../../../shared/errorMessages';
import {
  companyUserApiToStoreParser,
  userApiToStoreParser,
  usersApiToStoreParser,
} from '../usersParser';

/**
 * Fetch all users of one company
 * @param {string} companyIdIri - Company id
 * @returns {Users} array of users
 */
async function fetchUsers(companyIdIri: string) {
  try {
    // fetch users
    const result = await fetchDatas(`${companyIdIri}/users`);

    // parse users
    const parsedUsers = usersApiToStoreParser(result['hydra:member']);

    return parsedUsers;
  } catch (error) {
    return Promise.reject(error);
  }
}
/**
 * Fetch unique user datas
 * @param {string} userIdIri - user hydra id
 * @returns {User} - user datas
 */
async function fetchUserById(userIdIri: string) {
  return new Promise<User>((resolve, reject) => {
    const result = fetchDataById(userIdIri);

    return result.then(
      (response: any) => {
        try {
          // parse datas
          const parsedUser = userApiToStoreParser(response);

          return resolve(parsedUser);
        } catch (error) {
          return reject(error);
        }
      },
      (err) => {
        return reject(err);
      }
    );
  });
}
/**
 * update user datas
 * @param {string} userIdIri - user hydra id
 * @param {Partial<User>} user  - some user values to update
 * @returns {User} - user updated
 */
async function userUpdate(userIdIri: string, user: Partial<User>) {
  return new Promise<User>((resolve, reject) => {
    const result = updateEntity({ idIri: userIdIri, body: user });

    result.then(
      (response: any) => {
        try {
          // parse datas
          const parsedUser = userApiToStoreParser(response);

          return resolve(parsedUser);
        } catch (error) {
          return reject(error);
        }
      },
      (err) => {
        return reject(err);
      }
    );
  });
}
/**
 * Fetch user datas for this company
 * @param {number} userId  - user id
 * @param {number} companyId - compny id
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
async function fetchCompanyUser(userId: number, companyId: number) {
  try {
    if (!userId) throw new Error(classicErrorMessage.noUserId);
    if (!companyId) throw new Error(classicErrorMessage.noCompanyId);

    const url = `/company_users/company=${companyId};user=${userId}`;
    const response = await fetchDataById(url);

    const parsed = companyUserApiToStoreParser(response);
    return parsed as CompanyUser;
  } catch (error) {
    return Promise.reject(error);
  }
}
/**
 * Update user role or isActive datas for this company
 * @param {string} idIri  - user idIri
 * @param {string} companyIdIri - compny idIri
 * @param {?UserRoleTypes} roles - user role array
 * @param {?boolean} userIsActive - isActive user value
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
async function companyUserUpdate(params: IUpdateCompanyUserProps) {
  try {
    const { companyIdIri, roles, userIdIri, userIsActive } = params;

    if (!userIdIri) throw new Error(classicErrorMessage.noUserId);
    if (!companyIdIri) throw new Error(classicErrorMessage.noCompanyId);

    const response = await updateEntity({
      idIri: '/company_users',
      body: { user: userIdIri, company: companyIdIri, roles, userIsActive },
    });

    return response as UserUpdateResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  companyUserUpdate,
  fetchCompanyUser,
  fetchUserById,
  fetchUsers,
  userUpdate,
};
