import { useAppSelector } from '../../../App/store';
import { getAppState } from '../appSlice';

export default function useContactRoles() {
  const { contactRoles } = useAppSelector(getAppState);

  const getContactroleForSelect = () => {
    if (!contactRoles.result) {
      return [];
    }

    return contactRoles.result?.map((cr) => ({
      value: cr.idIri,
      display: cr.label,
    }));
  };

  return {
    contactRoles: contactRoles.result,
    contactRolesForSelect: getContactroleForSelect(),
  };
}
