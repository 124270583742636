import { nanoid } from '@reduxjs/toolkit';
import { CompanyProcessStepEnum } from '../../../auth/utils/connexionProcessEnums';
import './chooseCompanyModal.scss';

interface IChooseCompanyContentProps {
  companies: TokenCompanies;
  onCompanyChoice: (company: TokenCompany, step?: CompanyProcessStepEnum) => void;
}

function ChooseCompanyContent({
  companies,
  onCompanyChoice,
}: IChooseCompanyContentProps) {
  const handleCompanyClick = (company: TokenCompany) => {
    onCompanyChoice(company, CompanyProcessStepEnum.COMPANY_CHOSEN);
  };

  return (
    <div className="choose-company-modal">
      <h3>Choix de la compagnie</h3>
      <div className="company-list" data-cy="company-choice-content">
        {companies.map((c: TokenCompany, i: number) => (
          <button
            key={nanoid()}
            onClick={() => handleCompanyClick(c)}
            data-cy={`company-choice-btn-${i}`}
          >
            {c.name} {c.postalCode ? `( ${c.postalCode} )` : ''}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ChooseCompanyContent;
