import { Checkbox } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import pin1 from '../../../../assets/images/pin-1.svg';
import pin10 from '../../../../assets/images/pin-10.svg';
import pin2 from '../../../../assets/images/pin-2.svg';
import pin3 from '../../../../assets/images/pin-3.svg';
import pin4 from '../../../../assets/images/pin-4.svg';
import pin5 from '../../../../assets/images/pin-5.svg';
import pin6 from '../../../../assets/images/pin-6.svg';
import pin7 from '../../../../assets/images/pin-7.svg';
import pin8 from '../../../../assets/images/pin-8.svg';
import pin9 from '../../../../assets/images/pin-9.svg';

interface IFolderPinIconContainerProps {
  markerColor: string;
  onMarkerColorChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const folderMarkersColor = [
  '#10B2F7',
  '#1664DB',
  '#798E8A',
  '#E2DB13',
  '#E38F14',
  '#ED24F1',
  '#E21313',
  '#50D313',
  '#13E2BD',
  '#944BF2',
];

function FolderPinIconContainer({
  onMarkerColorChange,
  markerColor,
}: IFolderPinIconContainerProps) {
  let cnt = 0;
  return (
    <div className="pin-icons">
      {[pin1, pin2, pin3, pin4, pin5, pin6, pin7, pin8, pin9, pin10].map((p) => {
        cnt += 1;
        return (
          <div key={nanoid()} className={`pin-${cnt}`}>
            <img src={p} alt="icon" />
            <Checkbox
              className="check-icon"
              color="primary"
              size="small"
              onChange={onMarkerColorChange}
              value={folderMarkersColor[cnt - 1]}
              checked={markerColor === folderMarkersColor[cnt - 1]}
            />
          </div>
        );
      })}
    </div>
  );
}

export default FolderPinIconContainer;
