// import '../Prospection.scss';
import { memo } from 'react';
import { useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';

import { getMailshotsState } from '../../../features/mailshots/mailshotsSlice';
import { MailshotsSelectionTypeEnum } from '../../../features/mailshots/types/mailshotsEnums';
import AddFolderIcon from './AddFolderIcon';
import ArrowIcon from './ArrowIcon';
import EditIcon from './EditIcon';
import ExportIcon from './ExportIcon';
import EyeIcon from './EyeIconAction';
import GarbageIcon from './GarbageIcon';
import OptionsIcon from './OptionsIcon';
import styles from './itemActions.module.scss';
export interface IIconActionProps<T> {
  type: 'folder' | 'sector' | null;
  item: IFolderSector<T>;
}
interface IItemActionProps<T> {
  type: 'folder' | 'sector';
  item: IFolderSector<T>;
  isOpen: boolean;
  isDashboard?: boolean;
  mailshotActivated?: boolean;
  canOpen?: boolean;
}

const ItemActions = <T,>({
  type,
  item,
  isOpen,
  isDashboard,
  canOpen,
}: IItemActionProps<T>) => {
  const { userIdIri, isManager, isAdmin } = useAppSelector(getAuthState);
  const updateAllowed = item?.responsable?.idIri === userIdIri || isManager;
  const updateSectorAllowed = isManager || isAdmin;
  const { selectType } = useAppSelector(getMailshotsState);
  return (
    <div
      className={[styles.itemActions, canOpen ? '' : styles.rightPadding].join(' ')}
    >
      {type === 'folder' ? (
        <>
          {selectType === MailshotsSelectionTypeEnum.NONE ? (
            item.parent ? (
              <>
                {updateAllowed && <GarbageIcon type="folder" item={item} />}
                {updateAllowed && <EditIcon type="folder" item={item} />}
                <ExportIcon item={item} type={null} />
                <EyeIcon type="folder" item={item} />
              </>
            ) : (
              <>
                {updateAllowed && <GarbageIcon type="folder" item={item} />}
                {updateAllowed && <AddFolderIcon type="folder" item={item} />}
                <ExportIcon item={item} type={null} />
                {updateAllowed && <EditIcon type="folder" item={item} />}
              </>
            )
          ) : null}
          {canOpen && (
            <ArrowIcon
              type="folder"
              item={item}
              isOpen={isOpen}
              isDashboard={isDashboard}
            />
          )}
        </>
      ) : (
        <>
          {updateSectorAllowed && (
            <div className={styles.options}>
              <div className={styles.optionsIconContainer}>
                <EditIcon type="sector" item={item} />
                {!item.parent && <AddFolderIcon type="sector" item={item} />}
                <GarbageIcon type="sector" item={item} />
              </div>

              <OptionsIcon />
            </div>
          )}

          <EyeIcon type="sector" item={item} />
          {canOpen && (
            <ArrowIcon
              type="sector"
              item={item}
              isOpen={isOpen}
              isDashboard={isDashboard}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(ItemActions);
