import RightArrow from '../../../../../../assets/images/right-arrow-icon.png';
import './arrowTab.scss';

interface IArrowTab {
  label1: string;
  label2?: string;
  selected: boolean;
  onClick: () => void;
}

const ArrowTab = ({ label1, label2, selected, onClick }: IArrowTab): JSX.Element => {
  return (
    <div
      className={`tab ${selected ? 'selected' : ''}`}
      onClick={onClick}
      data-cy="arrow-tab"
    >
      {selected && <img src={RightArrow} alt="" />}

      <div className="menu-text">
        {label1} {label2 ? <br /> : ''} {label2 ?? ''}
      </div>
    </div>
  );
};

export default ArrowTab;
