import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import FilterSortBloc from '../../../../../shared/components/filterSortBloc/FilterSortBloc';
import { getAppState } from '../../../../app/appSlice';
import { fetchReportingIndividualUserPlotsExportExcel } from '../../../../dashboardReporting/dashboardReportingThunks';
import IndividualCircularLoader from '../../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import UserPlotStudiesTableContainer from './UserPlotStudiesTableContainer';
import { userPlotTableSortItems } from './constants';

interface IUserPlotsContainerProps {
  plots: PlotStudies | null;
  userFullName: string;
}
function UserPlotStudiesContainer({
  plots,
  userFullName,
}: IUserPlotsContainerProps) {
  const dispatch = useAppDispatch();
  const [sortKey, setSortKey] = useState<string>('id');
  const [order, setOrder] = useState<Order>('asc');
  const { plotStudyStatuses } = useAppSelector(getAppState);

  const handleDownload = () => {
    dispatch(
      fetchReportingIndividualUserPlotsExportExcel({
        plotStudies: plots,
        username: userFullName,
        plotStudyStatuses: plotStudyStatuses.result,
      })
    );
  };
  return (
    <>
      {!plots ? (
        <IndividualCircularLoader size={50} />
      ) : isEmpty(plots) ? (
        <p className="no-plot-studies p-max">Aucune étude liée à ce membre</p>
      ) : (
        <>
          <div className="collapse-table-action">
            <div className="collapse-table-action-filter">
              <div className="download-icon">
                <FontAwesomeIcon icon={faDownload} onClick={handleDownload} />
              </div>
              <FilterSortBloc
                setSortKey={setSortKey}
                setOrder={setOrder}
                selectItems={userPlotTableSortItems}
                selectPlaceHolder="Trier parcelles par"
              />
            </div>

            <UserPlotStudiesTableContainer
              sortKey={sortKey}
              order={order}
              plotStudies={plots}
            />
          </div>
        </>
      )}
    </>
  );
}

export default UserPlotStudiesContainer;
