import { Paper, Table, TableContainer } from '@mui/material';
import { isEmpty } from 'lodash';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../App/store';
import PaginationCustom from '../../../../shared/components/common/paginationCustom/PaginationCustom';
import { getBuildPermitsState } from '../../buildPermitsSlice';
import { genericSortBuildPermis } from '../../utils/genericSortBuildPermis';
import { PermitsTableContext } from './BuildPermisTable';
import TableContent from './TableBodyContent';
import TableHeader from './TableHeader';

const BuiltPermisTableBody = () => {
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [index, setIndex] = useState<number>(0);
  const [filteredBuildPermits, setFilteredBuildPermits] = useState<BuildPermits>([]);

  const { buildPermits, department } = useAppSelector(getBuildPermitsState);
  const { state } = useContext(PermitsTableContext);

  useEffect(() => {
    const result = genericSortBuildPermis(buildPermits.result ?? [], order, orderBy)
      ?.filter((row: BuildPermit) =>
        !isEmpty(state.inputSearch)
          ? row.postalAddress
              ?.toLowerCase()
              .includes(state.inputSearch.toLowerCase()) ||
            row.plotId?.toLowerCase().includes(state.inputSearch.toLowerCase()) ||
            row.autorisationNumero
              ?.toLowerCase()
              .includes(state.inputSearch.toLowerCase()) ||
            row.applicant?.toLowerCase().includes(state.inputSearch.toLowerCase())
          : true
      )
      ?.filter((row: BuildPermit) =>
        !isEmpty(state.inputAutorisationType)
          ? row.autorisationType === state.inputAutorisationType
          : true
      );

    setFilteredBuildPermits(result);
  }, [state, orderBy, order, itemsPerPage, index]);

  const handleChangeIndex = (index: number): void => {
    setIndex(index);
  };

  const handleItemsPerPageChange = (e: any) => {
    setItemsPerPage(e.target.value);
  };

  const handleRequestSort = (e: MouseEvent<unknown>, column: string) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  return (
    <Paper elevation={1} className="built-permis-body">
      <TableContainer>
        <Table>
          <TableHeader
            orderBy={orderBy}
            order={order}
            onRequestSort={handleRequestSort}
          />
          <TableContent
            filteredBuildPermits={
              filteredBuildPermits.filter(
                (p, i) => i >= index && i < index + itemsPerPage
              ) || []
            }
            department={department}
          />
        </Table>
      </TableContainer>

      <PaginationCustom
        itemPerPageLabel="Permis par page"
        elementsQty={filteredBuildPermits.length || 0}
        itemsPerPage={itemsPerPage}
        onMinIndexTab={handleChangeIndex}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Paper>
  );
};

export default BuiltPermisTableBody;
