import SelectFormCustom from '../../../../../shared/components/common/formComponents/SelectFormCustom';
import useUsers from '../../../../users/useUsers';

interface IContactSelectProps {
  name: string;
  className?: string;
  label?: nullable<string>;
  disabled?: boolean;
}

export default function UserSelect({
  name,
  className,
  label,
  disabled,
}: IContactSelectProps) {
  const { usersForSelect } = useUsers({});
  return (
    <SelectFormCustom
      name={name}
      className={className}
      displayEmpty
      label={label}
      noValue="Choisir un responsable"
      items={usersForSelect}
      disabled={disabled}
    />
  );
}
