import { memo } from 'react';
import Logo from '../../../../../../assets/images/logo_urbanease_vertical.svg';
import useRightPanel from '../../../../hooks/useRightPanel';
import { menuTabsLibelles } from './constants';

interface IMenuTabsHeaderProps {
  isCollapsed: boolean;
  onSelectedTab: (x: string) => void;
  menuTab: string;
}

function MenuTabsHeader({
  isCollapsed,
  onSelectedTab,
  menuTab,
}: IMenuTabsHeaderProps) {
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();

  const handleSelectedTab = (libelle: string) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }

    onSelectedTab(libelle);
  };

  return (
    <>
      {isCollapsed && (
        <div className={`tab-btn`} onClick={() => onSelectedTab(menuTab)}>
          <div className="icon-urba">
            <img src={Logo} alt="icon" />
          </div>
        </div>
      )}
      {menuTabsLibelles.map((m) => (
        <div
          key={m.libelle}
          className={`tab-btn ${
            menuTab === m.libelle && !isCollapsed
              ? 'selected'
              : menuTab === m.libelle && isCollapsed && 'normal'
          }`}
          onClick={() => handleSelectedTab(m.libelle)}
          data-cy="menu-tab"
        >
          <div className="icon">
            <img src={m.icon} alt="icon" />
          </div>
          <div className="label">{m.libelle}</div>
        </div>
      ))}
    </>
  );
}

export default memo(MenuTabsHeader);
