import MapPluginHelperModal from '../../plugins/mapPluginHelperModal/MapPluginHelperModal';

const CesiumHelperModal = (): JSX.Element => {
  return (
    <MapPluginHelperModal>
      <ul>
        <li>Cliquez sur la carte pour déplacer le repère Street View</li>
        <li>
          Lorsque vous cliquerez sur l’onglet “Vue Street View” vous serez positionné
          à cet emplacement
        </li>
      </ul>
    </MapPluginHelperModal>
  );
};

export default CesiumHelperModal;
