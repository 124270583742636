import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { pageStyles } from '../../shared/styles/pdfStyle';
import { SocioEconomicModeEnum } from '../../shared/types/enums';
import Footer from './components/Footer';
import Header from './components/Header';
import HorizontalBarChart from './components/HorizontalBarChart';
import { fontStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  socioEconomic: PDFLandSocioEconomic[];
  unemploymentRate: string;
  activePopulation: string;
  formDatas: IExternalPdfLandForm;
}

function SocioEconomicDataPage({
  agency,
  socioEconomic,
  unemploymentRate,
  activePopulation,
  formDatas,
}: IProps) {
  const age =
    socioEconomic.find((elt) => elt?.subcategory === SocioEconomicModeEnum['AGE']) ??
    null;
  const professions =
    socioEconomic.find(
      (elt) => elt?.subcategory === SocioEconomicModeEnum['PROFESSIONS']
    ) ?? null;
  const headerLines = ['Votre bien', '... et son environnement.'];

  return (
    <Page size="A4" style={[pageStyles.page, fontStyles.ffOrpi]}>
      <Header lines={headerLines} isOrpi />

      <Text style={styles.title}>Données socio-économiques</Text>

      <View style={styles.barChartContainer}>
        {age && (
          <HorizontalBarChart
            data={
              age.items as {
                label: string;
                percentage: number;
              }[]
            }
            title={`Groupes d'âge (${socioEconomic[0]?.year})`}
            city={formDatas.addressCity}
          />
        )}
        {professions && (
          <HorizontalBarChart
            data={
              professions.items as {
                label: string;
                percentage: number;
              }[]
            }
            title={`Catégorie socio-professionnelle (${socioEconomic[0]?.year})`}
            city={formDatas.addressCity}
          />
        )}
      </View>

      <View style={styles.lineChartContainer}>
        <View>
          <Text style={styles.chartTitle}>Taux de chômage</Text>
          <Image src={unemploymentRate} style={styles.graph} />
          <View style={styles.legend}>
            <View style={styles.legendColor} />
            <Text>{formDatas.addressCity} (Commune)</Text>
          </View>
        </View>
        <View>
          <Text style={styles.chartTitle}>Population active</Text>
          <Image src={activePopulation} style={styles.graph} />
          <View style={styles.legend}>
            <View style={styles.legendColor} />
            <Text>{formDatas.addressCity} (Commune)</Text>
          </View>
        </View>
      </View>

      <Footer agency={agency} formDatas={formDatas} isOrpi />
    </Page>
  );
}

export default SocioEconomicDataPage;

const styles = StyleSheet.create({
  title: {
    marginBottom: '15px',
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
  },
  barChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '70px',
  },
  chartTitle: {
    marginBottom: '10px',
    ...fontStyles.b700,
  },
  lineChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  graph: {
    width: '275px',
    height: '130px',
    marginVertical: '10px',
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '10px',
    ...fontStyles.fs10,
  },
  legendColor: {
    width: '10px',
    height: '10px',
    borderRadius: '25px',
    backgroundColor: 'rgb(6, 106, 190)',
    marginRight: '5px',
  },
});
