import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import extraReducerProspec from './extraReducerProspec';
import { fetchDatasForPdfGenerationThunk } from './services/thunks/errialPDF/fetchDatasForPdfGenerationThunk';
import { fetchPlotForMultiPlotPdfThunk } from './services/thunks/fetchPlotForMultiPlotPdfThunk';
import { fetchPlotServitudesThunk } from './services/thunks/fetchPlotServitudesThunk';
import { fetchSweepbrightPdfDataThunk } from './services/thunks/fetchSweepbrightPdfDataThunk';

const initialProspecDataState: PdfProspecDatas = {
  prospecCityId: { apiStatus: APIStatus.IDLE, result: null },
  area: null,
  landType: null,
  adsStats: { apiStatus: APIStatus.IDLE, result: null },
  comparableAds: { apiStatus: APIStatus.IDLE, result: null },
};
const initialState: externalPdfErrialState = {
  sweepBrightExternalId: null,
  pricehubbleId: null,
  phAccessToken: null,
  sweepbrightData: { apiStatus: APIStatus.IDLE, result: null },
  pdfRightPanelDisplay: true,
  pdfFormData: null,
  pricehubbleFolderDatas: { apiStatus: APIStatus.IDLE, noData: true, result: null },
  pricehubbleDatas: { apiStatus: APIStatus.IDLE, result: null },
  prospecDatas: initialProspecDataState,
  isMultiPlotSelectorPdf: false,
  multiPlotsPdf: { apiStatus: APIStatus.IDLE, result: null },
  plotCountPdf: 0,
  datasForPdfGeneration: { apiStatus: APIStatus.IDLE, result: null },
  servitudes: { apiStatus: APIStatus.IDLE, result: null },
  infoModalDisplay: {
    display: false,
    type: null,
    plotIds: null,
    errorMessage: null,
  },
  forceGenerate: false,
  clicked: false,
  unemploymentRateChart: '',
  activePopulationChart: '',
  avgPriceChart: '',
  avgPriceAreaChart: '',
  currentInsee: '',
};

const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    reset: () => initialState,
    pdfGenerationReset: (state) => {
      state.clicked = false;
      state.infoModalDisplay = {
        display: false,
        type: null,
        plotIds: null,
        errorMessage: null,
      };
      state.servitudes = { apiStatus: APIStatus.IDLE, result: null };
      state.datasForPdfGeneration = { apiStatus: APIStatus.IDLE, result: null };
      state.forceGenerate = false;
    },
    setClicked: (state, action: PayloadAction<boolean>) => {
      state.clicked = action.payload;
    },
    setForceGenerate: (state, action: PayloadAction<boolean>) => {
      state.forceGenerate = action.payload;
      state.clicked = true;
    },
    setConnectionId: (
      state,
      action: PayloadAction<
        Pick<IConnectionProcessQueries, 'token' | 'phId' | 'sbId'>
      >
    ) => {
      state.pricehubbleId = action.payload.phId;
      state.sweepBrightExternalId = action.payload.sbId;
      state.phAccessToken = action.payload.token;
    },
    setInfoModalDisplay: (state, action: PayloadAction<ErrialInfoModalType>) => {
      state.infoModalDisplay = action.payload;
    },
    resetInfoModalDisplay: (state) => {
      state.forceGenerate = false;
      state.infoModalDisplay = {
        display: false,
        type: null,
        plotIds: null,
        errorMessage: null,
      };
    },
    setSweepbrightData: (state, action: PayloadAction<ISweepbrightData | null>) => {
      state.sweepbrightData.result = action.payload;
    },
    setPdfRightPanelDisplay: (state, action: PayloadAction<boolean>) => {
      state.pdfRightPanelDisplay = action.payload;
    },
    setPdfFormData: (
      state,
      action: PayloadAction<IExternalPdfErrialForm | null>
    ) => {
      state.pdfFormData = action.payload;
    },
    setPdfLandFormData: (
      state,
      action: PayloadAction<IExternalPdfLandForm | null>
    ) => {
      state.pdfFormData = action.payload;
    },
    setIsMultiPlotSelectorPdf: (state, action: PayloadAction<boolean>) => {
      if (action.payload === false) {
        state.plotCountPdf = 0;
        state.multiPlotsPdf = { apiStatus: APIStatus.IDLE, result: null };
      }
      state.isMultiPlotSelectorPdf = action.payload;
    },
    removeMultiPlotsPdfFromSelection: (state, action: PayloadAction<number>) => {
      state.multiPlotsPdf.result =
        state.multiPlotsPdf.result
          ?.filter((f) => f.index !== action.payload)
          .map((m, i) => ({ ...m, index: i })) ?? null;
      state.plotCountPdf = state.plotCountPdf - 1;
    },
    setPricehubbleFolderInfosDatas: (
      state,
      action: PayloadAction<{
        apiStatus: APIStatus;
        noData: boolean;
        result: PDFLandDeal | null;
      }>
    ) => {
      state.pricehubbleFolderDatas = action.payload;
      if (action.payload.apiStatus === APIStatus.REJECTED) {
        state.infoModalDisplay = {
          display: true,
          type: 'errorPhFolder',
          plotIds: null,
          errorMessage: null,
        };
      }
    },
    setPricehubbleDatas: (
      state,
      action: PayloadAction<PDFLandPricehubbleDataType>
    ) => {
      state.pricehubbleDatas.result = action.payload;
    },
    setUnemploymentRateChart: (state, action: PayloadAction<string>) => {
      state.unemploymentRateChart = action.payload;
    },
    setActivePopulationChart: (state, action: PayloadAction<string>) => {
      state.activePopulationChart = action.payload;
    },
    setAvgPriceChart: (state, action: PayloadAction<string>) => {
      state.avgPriceChart = action.payload;
    },
    setAvgPriceAreaChart: (state, action: PayloadAction<string>) => {
      state.avgPriceAreaChart = action.payload;
    },
    prospecDataReset: (state) => {
      state.prospecDatas = initialProspecDataState;
    },
    setCurrentInsee: (state, action: PayloadAction<string>) => {
      state.currentInsee = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSweepbrightPdfDataThunk.pending, (state) => {
        state.sweepbrightData.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchSweepbrightPdfDataThunk.fulfilled,
        (state, action: PayloadAction<ISweepbrightData>) => {
          state.sweepbrightData.apiStatus = APIStatus.FULFILLED;
          state.sweepbrightData.result = action.payload ?? [];
        }
      )
      .addCase(fetchSweepbrightPdfDataThunk.rejected, (state, action) => {
        state.sweepbrightData.apiStatus = APIStatus.REJECTED;
        state.sweepbrightData.result = null;
        state.sweepbrightData.error = action.error;
        state.infoModalDisplay = {
          display: true,
          type: 'errorSweepbright',
          plotIds: null,
          errorMessage: null,
        };
      });

    builder
      .addCase(fetchPlotForMultiPlotPdfThunk.pending, (state) => {
        state.multiPlotsPdf.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPlotForMultiPlotPdfThunk.fulfilled,
        (state, action: PayloadAction<Omit<IMultiPlotsPdf, 'index'> | null>) => {
          state.multiPlotsPdf.apiStatus = APIStatus.IDLE;
          state.multiPlotsPdf.error = undefined;
          if (action.payload) {
            const isPlotExists = state.multiPlotsPdf.result?.find(
              (f) => f.fullPlotId === action.payload?.fullPlotId
            );

            if (!isPlotExists) {
              state.multiPlotsPdf.result = state.multiPlotsPdf.result
                ? state.multiPlotsPdf.result.concat({
                    ...action.payload,
                    index: state.multiPlotsPdf.result.length,
                  })
                : [
                    {
                      ...action.payload,
                      index: 0,
                    },
                  ];

              state.plotCountPdf = state.plotCountPdf + 1;
            }
          } else {
            state.infoModalDisplay = {
              display: true,
              type: 'plotNotFound',
              plotIds: null,
              errorMessage: null,
            };
          }
        }
      )
      .addCase(
        fetchPlotForMultiPlotPdfThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.multiPlotsPdf.apiStatus = APIStatus.REJECTED;
          state.multiPlotsPdf.error = action.payload;
        }
      );

    builder
      .addCase(fetchDatasForPdfGenerationThunk.pending, (state) => {
        state.datasForPdfGeneration.apiStatus = APIStatus.PENDING;
        state.datasForPdfGeneration.result = null;
        state.servitudes.result = null;
      })
      .addCase(
        fetchDatasForPdfGenerationThunk.fulfilled,
        (state, action: PayloadAction<DatasForPdfGeneration>) => {
          state.datasForPdfGeneration.result = action.payload;
          state.datasForPdfGeneration.apiStatus = APIStatus.IDLE;
          state.datasForPdfGeneration.error = undefined;
        }
      )
      .addCase(fetchDatasForPdfGenerationThunk.rejected, (state, action) => {
        state.datasForPdfGeneration.apiStatus = APIStatus.REJECTED;
        state.datasForPdfGeneration.error = action.error;
      });
    // servitudes
    builder
      .addCase(fetchPlotServitudesThunk.pending, (state) => {
        state.servitudes.apiStatus = APIStatus.PENDING;
        state.servitudes.result = null;
        state.servitudes.error = undefined;
      })
      .addCase(
        fetchPlotServitudesThunk.fulfilled,
        (state, action: PayloadAction<OrpiServitudes>) => {
          state.servitudes.apiStatus = APIStatus.IDLE;
          state.servitudes.result = action.payload;
          state.servitudes.error = undefined;
        }
      )
      .addCase(fetchPlotServitudesThunk.rejected, (state, action) => {
        state.servitudes.apiStatus = APIStatus.REJECTED;
        state.servitudes.result = {
          ponctPresc: null,
          linPresc: null,
          infoSurf: null,
          infoPonc: null,
          servOth: null,
          scot: null,
        };
        state.servitudes.error = action.error;
      });

    // prospec requests
    extraReducerProspec(builder);
  },
});

export default pdfSlice.reducer;
export const pdfActions = pdfSlice.actions;
export const getPdfState = (state: RootState) => state.pdf;
